///-- THIS FILE IS GENERATED --///
export enum Flex {

    LG_FLEX_1 = "lg:pinata-flex-1",

    LG_FLEX_AUTO = "lg:pinata-flex-auto",

    LG_FLEX_INITIAL = "lg:pinata-flex-initial",

    LG_FLEX_NONE = "lg:pinata-flex-none",

    MD_FLEX_1 = "md:pinata-flex-1",

    MD_FLEX_AUTO = "md:pinata-flex-auto",

    MD_FLEX_INITIAL = "md:pinata-flex-initial",

    MD_FLEX_NONE = "md:pinata-flex-none",

    SM_FLEX_1 = "sm:pinata-flex-1",

    SM_FLEX_AUTO = "sm:pinata-flex-auto",

    SM_FLEX_INITIAL = "sm:pinata-flex-initial",

    SM_FLEX_NONE = "sm:pinata-flex-none",

    XL_FLEX_1 = "xl:pinata-flex-1",

    XL_FLEX_AUTO = "xl:pinata-flex-auto",

    XL_FLEX_INITIAL = "xl:pinata-flex-initial",

    XL_FLEX_NONE = "xl:pinata-flex-none",

    XS_FLEX_1 = "xs:pinata-flex-1",

    XS_FLEX_AUTO = "xs:pinata-flex-auto",

    XS_FLEX_INITIAL = "xs:pinata-flex-initial",

    XS_FLEX_NONE = "xs:pinata-flex-none",

    FLEX_1 = "pinata-flex-1",

    FLEX_AUTO = "pinata-flex-auto",

    FLEX_INITIAL = "pinata-flex-initial",

    FLEX_NONE = "pinata-flex-none",

}