import * as React from 'react';
import { useEffect, useState } from 'react';

import { LoaderTextMode } from './LoaderEnums';
import { Timer } from '../../utils';
import { shuffle } from "lodash-es";

export interface ILoaderTextProps {
    text: string | string[];
    textDuration?: number;
    textMode?: LoaderTextMode;
}

const styleClass = {
    text: 'pinata-loader__text',
};

export const LoaderText: React.FunctionComponent<ILoaderTextProps> = ({text, textDuration, textMode}) => {

    let timeoutId: number;
    let i = 0;

    const textsArray: string[] = Array.isArray(text) ? text : [text];
    const randomOrder = textMode === LoaderTextMode.LOOP_RANDOM || textMode === LoaderTextMode.SEQUENCE_RANDOM;
    const texts = randomOrder ? shuffle(textsArray) : textsArray;

    const [textIndex, setTextIndex] = useState<number>(0);

    useEffect(() => {
        if (text.length > 1) {
            if (textMode !== LoaderTextMode.STATIC) {
                timeoutId = Timer.setInterval(() => {
                    if (textMode === LoaderTextMode.LOOP || textMode === LoaderTextMode.LOOP_RANDOM) {
                        i = (i + 1) % text.length;
                    } else if (textMode === LoaderTextMode.SEQUENCE || textMode === LoaderTextMode.SEQUENCE_RANDOM) {
                        i = Math.min(i + 1, text.length - 1);
                    }
                    setTextIndex(i);

                }, textDuration || 2000);

                return () => {
                    Timer.clearInterval(timeoutId);
                };
            }
        }
    }, [textMode]);

    return (
        <div className={styleClass.text}>
            {texts[textIndex]}
        </div>
    );
};
LoaderText.defaultProps = {
    textMode: LoaderTextMode.LOOP,
    text: "Vennligst vent",
    textDuration: 2000,
};
LoaderText.displayName = "LoaderText";
