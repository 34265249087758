import * as React from 'react';
import { classNames } from "../../utils";
import isArray from "lodash-es/isArray";

export interface GridItemProps extends React.HTMLAttributes<HTMLDivElement> {
    span?: string | string[];
    offset?: string | string[];
}

export const GridItem: React.FunctionComponent<GridItemProps> = (props) => {

    const styleClass = {
        root: 'pinata-grid__item',
        span: (span: string | string[]) => isArray(span) ? span.map(s => `pinata-grid--span-${s}`) : `pinata-grid--span-${span}`,
        offset: (offset: string | string[]) => isArray(offset) ? offset.map(s => `pinata-grid--offset-${s}`) : `pinata-grid--offset-${offset}`,
    };

    const {span, offset, className, ...rest} = props;
    const classes = classNames(
        styleClass.root,
        span ? styleClass.span(span) : null,
        offset ? styleClass.offset(offset) : null,
        className
    );

    return (
        <div
            {...rest}
            className={classes}
        >
            {props.children}
        </div>
    );
};

GridItem.displayName = 'GridItem';
