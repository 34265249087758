///-- THIS FILE IS GENERATED --///
export enum TextColor {

    HOVER_TEXT_BLACK = "hover:pinata-text-black",

    HOVER_TEXT_HEADER = "hover:pinata-text-header",

    HOVER_TEXT_INFO = "hover:pinata-text-info",

    HOVER_TEXT_NEGATIVE = "hover:pinata-text-negative",

    HOVER_TEXT_NEUTRAL_1 = "hover:pinata-text-neutral-1",

    HOVER_TEXT_NEUTRAL_2 = "hover:pinata-text-neutral-2",

    HOVER_TEXT_NEUTRAL_3 = "hover:pinata-text-neutral-3",

    HOVER_TEXT_NEUTRAL_4 = "hover:pinata-text-neutral-4",

    HOVER_TEXT_NEUTRAL_5 = "hover:pinata-text-neutral-5",

    HOVER_TEXT_NEUTRAL_6 = "hover:pinata-text-neutral-6",

    HOVER_TEXT_NEUTRAL_7 = "hover:pinata-text-neutral-7",

    HOVER_TEXT_NEUTRAL_8 = "hover:pinata-text-neutral-8",

    HOVER_TEXT_POSITIVE = "hover:pinata-text-positive",

    HOVER_TEXT_PRIMARY_1 = "hover:pinata-text-primary-1",

    HOVER_TEXT_PRIMARY_2 = "hover:pinata-text-primary-2",

    HOVER_TEXT_PRIMARY_3 = "hover:pinata-text-primary-3",

    HOVER_TEXT_PRIMARY_4 = "hover:pinata-text-primary-4",

    HOVER_TEXT_PRIMARY_DARK = "hover:pinata-text-primary-dark",

    HOVER_TEXT_PRIMARY_LIGHT = "hover:pinata-text-primary-light",

    HOVER_TEXT_SECONDARY_1 = "hover:pinata-text-secondary-1",

    HOVER_TEXT_SECONDARY_2 = "hover:pinata-text-secondary-2",

    HOVER_TEXT_SECONDARY_3 = "hover:pinata-text-secondary-3",

    HOVER_TEXT_SECONDARY_4 = "hover:pinata-text-secondary-4",

    HOVER_TEXT_TERTIARY_1 = "hover:pinata-text-tertiary-1",

    HOVER_TEXT_TERTIARY_2 = "hover:pinata-text-tertiary-2",

    HOVER_TEXT_TERTIARY_3 = "hover:pinata-text-tertiary-3",

    HOVER_TEXT_TERTIARY_4 = "hover:pinata-text-tertiary-4",

    HOVER_TEXT_TRANSPARENT = "hover:pinata-text-transparent",

    HOVER_TEXT_WARNING = "hover:pinata-text-warning",

    HOVER_TEXT_WHITE = "hover:pinata-text-white",

    TEXT_BLACK = "pinata-text-black",

    TEXT_HEADER = "pinata-text-header",

    TEXT_INFO = "pinata-text-info",

    TEXT_NEGATIVE = "pinata-text-negative",

    TEXT_NEUTRAL_1 = "pinata-text-neutral-1",

    TEXT_NEUTRAL_2 = "pinata-text-neutral-2",

    TEXT_NEUTRAL_3 = "pinata-text-neutral-3",

    TEXT_NEUTRAL_4 = "pinata-text-neutral-4",

    TEXT_NEUTRAL_5 = "pinata-text-neutral-5",

    TEXT_NEUTRAL_6 = "pinata-text-neutral-6",

    TEXT_NEUTRAL_7 = "pinata-text-neutral-7",

    TEXT_NEUTRAL_8 = "pinata-text-neutral-8",

    TEXT_POSITIVE = "pinata-text-positive",

    TEXT_PRIMARY_1 = "pinata-text-primary-1",

    TEXT_PRIMARY_2 = "pinata-text-primary-2",

    TEXT_PRIMARY_3 = "pinata-text-primary-3",

    TEXT_PRIMARY_4 = "pinata-text-primary-4",

    TEXT_PRIMARY_DARK = "pinata-text-primary-dark",

    TEXT_PRIMARY_LIGHT = "pinata-text-primary-light",

    TEXT_SECONDARY_1 = "pinata-text-secondary-1",

    TEXT_SECONDARY_2 = "pinata-text-secondary-2",

    TEXT_SECONDARY_3 = "pinata-text-secondary-3",

    TEXT_SECONDARY_4 = "pinata-text-secondary-4",

    TEXT_TERTIARY_1 = "pinata-text-tertiary-1",

    TEXT_TERTIARY_2 = "pinata-text-tertiary-2",

    TEXT_TERTIARY_3 = "pinata-text-tertiary-3",

    TEXT_TERTIARY_4 = "pinata-text-tertiary-4",

    TEXT_TRANSPARENT = "pinata-text-transparent",

    TEXT_WARNING = "pinata-text-warning",

    TEXT_WHITE = "pinata-text-white",

}