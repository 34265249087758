
export enum RadioButtonIcons {
    SIMPLE,
    CHECKMARK
}

export enum RadioButtonSkin {
    PRIMARY = 'primary',
    PRIMARY_2 = 'primary-2',
    PRIMARY_3 = 'primary-3',
    POSITIVE = 'positive',
    WARNING = 'warning',
    NEGATIVE = 'negative',
    INFO = 'info'
}
