export enum IconSize {

    SMALL = '16',

    BASE = '24',

    MEDIUM = '32',

    LARGE = '40',

    XLARGE = '48',

    XXLARGE = '56',

}

export enum IconFlow {

    INLINE = 'inline',

}

export enum IconColor {

    PRIMARY_LIGHT = 'primary-light',
    PRIMARY_DARK = 'primary-dark',

    PRIMARY_1 = 'primary-1',
    PRIMARY_2 = 'primary-2',
    PRIMARY_3 = 'primary-3',
    PRIMARY_4 = 'primary-4',

    SECONDARY_1 = 'secondary-1',
    SECONDARY_2 = 'secondary-2',
    SECONDARY_3 = 'secondary-3',
    SECONDARY_4 = 'secondary-4',

    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    WARNING = 'warning',
    INFO = 'info',

    NEUTRAL_1 = 'neutral-1',
    NEUTRAL_2 = 'neutral-2',
    NEUTRAL_3 = 'neutral-3',
    NEUTRAL_4 = 'neutral-4',
    NEUTRAL_5 = 'neutral-5',
    NEUTRAL_6 = 'neutral-6',
    NEUTRAL_7 = 'neutral-7',
    NEUTRAL_8 = 'neutral-8',
}