export enum LoaderColor {

    DEFAULT = '',
    WHITE = 'white',
    BLACK = 'black',

}

export enum LoaderTextMode {
    STATIC = "static",
    SEQUENCE = "sequence",
    SEQUENCE_RANDOM = "sequence-random",
    LOOP = "loop",
    LOOP_RANDOM = "loop-random"
}

export enum LoaderSize {

    DEFAULT = '',

    TINY = 'tiny',

    MINI = 'mini',

    SMALL = 'small',

    BASE = 'base',

    MEDIUM = 'medium',

    LARGE = 'large',

    XLARGE = 'xlarge',

    XXLARGE = 'xxlarge',

}

