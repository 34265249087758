import * as React from 'react';
import { RadioContext } from "./";
import { classNames } from "../../utils";
import { RadioButtonIcons, RadioButtonSkin } from "./RadioButtonEnums";
import { FocusEventHandler, ReactElement, ReactNode, useState } from 'react';

export interface IRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    text?: ReactNode;
    textSecondLine?: string;
    index?: number | string;
    errorMsg?: string;
    hideErrorMsg?: boolean;
    checkedIcon?: RadioButtonIcons
    skin?: RadioButtonSkin;
    icon?: JSX.Element;
    children?: ({checked}: { checked: boolean }) => JSX.Element
}

export interface IRadioButtonState {
    preChecked: boolean;
    hasFocus: boolean;
}


export const RadioButton: React.FunctionComponent<IRadioButtonProps> = (props) => {

    const styleClass = {
        root: "pinata-radiobutton",
        text: "pinata-radiobutton__text",
        twoLinesOfText: "pinata-radiobutton__text--two-lines",
        textSecondLine: "pinata-radiobutton__text--second-line",
        svg: "pinata-radiobutton__illustration",
        focus: 'pinata-radiobutton--focus',
        checked: 'pinata-radiobutton--checked',
        preChecked: 'pinata-radiobutton--pre-checked',
        disabled: 'pinata-radiobutton--disabled',
        control: 'pinata-radiobutton__control',
        wrapper: 'pinata-radiobutton__wrapper',
        skin: (color: RadioButtonSkin) => `pinata-radiobutton-skin--${color}`
    };

    const [state, setState] = useState<IRadioButtonState>({
        preChecked: !!props.checked,
        hasFocus: false
    });

    const isChecked = (groupChecked: boolean): boolean => {
        const {preChecked} = state;

        if (preChecked && !groupChecked) {
            return false;
        }

        if (!preChecked && groupChecked) {
            return true;
        }

        return groupChecked;
    };

    const onFocus: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onFocus: onFocusProp} = props;

        if (onFocusProp) {
            onFocusProp(__event)
        }

        setState({
            ...state,
            hasFocus: true,
        });
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onBlur: onBlurProp} = props;

        if (onBlurProp) {
            onBlurProp(__event)
        }

        setState({
            ...state,
            hasFocus: false,
        });
    };

    const getSymbol = (checked: boolean): ReactElement | null => {

        if (!checked) {
            return null;
        }

        const {checkedIcon} = props;
        const classes = classNames(
            styleClass.svg,
        );

        if (checkedIcon === RadioButtonIcons.SIMPLE) {
            return <svg
                className={classes}
                viewBox="0 0 100 100"
                width="24"
                height="18"
            >
                <g transform="translate(50, 50)">
                    <circle
                        className={'pinata-radio__simple-circle'}
                        cx="0"
                        cy="0"
                        r="40"
                    />
                </g>
            </svg>
        }

        if (checkedIcon === RadioButtonIcons.CHECKMARK) {
            return <svg
                className={classes}
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    className={'pinata-radio__checkmark-circle'}
                    d="M12.2439024,2 C15.3140277,2 18.0687066,3.31843014 19.9462098,5.40693583 L13.0456685,12.6032151 C12.5574767,13.1101215 12.1019473,13.5815066 11.6933292,14.0018477 L8.26121262,11.047935 C7.85698339,10.699161 7.23916824,10.7354241 6.88070219,11.1297831 C6.52318801,11.5241302 6.56035992,12.1277704 6.96460135,12.4765445 L11.1137965,16.0480978 C11.5085069,16.389443 12.1100914,16.3633945 12.4714375,15.9885719 L13.8132446,14.6060088 C15.9039285,12.4378066 18.7798089,9.40864849 21.1071818,6.98041474 C21.9847864,8.45662436 22.4878049,10.1709677 22.4878049,12 C22.4878049,17.5228475 17.9014535,22 12.2439024,22 C6.58635134,22 2,17.5228475 2,12 C2,6.4771525 6.58635134,2 12.2439024,2 Z"
                />
            </svg>
        }

        return null;
    };

    return (
        <RadioContext.Consumer>
            {radioGroup => {
                const {
                    className,
                    text,
                    textSecondLine,
                    disabled,
                    checked,
                    name,
                    checkedIcon,
                    skin,
                    children,
                    onClick,
                    icon,
                    ...rest
                } = props;
                const {preChecked, hasFocus} = state;
                const skinClass = styleClass.skin(skin || RadioButtonSkin.PRIMARY);

                const inputClasses = classNames(
                    styleClass.control,
                    className
                );

                const wrapperClasses = classNames(
                    styleClass.wrapper,
                );

                const rootClasses = classNames(
                    styleClass.root,
                    skinClass,
                    {[styleClass.focus]: hasFocus},
                    {[styleClass.checked]: isChecked(radioGroup.value === props.value)},
                    {[styleClass.preChecked]: preChecked},
                    {[styleClass.disabled]: disabled},
                );

                return (
                    <React.Fragment>
                        <div className={rootClasses}>
                            <label className={styleClass.text}>
                                <div className={wrapperClasses}>
                                    <input
                                        {...rest}
                                        name={radioGroup.name}
                                        className={inputClasses}
                                        defaultChecked={isChecked(radioGroup.value === props.value)}
                                        disabled={disabled}
                                        type="radio"
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onChange={radioGroup.onChange}
                                        onClick={(__event) => {
                                            radioGroup.onClick(__event as any);
                                            typeof onClick === 'function' && onClick(__event);
                                        }}
                                    />
                                    {getSymbol(isChecked(radioGroup.value === props.value))}
                                </div>
                                {textSecondLine ?
                                    <div className={styleClass.twoLinesOfText}>
                                        {typeof text === 'string' ? <span>{text}</span> : text}
                                        <span className={styleClass.textSecondLine}>{textSecondLine}</span>
                                    </div> : <span>{text}</span>}
                            </label>
                            {icon && icon}
                        </div>
                        {typeof children === 'function' ? children({checked: isChecked(radioGroup.value === props.value)}) : null}
                    </React.Fragment>
                )
            }
            }
        </RadioContext.Consumer>
    );
};

RadioButton.defaultProps = {
    skin: RadioButtonSkin.PRIMARY,
    checkedIcon: RadioButtonIcons.SIMPLE,
    checked: false,
    onChange: () => {
    }
};
RadioButton.displayName = 'RadioButton';
