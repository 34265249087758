const hasOwn = {}.hasOwnProperty;

export function classNames(...args: Array<string | number | object | undefined | null>) {
    const classes: string[] = [];

    for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (!arg) {
            continue;
        }

        if (typeof arg === 'string' || typeof arg === 'number') {
            // @ts-ignore
            classes.push((this && this[arg]) || arg);
        } else if (Array.isArray(arg)) {
            // @ts-ignore
            classes.push(classNames.apply(this, arg));
        } else if (typeof arg === 'object') {
            for (const key in arg) {
                if (hasOwn.call(arg, key) && (arg as any)[key]) {
                    // @ts-ignore
                    classes.push((this && this[key]) || key);
                }
            }
        }
    }

    return classes.join(' ');
}
