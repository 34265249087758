import * as React from 'react';
import { classNames } from '../../utils';
import { Grid, GridComposition } from '../grid';
import { ContainerAlign, ContainerWidth } from './ContainerEnums';

export * from "./ContainerEnums";

export { GridComposition as ContainerComposition }
export { ContainerAlign, ContainerWidth } from './ContainerEnums'

export interface IContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    composition?: GridComposition;
    width?: ContainerWidth;
    align?: ContainerAlign;
}

const styleClass = {
    root: 'pinata-container',
    width: (width?: ContainerWidth) => width ? `pinata-container--width-${width}` : '',
    align: (align?: ContainerAlign) => align ? `pinata-container--align-${align}` : '',
};

export const Container: React.FunctionComponent<IContainerProps> = (props) => {

    const {composition, children, width, align, className, ...rest} = props;

    const containerClasses = classNames(
        styleClass.root,
        {[styleClass.width(width)]: width},
        {[styleClass.align(align)]: align},
        className
    );

    if (!composition) {
        return (
            <div {...rest} className={containerClasses}>
                {children}
            </div>
        );
    }

    return (
        <div {...rest} className={containerClasses}>
            <Grid composition={composition}>
                {children}
            </Grid>
        </div>
    );

};

Container.displayName = 'Container';
