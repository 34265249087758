///-- THIS FILE IS GENERATED --///
export enum TextDecoration {

    HOVER_LINE_THROUGH = "hover:pinata-line-through",

    HOVER_NO_UNDERLINE = "hover:pinata-no-underline",

    HOVER_UNDERLINE = "hover:pinata-underline",

    LINE_THROUGH = "pinata-line-through",

    NO_UNDERLINE = "pinata-no-underline",

    UNDERLINE = "pinata-underline",

}