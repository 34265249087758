import * as React from 'react';
import {classNames} from "../../utils";
import { ChangeEventHandler, ReactElement, useState } from 'react';
import { FocusEventHandler } from 'react';
import { LegacyRef } from 'react';

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    text?: string;
    index?: number | string;
    errorMsg?: string;
    hideErrorMsg?: boolean;
    className?: string;
}

export interface ICheckboxState {
    checked: boolean;
    preChecked: boolean;
    hasFocus: boolean;
}

const styleClass = {
    root: "pinata-checkbox",
    text: "pinata-checkbox__text",
    svg: "pinata-checkbox__illustration",
    errorMsg: "pinata-checkbox--error-msg",
    checked:'pinata-checkbox--checked' ,
    preChecked:'pinata-checkbox--pre-checked' ,
    disabled: 'pinata-checkbox--disabled',
    focus: 'pinata-checkbox--focus',
    wrapper: 'pinata-checkbox__wrapper',
    control: 'pinata-checkbox__control',
    valid: "pinata-checkbox--valid",
    invalid: "pinata-checkbox--invalid"
};

export const Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {

    const Mark = 'M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16';

    const checkedProp = props.checked || false;
    const onChangeProp: ChangeEventHandler<HTMLInputElement> = props.onChange || (() => {});

    const pathRef: LegacyRef<SVGPathElement> = React.createRef();
    const [state, setState] = useState<ICheckboxState>({
        checked: checkedProp,
        preChecked: checkedProp,
        hasFocus: false,
    });

    if (state.checked !== checkedProp) {
        setState({ ...state, checked: checkedProp });
    }

    const onChange: ChangeEventHandler<HTMLInputElement> = (__event) => {
        __event.persist();

        onChangeProp(__event);

        if (__event.isDefaultPrevented()) {
            return;
        }

        setState({
            ...state,
            checked: __event.target.checked,
            preChecked: false
        });
    };


    const onFocus: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onFocus} = props;

        if (onFocus) {
            onFocus(__event)
        }

        setState({ ...state, hasFocus: true });
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onBlur} = props;

        if (onBlur) {
            onBlur(__event)
        }

        setState({ ...state, hasFocus: false });
    };

    const getSymbol: () => ReactElement | null = () => {

        const {checked} = state;

        if (!checked) {
            return null;
        }
        return <path
            ref={pathRef}
            d={Mark}
        />
    };

        const {errorMsg, className, text, disabled, ...rest} = props;
        const {checked, preChecked, hasFocus} = state;

        const hasError = typeof errorMsg === "string" && errorMsg;
        const errMsgClasses = classNames(
            {[styleClass.errorMsg]: hasError},
        );

        const inputClasses = classNames(
            styleClass.control,
            className
        );

        const wrapperClasses = classNames(
            styleClass.wrapper,
        );

        const rootClasses = classNames(
            styleClass.root,
            props.className,
            {[styleClass.focus]: hasFocus},
            {[styleClass.checked]: checked},
            {[styleClass.preChecked]: preChecked},
            {[styleClass.disabled]: disabled},
        );

        return (
            <div className={rootClasses}>
                <label className={styleClass.text}>
                    <div className={wrapperClasses}>
                        <input
                            {...rest}
                            className={inputClasses}
                            checked={checked}
                            disabled={disabled}
                            onChange={onChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            type="checkbox"
                        />
                        <svg
                            className={styleClass.svg}
                            viewBox="0 0 100 100"
                        >
                            {getSymbol()}
                        </svg>
                    </div>
                    <span>{text}</span>
                </label>
                {errorMsg && (<span className={errMsgClasses}>{errorMsg}</span>)}
            </div>
        );
};

Checkbox.displayName = 'Checkbox';
