import {Composition} from "./Composition";
import {Default} from "./Default";

export function createComposition(name: string, params: { size: number; grid: any; gridItem: any; emptyChildren: boolean; }): Composition {

    switch (name) {

        // @todo add more compositions here

        default:
            return new Default(params);

    }
}
