///-- THIS FILE IS GENERATED --///
export enum ZIndex {

    Z_0 = "pinata-z-0",

    Z_10 = "pinata-z-10",

    Z_20 = "pinata-z-20",

    Z_30 = "pinata-z-30",

    Z_40 = "pinata-z-40",

    Z_50 = "pinata-z-50",

    Z_AUTO = "pinata-z-auto",

    Z_BACKDROP = "pinata-z-backdrop",

    Z_DIALOG = "pinata-z-dialog",

    Z_MODAL = "pinata-z-modal",

}