///-- THIS FILE IS GENERATED --///
export enum FontStyle {

    HOVER_ITALIC = "hover:pinata-italic",

    HOVER_NOT_ITALIC = "hover:pinata-not-italic",

    ITALIC = "pinata-italic",

    NOT_ITALIC = "pinata-not-italic",

}