import * as React from 'react';
import { InputType } from "./InputEnums";
import { classNames } from "../../utils";
import uniqueId from "lodash-es/uniqueId"
import { ChangeEventHandler, useEffect, useState } from 'react';
import {Icon} from '../icon/index';
import {IconName, IconColor, IconSize} from '../icon';

export * from './InputEnums';

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    errorMsg?: string;
    validMsg?: boolean | string;
    className?: string;
    clearable?: boolean;
}

export const Input: React.FunctionComponent<IInputProps> = (props) => {

    const styleClass = {
        root: 'pinata-input',
        container: 'pinata-input--container',
        label: 'pinata-input-label',
        noLabel: 'pinata-input-label--hide',
        errorMsg: 'pinata-input__error-msg',
        errorMsgShow: 'pinata-input__error-msg--show',
        valid: 'pinata-input--valid',
        invalid: 'pinata-input--invalid',
        hasValue: 'pinata-input--has-value',
        clear: 'pinata-input--clear',
        clearable: 'pinata-input--clearable',
    };

    const inputRef = React.useRef<HTMLInputElement>(null);
    const id: string = uniqueId('input_');
    const [value, setValue] = useState<string | number | string[]>(props.value || '');
    useEffect(() => {
        if (props.value !== undefined && props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    const onChange: ChangeEventHandler<HTMLInputElement> = (__event) => {
        __event.preventDefault();

        const {onChange} = props;

        if (onChange) {
            onChange(__event);
        }

        setValue(__event.target.value);
    };

    const {children, className, label, errorMsg, onChange: onChangeProp, validMsg, clearable, ...rest} = props;

    const hasError = !!errorMsg;
    const hasValue = typeof value === 'number' || (typeof value === 'string' && value);

    const inputClasses = classNames(
        styleClass.root,
        {
            [styleClass.clearable]: clearable
        }
    );

    const errMsgClasses = classNames(
        {
            [styleClass.errorMsg]: hasError,
            [styleClass.errorMsgShow]: hasError
        },
    );

    const containerClasses = classNames(
        styleClass.container,
        {
            [styleClass.hasValue]: hasValue,
            [styleClass.valid]: validMsg && !hasError,
            [styleClass.invalid]: hasError,
            [styleClass.noLabel]: !label
        },
        className
    );

    const clearClasses = classNames(
        styleClass.clear,
    );

    if(!label && !rest.hasOwnProperty('aria-label')) {
        console.warn('All inputs that does not have a label should have an aria-label.');
    }

    const onClearClick = () => {
        setValue('');
        inputRef.current && inputRef.current.focus();
    };

    return (

        <div className={containerClasses}>
            <input
                {...rest}
                id={id}
                value={value}
                className={inputClasses}
                onChange={onChange}
                ref={inputRef}
            />
            {label ? <label
                htmlFor={id}
                className={styleClass.label}>
                {label}
            </label> : null}
            <span className="pinata-focus-bar"/>
            {clearable ?
                <Icon
                    name={IconName.CANCEL}
                    color={IconColor.NEUTRAL_4}
                    size={IconSize.SMALL}
                    onClick={onClearClick}
                    className={clearClasses}
                /> : null}
            {errorMsg && <span className={errMsgClasses}>{errorMsg}</span>}
        </div>

    );
};

Input.defaultProps = {
    type: InputType.TEXT,
};
Input.displayName = 'Input';
