import * as React from 'react';
import { HeadingLevel } from "./HeadingEnums";
import { classNames } from "../../utils";

export * from './HeadingEnums';

export interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
    level?: HeadingLevel;
}

export const Heading: React.FunctionComponent<HeadingProps> = (props) => {

    const styleClass = {
        root: `pinata-heading`,
        level: (level: HeadingLevel) => `pinata-heading--${level}`
    };

    const getHeadingElement = (): HeadingLevel => {
        const {level} = props;

        if (level === HeadingLevel.MEGA || level === HeadingLevel.HERO) {
            return HeadingLevel.ONE;
        }

        return level || HeadingLevel.TWO;
    };


    const {children, level, className, ...rest} = props;

    const classes = classNames(
        styleClass.root,
        styleClass.level(level || HeadingLevel.TWO),
        className,
    );

    const element = React.createElement(
        getHeadingElement(), {
            className: classes,
            ...rest
        }, children);

    return <>{element}</>;
};

Heading.defaultProps = {
    level: HeadingLevel.TWO
};
Heading.displayName = 'Heading';
