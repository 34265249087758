///-- THIS FILE IS GENERATED --///
export enum BorderWidth {

    BORDER = "pinata-border",

    BORDER_0 = "pinata-border-0",

    BORDER_1 = "pinata-border-1",

    BORDER_2 = "pinata-border-2",

    BORDER_4 = "pinata-border-4",

    BORDER_8 = "pinata-border-8",

    BORDER_B = "pinata-border-b",

    BORDER_B_0 = "pinata-border-b-0",

    BORDER_B_1 = "pinata-border-b-1",

    BORDER_B_2 = "pinata-border-b-2",

    BORDER_B_4 = "pinata-border-b-4",

    BORDER_B_8 = "pinata-border-b-8",

    BORDER_L = "pinata-border-l",

    BORDER_L_0 = "pinata-border-l-0",

    BORDER_L_1 = "pinata-border-l-1",

    BORDER_L_2 = "pinata-border-l-2",

    BORDER_L_4 = "pinata-border-l-4",

    BORDER_L_8 = "pinata-border-l-8",

    BORDER_R = "pinata-border-r",

    BORDER_R_0 = "pinata-border-r-0",

    BORDER_R_1 = "pinata-border-r-1",

    BORDER_R_2 = "pinata-border-r-2",

    BORDER_R_4 = "pinata-border-r-4",

    BORDER_R_8 = "pinata-border-r-8",

    BORDER_T = "pinata-border-t",

    BORDER_T_0 = "pinata-border-t-0",

    BORDER_T_1 = "pinata-border-t-1",

    BORDER_T_2 = "pinata-border-t-2",

    BORDER_T_4 = "pinata-border-t-4",

    BORDER_T_8 = "pinata-border-t-8",

}