///-- THIS FILE IS GENERATED --///
export enum Inset {

    LG_INSET_0 = "lg:pinata-inset-0",

    LG_INSET_AUTO = "lg:pinata-inset-auto",

    LG_INSET_X_0 = "lg:pinata-inset-x-0",

    LG_INSET_X_AUTO = "lg:pinata-inset-x-auto",

    LG_INSET_Y_0 = "lg:pinata-inset-y-0",

    LG_INSET_Y_AUTO = "lg:pinata-inset-y-auto",

    MD_INSET_0 = "md:pinata-inset-0",

    MD_INSET_AUTO = "md:pinata-inset-auto",

    MD_INSET_X_0 = "md:pinata-inset-x-0",

    MD_INSET_X_AUTO = "md:pinata-inset-x-auto",

    MD_INSET_Y_0 = "md:pinata-inset-y-0",

    MD_INSET_Y_AUTO = "md:pinata-inset-y-auto",

    SM_INSET_0 = "sm:pinata-inset-0",

    SM_INSET_AUTO = "sm:pinata-inset-auto",

    SM_INSET_X_0 = "sm:pinata-inset-x-0",

    SM_INSET_X_AUTO = "sm:pinata-inset-x-auto",

    SM_INSET_Y_0 = "sm:pinata-inset-y-0",

    SM_INSET_Y_AUTO = "sm:pinata-inset-y-auto",

    XL_INSET_0 = "xl:pinata-inset-0",

    XL_INSET_AUTO = "xl:pinata-inset-auto",

    XL_INSET_X_0 = "xl:pinata-inset-x-0",

    XL_INSET_X_AUTO = "xl:pinata-inset-x-auto",

    XL_INSET_Y_0 = "xl:pinata-inset-y-0",

    XL_INSET_Y_AUTO = "xl:pinata-inset-y-auto",

    XS_INSET_0 = "xs:pinata-inset-0",

    XS_INSET_AUTO = "xs:pinata-inset-auto",

    XS_INSET_X_0 = "xs:pinata-inset-x-0",

    XS_INSET_X_AUTO = "xs:pinata-inset-x-auto",

    XS_INSET_Y_0 = "xs:pinata-inset-y-0",

    XS_INSET_Y_AUTO = "xs:pinata-inset-y-auto",

    INSET_0 = "pinata-inset-0",

    INSET_AUTO = "pinata-inset-auto",

    INSET_X_0 = "pinata-inset-x-0",

    INSET_X_AUTO = "pinata-inset-x-auto",

    INSET_Y_0 = "pinata-inset-y-0",

    INSET_Y_AUTO = "pinata-inset-y-auto",

}