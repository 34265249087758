///-- THIS FILE IS GENERATED --///
export enum FontWeight {

    FONT_BLACK = "pinata-font-black",

    FONT_BOLD = "pinata-font-bold",

    FONT_EXTRABOLD = "pinata-font-extrabold",

    FONT_HAIRLINE = "pinata-font-hairline",

    FONT_LIGHT = "pinata-font-light",

    FONT_MEDIUM = "pinata-font-medium",

    FONT_NORMAL = "pinata-font-normal",

    FONT_SEMIBOLD = "pinata-font-semibold",

    FONT_THIN = "pinata-font-thin",

}