///-- THIS FILE IS GENERATED --///
export enum Overflow {

    LG_OVERFLOW_AUTO = "lg:pinata-overflow-auto",

    LG_OVERFLOW_HIDDEN = "lg:pinata-overflow-hidden",

    LG_OVERFLOW_SCROLL = "lg:pinata-overflow-scroll",

    LG_OVERFLOW_VISIBLE = "lg:pinata-overflow-visible",

    LG_OVERFLOW_X_AUTO = "lg:pinata-overflow-x-auto",

    LG_OVERFLOW_X_HIDDEN = "lg:pinata-overflow-x-hidden",

    LG_OVERFLOW_X_SCROLL = "lg:pinata-overflow-x-scroll",

    LG_OVERFLOW_Y_AUTO = "lg:pinata-overflow-y-auto",

    LG_OVERFLOW_Y_HIDDEN = "lg:pinata-overflow-y-hidden",

    LG_OVERFLOW_Y_SCROLL = "lg:pinata-overflow-y-scroll",

    LG_SCROLLING_AUTO = "lg:pinata-scrolling-auto",

    LG_SCROLLING_TOUCH = "lg:pinata-scrolling-touch",

    MD_OVERFLOW_AUTO = "md:pinata-overflow-auto",

    MD_OVERFLOW_HIDDEN = "md:pinata-overflow-hidden",

    MD_OVERFLOW_SCROLL = "md:pinata-overflow-scroll",

    MD_OVERFLOW_VISIBLE = "md:pinata-overflow-visible",

    MD_OVERFLOW_X_AUTO = "md:pinata-overflow-x-auto",

    MD_OVERFLOW_X_HIDDEN = "md:pinata-overflow-x-hidden",

    MD_OVERFLOW_X_SCROLL = "md:pinata-overflow-x-scroll",

    MD_OVERFLOW_Y_AUTO = "md:pinata-overflow-y-auto",

    MD_OVERFLOW_Y_HIDDEN = "md:pinata-overflow-y-hidden",

    MD_OVERFLOW_Y_SCROLL = "md:pinata-overflow-y-scroll",

    MD_SCROLLING_AUTO = "md:pinata-scrolling-auto",

    MD_SCROLLING_TOUCH = "md:pinata-scrolling-touch",

    SM_OVERFLOW_AUTO = "sm:pinata-overflow-auto",

    SM_OVERFLOW_HIDDEN = "sm:pinata-overflow-hidden",

    SM_OVERFLOW_SCROLL = "sm:pinata-overflow-scroll",

    SM_OVERFLOW_VISIBLE = "sm:pinata-overflow-visible",

    SM_OVERFLOW_X_AUTO = "sm:pinata-overflow-x-auto",

    SM_OVERFLOW_X_HIDDEN = "sm:pinata-overflow-x-hidden",

    SM_OVERFLOW_X_SCROLL = "sm:pinata-overflow-x-scroll",

    SM_OVERFLOW_Y_AUTO = "sm:pinata-overflow-y-auto",

    SM_OVERFLOW_Y_HIDDEN = "sm:pinata-overflow-y-hidden",

    SM_OVERFLOW_Y_SCROLL = "sm:pinata-overflow-y-scroll",

    SM_SCROLLING_AUTO = "sm:pinata-scrolling-auto",

    SM_SCROLLING_TOUCH = "sm:pinata-scrolling-touch",

    XL_OVERFLOW_AUTO = "xl:pinata-overflow-auto",

    XL_OVERFLOW_HIDDEN = "xl:pinata-overflow-hidden",

    XL_OVERFLOW_SCROLL = "xl:pinata-overflow-scroll",

    XL_OVERFLOW_VISIBLE = "xl:pinata-overflow-visible",

    XL_OVERFLOW_X_AUTO = "xl:pinata-overflow-x-auto",

    XL_OVERFLOW_X_HIDDEN = "xl:pinata-overflow-x-hidden",

    XL_OVERFLOW_X_SCROLL = "xl:pinata-overflow-x-scroll",

    XL_OVERFLOW_Y_AUTO = "xl:pinata-overflow-y-auto",

    XL_OVERFLOW_Y_HIDDEN = "xl:pinata-overflow-y-hidden",

    XL_OVERFLOW_Y_SCROLL = "xl:pinata-overflow-y-scroll",

    XL_SCROLLING_AUTO = "xl:pinata-scrolling-auto",

    XL_SCROLLING_TOUCH = "xl:pinata-scrolling-touch",

    XS_OVERFLOW_AUTO = "xs:pinata-overflow-auto",

    XS_OVERFLOW_HIDDEN = "xs:pinata-overflow-hidden",

    XS_OVERFLOW_SCROLL = "xs:pinata-overflow-scroll",

    XS_OVERFLOW_VISIBLE = "xs:pinata-overflow-visible",

    XS_OVERFLOW_X_AUTO = "xs:pinata-overflow-x-auto",

    XS_OVERFLOW_X_HIDDEN = "xs:pinata-overflow-x-hidden",

    XS_OVERFLOW_X_SCROLL = "xs:pinata-overflow-x-scroll",

    XS_OVERFLOW_Y_AUTO = "xs:pinata-overflow-y-auto",

    XS_OVERFLOW_Y_HIDDEN = "xs:pinata-overflow-y-hidden",

    XS_OVERFLOW_Y_SCROLL = "xs:pinata-overflow-y-scroll",

    XS_SCROLLING_AUTO = "xs:pinata-scrolling-auto",

    XS_SCROLLING_TOUCH = "xs:pinata-scrolling-touch",

    OVERFLOW_AUTO = "pinata-overflow-auto",

    OVERFLOW_HIDDEN = "pinata-overflow-hidden",

    OVERFLOW_SCROLL = "pinata-overflow-scroll",

    OVERFLOW_VISIBLE = "pinata-overflow-visible",

    OVERFLOW_X_AUTO = "pinata-overflow-x-auto",

    OVERFLOW_X_HIDDEN = "pinata-overflow-x-hidden",

    OVERFLOW_X_SCROLL = "pinata-overflow-x-scroll",

    OVERFLOW_Y_AUTO = "pinata-overflow-y-auto",

    OVERFLOW_Y_HIDDEN = "pinata-overflow-y-hidden",

    OVERFLOW_Y_SCROLL = "pinata-overflow-y-scroll",

    SCROLLING_AUTO = "pinata-scrolling-auto",

    SCROLLING_TOUCH = "pinata-scrolling-touch",

}