export enum ButtonSize {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum ButtonSkin {
    PRIMARY_1 = 'primary-1',
    PRIMARY_2 = 'primary-2',
    PRIMARY_3 = 'primary-3',
    PRIMARY_4 = 'primary-4',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    CONFIRM = 'confirm',
    WARNING = 'warning',
    NEGATIVE = 'negative',
    INFO = 'info'
}
