///-- THIS FILE IS GENERATED --///
export enum Padding {

    LG_P_0 = "lg:pinata-p-0",

    LG_P_1 = "lg:pinata-p-1",

    LG_P_10 = "lg:pinata-p-10",

    LG_P_12 = "lg:pinata-p-12",

    LG_P_14 = "lg:pinata-p-14",

    LG_P_16 = "lg:pinata-p-16",

    LG_P_2 = "lg:pinata-p-2",

    LG_P_20 = "lg:pinata-p-20",

    LG_P_24 = "lg:pinata-p-24",

    LG_P_2XL = "lg:pinata-p-2xl",

    LG_P_3 = "lg:pinata-p-3",

    LG_P_32 = "lg:pinata-p-32",

    LG_P_3XL = "lg:pinata-p-3xl",

    LG_P_4 = "lg:pinata-p-4",

    LG_P_40 = "lg:pinata-p-40",

    LG_P_48 = "lg:pinata-p-48",

    LG_P_4XL = "lg:pinata-p-4xl",

    LG_P_5 = "lg:pinata-p-5",

    LG_P_56 = "lg:pinata-p-56",

    LG_P_5XL = "lg:pinata-p-5xl",

    LG_P_6 = "lg:pinata-p-6",

    LG_P_64 = "lg:pinata-p-64",

    LG_P_6XL = "lg:pinata-p-6xl",

    LG_P_8 = "lg:pinata-p-8",

    LG_P_FULL = "lg:pinata-p-full",

    LG_P_LG = "lg:pinata-p-lg",

    LG_P_MD = "lg:pinata-p-md",

    LG_P_PX = "lg:pinata-p-px",

    LG_P_SM = "lg:pinata-p-sm",

    LG_P_XL = "lg:pinata-p-xl",

    LG_P_XS = "lg:pinata-p-xs",

    LG_PB_0 = "lg:pinata-pb-0",

    LG_PB_1 = "lg:pinata-pb-1",

    LG_PB_10 = "lg:pinata-pb-10",

    LG_PB_12 = "lg:pinata-pb-12",

    LG_PB_14 = "lg:pinata-pb-14",

    LG_PB_16 = "lg:pinata-pb-16",

    LG_PB_2 = "lg:pinata-pb-2",

    LG_PB_20 = "lg:pinata-pb-20",

    LG_PB_24 = "lg:pinata-pb-24",

    LG_PB_2XL = "lg:pinata-pb-2xl",

    LG_PB_3 = "lg:pinata-pb-3",

    LG_PB_32 = "lg:pinata-pb-32",

    LG_PB_3XL = "lg:pinata-pb-3xl",

    LG_PB_4 = "lg:pinata-pb-4",

    LG_PB_40 = "lg:pinata-pb-40",

    LG_PB_48 = "lg:pinata-pb-48",

    LG_PB_4XL = "lg:pinata-pb-4xl",

    LG_PB_5 = "lg:pinata-pb-5",

    LG_PB_56 = "lg:pinata-pb-56",

    LG_PB_5XL = "lg:pinata-pb-5xl",

    LG_PB_6 = "lg:pinata-pb-6",

    LG_PB_64 = "lg:pinata-pb-64",

    LG_PB_6XL = "lg:pinata-pb-6xl",

    LG_PB_8 = "lg:pinata-pb-8",

    LG_PB_FULL = "lg:pinata-pb-full",

    LG_PB_LG = "lg:pinata-pb-lg",

    LG_PB_MD = "lg:pinata-pb-md",

    LG_PB_PX = "lg:pinata-pb-px",

    LG_PB_SM = "lg:pinata-pb-sm",

    LG_PB_XL = "lg:pinata-pb-xl",

    LG_PB_XS = "lg:pinata-pb-xs",

    LG_PL_0 = "lg:pinata-pl-0",

    LG_PL_1 = "lg:pinata-pl-1",

    LG_PL_10 = "lg:pinata-pl-10",

    LG_PL_12 = "lg:pinata-pl-12",

    LG_PL_14 = "lg:pinata-pl-14",

    LG_PL_16 = "lg:pinata-pl-16",

    LG_PL_2 = "lg:pinata-pl-2",

    LG_PL_20 = "lg:pinata-pl-20",

    LG_PL_24 = "lg:pinata-pl-24",

    LG_PL_2XL = "lg:pinata-pl-2xl",

    LG_PL_3 = "lg:pinata-pl-3",

    LG_PL_32 = "lg:pinata-pl-32",

    LG_PL_3XL = "lg:pinata-pl-3xl",

    LG_PL_4 = "lg:pinata-pl-4",

    LG_PL_40 = "lg:pinata-pl-40",

    LG_PL_48 = "lg:pinata-pl-48",

    LG_PL_4XL = "lg:pinata-pl-4xl",

    LG_PL_5 = "lg:pinata-pl-5",

    LG_PL_56 = "lg:pinata-pl-56",

    LG_PL_5XL = "lg:pinata-pl-5xl",

    LG_PL_6 = "lg:pinata-pl-6",

    LG_PL_64 = "lg:pinata-pl-64",

    LG_PL_6XL = "lg:pinata-pl-6xl",

    LG_PL_8 = "lg:pinata-pl-8",

    LG_PL_FULL = "lg:pinata-pl-full",

    LG_PL_LG = "lg:pinata-pl-lg",

    LG_PL_MD = "lg:pinata-pl-md",

    LG_PL_PX = "lg:pinata-pl-px",

    LG_PL_SM = "lg:pinata-pl-sm",

    LG_PL_XL = "lg:pinata-pl-xl",

    LG_PL_XS = "lg:pinata-pl-xs",

    LG_PR_0 = "lg:pinata-pr-0",

    LG_PR_1 = "lg:pinata-pr-1",

    LG_PR_10 = "lg:pinata-pr-10",

    LG_PR_12 = "lg:pinata-pr-12",

    LG_PR_14 = "lg:pinata-pr-14",

    LG_PR_16 = "lg:pinata-pr-16",

    LG_PR_2 = "lg:pinata-pr-2",

    LG_PR_20 = "lg:pinata-pr-20",

    LG_PR_24 = "lg:pinata-pr-24",

    LG_PR_2XL = "lg:pinata-pr-2xl",

    LG_PR_3 = "lg:pinata-pr-3",

    LG_PR_32 = "lg:pinata-pr-32",

    LG_PR_3XL = "lg:pinata-pr-3xl",

    LG_PR_4 = "lg:pinata-pr-4",

    LG_PR_40 = "lg:pinata-pr-40",

    LG_PR_48 = "lg:pinata-pr-48",

    LG_PR_4XL = "lg:pinata-pr-4xl",

    LG_PR_5 = "lg:pinata-pr-5",

    LG_PR_56 = "lg:pinata-pr-56",

    LG_PR_5XL = "lg:pinata-pr-5xl",

    LG_PR_6 = "lg:pinata-pr-6",

    LG_PR_64 = "lg:pinata-pr-64",

    LG_PR_6XL = "lg:pinata-pr-6xl",

    LG_PR_8 = "lg:pinata-pr-8",

    LG_PR_FULL = "lg:pinata-pr-full",

    LG_PR_LG = "lg:pinata-pr-lg",

    LG_PR_MD = "lg:pinata-pr-md",

    LG_PR_PX = "lg:pinata-pr-px",

    LG_PR_SM = "lg:pinata-pr-sm",

    LG_PR_XL = "lg:pinata-pr-xl",

    LG_PR_XS = "lg:pinata-pr-xs",

    LG_PT_0 = "lg:pinata-pt-0",

    LG_PT_1 = "lg:pinata-pt-1",

    LG_PT_10 = "lg:pinata-pt-10",

    LG_PT_12 = "lg:pinata-pt-12",

    LG_PT_14 = "lg:pinata-pt-14",

    LG_PT_16 = "lg:pinata-pt-16",

    LG_PT_2 = "lg:pinata-pt-2",

    LG_PT_20 = "lg:pinata-pt-20",

    LG_PT_24 = "lg:pinata-pt-24",

    LG_PT_2XL = "lg:pinata-pt-2xl",

    LG_PT_3 = "lg:pinata-pt-3",

    LG_PT_32 = "lg:pinata-pt-32",

    LG_PT_3XL = "lg:pinata-pt-3xl",

    LG_PT_4 = "lg:pinata-pt-4",

    LG_PT_40 = "lg:pinata-pt-40",

    LG_PT_48 = "lg:pinata-pt-48",

    LG_PT_4XL = "lg:pinata-pt-4xl",

    LG_PT_5 = "lg:pinata-pt-5",

    LG_PT_56 = "lg:pinata-pt-56",

    LG_PT_5XL = "lg:pinata-pt-5xl",

    LG_PT_6 = "lg:pinata-pt-6",

    LG_PT_64 = "lg:pinata-pt-64",

    LG_PT_6XL = "lg:pinata-pt-6xl",

    LG_PT_8 = "lg:pinata-pt-8",

    LG_PT_FULL = "lg:pinata-pt-full",

    LG_PT_LG = "lg:pinata-pt-lg",

    LG_PT_MD = "lg:pinata-pt-md",

    LG_PT_PX = "lg:pinata-pt-px",

    LG_PT_SM = "lg:pinata-pt-sm",

    LG_PT_XL = "lg:pinata-pt-xl",

    LG_PT_XS = "lg:pinata-pt-xs",

    LG_PX_0 = "lg:pinata-px-0",

    LG_PX_1 = "lg:pinata-px-1",

    LG_PX_10 = "lg:pinata-px-10",

    LG_PX_12 = "lg:pinata-px-12",

    LG_PX_14 = "lg:pinata-px-14",

    LG_PX_16 = "lg:pinata-px-16",

    LG_PX_2 = "lg:pinata-px-2",

    LG_PX_20 = "lg:pinata-px-20",

    LG_PX_24 = "lg:pinata-px-24",

    LG_PX_2XL = "lg:pinata-px-2xl",

    LG_PX_3 = "lg:pinata-px-3",

    LG_PX_32 = "lg:pinata-px-32",

    LG_PX_3XL = "lg:pinata-px-3xl",

    LG_PX_4 = "lg:pinata-px-4",

    LG_PX_40 = "lg:pinata-px-40",

    LG_PX_48 = "lg:pinata-px-48",

    LG_PX_4XL = "lg:pinata-px-4xl",

    LG_PX_5 = "lg:pinata-px-5",

    LG_PX_56 = "lg:pinata-px-56",

    LG_PX_5XL = "lg:pinata-px-5xl",

    LG_PX_6 = "lg:pinata-px-6",

    LG_PX_64 = "lg:pinata-px-64",

    LG_PX_6XL = "lg:pinata-px-6xl",

    LG_PX_8 = "lg:pinata-px-8",

    LG_PX_FULL = "lg:pinata-px-full",

    LG_PX_LG = "lg:pinata-px-lg",

    LG_PX_MD = "lg:pinata-px-md",

    LG_PX_PX = "lg:pinata-px-px",

    LG_PX_SM = "lg:pinata-px-sm",

    LG_PX_XL = "lg:pinata-px-xl",

    LG_PX_XS = "lg:pinata-px-xs",

    LG_PY_0 = "lg:pinata-py-0",

    LG_PY_1 = "lg:pinata-py-1",

    LG_PY_10 = "lg:pinata-py-10",

    LG_PY_12 = "lg:pinata-py-12",

    LG_PY_14 = "lg:pinata-py-14",

    LG_PY_16 = "lg:pinata-py-16",

    LG_PY_2 = "lg:pinata-py-2",

    LG_PY_20 = "lg:pinata-py-20",

    LG_PY_24 = "lg:pinata-py-24",

    LG_PY_2XL = "lg:pinata-py-2xl",

    LG_PY_3 = "lg:pinata-py-3",

    LG_PY_32 = "lg:pinata-py-32",

    LG_PY_3XL = "lg:pinata-py-3xl",

    LG_PY_4 = "lg:pinata-py-4",

    LG_PY_40 = "lg:pinata-py-40",

    LG_PY_48 = "lg:pinata-py-48",

    LG_PY_4XL = "lg:pinata-py-4xl",

    LG_PY_5 = "lg:pinata-py-5",

    LG_PY_56 = "lg:pinata-py-56",

    LG_PY_5XL = "lg:pinata-py-5xl",

    LG_PY_6 = "lg:pinata-py-6",

    LG_PY_64 = "lg:pinata-py-64",

    LG_PY_6XL = "lg:pinata-py-6xl",

    LG_PY_8 = "lg:pinata-py-8",

    LG_PY_FULL = "lg:pinata-py-full",

    LG_PY_LG = "lg:pinata-py-lg",

    LG_PY_MD = "lg:pinata-py-md",

    LG_PY_PX = "lg:pinata-py-px",

    LG_PY_SM = "lg:pinata-py-sm",

    LG_PY_XL = "lg:pinata-py-xl",

    LG_PY_XS = "lg:pinata-py-xs",

    MD_P_0 = "md:pinata-p-0",

    MD_P_1 = "md:pinata-p-1",

    MD_P_10 = "md:pinata-p-10",

    MD_P_12 = "md:pinata-p-12",

    MD_P_14 = "md:pinata-p-14",

    MD_P_16 = "md:pinata-p-16",

    MD_P_2 = "md:pinata-p-2",

    MD_P_20 = "md:pinata-p-20",

    MD_P_24 = "md:pinata-p-24",

    MD_P_2XL = "md:pinata-p-2xl",

    MD_P_3 = "md:pinata-p-3",

    MD_P_32 = "md:pinata-p-32",

    MD_P_3XL = "md:pinata-p-3xl",

    MD_P_4 = "md:pinata-p-4",

    MD_P_40 = "md:pinata-p-40",

    MD_P_48 = "md:pinata-p-48",

    MD_P_4XL = "md:pinata-p-4xl",

    MD_P_5 = "md:pinata-p-5",

    MD_P_56 = "md:pinata-p-56",

    MD_P_5XL = "md:pinata-p-5xl",

    MD_P_6 = "md:pinata-p-6",

    MD_P_64 = "md:pinata-p-64",

    MD_P_6XL = "md:pinata-p-6xl",

    MD_P_8 = "md:pinata-p-8",

    MD_P_FULL = "md:pinata-p-full",

    MD_P_LG = "md:pinata-p-lg",

    MD_P_MD = "md:pinata-p-md",

    MD_P_PX = "md:pinata-p-px",

    MD_P_SM = "md:pinata-p-sm",

    MD_P_XL = "md:pinata-p-xl",

    MD_P_XS = "md:pinata-p-xs",

    MD_PB_0 = "md:pinata-pb-0",

    MD_PB_1 = "md:pinata-pb-1",

    MD_PB_10 = "md:pinata-pb-10",

    MD_PB_12 = "md:pinata-pb-12",

    MD_PB_14 = "md:pinata-pb-14",

    MD_PB_16 = "md:pinata-pb-16",

    MD_PB_2 = "md:pinata-pb-2",

    MD_PB_20 = "md:pinata-pb-20",

    MD_PB_24 = "md:pinata-pb-24",

    MD_PB_2XL = "md:pinata-pb-2xl",

    MD_PB_3 = "md:pinata-pb-3",

    MD_PB_32 = "md:pinata-pb-32",

    MD_PB_3XL = "md:pinata-pb-3xl",

    MD_PB_4 = "md:pinata-pb-4",

    MD_PB_40 = "md:pinata-pb-40",

    MD_PB_48 = "md:pinata-pb-48",

    MD_PB_4XL = "md:pinata-pb-4xl",

    MD_PB_5 = "md:pinata-pb-5",

    MD_PB_56 = "md:pinata-pb-56",

    MD_PB_5XL = "md:pinata-pb-5xl",

    MD_PB_6 = "md:pinata-pb-6",

    MD_PB_64 = "md:pinata-pb-64",

    MD_PB_6XL = "md:pinata-pb-6xl",

    MD_PB_8 = "md:pinata-pb-8",

    MD_PB_FULL = "md:pinata-pb-full",

    MD_PB_LG = "md:pinata-pb-lg",

    MD_PB_MD = "md:pinata-pb-md",

    MD_PB_PX = "md:pinata-pb-px",

    MD_PB_SM = "md:pinata-pb-sm",

    MD_PB_XL = "md:pinata-pb-xl",

    MD_PB_XS = "md:pinata-pb-xs",

    MD_PL_0 = "md:pinata-pl-0",

    MD_PL_1 = "md:pinata-pl-1",

    MD_PL_10 = "md:pinata-pl-10",

    MD_PL_12 = "md:pinata-pl-12",

    MD_PL_14 = "md:pinata-pl-14",

    MD_PL_16 = "md:pinata-pl-16",

    MD_PL_2 = "md:pinata-pl-2",

    MD_PL_20 = "md:pinata-pl-20",

    MD_PL_24 = "md:pinata-pl-24",

    MD_PL_2XL = "md:pinata-pl-2xl",

    MD_PL_3 = "md:pinata-pl-3",

    MD_PL_32 = "md:pinata-pl-32",

    MD_PL_3XL = "md:pinata-pl-3xl",

    MD_PL_4 = "md:pinata-pl-4",

    MD_PL_40 = "md:pinata-pl-40",

    MD_PL_48 = "md:pinata-pl-48",

    MD_PL_4XL = "md:pinata-pl-4xl",

    MD_PL_5 = "md:pinata-pl-5",

    MD_PL_56 = "md:pinata-pl-56",

    MD_PL_5XL = "md:pinata-pl-5xl",

    MD_PL_6 = "md:pinata-pl-6",

    MD_PL_64 = "md:pinata-pl-64",

    MD_PL_6XL = "md:pinata-pl-6xl",

    MD_PL_8 = "md:pinata-pl-8",

    MD_PL_FULL = "md:pinata-pl-full",

    MD_PL_LG = "md:pinata-pl-lg",

    MD_PL_MD = "md:pinata-pl-md",

    MD_PL_PX = "md:pinata-pl-px",

    MD_PL_SM = "md:pinata-pl-sm",

    MD_PL_XL = "md:pinata-pl-xl",

    MD_PL_XS = "md:pinata-pl-xs",

    MD_PR_0 = "md:pinata-pr-0",

    MD_PR_1 = "md:pinata-pr-1",

    MD_PR_10 = "md:pinata-pr-10",

    MD_PR_12 = "md:pinata-pr-12",

    MD_PR_14 = "md:pinata-pr-14",

    MD_PR_16 = "md:pinata-pr-16",

    MD_PR_2 = "md:pinata-pr-2",

    MD_PR_20 = "md:pinata-pr-20",

    MD_PR_24 = "md:pinata-pr-24",

    MD_PR_2XL = "md:pinata-pr-2xl",

    MD_PR_3 = "md:pinata-pr-3",

    MD_PR_32 = "md:pinata-pr-32",

    MD_PR_3XL = "md:pinata-pr-3xl",

    MD_PR_4 = "md:pinata-pr-4",

    MD_PR_40 = "md:pinata-pr-40",

    MD_PR_48 = "md:pinata-pr-48",

    MD_PR_4XL = "md:pinata-pr-4xl",

    MD_PR_5 = "md:pinata-pr-5",

    MD_PR_56 = "md:pinata-pr-56",

    MD_PR_5XL = "md:pinata-pr-5xl",

    MD_PR_6 = "md:pinata-pr-6",

    MD_PR_64 = "md:pinata-pr-64",

    MD_PR_6XL = "md:pinata-pr-6xl",

    MD_PR_8 = "md:pinata-pr-8",

    MD_PR_FULL = "md:pinata-pr-full",

    MD_PR_LG = "md:pinata-pr-lg",

    MD_PR_MD = "md:pinata-pr-md",

    MD_PR_PX = "md:pinata-pr-px",

    MD_PR_SM = "md:pinata-pr-sm",

    MD_PR_XL = "md:pinata-pr-xl",

    MD_PR_XS = "md:pinata-pr-xs",

    MD_PT_0 = "md:pinata-pt-0",

    MD_PT_1 = "md:pinata-pt-1",

    MD_PT_10 = "md:pinata-pt-10",

    MD_PT_12 = "md:pinata-pt-12",

    MD_PT_14 = "md:pinata-pt-14",

    MD_PT_16 = "md:pinata-pt-16",

    MD_PT_2 = "md:pinata-pt-2",

    MD_PT_20 = "md:pinata-pt-20",

    MD_PT_24 = "md:pinata-pt-24",

    MD_PT_2XL = "md:pinata-pt-2xl",

    MD_PT_3 = "md:pinata-pt-3",

    MD_PT_32 = "md:pinata-pt-32",

    MD_PT_3XL = "md:pinata-pt-3xl",

    MD_PT_4 = "md:pinata-pt-4",

    MD_PT_40 = "md:pinata-pt-40",

    MD_PT_48 = "md:pinata-pt-48",

    MD_PT_4XL = "md:pinata-pt-4xl",

    MD_PT_5 = "md:pinata-pt-5",

    MD_PT_56 = "md:pinata-pt-56",

    MD_PT_5XL = "md:pinata-pt-5xl",

    MD_PT_6 = "md:pinata-pt-6",

    MD_PT_64 = "md:pinata-pt-64",

    MD_PT_6XL = "md:pinata-pt-6xl",

    MD_PT_8 = "md:pinata-pt-8",

    MD_PT_FULL = "md:pinata-pt-full",

    MD_PT_LG = "md:pinata-pt-lg",

    MD_PT_MD = "md:pinata-pt-md",

    MD_PT_PX = "md:pinata-pt-px",

    MD_PT_SM = "md:pinata-pt-sm",

    MD_PT_XL = "md:pinata-pt-xl",

    MD_PT_XS = "md:pinata-pt-xs",

    MD_PX_0 = "md:pinata-px-0",

    MD_PX_1 = "md:pinata-px-1",

    MD_PX_10 = "md:pinata-px-10",

    MD_PX_12 = "md:pinata-px-12",

    MD_PX_14 = "md:pinata-px-14",

    MD_PX_16 = "md:pinata-px-16",

    MD_PX_2 = "md:pinata-px-2",

    MD_PX_20 = "md:pinata-px-20",

    MD_PX_24 = "md:pinata-px-24",

    MD_PX_2XL = "md:pinata-px-2xl",

    MD_PX_3 = "md:pinata-px-3",

    MD_PX_32 = "md:pinata-px-32",

    MD_PX_3XL = "md:pinata-px-3xl",

    MD_PX_4 = "md:pinata-px-4",

    MD_PX_40 = "md:pinata-px-40",

    MD_PX_48 = "md:pinata-px-48",

    MD_PX_4XL = "md:pinata-px-4xl",

    MD_PX_5 = "md:pinata-px-5",

    MD_PX_56 = "md:pinata-px-56",

    MD_PX_5XL = "md:pinata-px-5xl",

    MD_PX_6 = "md:pinata-px-6",

    MD_PX_64 = "md:pinata-px-64",

    MD_PX_6XL = "md:pinata-px-6xl",

    MD_PX_8 = "md:pinata-px-8",

    MD_PX_FULL = "md:pinata-px-full",

    MD_PX_LG = "md:pinata-px-lg",

    MD_PX_MD = "md:pinata-px-md",

    MD_PX_PX = "md:pinata-px-px",

    MD_PX_SM = "md:pinata-px-sm",

    MD_PX_XL = "md:pinata-px-xl",

    MD_PX_XS = "md:pinata-px-xs",

    MD_PY_0 = "md:pinata-py-0",

    MD_PY_1 = "md:pinata-py-1",

    MD_PY_10 = "md:pinata-py-10",

    MD_PY_12 = "md:pinata-py-12",

    MD_PY_14 = "md:pinata-py-14",

    MD_PY_16 = "md:pinata-py-16",

    MD_PY_2 = "md:pinata-py-2",

    MD_PY_20 = "md:pinata-py-20",

    MD_PY_24 = "md:pinata-py-24",

    MD_PY_2XL = "md:pinata-py-2xl",

    MD_PY_3 = "md:pinata-py-3",

    MD_PY_32 = "md:pinata-py-32",

    MD_PY_3XL = "md:pinata-py-3xl",

    MD_PY_4 = "md:pinata-py-4",

    MD_PY_40 = "md:pinata-py-40",

    MD_PY_48 = "md:pinata-py-48",

    MD_PY_4XL = "md:pinata-py-4xl",

    MD_PY_5 = "md:pinata-py-5",

    MD_PY_56 = "md:pinata-py-56",

    MD_PY_5XL = "md:pinata-py-5xl",

    MD_PY_6 = "md:pinata-py-6",

    MD_PY_64 = "md:pinata-py-64",

    MD_PY_6XL = "md:pinata-py-6xl",

    MD_PY_8 = "md:pinata-py-8",

    MD_PY_FULL = "md:pinata-py-full",

    MD_PY_LG = "md:pinata-py-lg",

    MD_PY_MD = "md:pinata-py-md",

    MD_PY_PX = "md:pinata-py-px",

    MD_PY_SM = "md:pinata-py-sm",

    MD_PY_XL = "md:pinata-py-xl",

    MD_PY_XS = "md:pinata-py-xs",

    SM_P_0 = "sm:pinata-p-0",

    SM_P_1 = "sm:pinata-p-1",

    SM_P_10 = "sm:pinata-p-10",

    SM_P_12 = "sm:pinata-p-12",

    SM_P_14 = "sm:pinata-p-14",

    SM_P_16 = "sm:pinata-p-16",

    SM_P_2 = "sm:pinata-p-2",

    SM_P_20 = "sm:pinata-p-20",

    SM_P_24 = "sm:pinata-p-24",

    SM_P_2XL = "sm:pinata-p-2xl",

    SM_P_3 = "sm:pinata-p-3",

    SM_P_32 = "sm:pinata-p-32",

    SM_P_3XL = "sm:pinata-p-3xl",

    SM_P_4 = "sm:pinata-p-4",

    SM_P_40 = "sm:pinata-p-40",

    SM_P_48 = "sm:pinata-p-48",

    SM_P_4XL = "sm:pinata-p-4xl",

    SM_P_5 = "sm:pinata-p-5",

    SM_P_56 = "sm:pinata-p-56",

    SM_P_5XL = "sm:pinata-p-5xl",

    SM_P_6 = "sm:pinata-p-6",

    SM_P_64 = "sm:pinata-p-64",

    SM_P_6XL = "sm:pinata-p-6xl",

    SM_P_8 = "sm:pinata-p-8",

    SM_P_FULL = "sm:pinata-p-full",

    SM_P_LG = "sm:pinata-p-lg",

    SM_P_MD = "sm:pinata-p-md",

    SM_P_PX = "sm:pinata-p-px",

    SM_P_SM = "sm:pinata-p-sm",

    SM_P_XL = "sm:pinata-p-xl",

    SM_P_XS = "sm:pinata-p-xs",

    SM_PB_0 = "sm:pinata-pb-0",

    SM_PB_1 = "sm:pinata-pb-1",

    SM_PB_10 = "sm:pinata-pb-10",

    SM_PB_12 = "sm:pinata-pb-12",

    SM_PB_14 = "sm:pinata-pb-14",

    SM_PB_16 = "sm:pinata-pb-16",

    SM_PB_2 = "sm:pinata-pb-2",

    SM_PB_20 = "sm:pinata-pb-20",

    SM_PB_24 = "sm:pinata-pb-24",

    SM_PB_2XL = "sm:pinata-pb-2xl",

    SM_PB_3 = "sm:pinata-pb-3",

    SM_PB_32 = "sm:pinata-pb-32",

    SM_PB_3XL = "sm:pinata-pb-3xl",

    SM_PB_4 = "sm:pinata-pb-4",

    SM_PB_40 = "sm:pinata-pb-40",

    SM_PB_48 = "sm:pinata-pb-48",

    SM_PB_4XL = "sm:pinata-pb-4xl",

    SM_PB_5 = "sm:pinata-pb-5",

    SM_PB_56 = "sm:pinata-pb-56",

    SM_PB_5XL = "sm:pinata-pb-5xl",

    SM_PB_6 = "sm:pinata-pb-6",

    SM_PB_64 = "sm:pinata-pb-64",

    SM_PB_6XL = "sm:pinata-pb-6xl",

    SM_PB_8 = "sm:pinata-pb-8",

    SM_PB_FULL = "sm:pinata-pb-full",

    SM_PB_LG = "sm:pinata-pb-lg",

    SM_PB_MD = "sm:pinata-pb-md",

    SM_PB_PX = "sm:pinata-pb-px",

    SM_PB_SM = "sm:pinata-pb-sm",

    SM_PB_XL = "sm:pinata-pb-xl",

    SM_PB_XS = "sm:pinata-pb-xs",

    SM_PL_0 = "sm:pinata-pl-0",

    SM_PL_1 = "sm:pinata-pl-1",

    SM_PL_10 = "sm:pinata-pl-10",

    SM_PL_12 = "sm:pinata-pl-12",

    SM_PL_14 = "sm:pinata-pl-14",

    SM_PL_16 = "sm:pinata-pl-16",

    SM_PL_2 = "sm:pinata-pl-2",

    SM_PL_20 = "sm:pinata-pl-20",

    SM_PL_24 = "sm:pinata-pl-24",

    SM_PL_2XL = "sm:pinata-pl-2xl",

    SM_PL_3 = "sm:pinata-pl-3",

    SM_PL_32 = "sm:pinata-pl-32",

    SM_PL_3XL = "sm:pinata-pl-3xl",

    SM_PL_4 = "sm:pinata-pl-4",

    SM_PL_40 = "sm:pinata-pl-40",

    SM_PL_48 = "sm:pinata-pl-48",

    SM_PL_4XL = "sm:pinata-pl-4xl",

    SM_PL_5 = "sm:pinata-pl-5",

    SM_PL_56 = "sm:pinata-pl-56",

    SM_PL_5XL = "sm:pinata-pl-5xl",

    SM_PL_6 = "sm:pinata-pl-6",

    SM_PL_64 = "sm:pinata-pl-64",

    SM_PL_6XL = "sm:pinata-pl-6xl",

    SM_PL_8 = "sm:pinata-pl-8",

    SM_PL_FULL = "sm:pinata-pl-full",

    SM_PL_LG = "sm:pinata-pl-lg",

    SM_PL_MD = "sm:pinata-pl-md",

    SM_PL_PX = "sm:pinata-pl-px",

    SM_PL_SM = "sm:pinata-pl-sm",

    SM_PL_XL = "sm:pinata-pl-xl",

    SM_PL_XS = "sm:pinata-pl-xs",

    SM_PR_0 = "sm:pinata-pr-0",

    SM_PR_1 = "sm:pinata-pr-1",

    SM_PR_10 = "sm:pinata-pr-10",

    SM_PR_12 = "sm:pinata-pr-12",

    SM_PR_14 = "sm:pinata-pr-14",

    SM_PR_16 = "sm:pinata-pr-16",

    SM_PR_2 = "sm:pinata-pr-2",

    SM_PR_20 = "sm:pinata-pr-20",

    SM_PR_24 = "sm:pinata-pr-24",

    SM_PR_2XL = "sm:pinata-pr-2xl",

    SM_PR_3 = "sm:pinata-pr-3",

    SM_PR_32 = "sm:pinata-pr-32",

    SM_PR_3XL = "sm:pinata-pr-3xl",

    SM_PR_4 = "sm:pinata-pr-4",

    SM_PR_40 = "sm:pinata-pr-40",

    SM_PR_48 = "sm:pinata-pr-48",

    SM_PR_4XL = "sm:pinata-pr-4xl",

    SM_PR_5 = "sm:pinata-pr-5",

    SM_PR_56 = "sm:pinata-pr-56",

    SM_PR_5XL = "sm:pinata-pr-5xl",

    SM_PR_6 = "sm:pinata-pr-6",

    SM_PR_64 = "sm:pinata-pr-64",

    SM_PR_6XL = "sm:pinata-pr-6xl",

    SM_PR_8 = "sm:pinata-pr-8",

    SM_PR_FULL = "sm:pinata-pr-full",

    SM_PR_LG = "sm:pinata-pr-lg",

    SM_PR_MD = "sm:pinata-pr-md",

    SM_PR_PX = "sm:pinata-pr-px",

    SM_PR_SM = "sm:pinata-pr-sm",

    SM_PR_XL = "sm:pinata-pr-xl",

    SM_PR_XS = "sm:pinata-pr-xs",

    SM_PT_0 = "sm:pinata-pt-0",

    SM_PT_1 = "sm:pinata-pt-1",

    SM_PT_10 = "sm:pinata-pt-10",

    SM_PT_12 = "sm:pinata-pt-12",

    SM_PT_14 = "sm:pinata-pt-14",

    SM_PT_16 = "sm:pinata-pt-16",

    SM_PT_2 = "sm:pinata-pt-2",

    SM_PT_20 = "sm:pinata-pt-20",

    SM_PT_24 = "sm:pinata-pt-24",

    SM_PT_2XL = "sm:pinata-pt-2xl",

    SM_PT_3 = "sm:pinata-pt-3",

    SM_PT_32 = "sm:pinata-pt-32",

    SM_PT_3XL = "sm:pinata-pt-3xl",

    SM_PT_4 = "sm:pinata-pt-4",

    SM_PT_40 = "sm:pinata-pt-40",

    SM_PT_48 = "sm:pinata-pt-48",

    SM_PT_4XL = "sm:pinata-pt-4xl",

    SM_PT_5 = "sm:pinata-pt-5",

    SM_PT_56 = "sm:pinata-pt-56",

    SM_PT_5XL = "sm:pinata-pt-5xl",

    SM_PT_6 = "sm:pinata-pt-6",

    SM_PT_64 = "sm:pinata-pt-64",

    SM_PT_6XL = "sm:pinata-pt-6xl",

    SM_PT_8 = "sm:pinata-pt-8",

    SM_PT_FULL = "sm:pinata-pt-full",

    SM_PT_LG = "sm:pinata-pt-lg",

    SM_PT_MD = "sm:pinata-pt-md",

    SM_PT_PX = "sm:pinata-pt-px",

    SM_PT_SM = "sm:pinata-pt-sm",

    SM_PT_XL = "sm:pinata-pt-xl",

    SM_PT_XS = "sm:pinata-pt-xs",

    SM_PX_0 = "sm:pinata-px-0",

    SM_PX_1 = "sm:pinata-px-1",

    SM_PX_10 = "sm:pinata-px-10",

    SM_PX_12 = "sm:pinata-px-12",

    SM_PX_14 = "sm:pinata-px-14",

    SM_PX_16 = "sm:pinata-px-16",

    SM_PX_2 = "sm:pinata-px-2",

    SM_PX_20 = "sm:pinata-px-20",

    SM_PX_24 = "sm:pinata-px-24",

    SM_PX_2XL = "sm:pinata-px-2xl",

    SM_PX_3 = "sm:pinata-px-3",

    SM_PX_32 = "sm:pinata-px-32",

    SM_PX_3XL = "sm:pinata-px-3xl",

    SM_PX_4 = "sm:pinata-px-4",

    SM_PX_40 = "sm:pinata-px-40",

    SM_PX_48 = "sm:pinata-px-48",

    SM_PX_4XL = "sm:pinata-px-4xl",

    SM_PX_5 = "sm:pinata-px-5",

    SM_PX_56 = "sm:pinata-px-56",

    SM_PX_5XL = "sm:pinata-px-5xl",

    SM_PX_6 = "sm:pinata-px-6",

    SM_PX_64 = "sm:pinata-px-64",

    SM_PX_6XL = "sm:pinata-px-6xl",

    SM_PX_8 = "sm:pinata-px-8",

    SM_PX_FULL = "sm:pinata-px-full",

    SM_PX_LG = "sm:pinata-px-lg",

    SM_PX_MD = "sm:pinata-px-md",

    SM_PX_PX = "sm:pinata-px-px",

    SM_PX_SM = "sm:pinata-px-sm",

    SM_PX_XL = "sm:pinata-px-xl",

    SM_PX_XS = "sm:pinata-px-xs",

    SM_PY_0 = "sm:pinata-py-0",

    SM_PY_1 = "sm:pinata-py-1",

    SM_PY_10 = "sm:pinata-py-10",

    SM_PY_12 = "sm:pinata-py-12",

    SM_PY_14 = "sm:pinata-py-14",

    SM_PY_16 = "sm:pinata-py-16",

    SM_PY_2 = "sm:pinata-py-2",

    SM_PY_20 = "sm:pinata-py-20",

    SM_PY_24 = "sm:pinata-py-24",

    SM_PY_2XL = "sm:pinata-py-2xl",

    SM_PY_3 = "sm:pinata-py-3",

    SM_PY_32 = "sm:pinata-py-32",

    SM_PY_3XL = "sm:pinata-py-3xl",

    SM_PY_4 = "sm:pinata-py-4",

    SM_PY_40 = "sm:pinata-py-40",

    SM_PY_48 = "sm:pinata-py-48",

    SM_PY_4XL = "sm:pinata-py-4xl",

    SM_PY_5 = "sm:pinata-py-5",

    SM_PY_56 = "sm:pinata-py-56",

    SM_PY_5XL = "sm:pinata-py-5xl",

    SM_PY_6 = "sm:pinata-py-6",

    SM_PY_64 = "sm:pinata-py-64",

    SM_PY_6XL = "sm:pinata-py-6xl",

    SM_PY_8 = "sm:pinata-py-8",

    SM_PY_FULL = "sm:pinata-py-full",

    SM_PY_LG = "sm:pinata-py-lg",

    SM_PY_MD = "sm:pinata-py-md",

    SM_PY_PX = "sm:pinata-py-px",

    SM_PY_SM = "sm:pinata-py-sm",

    SM_PY_XL = "sm:pinata-py-xl",

    SM_PY_XS = "sm:pinata-py-xs",

    XL_P_0 = "xl:pinata-p-0",

    XL_P_1 = "xl:pinata-p-1",

    XL_P_10 = "xl:pinata-p-10",

    XL_P_12 = "xl:pinata-p-12",

    XL_P_14 = "xl:pinata-p-14",

    XL_P_16 = "xl:pinata-p-16",

    XL_P_2 = "xl:pinata-p-2",

    XL_P_20 = "xl:pinata-p-20",

    XL_P_24 = "xl:pinata-p-24",

    XL_P_2XL = "xl:pinata-p-2xl",

    XL_P_3 = "xl:pinata-p-3",

    XL_P_32 = "xl:pinata-p-32",

    XL_P_3XL = "xl:pinata-p-3xl",

    XL_P_4 = "xl:pinata-p-4",

    XL_P_40 = "xl:pinata-p-40",

    XL_P_48 = "xl:pinata-p-48",

    XL_P_4XL = "xl:pinata-p-4xl",

    XL_P_5 = "xl:pinata-p-5",

    XL_P_56 = "xl:pinata-p-56",

    XL_P_5XL = "xl:pinata-p-5xl",

    XL_P_6 = "xl:pinata-p-6",

    XL_P_64 = "xl:pinata-p-64",

    XL_P_6XL = "xl:pinata-p-6xl",

    XL_P_8 = "xl:pinata-p-8",

    XL_P_FULL = "xl:pinata-p-full",

    XL_P_LG = "xl:pinata-p-lg",

    XL_P_MD = "xl:pinata-p-md",

    XL_P_PX = "xl:pinata-p-px",

    XL_P_SM = "xl:pinata-p-sm",

    XL_P_XL = "xl:pinata-p-xl",

    XL_P_XS = "xl:pinata-p-xs",

    XL_PB_0 = "xl:pinata-pb-0",

    XL_PB_1 = "xl:pinata-pb-1",

    XL_PB_10 = "xl:pinata-pb-10",

    XL_PB_12 = "xl:pinata-pb-12",

    XL_PB_14 = "xl:pinata-pb-14",

    XL_PB_16 = "xl:pinata-pb-16",

    XL_PB_2 = "xl:pinata-pb-2",

    XL_PB_20 = "xl:pinata-pb-20",

    XL_PB_24 = "xl:pinata-pb-24",

    XL_PB_2XL = "xl:pinata-pb-2xl",

    XL_PB_3 = "xl:pinata-pb-3",

    XL_PB_32 = "xl:pinata-pb-32",

    XL_PB_3XL = "xl:pinata-pb-3xl",

    XL_PB_4 = "xl:pinata-pb-4",

    XL_PB_40 = "xl:pinata-pb-40",

    XL_PB_48 = "xl:pinata-pb-48",

    XL_PB_4XL = "xl:pinata-pb-4xl",

    XL_PB_5 = "xl:pinata-pb-5",

    XL_PB_56 = "xl:pinata-pb-56",

    XL_PB_5XL = "xl:pinata-pb-5xl",

    XL_PB_6 = "xl:pinata-pb-6",

    XL_PB_64 = "xl:pinata-pb-64",

    XL_PB_6XL = "xl:pinata-pb-6xl",

    XL_PB_8 = "xl:pinata-pb-8",

    XL_PB_FULL = "xl:pinata-pb-full",

    XL_PB_LG = "xl:pinata-pb-lg",

    XL_PB_MD = "xl:pinata-pb-md",

    XL_PB_PX = "xl:pinata-pb-px",

    XL_PB_SM = "xl:pinata-pb-sm",

    XL_PB_XL = "xl:pinata-pb-xl",

    XL_PB_XS = "xl:pinata-pb-xs",

    XL_PL_0 = "xl:pinata-pl-0",

    XL_PL_1 = "xl:pinata-pl-1",

    XL_PL_10 = "xl:pinata-pl-10",

    XL_PL_12 = "xl:pinata-pl-12",

    XL_PL_14 = "xl:pinata-pl-14",

    XL_PL_16 = "xl:pinata-pl-16",

    XL_PL_2 = "xl:pinata-pl-2",

    XL_PL_20 = "xl:pinata-pl-20",

    XL_PL_24 = "xl:pinata-pl-24",

    XL_PL_2XL = "xl:pinata-pl-2xl",

    XL_PL_3 = "xl:pinata-pl-3",

    XL_PL_32 = "xl:pinata-pl-32",

    XL_PL_3XL = "xl:pinata-pl-3xl",

    XL_PL_4 = "xl:pinata-pl-4",

    XL_PL_40 = "xl:pinata-pl-40",

    XL_PL_48 = "xl:pinata-pl-48",

    XL_PL_4XL = "xl:pinata-pl-4xl",

    XL_PL_5 = "xl:pinata-pl-5",

    XL_PL_56 = "xl:pinata-pl-56",

    XL_PL_5XL = "xl:pinata-pl-5xl",

    XL_PL_6 = "xl:pinata-pl-6",

    XL_PL_64 = "xl:pinata-pl-64",

    XL_PL_6XL = "xl:pinata-pl-6xl",

    XL_PL_8 = "xl:pinata-pl-8",

    XL_PL_FULL = "xl:pinata-pl-full",

    XL_PL_LG = "xl:pinata-pl-lg",

    XL_PL_MD = "xl:pinata-pl-md",

    XL_PL_PX = "xl:pinata-pl-px",

    XL_PL_SM = "xl:pinata-pl-sm",

    XL_PL_XL = "xl:pinata-pl-xl",

    XL_PL_XS = "xl:pinata-pl-xs",

    XL_PR_0 = "xl:pinata-pr-0",

    XL_PR_1 = "xl:pinata-pr-1",

    XL_PR_10 = "xl:pinata-pr-10",

    XL_PR_12 = "xl:pinata-pr-12",

    XL_PR_14 = "xl:pinata-pr-14",

    XL_PR_16 = "xl:pinata-pr-16",

    XL_PR_2 = "xl:pinata-pr-2",

    XL_PR_20 = "xl:pinata-pr-20",

    XL_PR_24 = "xl:pinata-pr-24",

    XL_PR_2XL = "xl:pinata-pr-2xl",

    XL_PR_3 = "xl:pinata-pr-3",

    XL_PR_32 = "xl:pinata-pr-32",

    XL_PR_3XL = "xl:pinata-pr-3xl",

    XL_PR_4 = "xl:pinata-pr-4",

    XL_PR_40 = "xl:pinata-pr-40",

    XL_PR_48 = "xl:pinata-pr-48",

    XL_PR_4XL = "xl:pinata-pr-4xl",

    XL_PR_5 = "xl:pinata-pr-5",

    XL_PR_56 = "xl:pinata-pr-56",

    XL_PR_5XL = "xl:pinata-pr-5xl",

    XL_PR_6 = "xl:pinata-pr-6",

    XL_PR_64 = "xl:pinata-pr-64",

    XL_PR_6XL = "xl:pinata-pr-6xl",

    XL_PR_8 = "xl:pinata-pr-8",

    XL_PR_FULL = "xl:pinata-pr-full",

    XL_PR_LG = "xl:pinata-pr-lg",

    XL_PR_MD = "xl:pinata-pr-md",

    XL_PR_PX = "xl:pinata-pr-px",

    XL_PR_SM = "xl:pinata-pr-sm",

    XL_PR_XL = "xl:pinata-pr-xl",

    XL_PR_XS = "xl:pinata-pr-xs",

    XL_PT_0 = "xl:pinata-pt-0",

    XL_PT_1 = "xl:pinata-pt-1",

    XL_PT_10 = "xl:pinata-pt-10",

    XL_PT_12 = "xl:pinata-pt-12",

    XL_PT_14 = "xl:pinata-pt-14",

    XL_PT_16 = "xl:pinata-pt-16",

    XL_PT_2 = "xl:pinata-pt-2",

    XL_PT_20 = "xl:pinata-pt-20",

    XL_PT_24 = "xl:pinata-pt-24",

    XL_PT_2XL = "xl:pinata-pt-2xl",

    XL_PT_3 = "xl:pinata-pt-3",

    XL_PT_32 = "xl:pinata-pt-32",

    XL_PT_3XL = "xl:pinata-pt-3xl",

    XL_PT_4 = "xl:pinata-pt-4",

    XL_PT_40 = "xl:pinata-pt-40",

    XL_PT_48 = "xl:pinata-pt-48",

    XL_PT_4XL = "xl:pinata-pt-4xl",

    XL_PT_5 = "xl:pinata-pt-5",

    XL_PT_56 = "xl:pinata-pt-56",

    XL_PT_5XL = "xl:pinata-pt-5xl",

    XL_PT_6 = "xl:pinata-pt-6",

    XL_PT_64 = "xl:pinata-pt-64",

    XL_PT_6XL = "xl:pinata-pt-6xl",

    XL_PT_8 = "xl:pinata-pt-8",

    XL_PT_FULL = "xl:pinata-pt-full",

    XL_PT_LG = "xl:pinata-pt-lg",

    XL_PT_MD = "xl:pinata-pt-md",

    XL_PT_PX = "xl:pinata-pt-px",

    XL_PT_SM = "xl:pinata-pt-sm",

    XL_PT_XL = "xl:pinata-pt-xl",

    XL_PT_XS = "xl:pinata-pt-xs",

    XL_PX_0 = "xl:pinata-px-0",

    XL_PX_1 = "xl:pinata-px-1",

    XL_PX_10 = "xl:pinata-px-10",

    XL_PX_12 = "xl:pinata-px-12",

    XL_PX_14 = "xl:pinata-px-14",

    XL_PX_16 = "xl:pinata-px-16",

    XL_PX_2 = "xl:pinata-px-2",

    XL_PX_20 = "xl:pinata-px-20",

    XL_PX_24 = "xl:pinata-px-24",

    XL_PX_2XL = "xl:pinata-px-2xl",

    XL_PX_3 = "xl:pinata-px-3",

    XL_PX_32 = "xl:pinata-px-32",

    XL_PX_3XL = "xl:pinata-px-3xl",

    XL_PX_4 = "xl:pinata-px-4",

    XL_PX_40 = "xl:pinata-px-40",

    XL_PX_48 = "xl:pinata-px-48",

    XL_PX_4XL = "xl:pinata-px-4xl",

    XL_PX_5 = "xl:pinata-px-5",

    XL_PX_56 = "xl:pinata-px-56",

    XL_PX_5XL = "xl:pinata-px-5xl",

    XL_PX_6 = "xl:pinata-px-6",

    XL_PX_64 = "xl:pinata-px-64",

    XL_PX_6XL = "xl:pinata-px-6xl",

    XL_PX_8 = "xl:pinata-px-8",

    XL_PX_FULL = "xl:pinata-px-full",

    XL_PX_LG = "xl:pinata-px-lg",

    XL_PX_MD = "xl:pinata-px-md",

    XL_PX_PX = "xl:pinata-px-px",

    XL_PX_SM = "xl:pinata-px-sm",

    XL_PX_XL = "xl:pinata-px-xl",

    XL_PX_XS = "xl:pinata-px-xs",

    XL_PY_0 = "xl:pinata-py-0",

    XL_PY_1 = "xl:pinata-py-1",

    XL_PY_10 = "xl:pinata-py-10",

    XL_PY_12 = "xl:pinata-py-12",

    XL_PY_14 = "xl:pinata-py-14",

    XL_PY_16 = "xl:pinata-py-16",

    XL_PY_2 = "xl:pinata-py-2",

    XL_PY_20 = "xl:pinata-py-20",

    XL_PY_24 = "xl:pinata-py-24",

    XL_PY_2XL = "xl:pinata-py-2xl",

    XL_PY_3 = "xl:pinata-py-3",

    XL_PY_32 = "xl:pinata-py-32",

    XL_PY_3XL = "xl:pinata-py-3xl",

    XL_PY_4 = "xl:pinata-py-4",

    XL_PY_40 = "xl:pinata-py-40",

    XL_PY_48 = "xl:pinata-py-48",

    XL_PY_4XL = "xl:pinata-py-4xl",

    XL_PY_5 = "xl:pinata-py-5",

    XL_PY_56 = "xl:pinata-py-56",

    XL_PY_5XL = "xl:pinata-py-5xl",

    XL_PY_6 = "xl:pinata-py-6",

    XL_PY_64 = "xl:pinata-py-64",

    XL_PY_6XL = "xl:pinata-py-6xl",

    XL_PY_8 = "xl:pinata-py-8",

    XL_PY_FULL = "xl:pinata-py-full",

    XL_PY_LG = "xl:pinata-py-lg",

    XL_PY_MD = "xl:pinata-py-md",

    XL_PY_PX = "xl:pinata-py-px",

    XL_PY_SM = "xl:pinata-py-sm",

    XL_PY_XL = "xl:pinata-py-xl",

    XL_PY_XS = "xl:pinata-py-xs",

    XS_P_0 = "xs:pinata-p-0",

    XS_P_1 = "xs:pinata-p-1",

    XS_P_10 = "xs:pinata-p-10",

    XS_P_12 = "xs:pinata-p-12",

    XS_P_14 = "xs:pinata-p-14",

    XS_P_16 = "xs:pinata-p-16",

    XS_P_2 = "xs:pinata-p-2",

    XS_P_20 = "xs:pinata-p-20",

    XS_P_24 = "xs:pinata-p-24",

    XS_P_2XL = "xs:pinata-p-2xl",

    XS_P_3 = "xs:pinata-p-3",

    XS_P_32 = "xs:pinata-p-32",

    XS_P_3XL = "xs:pinata-p-3xl",

    XS_P_4 = "xs:pinata-p-4",

    XS_P_40 = "xs:pinata-p-40",

    XS_P_48 = "xs:pinata-p-48",

    XS_P_4XL = "xs:pinata-p-4xl",

    XS_P_5 = "xs:pinata-p-5",

    XS_P_56 = "xs:pinata-p-56",

    XS_P_5XL = "xs:pinata-p-5xl",

    XS_P_6 = "xs:pinata-p-6",

    XS_P_64 = "xs:pinata-p-64",

    XS_P_6XL = "xs:pinata-p-6xl",

    XS_P_8 = "xs:pinata-p-8",

    XS_P_FULL = "xs:pinata-p-full",

    XS_P_LG = "xs:pinata-p-lg",

    XS_P_MD = "xs:pinata-p-md",

    XS_P_PX = "xs:pinata-p-px",

    XS_P_SM = "xs:pinata-p-sm",

    XS_P_XL = "xs:pinata-p-xl",

    XS_P_XS = "xs:pinata-p-xs",

    XS_PB_0 = "xs:pinata-pb-0",

    XS_PB_1 = "xs:pinata-pb-1",

    XS_PB_10 = "xs:pinata-pb-10",

    XS_PB_12 = "xs:pinata-pb-12",

    XS_PB_14 = "xs:pinata-pb-14",

    XS_PB_16 = "xs:pinata-pb-16",

    XS_PB_2 = "xs:pinata-pb-2",

    XS_PB_20 = "xs:pinata-pb-20",

    XS_PB_24 = "xs:pinata-pb-24",

    XS_PB_2XL = "xs:pinata-pb-2xl",

    XS_PB_3 = "xs:pinata-pb-3",

    XS_PB_32 = "xs:pinata-pb-32",

    XS_PB_3XL = "xs:pinata-pb-3xl",

    XS_PB_4 = "xs:pinata-pb-4",

    XS_PB_40 = "xs:pinata-pb-40",

    XS_PB_48 = "xs:pinata-pb-48",

    XS_PB_4XL = "xs:pinata-pb-4xl",

    XS_PB_5 = "xs:pinata-pb-5",

    XS_PB_56 = "xs:pinata-pb-56",

    XS_PB_5XL = "xs:pinata-pb-5xl",

    XS_PB_6 = "xs:pinata-pb-6",

    XS_PB_64 = "xs:pinata-pb-64",

    XS_PB_6XL = "xs:pinata-pb-6xl",

    XS_PB_8 = "xs:pinata-pb-8",

    XS_PB_FULL = "xs:pinata-pb-full",

    XS_PB_LG = "xs:pinata-pb-lg",

    XS_PB_MD = "xs:pinata-pb-md",

    XS_PB_PX = "xs:pinata-pb-px",

    XS_PB_SM = "xs:pinata-pb-sm",

    XS_PB_XL = "xs:pinata-pb-xl",

    XS_PB_XS = "xs:pinata-pb-xs",

    XS_PL_0 = "xs:pinata-pl-0",

    XS_PL_1 = "xs:pinata-pl-1",

    XS_PL_10 = "xs:pinata-pl-10",

    XS_PL_12 = "xs:pinata-pl-12",

    XS_PL_14 = "xs:pinata-pl-14",

    XS_PL_16 = "xs:pinata-pl-16",

    XS_PL_2 = "xs:pinata-pl-2",

    XS_PL_20 = "xs:pinata-pl-20",

    XS_PL_24 = "xs:pinata-pl-24",

    XS_PL_2XL = "xs:pinata-pl-2xl",

    XS_PL_3 = "xs:pinata-pl-3",

    XS_PL_32 = "xs:pinata-pl-32",

    XS_PL_3XL = "xs:pinata-pl-3xl",

    XS_PL_4 = "xs:pinata-pl-4",

    XS_PL_40 = "xs:pinata-pl-40",

    XS_PL_48 = "xs:pinata-pl-48",

    XS_PL_4XL = "xs:pinata-pl-4xl",

    XS_PL_5 = "xs:pinata-pl-5",

    XS_PL_56 = "xs:pinata-pl-56",

    XS_PL_5XL = "xs:pinata-pl-5xl",

    XS_PL_6 = "xs:pinata-pl-6",

    XS_PL_64 = "xs:pinata-pl-64",

    XS_PL_6XL = "xs:pinata-pl-6xl",

    XS_PL_8 = "xs:pinata-pl-8",

    XS_PL_FULL = "xs:pinata-pl-full",

    XS_PL_LG = "xs:pinata-pl-lg",

    XS_PL_MD = "xs:pinata-pl-md",

    XS_PL_PX = "xs:pinata-pl-px",

    XS_PL_SM = "xs:pinata-pl-sm",

    XS_PL_XL = "xs:pinata-pl-xl",

    XS_PL_XS = "xs:pinata-pl-xs",

    XS_PR_0 = "xs:pinata-pr-0",

    XS_PR_1 = "xs:pinata-pr-1",

    XS_PR_10 = "xs:pinata-pr-10",

    XS_PR_12 = "xs:pinata-pr-12",

    XS_PR_14 = "xs:pinata-pr-14",

    XS_PR_16 = "xs:pinata-pr-16",

    XS_PR_2 = "xs:pinata-pr-2",

    XS_PR_20 = "xs:pinata-pr-20",

    XS_PR_24 = "xs:pinata-pr-24",

    XS_PR_2XL = "xs:pinata-pr-2xl",

    XS_PR_3 = "xs:pinata-pr-3",

    XS_PR_32 = "xs:pinata-pr-32",

    XS_PR_3XL = "xs:pinata-pr-3xl",

    XS_PR_4 = "xs:pinata-pr-4",

    XS_PR_40 = "xs:pinata-pr-40",

    XS_PR_48 = "xs:pinata-pr-48",

    XS_PR_4XL = "xs:pinata-pr-4xl",

    XS_PR_5 = "xs:pinata-pr-5",

    XS_PR_56 = "xs:pinata-pr-56",

    XS_PR_5XL = "xs:pinata-pr-5xl",

    XS_PR_6 = "xs:pinata-pr-6",

    XS_PR_64 = "xs:pinata-pr-64",

    XS_PR_6XL = "xs:pinata-pr-6xl",

    XS_PR_8 = "xs:pinata-pr-8",

    XS_PR_FULL = "xs:pinata-pr-full",

    XS_PR_LG = "xs:pinata-pr-lg",

    XS_PR_MD = "xs:pinata-pr-md",

    XS_PR_PX = "xs:pinata-pr-px",

    XS_PR_SM = "xs:pinata-pr-sm",

    XS_PR_XL = "xs:pinata-pr-xl",

    XS_PR_XS = "xs:pinata-pr-xs",

    XS_PT_0 = "xs:pinata-pt-0",

    XS_PT_1 = "xs:pinata-pt-1",

    XS_PT_10 = "xs:pinata-pt-10",

    XS_PT_12 = "xs:pinata-pt-12",

    XS_PT_14 = "xs:pinata-pt-14",

    XS_PT_16 = "xs:pinata-pt-16",

    XS_PT_2 = "xs:pinata-pt-2",

    XS_PT_20 = "xs:pinata-pt-20",

    XS_PT_24 = "xs:pinata-pt-24",

    XS_PT_2XL = "xs:pinata-pt-2xl",

    XS_PT_3 = "xs:pinata-pt-3",

    XS_PT_32 = "xs:pinata-pt-32",

    XS_PT_3XL = "xs:pinata-pt-3xl",

    XS_PT_4 = "xs:pinata-pt-4",

    XS_PT_40 = "xs:pinata-pt-40",

    XS_PT_48 = "xs:pinata-pt-48",

    XS_PT_4XL = "xs:pinata-pt-4xl",

    XS_PT_5 = "xs:pinata-pt-5",

    XS_PT_56 = "xs:pinata-pt-56",

    XS_PT_5XL = "xs:pinata-pt-5xl",

    XS_PT_6 = "xs:pinata-pt-6",

    XS_PT_64 = "xs:pinata-pt-64",

    XS_PT_6XL = "xs:pinata-pt-6xl",

    XS_PT_8 = "xs:pinata-pt-8",

    XS_PT_FULL = "xs:pinata-pt-full",

    XS_PT_LG = "xs:pinata-pt-lg",

    XS_PT_MD = "xs:pinata-pt-md",

    XS_PT_PX = "xs:pinata-pt-px",

    XS_PT_SM = "xs:pinata-pt-sm",

    XS_PT_XL = "xs:pinata-pt-xl",

    XS_PT_XS = "xs:pinata-pt-xs",

    XS_PX_0 = "xs:pinata-px-0",

    XS_PX_1 = "xs:pinata-px-1",

    XS_PX_10 = "xs:pinata-px-10",

    XS_PX_12 = "xs:pinata-px-12",

    XS_PX_14 = "xs:pinata-px-14",

    XS_PX_16 = "xs:pinata-px-16",

    XS_PX_2 = "xs:pinata-px-2",

    XS_PX_20 = "xs:pinata-px-20",

    XS_PX_24 = "xs:pinata-px-24",

    XS_PX_2XL = "xs:pinata-px-2xl",

    XS_PX_3 = "xs:pinata-px-3",

    XS_PX_32 = "xs:pinata-px-32",

    XS_PX_3XL = "xs:pinata-px-3xl",

    XS_PX_4 = "xs:pinata-px-4",

    XS_PX_40 = "xs:pinata-px-40",

    XS_PX_48 = "xs:pinata-px-48",

    XS_PX_4XL = "xs:pinata-px-4xl",

    XS_PX_5 = "xs:pinata-px-5",

    XS_PX_56 = "xs:pinata-px-56",

    XS_PX_5XL = "xs:pinata-px-5xl",

    XS_PX_6 = "xs:pinata-px-6",

    XS_PX_64 = "xs:pinata-px-64",

    XS_PX_6XL = "xs:pinata-px-6xl",

    XS_PX_8 = "xs:pinata-px-8",

    XS_PX_FULL = "xs:pinata-px-full",

    XS_PX_LG = "xs:pinata-px-lg",

    XS_PX_MD = "xs:pinata-px-md",

    XS_PX_PX = "xs:pinata-px-px",

    XS_PX_SM = "xs:pinata-px-sm",

    XS_PX_XL = "xs:pinata-px-xl",

    XS_PX_XS = "xs:pinata-px-xs",

    XS_PY_0 = "xs:pinata-py-0",

    XS_PY_1 = "xs:pinata-py-1",

    XS_PY_10 = "xs:pinata-py-10",

    XS_PY_12 = "xs:pinata-py-12",

    XS_PY_14 = "xs:pinata-py-14",

    XS_PY_16 = "xs:pinata-py-16",

    XS_PY_2 = "xs:pinata-py-2",

    XS_PY_20 = "xs:pinata-py-20",

    XS_PY_24 = "xs:pinata-py-24",

    XS_PY_2XL = "xs:pinata-py-2xl",

    XS_PY_3 = "xs:pinata-py-3",

    XS_PY_32 = "xs:pinata-py-32",

    XS_PY_3XL = "xs:pinata-py-3xl",

    XS_PY_4 = "xs:pinata-py-4",

    XS_PY_40 = "xs:pinata-py-40",

    XS_PY_48 = "xs:pinata-py-48",

    XS_PY_4XL = "xs:pinata-py-4xl",

    XS_PY_5 = "xs:pinata-py-5",

    XS_PY_56 = "xs:pinata-py-56",

    XS_PY_5XL = "xs:pinata-py-5xl",

    XS_PY_6 = "xs:pinata-py-6",

    XS_PY_64 = "xs:pinata-py-64",

    XS_PY_6XL = "xs:pinata-py-6xl",

    XS_PY_8 = "xs:pinata-py-8",

    XS_PY_FULL = "xs:pinata-py-full",

    XS_PY_LG = "xs:pinata-py-lg",

    XS_PY_MD = "xs:pinata-py-md",

    XS_PY_PX = "xs:pinata-py-px",

    XS_PY_SM = "xs:pinata-py-sm",

    XS_PY_XL = "xs:pinata-py-xl",

    XS_PY_XS = "xs:pinata-py-xs",

    P_0 = "pinata-p-0",

    P_1 = "pinata-p-1",

    P_10 = "pinata-p-10",

    P_12 = "pinata-p-12",

    P_14 = "pinata-p-14",

    P_16 = "pinata-p-16",

    P_2 = "pinata-p-2",

    P_20 = "pinata-p-20",

    P_24 = "pinata-p-24",

    P_2XL = "pinata-p-2xl",

    P_3 = "pinata-p-3",

    P_32 = "pinata-p-32",

    P_3XL = "pinata-p-3xl",

    P_4 = "pinata-p-4",

    P_40 = "pinata-p-40",

    P_48 = "pinata-p-48",

    P_4XL = "pinata-p-4xl",

    P_5 = "pinata-p-5",

    P_56 = "pinata-p-56",

    P_5XL = "pinata-p-5xl",

    P_6 = "pinata-p-6",

    P_64 = "pinata-p-64",

    P_6XL = "pinata-p-6xl",

    P_8 = "pinata-p-8",

    P_FULL = "pinata-p-full",

    P_LG = "pinata-p-lg",

    P_MD = "pinata-p-md",

    P_PX = "pinata-p-px",

    P_SM = "pinata-p-sm",

    P_XL = "pinata-p-xl",

    P_XS = "pinata-p-xs",

    PB_0 = "pinata-pb-0",

    PB_1 = "pinata-pb-1",

    PB_10 = "pinata-pb-10",

    PB_12 = "pinata-pb-12",

    PB_14 = "pinata-pb-14",

    PB_16 = "pinata-pb-16",

    PB_2 = "pinata-pb-2",

    PB_20 = "pinata-pb-20",

    PB_24 = "pinata-pb-24",

    PB_2XL = "pinata-pb-2xl",

    PB_3 = "pinata-pb-3",

    PB_32 = "pinata-pb-32",

    PB_3XL = "pinata-pb-3xl",

    PB_4 = "pinata-pb-4",

    PB_40 = "pinata-pb-40",

    PB_48 = "pinata-pb-48",

    PB_4XL = "pinata-pb-4xl",

    PB_5 = "pinata-pb-5",

    PB_56 = "pinata-pb-56",

    PB_5XL = "pinata-pb-5xl",

    PB_6 = "pinata-pb-6",

    PB_64 = "pinata-pb-64",

    PB_6XL = "pinata-pb-6xl",

    PB_8 = "pinata-pb-8",

    PB_FULL = "pinata-pb-full",

    PB_LG = "pinata-pb-lg",

    PB_MD = "pinata-pb-md",

    PB_PX = "pinata-pb-px",

    PB_SM = "pinata-pb-sm",

    PB_XL = "pinata-pb-xl",

    PB_XS = "pinata-pb-xs",

    PL_0 = "pinata-pl-0",

    PL_1 = "pinata-pl-1",

    PL_10 = "pinata-pl-10",

    PL_12 = "pinata-pl-12",

    PL_14 = "pinata-pl-14",

    PL_16 = "pinata-pl-16",

    PL_2 = "pinata-pl-2",

    PL_20 = "pinata-pl-20",

    PL_24 = "pinata-pl-24",

    PL_2XL = "pinata-pl-2xl",

    PL_3 = "pinata-pl-3",

    PL_32 = "pinata-pl-32",

    PL_3XL = "pinata-pl-3xl",

    PL_4 = "pinata-pl-4",

    PL_40 = "pinata-pl-40",

    PL_48 = "pinata-pl-48",

    PL_4XL = "pinata-pl-4xl",

    PL_5 = "pinata-pl-5",

    PL_56 = "pinata-pl-56",

    PL_5XL = "pinata-pl-5xl",

    PL_6 = "pinata-pl-6",

    PL_64 = "pinata-pl-64",

    PL_6XL = "pinata-pl-6xl",

    PL_8 = "pinata-pl-8",

    PL_FULL = "pinata-pl-full",

    PL_LG = "pinata-pl-lg",

    PL_MD = "pinata-pl-md",

    PL_PX = "pinata-pl-px",

    PL_SM = "pinata-pl-sm",

    PL_XL = "pinata-pl-xl",

    PL_XS = "pinata-pl-xs",

    PR_0 = "pinata-pr-0",

    PR_1 = "pinata-pr-1",

    PR_10 = "pinata-pr-10",

    PR_12 = "pinata-pr-12",

    PR_14 = "pinata-pr-14",

    PR_16 = "pinata-pr-16",

    PR_2 = "pinata-pr-2",

    PR_20 = "pinata-pr-20",

    PR_24 = "pinata-pr-24",

    PR_2XL = "pinata-pr-2xl",

    PR_3 = "pinata-pr-3",

    PR_32 = "pinata-pr-32",

    PR_3XL = "pinata-pr-3xl",

    PR_4 = "pinata-pr-4",

    PR_40 = "pinata-pr-40",

    PR_48 = "pinata-pr-48",

    PR_4XL = "pinata-pr-4xl",

    PR_5 = "pinata-pr-5",

    PR_56 = "pinata-pr-56",

    PR_5XL = "pinata-pr-5xl",

    PR_6 = "pinata-pr-6",

    PR_64 = "pinata-pr-64",

    PR_6XL = "pinata-pr-6xl",

    PR_8 = "pinata-pr-8",

    PR_FULL = "pinata-pr-full",

    PR_LG = "pinata-pr-lg",

    PR_MD = "pinata-pr-md",

    PR_PX = "pinata-pr-px",

    PR_SM = "pinata-pr-sm",

    PR_XL = "pinata-pr-xl",

    PR_XS = "pinata-pr-xs",

    PT_0 = "pinata-pt-0",

    PT_1 = "pinata-pt-1",

    PT_10 = "pinata-pt-10",

    PT_12 = "pinata-pt-12",

    PT_14 = "pinata-pt-14",

    PT_16 = "pinata-pt-16",

    PT_2 = "pinata-pt-2",

    PT_20 = "pinata-pt-20",

    PT_24 = "pinata-pt-24",

    PT_2XL = "pinata-pt-2xl",

    PT_3 = "pinata-pt-3",

    PT_32 = "pinata-pt-32",

    PT_3XL = "pinata-pt-3xl",

    PT_4 = "pinata-pt-4",

    PT_40 = "pinata-pt-40",

    PT_48 = "pinata-pt-48",

    PT_4XL = "pinata-pt-4xl",

    PT_5 = "pinata-pt-5",

    PT_56 = "pinata-pt-56",

    PT_5XL = "pinata-pt-5xl",

    PT_6 = "pinata-pt-6",

    PT_64 = "pinata-pt-64",

    PT_6XL = "pinata-pt-6xl",

    PT_8 = "pinata-pt-8",

    PT_FULL = "pinata-pt-full",

    PT_LG = "pinata-pt-lg",

    PT_MD = "pinata-pt-md",

    PT_PX = "pinata-pt-px",

    PT_SM = "pinata-pt-sm",

    PT_XL = "pinata-pt-xl",

    PT_XS = "pinata-pt-xs",

    PX_0 = "pinata-px-0",

    PX_1 = "pinata-px-1",

    PX_10 = "pinata-px-10",

    PX_12 = "pinata-px-12",

    PX_14 = "pinata-px-14",

    PX_16 = "pinata-px-16",

    PX_2 = "pinata-px-2",

    PX_20 = "pinata-px-20",

    PX_24 = "pinata-px-24",

    PX_2XL = "pinata-px-2xl",

    PX_3 = "pinata-px-3",

    PX_32 = "pinata-px-32",

    PX_3XL = "pinata-px-3xl",

    PX_4 = "pinata-px-4",

    PX_40 = "pinata-px-40",

    PX_48 = "pinata-px-48",

    PX_4XL = "pinata-px-4xl",

    PX_5 = "pinata-px-5",

    PX_56 = "pinata-px-56",

    PX_5XL = "pinata-px-5xl",

    PX_6 = "pinata-px-6",

    PX_64 = "pinata-px-64",

    PX_6XL = "pinata-px-6xl",

    PX_8 = "pinata-px-8",

    PX_FULL = "pinata-px-full",

    PX_LG = "pinata-px-lg",

    PX_MD = "pinata-px-md",

    PX_PX = "pinata-px-px",

    PX_SM = "pinata-px-sm",

    PX_XL = "pinata-px-xl",

    PX_XS = "pinata-px-xs",

    PY_0 = "pinata-py-0",

    PY_1 = "pinata-py-1",

    PY_10 = "pinata-py-10",

    PY_12 = "pinata-py-12",

    PY_14 = "pinata-py-14",

    PY_16 = "pinata-py-16",

    PY_2 = "pinata-py-2",

    PY_20 = "pinata-py-20",

    PY_24 = "pinata-py-24",

    PY_2XL = "pinata-py-2xl",

    PY_3 = "pinata-py-3",

    PY_32 = "pinata-py-32",

    PY_3XL = "pinata-py-3xl",

    PY_4 = "pinata-py-4",

    PY_40 = "pinata-py-40",

    PY_48 = "pinata-py-48",

    PY_4XL = "pinata-py-4xl",

    PY_5 = "pinata-py-5",

    PY_56 = "pinata-py-56",

    PY_5XL = "pinata-py-5xl",

    PY_6 = "pinata-py-6",

    PY_64 = "pinata-py-64",

    PY_6XL = "pinata-py-6xl",

    PY_8 = "pinata-py-8",

    PY_FULL = "pinata-py-full",

    PY_LG = "pinata-py-lg",

    PY_MD = "pinata-py-md",

    PY_PX = "pinata-py-px",

    PY_SM = "pinata-py-sm",

    PY_XL = "pinata-py-xl",

    PY_XS = "pinata-py-xs",

}