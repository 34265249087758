export enum TooltipWidth {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum TooltipPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
}