///-- THIS FILE IS GENERATED --///
export enum LetterSpacing {

    LG_TRACKING_HEADLINE1 = "lg:pinata-tracking-headline1",

    LG_TRACKING_HEADLINE2 = "lg:pinata-tracking-headline2",

    LG_TRACKING_HEADLINE3 = "lg:pinata-tracking-headline3",

    LG_TRACKING_HEADLINE4 = "lg:pinata-tracking-headline4",

    LG_TRACKING_HEADLINE5 = "lg:pinata-tracking-headline5",

    LG_TRACKING_HERO = "lg:pinata-tracking-hero",

    LG_TRACKING_MEGA = "lg:pinata-tracking-mega",

    LG_TRACKING_NORMAL = "lg:pinata-tracking-normal",

    LG_TRACKING_TIGHT = "lg:pinata-tracking-tight",

    LG_TRACKING_WIDE = "lg:pinata-tracking-wide",

    MD_TRACKING_HEADLINE1 = "md:pinata-tracking-headline1",

    MD_TRACKING_HEADLINE2 = "md:pinata-tracking-headline2",

    MD_TRACKING_HEADLINE3 = "md:pinata-tracking-headline3",

    MD_TRACKING_HEADLINE4 = "md:pinata-tracking-headline4",

    MD_TRACKING_HEADLINE5 = "md:pinata-tracking-headline5",

    MD_TRACKING_HERO = "md:pinata-tracking-hero",

    MD_TRACKING_MEGA = "md:pinata-tracking-mega",

    MD_TRACKING_NORMAL = "md:pinata-tracking-normal",

    MD_TRACKING_TIGHT = "md:pinata-tracking-tight",

    MD_TRACKING_WIDE = "md:pinata-tracking-wide",

    SM_TRACKING_HEADLINE1 = "sm:pinata-tracking-headline1",

    SM_TRACKING_HEADLINE2 = "sm:pinata-tracking-headline2",

    SM_TRACKING_HEADLINE3 = "sm:pinata-tracking-headline3",

    SM_TRACKING_HEADLINE4 = "sm:pinata-tracking-headline4",

    SM_TRACKING_HEADLINE5 = "sm:pinata-tracking-headline5",

    SM_TRACKING_HERO = "sm:pinata-tracking-hero",

    SM_TRACKING_MEGA = "sm:pinata-tracking-mega",

    SM_TRACKING_NORMAL = "sm:pinata-tracking-normal",

    SM_TRACKING_TIGHT = "sm:pinata-tracking-tight",

    SM_TRACKING_WIDE = "sm:pinata-tracking-wide",

    XL_TRACKING_HEADLINE1 = "xl:pinata-tracking-headline1",

    XL_TRACKING_HEADLINE2 = "xl:pinata-tracking-headline2",

    XL_TRACKING_HEADLINE3 = "xl:pinata-tracking-headline3",

    XL_TRACKING_HEADLINE4 = "xl:pinata-tracking-headline4",

    XL_TRACKING_HEADLINE5 = "xl:pinata-tracking-headline5",

    XL_TRACKING_HERO = "xl:pinata-tracking-hero",

    XL_TRACKING_MEGA = "xl:pinata-tracking-mega",

    XL_TRACKING_NORMAL = "xl:pinata-tracking-normal",

    XL_TRACKING_TIGHT = "xl:pinata-tracking-tight",

    XL_TRACKING_WIDE = "xl:pinata-tracking-wide",

    XS_TRACKING_HEADLINE1 = "xs:pinata-tracking-headline1",

    XS_TRACKING_HEADLINE2 = "xs:pinata-tracking-headline2",

    XS_TRACKING_HEADLINE3 = "xs:pinata-tracking-headline3",

    XS_TRACKING_HEADLINE4 = "xs:pinata-tracking-headline4",

    XS_TRACKING_HEADLINE5 = "xs:pinata-tracking-headline5",

    XS_TRACKING_HERO = "xs:pinata-tracking-hero",

    XS_TRACKING_MEGA = "xs:pinata-tracking-mega",

    XS_TRACKING_NORMAL = "xs:pinata-tracking-normal",

    XS_TRACKING_TIGHT = "xs:pinata-tracking-tight",

    XS_TRACKING_WIDE = "xs:pinata-tracking-wide",

    TRACKING_HEADLINE1 = "pinata-tracking-headline1",

    TRACKING_HEADLINE2 = "pinata-tracking-headline2",

    TRACKING_HEADLINE3 = "pinata-tracking-headline3",

    TRACKING_HEADLINE4 = "pinata-tracking-headline4",

    TRACKING_HEADLINE5 = "pinata-tracking-headline5",

    TRACKING_HERO = "pinata-tracking-hero",

    TRACKING_MEGA = "pinata-tracking-mega",

    TRACKING_NORMAL = "pinata-tracking-normal",

    TRACKING_TIGHT = "pinata-tracking-tight",

    TRACKING_WIDE = "pinata-tracking-wide",

}