import * as React from 'react';
import { IconFlow, IconSize } from "./IconEnums";
import { IconName } from "./IconName";
import { classNames } from "../../utils";

export * from './IconEnums';
export * from './IconName';

export interface IIconProps extends React.SVGProps<SVGSVGElement> {
    name: IconName;
    size?: string;
    flow?: string;
    color?: string;
    className?: string;
    screenReaderText?: string;
}

export const Icon: React.FunctionComponent<IIconProps> = (props) => {

    const styleClass = {
        root: 'pinata-icon',
        flow: (flow: string) => `pinata-icon--flow-${flow}`,
        size: (size: string) => `pinata-icon--size-${size}`,
        color: (color?: string) => `pinata-icon--color-${color}`,
        widthAuto: `pinata-icon--width-auto`
    };

    const getSpriteIconPath = (name: IconName) => {
        return `#${name}`;
    };

    const {flow, size, color, className, name, screenReaderText, ...rest} = props;

    const classes = classNames(
      styleClass.root,
      {
          [styleClass.flow(flow!)]: flow,
          [styleClass.size(size!)]: size,
          [styleClass.color(color)]: color,
          [styleClass.widthAuto]: typeof rest.width !== 'undefined'
      },
      className
    );

    let accessibilityProps: {'aria-hidden': boolean | undefined, role: string | undefined} = {
        'aria-hidden': true,
        'role': undefined,
    };

    if(screenReaderText) {
        accessibilityProps['aria-hidden'] = undefined;
        accessibilityProps['role'] = 'img';
    }

    return (
      <svg
        {...rest}
        className={classes}
        {...accessibilityProps}
      >
          {screenReaderText && <title id={getSpriteIconPath(name)}>{screenReaderText}</title>}
          <use xlinkHref={getSpriteIconPath(name)}/>
      </svg>
    );
};

Icon.defaultProps = {
    size: IconSize.BASE,
    flow: IconFlow.INLINE
};
Icon.displayName = "Icon";
