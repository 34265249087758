///-- THIS FILE IS GENERATED --///
export enum BorderStyle {

    BORDER_DASHED = "pinata-border-dashed",

    BORDER_DOTTED = "pinata-border-dotted",

    BORDER_NONE = "pinata-border-none",

    BORDER_SOLID = "pinata-border-solid",

}