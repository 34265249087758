import * as React from 'react';
import {classNames} from "../../utils";
import { MouseEventHandler, useState } from 'react';

export enum TableRowType {
    DATA,
    HEAD,
    META,
    INFO,
    TRIGGER
}

export interface ITableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    type?: TableRowType;
    expandable?: boolean;
}

export const TableRow: React.FunctionComponent<ITableRowProps> = (props) => {

    const styleClass = {
        root: 'pinata-table__row',
        rowHead: 'pinata-table__row--head',
        rowMeta: 'pinata-table__row--meta',
        rowToggleTarget: 'pinata-table__row--toggle-target',
        rowToggleControl: 'pinata-table__row--toggle-control',
        rowExpanded: 'pinata-table__row--expanded',
        rowExpandable: 'pinata-table__row--expandable'
    };
    const [expanded, setExpanded] = useState<boolean>(false);

    const onClick: MouseEventHandler<HTMLTableRowElement> = (__event) => {
        const {onClick: onClickProp, expandable} = props;

        __event.persist();

        (onClickProp || (() => {}))(__event);

        if (expandable && !__event.isDefaultPrevented()) {
            setExpanded(!expanded);
        }
    };


        const {children, expandable, onClick: onClickProp, className, type, ...rest} = props;

        const componentClasses = classNames(
            styleClass.root,
            {[styleClass.rowExpandable]: expandable},
            {[styleClass.rowHead]: (type === TableRowType.HEAD)},
            {[styleClass.rowMeta]: (type === TableRowType.META)},
            {[styleClass.rowToggleTarget]: (type === TableRowType.INFO)},
            {[styleClass.rowToggleControl]: (type === TableRowType.TRIGGER)},
            {[styleClass.rowExpanded]: (type !== TableRowType.HEAD && expanded)},
            className
        );

        return (
            <tr
                {...rest}
                className={componentClasses}
                onClick={onClick}
            >
                {children}
            </tr>
        );

};

TableRow.defaultProps = {
    type: TableRowType.DATA,
    expandable: true,
    onClick: () => {},
};
TableRow.displayName = 'TableRow';
