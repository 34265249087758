import * as React from 'react';
import { classNames } from '../../utils';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export interface ILinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
}

const styleClass = {
    root: 'pinata-link',
};

export const StyledRouterLink: React.FunctionComponent<LinkProps> = (props) => {
    const styleClasses = classNames(
        props.className,
        styleClass.root,
    );
    return (
        <RouterLink {...props} className={styleClasses}/>
    )
};

export const Link: React.FunctionComponent<ILinkProps> = (props) => {

    const {children, className, ...rest} = props;
    const linkClasses = classNames(
        styleClass.root,
        className,
    );
    return (
        <a
            {...rest}
            className={linkClasses}
        >
            {children}
        </a>
    );

};

Link.displayName = 'Index';
