export enum ToggleSize {
    LARGE = 'large',
    SMALL = 'small'
}

export enum ToggleSkin {
    PRIMARY_1 = 'primary-1',
    PRIMARY_2 = 'primary-2',
    PRIMARY_3 = 'primary-3',
    PRIMARY_4 = 'primary-4',
    PRIMARY = PRIMARY_3,
    CONFIRM = 'confirm',
    WARNING = 'warning',
    NEGATIVE = 'negative',
    INFO = 'info'
}