///-- THIS FILE IS GENERATED --///
export enum FontFamily {

    LG_FONT_BODY = "lg:pinata-font-body",

    LG_FONT_TITLE_1 = "lg:pinata-font-title-1",

    LG_FONT_TITLE_2 = "lg:pinata-font-title-2",

    MD_FONT_BODY = "md:pinata-font-body",

    MD_FONT_TITLE_1 = "md:pinata-font-title-1",

    MD_FONT_TITLE_2 = "md:pinata-font-title-2",

    SM_FONT_BODY = "sm:pinata-font-body",

    SM_FONT_TITLE_1 = "sm:pinata-font-title-1",

    SM_FONT_TITLE_2 = "sm:pinata-font-title-2",

    XL_FONT_BODY = "xl:pinata-font-body",

    XL_FONT_TITLE_1 = "xl:pinata-font-title-1",

    XL_FONT_TITLE_2 = "xl:pinata-font-title-2",

    XS_FONT_BODY = "xs:pinata-font-body",

    XS_FONT_TITLE_1 = "xs:pinata-font-title-1",

    XS_FONT_TITLE_2 = "xs:pinata-font-title-2",

    FONT_BODY = "pinata-font-body",

    FONT_TITLE_1 = "pinata-font-title-1",

    FONT_TITLE_2 = "pinata-font-title-2",

}