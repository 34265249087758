///-- THIS FILE IS GENERATED --///
export enum MaxHeight {

    LG_MAX_H_0 = "lg:pinata-max-h-0",

    LG_MAX_H_1 = "lg:pinata-max-h-1",

    LG_MAX_H_10 = "lg:pinata-max-h-10",

    LG_MAX_H_12 = "lg:pinata-max-h-12",

    LG_MAX_H_14 = "lg:pinata-max-h-14",

    LG_MAX_H_16 = "lg:pinata-max-h-16",

    LG_MAX_H_2 = "lg:pinata-max-h-2",

    LG_MAX_H_20 = "lg:pinata-max-h-20",

    LG_MAX_H_24 = "lg:pinata-max-h-24",

    LG_MAX_H_2XL = "lg:pinata-max-h-2xl",

    LG_MAX_H_3 = "lg:pinata-max-h-3",

    LG_MAX_H_32 = "lg:pinata-max-h-32",

    LG_MAX_H_3XL = "lg:pinata-max-h-3xl",

    LG_MAX_H_4 = "lg:pinata-max-h-4",

    LG_MAX_H_40 = "lg:pinata-max-h-40",

    LG_MAX_H_48 = "lg:pinata-max-h-48",

    LG_MAX_H_4XL = "lg:pinata-max-h-4xl",

    LG_MAX_H_5 = "lg:pinata-max-h-5",

    LG_MAX_H_56 = "lg:pinata-max-h-56",

    LG_MAX_H_5XL = "lg:pinata-max-h-5xl",

    LG_MAX_H_6 = "lg:pinata-max-h-6",

    LG_MAX_H_64 = "lg:pinata-max-h-64",

    LG_MAX_H_6XL = "lg:pinata-max-h-6xl",

    LG_MAX_H_8 = "lg:pinata-max-h-8",

    LG_MAX_H_FULL = "lg:pinata-max-h-full",

    LG_MAX_H_LG = "lg:pinata-max-h-lg",

    LG_MAX_H_MD = "lg:pinata-max-h-md",

    LG_MAX_H_PX = "lg:pinata-max-h-px",

    LG_MAX_H_SCREEN = "lg:pinata-max-h-screen",

    LG_MAX_H_SM = "lg:pinata-max-h-sm",

    LG_MAX_H_XL = "lg:pinata-max-h-xl",

    LG_MAX_H_XS = "lg:pinata-max-h-xs",

    MD_MAX_H_0 = "md:pinata-max-h-0",

    MD_MAX_H_1 = "md:pinata-max-h-1",

    MD_MAX_H_10 = "md:pinata-max-h-10",

    MD_MAX_H_12 = "md:pinata-max-h-12",

    MD_MAX_H_14 = "md:pinata-max-h-14",

    MD_MAX_H_16 = "md:pinata-max-h-16",

    MD_MAX_H_2 = "md:pinata-max-h-2",

    MD_MAX_H_20 = "md:pinata-max-h-20",

    MD_MAX_H_24 = "md:pinata-max-h-24",

    MD_MAX_H_2XL = "md:pinata-max-h-2xl",

    MD_MAX_H_3 = "md:pinata-max-h-3",

    MD_MAX_H_32 = "md:pinata-max-h-32",

    MD_MAX_H_3XL = "md:pinata-max-h-3xl",

    MD_MAX_H_4 = "md:pinata-max-h-4",

    MD_MAX_H_40 = "md:pinata-max-h-40",

    MD_MAX_H_48 = "md:pinata-max-h-48",

    MD_MAX_H_4XL = "md:pinata-max-h-4xl",

    MD_MAX_H_5 = "md:pinata-max-h-5",

    MD_MAX_H_56 = "md:pinata-max-h-56",

    MD_MAX_H_5XL = "md:pinata-max-h-5xl",

    MD_MAX_H_6 = "md:pinata-max-h-6",

    MD_MAX_H_64 = "md:pinata-max-h-64",

    MD_MAX_H_6XL = "md:pinata-max-h-6xl",

    MD_MAX_H_8 = "md:pinata-max-h-8",

    MD_MAX_H_FULL = "md:pinata-max-h-full",

    MD_MAX_H_LG = "md:pinata-max-h-lg",

    MD_MAX_H_MD = "md:pinata-max-h-md",

    MD_MAX_H_PX = "md:pinata-max-h-px",

    MD_MAX_H_SCREEN = "md:pinata-max-h-screen",

    MD_MAX_H_SM = "md:pinata-max-h-sm",

    MD_MAX_H_XL = "md:pinata-max-h-xl",

    MD_MAX_H_XS = "md:pinata-max-h-xs",

    SM_MAX_H_0 = "sm:pinata-max-h-0",

    SM_MAX_H_1 = "sm:pinata-max-h-1",

    SM_MAX_H_10 = "sm:pinata-max-h-10",

    SM_MAX_H_12 = "sm:pinata-max-h-12",

    SM_MAX_H_14 = "sm:pinata-max-h-14",

    SM_MAX_H_16 = "sm:pinata-max-h-16",

    SM_MAX_H_2 = "sm:pinata-max-h-2",

    SM_MAX_H_20 = "sm:pinata-max-h-20",

    SM_MAX_H_24 = "sm:pinata-max-h-24",

    SM_MAX_H_2XL = "sm:pinata-max-h-2xl",

    SM_MAX_H_3 = "sm:pinata-max-h-3",

    SM_MAX_H_32 = "sm:pinata-max-h-32",

    SM_MAX_H_3XL = "sm:pinata-max-h-3xl",

    SM_MAX_H_4 = "sm:pinata-max-h-4",

    SM_MAX_H_40 = "sm:pinata-max-h-40",

    SM_MAX_H_48 = "sm:pinata-max-h-48",

    SM_MAX_H_4XL = "sm:pinata-max-h-4xl",

    SM_MAX_H_5 = "sm:pinata-max-h-5",

    SM_MAX_H_56 = "sm:pinata-max-h-56",

    SM_MAX_H_5XL = "sm:pinata-max-h-5xl",

    SM_MAX_H_6 = "sm:pinata-max-h-6",

    SM_MAX_H_64 = "sm:pinata-max-h-64",

    SM_MAX_H_6XL = "sm:pinata-max-h-6xl",

    SM_MAX_H_8 = "sm:pinata-max-h-8",

    SM_MAX_H_FULL = "sm:pinata-max-h-full",

    SM_MAX_H_LG = "sm:pinata-max-h-lg",

    SM_MAX_H_MD = "sm:pinata-max-h-md",

    SM_MAX_H_PX = "sm:pinata-max-h-px",

    SM_MAX_H_SCREEN = "sm:pinata-max-h-screen",

    SM_MAX_H_SM = "sm:pinata-max-h-sm",

    SM_MAX_H_XL = "sm:pinata-max-h-xl",

    SM_MAX_H_XS = "sm:pinata-max-h-xs",

    XL_MAX_H_0 = "xl:pinata-max-h-0",

    XL_MAX_H_1 = "xl:pinata-max-h-1",

    XL_MAX_H_10 = "xl:pinata-max-h-10",

    XL_MAX_H_12 = "xl:pinata-max-h-12",

    XL_MAX_H_14 = "xl:pinata-max-h-14",

    XL_MAX_H_16 = "xl:pinata-max-h-16",

    XL_MAX_H_2 = "xl:pinata-max-h-2",

    XL_MAX_H_20 = "xl:pinata-max-h-20",

    XL_MAX_H_24 = "xl:pinata-max-h-24",

    XL_MAX_H_2XL = "xl:pinata-max-h-2xl",

    XL_MAX_H_3 = "xl:pinata-max-h-3",

    XL_MAX_H_32 = "xl:pinata-max-h-32",

    XL_MAX_H_3XL = "xl:pinata-max-h-3xl",

    XL_MAX_H_4 = "xl:pinata-max-h-4",

    XL_MAX_H_40 = "xl:pinata-max-h-40",

    XL_MAX_H_48 = "xl:pinata-max-h-48",

    XL_MAX_H_4XL = "xl:pinata-max-h-4xl",

    XL_MAX_H_5 = "xl:pinata-max-h-5",

    XL_MAX_H_56 = "xl:pinata-max-h-56",

    XL_MAX_H_5XL = "xl:pinata-max-h-5xl",

    XL_MAX_H_6 = "xl:pinata-max-h-6",

    XL_MAX_H_64 = "xl:pinata-max-h-64",

    XL_MAX_H_6XL = "xl:pinata-max-h-6xl",

    XL_MAX_H_8 = "xl:pinata-max-h-8",

    XL_MAX_H_FULL = "xl:pinata-max-h-full",

    XL_MAX_H_LG = "xl:pinata-max-h-lg",

    XL_MAX_H_MD = "xl:pinata-max-h-md",

    XL_MAX_H_PX = "xl:pinata-max-h-px",

    XL_MAX_H_SCREEN = "xl:pinata-max-h-screen",

    XL_MAX_H_SM = "xl:pinata-max-h-sm",

    XL_MAX_H_XL = "xl:pinata-max-h-xl",

    XL_MAX_H_XS = "xl:pinata-max-h-xs",

    XS_MAX_H_0 = "xs:pinata-max-h-0",

    XS_MAX_H_1 = "xs:pinata-max-h-1",

    XS_MAX_H_10 = "xs:pinata-max-h-10",

    XS_MAX_H_12 = "xs:pinata-max-h-12",

    XS_MAX_H_14 = "xs:pinata-max-h-14",

    XS_MAX_H_16 = "xs:pinata-max-h-16",

    XS_MAX_H_2 = "xs:pinata-max-h-2",

    XS_MAX_H_20 = "xs:pinata-max-h-20",

    XS_MAX_H_24 = "xs:pinata-max-h-24",

    XS_MAX_H_2XL = "xs:pinata-max-h-2xl",

    XS_MAX_H_3 = "xs:pinata-max-h-3",

    XS_MAX_H_32 = "xs:pinata-max-h-32",

    XS_MAX_H_3XL = "xs:pinata-max-h-3xl",

    XS_MAX_H_4 = "xs:pinata-max-h-4",

    XS_MAX_H_40 = "xs:pinata-max-h-40",

    XS_MAX_H_48 = "xs:pinata-max-h-48",

    XS_MAX_H_4XL = "xs:pinata-max-h-4xl",

    XS_MAX_H_5 = "xs:pinata-max-h-5",

    XS_MAX_H_56 = "xs:pinata-max-h-56",

    XS_MAX_H_5XL = "xs:pinata-max-h-5xl",

    XS_MAX_H_6 = "xs:pinata-max-h-6",

    XS_MAX_H_64 = "xs:pinata-max-h-64",

    XS_MAX_H_6XL = "xs:pinata-max-h-6xl",

    XS_MAX_H_8 = "xs:pinata-max-h-8",

    XS_MAX_H_FULL = "xs:pinata-max-h-full",

    XS_MAX_H_LG = "xs:pinata-max-h-lg",

    XS_MAX_H_MD = "xs:pinata-max-h-md",

    XS_MAX_H_PX = "xs:pinata-max-h-px",

    XS_MAX_H_SCREEN = "xs:pinata-max-h-screen",

    XS_MAX_H_SM = "xs:pinata-max-h-sm",

    XS_MAX_H_XL = "xs:pinata-max-h-xl",

    XS_MAX_H_XS = "xs:pinata-max-h-xs",

    MAX_H_0 = "pinata-max-h-0",

    MAX_H_1 = "pinata-max-h-1",

    MAX_H_10 = "pinata-max-h-10",

    MAX_H_12 = "pinata-max-h-12",

    MAX_H_14 = "pinata-max-h-14",

    MAX_H_16 = "pinata-max-h-16",

    MAX_H_2 = "pinata-max-h-2",

    MAX_H_20 = "pinata-max-h-20",

    MAX_H_24 = "pinata-max-h-24",

    MAX_H_2XL = "pinata-max-h-2xl",

    MAX_H_3 = "pinata-max-h-3",

    MAX_H_32 = "pinata-max-h-32",

    MAX_H_3XL = "pinata-max-h-3xl",

    MAX_H_4 = "pinata-max-h-4",

    MAX_H_40 = "pinata-max-h-40",

    MAX_H_48 = "pinata-max-h-48",

    MAX_H_4XL = "pinata-max-h-4xl",

    MAX_H_5 = "pinata-max-h-5",

    MAX_H_56 = "pinata-max-h-56",

    MAX_H_5XL = "pinata-max-h-5xl",

    MAX_H_6 = "pinata-max-h-6",

    MAX_H_64 = "pinata-max-h-64",

    MAX_H_6XL = "pinata-max-h-6xl",

    MAX_H_8 = "pinata-max-h-8",

    MAX_H_FULL = "pinata-max-h-full",

    MAX_H_LG = "pinata-max-h-lg",

    MAX_H_MD = "pinata-max-h-md",

    MAX_H_PX = "pinata-max-h-px",

    MAX_H_SCREEN = "pinata-max-h-screen",

    MAX_H_SM = "pinata-max-h-sm",

    MAX_H_XL = "pinata-max-h-xl",

    MAX_H_XS = "pinata-max-h-xs",

}