import * as React from 'react';
import {Grid, GridItemProps} from "../";
import {GridItem} from "../GridItem";
import {GridProps} from "../index";


export abstract class Composition {

    private _size: number;
    private _emptyChildren: boolean;

    constructor(params: { size: number, emptyChildren: boolean }) {
        this._size = params.size;
        this._emptyChildren = params.emptyChildren;
    }


    public layout(child: any, index: number) {
        if (this.isGridType(child)) {
            return React.cloneElement(child, {
                isNested: true
            });
        } else if (this.isItemType(child)) {
            return child;
        } else if (!this._emptyChildren && this.determineChildEmpty(child)) {
            return null;
        }

        return (
            <GridItem>
                {child}
            </GridItem>
        );
    }

    protected wrapChild(child: React.ReactElement, i: number, props = {}): any {
        if (this.isItemType(child)) {
            return React.cloneElement(child, props);
        }
        return (
            <GridItem key={i} {...props}>
                {child}
            </GridItem>
        );
    }

    protected determineChildEmpty(child: React.ReactElement): boolean {
        return React.Children.count(child.props.children) === 0;
    }

    protected isItemType(child: React.ReactElement): boolean {
        return this.isTypeOf(child, GridItem);
    }

    protected isGridType(child: React.ReactElement): boolean {
        return this.isTypeOf(child, Grid);
    }

    private isTypeOf(child: React.ReactElement, component: typeof Grid | typeof GridItem): boolean {
        return (typeof child.type === 'function' && child.type.hasOwnProperty('displayName') && (child.type as any).displayName === component.displayName)
    }

    protected getSize() {
        return this._size;
    }

    protected getItem() {
        return GridItem;
    }

    protected getGrid() {
        return Grid;
    }
}
