///-- THIS FILE IS GENERATED --///
export enum Stroke {

    HOVER_STROKE_BLACK = "hover:pinata-stroke-black",

    HOVER_STROKE_CURRENT = "hover:pinata-stroke-current",

    HOVER_STROKE_HEADER = "hover:pinata-stroke-header",

    HOVER_STROKE_INFO = "hover:pinata-stroke-info",

    HOVER_STROKE_NEGATIVE = "hover:pinata-stroke-negative",

    HOVER_STROKE_NEUTRAL_1 = "hover:pinata-stroke-neutral-1",

    HOVER_STROKE_NEUTRAL_2 = "hover:pinata-stroke-neutral-2",

    HOVER_STROKE_NEUTRAL_3 = "hover:pinata-stroke-neutral-3",

    HOVER_STROKE_NEUTRAL_4 = "hover:pinata-stroke-neutral-4",

    HOVER_STROKE_NEUTRAL_5 = "hover:pinata-stroke-neutral-5",

    HOVER_STROKE_NEUTRAL_6 = "hover:pinata-stroke-neutral-6",

    HOVER_STROKE_NEUTRAL_7 = "hover:pinata-stroke-neutral-7",

    HOVER_STROKE_NEUTRAL_8 = "hover:pinata-stroke-neutral-8",

    HOVER_STROKE_POSITIVE = "hover:pinata-stroke-positive",

    HOVER_STROKE_PRIMARY_1 = "hover:pinata-stroke-primary-1",

    HOVER_STROKE_PRIMARY_2 = "hover:pinata-stroke-primary-2",

    HOVER_STROKE_PRIMARY_3 = "hover:pinata-stroke-primary-3",

    HOVER_STROKE_PRIMARY_4 = "hover:pinata-stroke-primary-4",

    HOVER_STROKE_PRIMARY_DARK = "hover:pinata-stroke-primary-dark",

    HOVER_STROKE_PRIMARY_LIGHT = "hover:pinata-stroke-primary-light",

    HOVER_STROKE_SECONDARY_1 = "hover:pinata-stroke-secondary-1",

    HOVER_STROKE_SECONDARY_2 = "hover:pinata-stroke-secondary-2",

    HOVER_STROKE_SECONDARY_3 = "hover:pinata-stroke-secondary-3",

    HOVER_STROKE_SECONDARY_4 = "hover:pinata-stroke-secondary-4",

    HOVER_STROKE_TERTIARY_1 = "hover:pinata-stroke-tertiary-1",

    HOVER_STROKE_TERTIARY_2 = "hover:pinata-stroke-tertiary-2",

    HOVER_STROKE_TERTIARY_3 = "hover:pinata-stroke-tertiary-3",

    HOVER_STROKE_TERTIARY_4 = "hover:pinata-stroke-tertiary-4",

    HOVER_STROKE_TRANSPARENT = "hover:pinata-stroke-transparent",

    HOVER_STROKE_WARNING = "hover:pinata-stroke-warning",

    HOVER_STROKE_WHITE = "hover:pinata-stroke-white",

    FOCUS_STROKE_BLACK = "focus:pinata-stroke-black",

    FOCUS_STROKE_CURRENT = "focus:pinata-stroke-current",

    FOCUS_STROKE_HEADER = "focus:pinata-stroke-header",

    FOCUS_STROKE_INFO = "focus:pinata-stroke-info",

    FOCUS_STROKE_NEGATIVE = "focus:pinata-stroke-negative",

    FOCUS_STROKE_NEUTRAL_1 = "focus:pinata-stroke-neutral-1",

    FOCUS_STROKE_NEUTRAL_2 = "focus:pinata-stroke-neutral-2",

    FOCUS_STROKE_NEUTRAL_3 = "focus:pinata-stroke-neutral-3",

    FOCUS_STROKE_NEUTRAL_4 = "focus:pinata-stroke-neutral-4",

    FOCUS_STROKE_NEUTRAL_5 = "focus:pinata-stroke-neutral-5",

    FOCUS_STROKE_NEUTRAL_6 = "focus:pinata-stroke-neutral-6",

    FOCUS_STROKE_NEUTRAL_7 = "focus:pinata-stroke-neutral-7",

    FOCUS_STROKE_NEUTRAL_8 = "focus:pinata-stroke-neutral-8",

    FOCUS_STROKE_POSITIVE = "focus:pinata-stroke-positive",

    FOCUS_STROKE_PRIMARY_1 = "focus:pinata-stroke-primary-1",

    FOCUS_STROKE_PRIMARY_2 = "focus:pinata-stroke-primary-2",

    FOCUS_STROKE_PRIMARY_3 = "focus:pinata-stroke-primary-3",

    FOCUS_STROKE_PRIMARY_4 = "focus:pinata-stroke-primary-4",

    FOCUS_STROKE_PRIMARY_DARK = "focus:pinata-stroke-primary-dark",

    FOCUS_STROKE_PRIMARY_LIGHT = "focus:pinata-stroke-primary-light",

    FOCUS_STROKE_SECONDARY_1 = "focus:pinata-stroke-secondary-1",

    FOCUS_STROKE_SECONDARY_2 = "focus:pinata-stroke-secondary-2",

    FOCUS_STROKE_SECONDARY_3 = "focus:pinata-stroke-secondary-3",

    FOCUS_STROKE_SECONDARY_4 = "focus:pinata-stroke-secondary-4",

    FOCUS_STROKE_TERTIARY_1 = "focus:pinata-stroke-tertiary-1",

    FOCUS_STROKE_TERTIARY_2 = "focus:pinata-stroke-tertiary-2",

    FOCUS_STROKE_TERTIARY_3 = "focus:pinata-stroke-tertiary-3",

    FOCUS_STROKE_TERTIARY_4 = "focus:pinata-stroke-tertiary-4",

    FOCUS_STROKE_TRANSPARENT = "focus:pinata-stroke-transparent",

    FOCUS_STROKE_WARNING = "focus:pinata-stroke-warning",

    FOCUS_STROKE_WHITE = "focus:pinata-stroke-white",

    STROKE_BLACK = "pinata-stroke-black",

    STROKE_CURRENT = "pinata-stroke-current",

    STROKE_HEADER = "pinata-stroke-header",

    STROKE_INFO = "pinata-stroke-info",

    STROKE_NEGATIVE = "pinata-stroke-negative",

    STROKE_NEUTRAL_1 = "pinata-stroke-neutral-1",

    STROKE_NEUTRAL_2 = "pinata-stroke-neutral-2",

    STROKE_NEUTRAL_3 = "pinata-stroke-neutral-3",

    STROKE_NEUTRAL_4 = "pinata-stroke-neutral-4",

    STROKE_NEUTRAL_5 = "pinata-stroke-neutral-5",

    STROKE_NEUTRAL_6 = "pinata-stroke-neutral-6",

    STROKE_NEUTRAL_7 = "pinata-stroke-neutral-7",

    STROKE_NEUTRAL_8 = "pinata-stroke-neutral-8",

    STROKE_POSITIVE = "pinata-stroke-positive",

    STROKE_PRIMARY_1 = "pinata-stroke-primary-1",

    STROKE_PRIMARY_2 = "pinata-stroke-primary-2",

    STROKE_PRIMARY_3 = "pinata-stroke-primary-3",

    STROKE_PRIMARY_4 = "pinata-stroke-primary-4",

    STROKE_PRIMARY_DARK = "pinata-stroke-primary-dark",

    STROKE_PRIMARY_LIGHT = "pinata-stroke-primary-light",

    STROKE_SECONDARY_1 = "pinata-stroke-secondary-1",

    STROKE_SECONDARY_2 = "pinata-stroke-secondary-2",

    STROKE_SECONDARY_3 = "pinata-stroke-secondary-3",

    STROKE_SECONDARY_4 = "pinata-stroke-secondary-4",

    STROKE_TERTIARY_1 = "pinata-stroke-tertiary-1",

    STROKE_TERTIARY_2 = "pinata-stroke-tertiary-2",

    STROKE_TERTIARY_3 = "pinata-stroke-tertiary-3",

    STROKE_TERTIARY_4 = "pinata-stroke-tertiary-4",

    STROKE_TRANSPARENT = "pinata-stroke-transparent",

    STROKE_WARNING = "pinata-stroke-warning",

    STROKE_WHITE = "pinata-stroke-white",

}