///-- THIS FILE IS GENERATED --///
export enum IconName {

    _EDIT = "_edit",

    ACCOUNT_BOX = "account-box",

    ACCOUNT_BALANCE_WALLET = "account-balance-wallet",

    ADD_ALERT = "add-alert",

    ACCOUNT_CIRCLE = "account-circle",

    ADD = "add",

    ADMIN = "admin",

    AGREEMENT = "agreement",

    AGREEMENTS = "agreements",

    ANNOUNCEMENT = "announcement",

    APPS = "apps",

    ARROW_BACK = "arrow-back",

    ARROW_DOWNWARD = "arrow-downward",

    ARROW_DROP_DOWN_CIRCLE = "arrow-drop-down-circle",

    ARROW_DROP_DOWN = "arrow-drop-down",

    ARROW_DROP_UP = "arrow-drop-up",

    ARROW_FORWARD = "arrow-forward",

    ARROW_LEFT = "arrow-left",

    ARROW_UPWARD = "arrow-upward",

    ASSESSMENT = "assessment",

    ASSIGNMENT = "assignment",

    ATTACH_FILE = "attach-file",

    ATTACHMENT = "attachment",

    AUTORENEW = "autorenew",

    BEDRIFT_ADMINS = "bedrift-admins",

    BACKUP = "backup",

    BEDRIFT_AVTALE = "bedrift-avtale",

    BEDRIFT_ADMIN = "bedrift-admin",

    BEDRIFT_EDIT_PRODUCTS = "bedrift-edit_products",

    BEDRIFT_ICON_GROUP = "bedrift-icon_group",

    BEDRIFT_EDIT_DIGITAL = "bedrift-edit_digital",

    BEDRIFT_ICON_INVITE = "bedrift-icon_invite",

    BEDRIFT_ICON_INVOICE = "bedrift-icon_invoice",

    BEDRIFT_KR = "bedrift-kr",

    BEDRIFT_MENU_LOGGEDINUSER = "bedrift-menu_loggedinuser",

    BEDRIFT_SCHIBSTEDADMIN = "bedrift-schibstedadmin",

    BEDRIFT_SETTINGS_OUTLINE = "bedrift-settings_outline",

    BEDRIFT_USER = "bedrift-user",

    BOOKMARK_BORDER = "bookmark-border",

    BEENHERE = "beenhere",

    BOOKMARK = "bookmark",

    BUILD = "build",

    CAMPAIGN_OUTLINE = "campaign-outline",

    CAMPAIGN = "campaign",

    CALL_MADE = "call-made",

    CANCEL = "cancel",

    CARD_GIFTCARD = "card-giftcard",

    CARD_MEMBERSHIP = "card-membership",

    CHAT_BUBBLE_OUTLINE = "chat-bubble-outline",

    CHAT_BUBBLE = "chat-bubble",

    CELEBRATE = "celebrate",

    CHAT = "chat",

    CHECK_BOX = "check-box",

    CHECK_BOX_OUTLINE_BLANK = "check-box-outline-blank",

    CHECK_CIRCLE_OUTLINE = "check-circle-outline",

    CHECK_CIRCLE = "check-circle",

    CHECK = "check",

    CHEVRON_LEFT = "chevron-left",

    CHEVRON_RIGHT = "chevron-right",

    CLOSE = "close",

    CLOUD_DONE = "cloud-done",

    CLOUD_DOWNLOAD = "cloud-download",

    CLOUD = "cloud",

    COFFE_OUTLINE = "coffe-outline",

    COFFE = "coffe",

    COMMENT = "comment",

    COMPARE_ARROWS = "compare-arrows",

    CONTACT_INFO = "contact-info",

    CREATE = "create",

    CREDIT_CARD = "credit-card",

    DASHBOARD = "dashboard",

    DATE_RANGE = "date-range",

    DELETE_FOREVER = "delete-forever",

    DELETE = "delete",

    DESCRIPTION = "description",

    DEVICES = "devices",

    DONE_ALL = "done-all",

    CREATE_NEW_FOLDER = "create-new-folder",

    DONE = "done",

    DONUT_LARGE = "donut-large",

    DOT = "dot",

    EDIT_OUTLINE = "edit-outline",

    EDIT = "edit",

    EMAIL_OUTLINE = "email-outline",

    EMAIL_SENDT = "email-sendt",

    EMAIL = "email",

    EMPLOYEES = "employees",

    ENVELOPE = "envelope",

    ENHANCED_ENCRYPTION = "enhanced-encryption",

    EQUALIZER = "equalizer",

    ERROR_OUTLINE = "error-outline",

    ERROR = "error",

    EURO_SYMBOL = "euro-symbol",

    EXIT_TO_APP = "exit-to-app",

    EXPAND_LESS = "expand-less",

    EXPAND_MORE = "expand-more",

    EXCEL = "excel",

    FACE = "face",

    FAVORITE = "favorite",

    FEEDBACK = "feedback",

    FILTER = "filter",

    FILTER2 = "filter2",

    FIND_IN_PAGE = "find-in-page",

    FINGERPRINT = "fingerprint",

    FORWARD = "forward",

    FLAG = "flag",

    GET_APP = "get-app",

    GIFT_OUTLINE = "gift-outline",

    GIFT = "gift",

    GROUP = "group",

    GROUP1 = "group1",

    GROUP4 = "group4",

    HELP_OUTLINE = "help-outline",

    HIGHLIGHT_OFF = "highlight-off",

    HELP = "help",

    HISTORY = "history",

    HOME = "home",

    HTTPS = "https",

    INDETERMINATE_CHECK_BOX = "indeterminate-check-box",

    INFO_OUTLINE = "info-outline",

    INFO = "info",

    INPUT_DELETE = "input-delete",

    INPUT = "input",

    INSERT_CHART = "insert-chart",

    INVITE = "invite",

    INVOICE = "invoice",

    INVOICE_ILLUSTRATION = "invoiceIllustration",

    KEYBOARD_ARROW_DOWN = "keyboard-arrow-down",

    KEYBOARD_ARROW_LEFT = "keyboard-arrow-left",

    KEYBOARD_ARROW_RIGHT = "keyboard-arrow-right",

    KEYBOARD_ARROW_UP = "keyboard-arrow-up",

    KEYBOARD_BACKSPACE = "keyboard-backspace",

    KEYBOARD_RETURN = "keyboard-return",

    KR = "kr",

    LABEL_OUTLINE = "label-outline",

    LABEL = "label",

    LANGUAGE = "language",

    LAPTOP = "laptop",

    LAYERS = "layers",

    LIGHTBULB_OUTLINE = "lightbulb-outline",

    LISTEN = "listen",

    LOADING_WHEEL = "loading_wheel",

    LOCAL_OFFER = "local-offer",

    LOCATION_ON = "location-on",

    LOCK_OPEN = "lock-open",

    LOCK_OUTLINE = "lock-outline",

    LOGIN = "login",

    LOGO_AP_INITIAL = "logo-ap-initial",

    LOGO_AP_CIRCLE = "logo-ap-circle",

    LOGO_BT_CIRCLE = "logo-bt-circle",

    LOGO_PINJATA_CIRCLE = "logo-pinjata-circle",

    LOGO_PINJATA_INITIAL = "logo-pinjata-initial",

    LOGO_SA_CIRCLE = "logo-sa-circle",

    LOGO_SA_INITIAL = "logo-sa-initial",

    MINUS = "minus",

    MOBILE_MENU_ADMIN = "mobile-menu-admin",

    MOBILE_MENU_SUPERADMIN = "mobile-menu-superadmin",

    MOBILE_MENU_USER = "mobile-menu-user",

    MOBILE_PHONE = "mobile-phone",

    MOBILECODE_FILLED = "mobilecode-filled",

    MOBILECODE_OUTLINE = "mobilecode-outline",

    MOOD_BAD = "mood-bad",

    MOOD = "mood",

    MORE_HORIZ = "more-horiz",

    MORE_VERT = "more-vert",

    MY_LOCATION = "my-location",

    NEW_RELEASES = "new-releases",

    NEWSPAPER_OUTLINE = "newspaper-outline",

    NEWSPAPER = "newspaper",

    NORTHSTAR = "northstar",

    NOT_INTERESTED = "not-interested",

    NOTIFICATIONS_ACTIVE = "notifications-active",

    NOTE_ADD = "note-add",

    NOTIFICATIONS_NONE = "notifications-none",

    OPEN_IN_BROWSER = "open-in-browser",

    PANORAMA = "panorama",

    PAUSE_CIRCLE_FILLED = "pause-circle-filled",

    PAUSE_CIRCLE_OUTLINE = "pause-circle-outline",

    NOTIFICATIONS = "notifications",

    PAYMENT_OUTLINE = "payment-outline",

    PAYMENT = "payment",

    PDF = "pdf",

    PERM_IDENTITY = "perm-identity",

    PHONE_IPHONE = "phone-iphone",

    PERSON = "person",

    PLACE = "place",

    PAUSE = "pause",

    PLAY_ARROW = "play-arrow",

    PLAY_CIRCLE_FILLED = "play-circle-filled",

    PLAY_CIRCLE_OUTLINE = "play-circle-outline",

    PLACE_OUTLINE = "place-outline",

    PLUS = "plus",

    PRINTER = "printer",

    PRIORITY_HIGH = "priority-high",

    PRODUCTS = "products",

    QUESTION_ANSWER = "question-answer",

    QUESTION_CIRCLE = "question-circle",

    RADIO_BUTTON_CHECKED = "radio-button-checked",

    RADIO_BUTTON_UNCHECKED = "radio-button-unchecked",

    RECEIPT = "receipt",

    REDO = "redo",

    REFRESH = "refresh",

    REORDER = "reorder",

    REPLY_ALL = "reply-all",

    REPLY = "reply",

    REPORT_PROBLEM = "report-problem",

    REPORT = "report",

    RESTORE = "restore",

    SCHEDULE = "schedule",

    SCH_LOGOS = "sch-logos",

    SCHIBSTED_LOGO_SIMPLE = "schibsted-logo-simple",

    SEARCH = "search",

    SECURITY = "security",

    SEND = "send",

    SETTINGS_BACKUP_RESTORE = "settings-backup-restore",

    SETTINGS = "settings",

    SHARED_OUTLINE = "shared-outline",

    SHARE = "share",

    SHARED = "shared",

    SHOPPING_CART = "shopping-cart",

    SOME_EMAIL_CIRCLE_OUTLINE = "some_email-circle-outline",

    SOME_EMAIL_CIRCLE = "some_email-circle",

    SOME_EMAIL = "some_email",

    SOME_FACEBOOK_CIRCLE_OUTLINE = "some_facebook-circle-outline",

    SOME_FACEBOOK_CIRCLE = "some_facebook-circle",

    SOME_FACEBOOK = "some_facebook",

    SOME_INSTAGRAM_OUTLINE = "some_instagram-outline",

    SOME_INSTAGRAM = "some_instagram",

    SOME_LINKEDIN_CIRCLE_OUTLINE = "some_linkedin-circle-outline",

    SOME_LINKEDIN_CIRCLE = "some_linkedin-circle",

    SOME_LINKEDIN = "some_linkedin",

    SOME_PINTEREST_CIRCLE_OUTLINE = "some_pinterest-circle-outline",

    SOME_PINTEREST_CIRCLE = "some_pinterest-circle",

    SOME_PINTEREST = "some_pinterest",

    SOME_RSS_CIRLE_OUTLINE = "some_rss-cirle-outline",

    SOME_RSS_CIRLE = "some_rss-cirle",

    SOME_RSS = "some_rss",

    SOME_TWITTER_CIRCLE_OUTLINE = "some_twitter-circle-outline",

    SOME_TWITTER_CIRCLE = "some_twitter-circle",

    SOME_TWITTER = "some_twitter",

    STAR_BORDER = "star-border",

    STAR_HALF = "star-half",

    STAR = "star",

    STREAMLINE_ALARM_BELL = "streamline-alarm-bell",

    STREAMLINE_CALENDAR_HOME = "streamline-calendar-home",

    STREAMLINE_DELIVERY_PROBLEMS = "streamline-delivery-problems",

    STREAMLINE_COMPANY_PAYS = "streamline-company-pays",

    STREAMLINE_DELIVERY_STOP = "streamline-delivery-stop",

    STREAMLINE_DELIVERY = "streamline-delivery",

    STREAMLINE_DELIVERY_TEMPORARILY_CHANGE = "streamline-delivery-temporarily-change",

    STREAMLINE_DELIVERYADRESS = "streamline-deliveryadress",

    STREAMLINE_INVOICE_EMAIL = "streamline-invoice-email",

    STREAMLINE_MAIN_ADRESS = "streamline-main-adress",

    STREAMLINE_INVOICE_OVERVIEW = "streamline-invoice-overview",

    STREAMLINE_NOTIFICATION_MESSAGE = "streamline-notification-message",

    STREAMLINE_NEWSLETTERS = "streamline-newsletters",

    STREAMLINE_PAYMENT_METHOD = "streamline-payment-method",

    STREAMLINE_POST = "streamline-post",

    STREAMLINE_PERSONAL_DATA = "streamline-personal-data",

    SUBDIRECTORY_ARROW_LEFT = "subdirectory-arrow-left",

    SUBDIRECTORY_ARROW_RIGHT = "subdirectory-arrow-right",

    SYNC = "sync",

    STREAMLINE_PAYMENTINFO = "streamline-paymentinfo",

    TAX = "tax",

    SUPERADMIN = "superadmin",

    THUMBS_DOWN = "thumbs_down",

    THUMBS_UP = "thumbs_up",

    TODAY = "today",

    TOUCH_APP = "touch-app",

    TRENDING_DOWN = "trending-down",

    TRENDING_FLAT = "trending-flat",

    TRENDING_UP = "trending-up",

    UNDO = "undo",

    USER_LOGGEDIN_FILLED = "user-loggedin-filled",

    USER_LOGGEDIN_OUTLINE = "user-loggedin-outline",

    USER = "user",

    VERIFIED_USER = "verified-user",

    VISIBILITY_OFF = "visibility-off",

    VISIBILITY = "visibility",

    WARNING = "warning",

    WATCH_LATER = "watch-later",

    ZOOM_IN = "zoom-in",

    ZOOM_OUT = "zoom-out",

}