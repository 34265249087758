import * as React from 'react';
import { classNames } from "../../utils";


export enum TableCellType {
    DATA,
    HEAD,
    META
}

export interface ITableCellProps extends React.HTMLAttributes<HTMLTableDataCellElement> {
    type?: TableCellType;
}

export const TableCell: React.FunctionComponent<ITableCellProps> = (props) => {

    const styleClass = {
        root: 'pinata-table__cell',
        check: 'pinata-table__cell--check',
        cellHead: 'pinata-table__cell--head',
        cellMeta: 'pinata-table__cell--meta',
    };

    const renderCellData = () => {

        const {type, children, className, ...rest} = props;

        const componentClasses = classNames(
            styleClass.root,
            className
        );

        return (
            <td
                {...rest}
                className={componentClasses}
            >
                {children}
            </td>
        );
    };

    const renderCellHead = () => {

        const {type, children, className, ...rest} = props;

        const componentClasses = classNames(
            styleClass.root,
            styleClass.cellHead,
            className
        );
        return (
            <th
                {...rest}
                className={componentClasses}
            >
                {children}
            </th>
        );
    };

    const renderCellMeta = () => {

        const {type, children, className, ...rest} = props;

        const componentClasses = classNames(
            styleClass.root,
            styleClass.cellMeta,
            className
        );

        return (
            <td
                {...rest}
                className={componentClasses}
            >
                {children}
            </td>
        );
    };

    switch (props.type) {

        case TableCellType.DATA:
            return renderCellData();

        case TableCellType.HEAD:
            return renderCellHead();

        case TableCellType.META:
            return renderCellMeta();

        default:
            return null;
    }
};

TableCell.defaultProps = {
    type: TableCellType.DATA
};
TableCell.displayName = 'TableCell';
