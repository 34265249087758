///-- THIS FILE IS GENERATED --///
export enum FlexWrap {

    LG_FLEX_NO_WRAP = "lg:pinata-flex-no-wrap",

    LG_FLEX_WRAP = "lg:pinata-flex-wrap",

    LG_FLEX_WRAP_REVERSE = "lg:pinata-flex-wrap-reverse",

    MD_FLEX_NO_WRAP = "md:pinata-flex-no-wrap",

    MD_FLEX_WRAP = "md:pinata-flex-wrap",

    MD_FLEX_WRAP_REVERSE = "md:pinata-flex-wrap-reverse",

    SM_FLEX_NO_WRAP = "sm:pinata-flex-no-wrap",

    SM_FLEX_WRAP = "sm:pinata-flex-wrap",

    SM_FLEX_WRAP_REVERSE = "sm:pinata-flex-wrap-reverse",

    XL_FLEX_NO_WRAP = "xl:pinata-flex-no-wrap",

    XL_FLEX_WRAP = "xl:pinata-flex-wrap",

    XL_FLEX_WRAP_REVERSE = "xl:pinata-flex-wrap-reverse",

    XS_FLEX_NO_WRAP = "xs:pinata-flex-no-wrap",

    XS_FLEX_WRAP = "xs:pinata-flex-wrap",

    XS_FLEX_WRAP_REVERSE = "xs:pinata-flex-wrap-reverse",

    FLEX_NO_WRAP = "pinata-flex-no-wrap",

    FLEX_WRAP = "pinata-flex-wrap",

    FLEX_WRAP_REVERSE = "pinata-flex-wrap-reverse",

}