///-- THIS FILE IS GENERATED --///
export enum Height {

    LG_H_0 = "lg:pinata-h-0",

    LG_H_1 = "lg:pinata-h-1",

    LG_H_10 = "lg:pinata-h-10",

    LG_H_12 = "lg:pinata-h-12",

    LG_H_14 = "lg:pinata-h-14",

    LG_H_16 = "lg:pinata-h-16",

    LG_H_2 = "lg:pinata-h-2",

    LG_H_20 = "lg:pinata-h-20",

    LG_H_24 = "lg:pinata-h-24",

    LG_H_2XL = "lg:pinata-h-2xl",

    LG_H_3 = "lg:pinata-h-3",

    LG_H_32 = "lg:pinata-h-32",

    LG_H_3XL = "lg:pinata-h-3xl",

    LG_H_4 = "lg:pinata-h-4",

    LG_H_40 = "lg:pinata-h-40",

    LG_H_48 = "lg:pinata-h-48",

    LG_H_4XL = "lg:pinata-h-4xl",

    LG_H_5 = "lg:pinata-h-5",

    LG_H_56 = "lg:pinata-h-56",

    LG_H_5XL = "lg:pinata-h-5xl",

    LG_H_6 = "lg:pinata-h-6",

    LG_H_64 = "lg:pinata-h-64",

    LG_H_6XL = "lg:pinata-h-6xl",

    LG_H_8 = "lg:pinata-h-8",

    LG_H_AUTO = "lg:pinata-h-auto",

    LG_H_FULL = "lg:pinata-h-full",

    LG_H_LG = "lg:pinata-h-lg",

    LG_H_MD = "lg:pinata-h-md",

    LG_H_PX = "lg:pinata-h-px",

    LG_H_SCREEN = "lg:pinata-h-screen",

    LG_H_SM = "lg:pinata-h-sm",

    LG_H_XL = "lg:pinata-h-xl",

    LG_H_XS = "lg:pinata-h-xs",

    MD_H_0 = "md:pinata-h-0",

    MD_H_1 = "md:pinata-h-1",

    MD_H_10 = "md:pinata-h-10",

    MD_H_12 = "md:pinata-h-12",

    MD_H_14 = "md:pinata-h-14",

    MD_H_16 = "md:pinata-h-16",

    MD_H_2 = "md:pinata-h-2",

    MD_H_20 = "md:pinata-h-20",

    MD_H_24 = "md:pinata-h-24",

    MD_H_2XL = "md:pinata-h-2xl",

    MD_H_3 = "md:pinata-h-3",

    MD_H_32 = "md:pinata-h-32",

    MD_H_3XL = "md:pinata-h-3xl",

    MD_H_4 = "md:pinata-h-4",

    MD_H_40 = "md:pinata-h-40",

    MD_H_48 = "md:pinata-h-48",

    MD_H_4XL = "md:pinata-h-4xl",

    MD_H_5 = "md:pinata-h-5",

    MD_H_56 = "md:pinata-h-56",

    MD_H_5XL = "md:pinata-h-5xl",

    MD_H_6 = "md:pinata-h-6",

    MD_H_64 = "md:pinata-h-64",

    MD_H_6XL = "md:pinata-h-6xl",

    MD_H_8 = "md:pinata-h-8",

    MD_H_AUTO = "md:pinata-h-auto",

    MD_H_FULL = "md:pinata-h-full",

    MD_H_LG = "md:pinata-h-lg",

    MD_H_MD = "md:pinata-h-md",

    MD_H_PX = "md:pinata-h-px",

    MD_H_SCREEN = "md:pinata-h-screen",

    MD_H_SM = "md:pinata-h-sm",

    MD_H_XL = "md:pinata-h-xl",

    MD_H_XS = "md:pinata-h-xs",

    SM_H_0 = "sm:pinata-h-0",

    SM_H_1 = "sm:pinata-h-1",

    SM_H_10 = "sm:pinata-h-10",

    SM_H_12 = "sm:pinata-h-12",

    SM_H_14 = "sm:pinata-h-14",

    SM_H_16 = "sm:pinata-h-16",

    SM_H_2 = "sm:pinata-h-2",

    SM_H_20 = "sm:pinata-h-20",

    SM_H_24 = "sm:pinata-h-24",

    SM_H_2XL = "sm:pinata-h-2xl",

    SM_H_3 = "sm:pinata-h-3",

    SM_H_32 = "sm:pinata-h-32",

    SM_H_3XL = "sm:pinata-h-3xl",

    SM_H_4 = "sm:pinata-h-4",

    SM_H_40 = "sm:pinata-h-40",

    SM_H_48 = "sm:pinata-h-48",

    SM_H_4XL = "sm:pinata-h-4xl",

    SM_H_5 = "sm:pinata-h-5",

    SM_H_56 = "sm:pinata-h-56",

    SM_H_5XL = "sm:pinata-h-5xl",

    SM_H_6 = "sm:pinata-h-6",

    SM_H_64 = "sm:pinata-h-64",

    SM_H_6XL = "sm:pinata-h-6xl",

    SM_H_8 = "sm:pinata-h-8",

    SM_H_AUTO = "sm:pinata-h-auto",

    SM_H_FULL = "sm:pinata-h-full",

    SM_H_LG = "sm:pinata-h-lg",

    SM_H_MD = "sm:pinata-h-md",

    SM_H_PX = "sm:pinata-h-px",

    SM_H_SCREEN = "sm:pinata-h-screen",

    SM_H_SM = "sm:pinata-h-sm",

    SM_H_XL = "sm:pinata-h-xl",

    SM_H_XS = "sm:pinata-h-xs",

    XL_H_0 = "xl:pinata-h-0",

    XL_H_1 = "xl:pinata-h-1",

    XL_H_10 = "xl:pinata-h-10",

    XL_H_12 = "xl:pinata-h-12",

    XL_H_14 = "xl:pinata-h-14",

    XL_H_16 = "xl:pinata-h-16",

    XL_H_2 = "xl:pinata-h-2",

    XL_H_20 = "xl:pinata-h-20",

    XL_H_24 = "xl:pinata-h-24",

    XL_H_2XL = "xl:pinata-h-2xl",

    XL_H_3 = "xl:pinata-h-3",

    XL_H_32 = "xl:pinata-h-32",

    XL_H_3XL = "xl:pinata-h-3xl",

    XL_H_4 = "xl:pinata-h-4",

    XL_H_40 = "xl:pinata-h-40",

    XL_H_48 = "xl:pinata-h-48",

    XL_H_4XL = "xl:pinata-h-4xl",

    XL_H_5 = "xl:pinata-h-5",

    XL_H_56 = "xl:pinata-h-56",

    XL_H_5XL = "xl:pinata-h-5xl",

    XL_H_6 = "xl:pinata-h-6",

    XL_H_64 = "xl:pinata-h-64",

    XL_H_6XL = "xl:pinata-h-6xl",

    XL_H_8 = "xl:pinata-h-8",

    XL_H_AUTO = "xl:pinata-h-auto",

    XL_H_FULL = "xl:pinata-h-full",

    XL_H_LG = "xl:pinata-h-lg",

    XL_H_MD = "xl:pinata-h-md",

    XL_H_PX = "xl:pinata-h-px",

    XL_H_SCREEN = "xl:pinata-h-screen",

    XL_H_SM = "xl:pinata-h-sm",

    XL_H_XL = "xl:pinata-h-xl",

    XL_H_XS = "xl:pinata-h-xs",

    XS_H_0 = "xs:pinata-h-0",

    XS_H_1 = "xs:pinata-h-1",

    XS_H_10 = "xs:pinata-h-10",

    XS_H_12 = "xs:pinata-h-12",

    XS_H_14 = "xs:pinata-h-14",

    XS_H_16 = "xs:pinata-h-16",

    XS_H_2 = "xs:pinata-h-2",

    XS_H_20 = "xs:pinata-h-20",

    XS_H_24 = "xs:pinata-h-24",

    XS_H_2XL = "xs:pinata-h-2xl",

    XS_H_3 = "xs:pinata-h-3",

    XS_H_32 = "xs:pinata-h-32",

    XS_H_3XL = "xs:pinata-h-3xl",

    XS_H_4 = "xs:pinata-h-4",

    XS_H_40 = "xs:pinata-h-40",

    XS_H_48 = "xs:pinata-h-48",

    XS_H_4XL = "xs:pinata-h-4xl",

    XS_H_5 = "xs:pinata-h-5",

    XS_H_56 = "xs:pinata-h-56",

    XS_H_5XL = "xs:pinata-h-5xl",

    XS_H_6 = "xs:pinata-h-6",

    XS_H_64 = "xs:pinata-h-64",

    XS_H_6XL = "xs:pinata-h-6xl",

    XS_H_8 = "xs:pinata-h-8",

    XS_H_AUTO = "xs:pinata-h-auto",

    XS_H_FULL = "xs:pinata-h-full",

    XS_H_LG = "xs:pinata-h-lg",

    XS_H_MD = "xs:pinata-h-md",

    XS_H_PX = "xs:pinata-h-px",

    XS_H_SCREEN = "xs:pinata-h-screen",

    XS_H_SM = "xs:pinata-h-sm",

    XS_H_XL = "xs:pinata-h-xl",

    XS_H_XS = "xs:pinata-h-xs",

    H_0 = "pinata-h-0",

    H_1 = "pinata-h-1",

    H_10 = "pinata-h-10",

    H_12 = "pinata-h-12",

    H_14 = "pinata-h-14",

    H_16 = "pinata-h-16",

    H_2 = "pinata-h-2",

    H_20 = "pinata-h-20",

    H_24 = "pinata-h-24",

    H_2XL = "pinata-h-2xl",

    H_3 = "pinata-h-3",

    H_32 = "pinata-h-32",

    H_3XL = "pinata-h-3xl",

    H_4 = "pinata-h-4",

    H_40 = "pinata-h-40",

    H_48 = "pinata-h-48",

    H_4XL = "pinata-h-4xl",

    H_5 = "pinata-h-5",

    H_56 = "pinata-h-56",

    H_5XL = "pinata-h-5xl",

    H_6 = "pinata-h-6",

    H_64 = "pinata-h-64",

    H_6XL = "pinata-h-6xl",

    H_8 = "pinata-h-8",

    H_AUTO = "pinata-h-auto",

    H_FULL = "pinata-h-full",

    H_LG = "pinata-h-lg",

    H_MD = "pinata-h-md",

    H_PX = "pinata-h-px",

    H_SCREEN = "pinata-h-screen",

    H_SM = "pinata-h-sm",

    H_XL = "pinata-h-xl",

    H_XS = "pinata-h-xs",

}