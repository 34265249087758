export function getTouchPosition (e: TouchEvent): { x: number, y: number } {
    return {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
    }
}

export function getTouchId (e: TouchEvent) {
    return e.changedTouches[0].identifier
}

export class TouchMoveRecord {

    public readonly x: number;
    public readonly y: number;
    public readonly time: number;

    constructor(__event: TouchEvent) {
        const {x, y} = getTouchPosition(__event);
        this.x = x;
        this.y = y;
        this.time = Date.now();
    }
}
