///-- THIS FILE IS GENERATED --///
export enum AlignContent {

    LG_CONTENT_AROUND = "lg:pinata-content-around",

    LG_CONTENT_BETWEEN = "lg:pinata-content-between",

    LG_CONTENT_CENTER = "lg:pinata-content-center",

    LG_CONTENT_END = "lg:pinata-content-end",

    LG_CONTENT_START = "lg:pinata-content-start",

    MD_CONTENT_AROUND = "md:pinata-content-around",

    MD_CONTENT_BETWEEN = "md:pinata-content-between",

    MD_CONTENT_CENTER = "md:pinata-content-center",

    MD_CONTENT_END = "md:pinata-content-end",

    MD_CONTENT_START = "md:pinata-content-start",

    SM_CONTENT_AROUND = "sm:pinata-content-around",

    SM_CONTENT_BETWEEN = "sm:pinata-content-between",

    SM_CONTENT_CENTER = "sm:pinata-content-center",

    SM_CONTENT_END = "sm:pinata-content-end",

    SM_CONTENT_START = "sm:pinata-content-start",

    XL_CONTENT_AROUND = "xl:pinata-content-around",

    XL_CONTENT_BETWEEN = "xl:pinata-content-between",

    XL_CONTENT_CENTER = "xl:pinata-content-center",

    XL_CONTENT_END = "xl:pinata-content-end",

    XL_CONTENT_START = "xl:pinata-content-start",

    XS_CONTENT_AROUND = "xs:pinata-content-around",

    XS_CONTENT_BETWEEN = "xs:pinata-content-between",

    XS_CONTENT_CENTER = "xs:pinata-content-center",

    XS_CONTENT_END = "xs:pinata-content-end",

    XS_CONTENT_START = "xs:pinata-content-start",

    CONTENT_AROUND = "pinata-content-around",

    CONTENT_BETWEEN = "pinata-content-between",

    CONTENT_CENTER = "pinata-content-center",

    CONTENT_END = "pinata-content-end",

    CONTENT_START = "pinata-content-start",

}