///-- THIS FILE IS GENERATED --///
export enum LineHeight {

    LG_LEADING_BASE = "lg:pinata-leading-base",

    LG_LEADING_HEADLINE1 = "lg:pinata-leading-headline1",

    LG_LEADING_HEADLINE2 = "lg:pinata-leading-headline2",

    LG_LEADING_HEADLINE3 = "lg:pinata-leading-headline3",

    LG_LEADING_HEADLINE4 = "lg:pinata-leading-headline4",

    LG_LEADING_HEADLINE5 = "lg:pinata-leading-headline5",

    LG_LEADING_HERO = "lg:pinata-leading-hero",

    LG_LEADING_LOOSE = "lg:pinata-leading-loose",

    LG_LEADING_MEGA = "lg:pinata-leading-mega",

    LG_LEADING_NONE = "lg:pinata-leading-none",

    LG_LEADING_NORMAL = "lg:pinata-leading-normal",

    LG_LEADING_TIGHT = "lg:pinata-leading-tight",

    MD_LEADING_BASE = "md:pinata-leading-base",

    MD_LEADING_HEADLINE1 = "md:pinata-leading-headline1",

    MD_LEADING_HEADLINE2 = "md:pinata-leading-headline2",

    MD_LEADING_HEADLINE3 = "md:pinata-leading-headline3",

    MD_LEADING_HEADLINE4 = "md:pinata-leading-headline4",

    MD_LEADING_HEADLINE5 = "md:pinata-leading-headline5",

    MD_LEADING_HERO = "md:pinata-leading-hero",

    MD_LEADING_LOOSE = "md:pinata-leading-loose",

    MD_LEADING_MEGA = "md:pinata-leading-mega",

    MD_LEADING_NONE = "md:pinata-leading-none",

    MD_LEADING_NORMAL = "md:pinata-leading-normal",

    MD_LEADING_TIGHT = "md:pinata-leading-tight",

    SM_LEADING_BASE = "sm:pinata-leading-base",

    SM_LEADING_HEADLINE1 = "sm:pinata-leading-headline1",

    SM_LEADING_HEADLINE2 = "sm:pinata-leading-headline2",

    SM_LEADING_HEADLINE3 = "sm:pinata-leading-headline3",

    SM_LEADING_HEADLINE4 = "sm:pinata-leading-headline4",

    SM_LEADING_HEADLINE5 = "sm:pinata-leading-headline5",

    SM_LEADING_HERO = "sm:pinata-leading-hero",

    SM_LEADING_LOOSE = "sm:pinata-leading-loose",

    SM_LEADING_MEGA = "sm:pinata-leading-mega",

    SM_LEADING_NONE = "sm:pinata-leading-none",

    SM_LEADING_NORMAL = "sm:pinata-leading-normal",

    SM_LEADING_TIGHT = "sm:pinata-leading-tight",

    XL_LEADING_BASE = "xl:pinata-leading-base",

    XL_LEADING_HEADLINE1 = "xl:pinata-leading-headline1",

    XL_LEADING_HEADLINE2 = "xl:pinata-leading-headline2",

    XL_LEADING_HEADLINE3 = "xl:pinata-leading-headline3",

    XL_LEADING_HEADLINE4 = "xl:pinata-leading-headline4",

    XL_LEADING_HEADLINE5 = "xl:pinata-leading-headline5",

    XL_LEADING_HERO = "xl:pinata-leading-hero",

    XL_LEADING_LOOSE = "xl:pinata-leading-loose",

    XL_LEADING_MEGA = "xl:pinata-leading-mega",

    XL_LEADING_NONE = "xl:pinata-leading-none",

    XL_LEADING_NORMAL = "xl:pinata-leading-normal",

    XL_LEADING_TIGHT = "xl:pinata-leading-tight",

    XS_LEADING_BASE = "xs:pinata-leading-base",

    XS_LEADING_HEADLINE1 = "xs:pinata-leading-headline1",

    XS_LEADING_HEADLINE2 = "xs:pinata-leading-headline2",

    XS_LEADING_HEADLINE3 = "xs:pinata-leading-headline3",

    XS_LEADING_HEADLINE4 = "xs:pinata-leading-headline4",

    XS_LEADING_HEADLINE5 = "xs:pinata-leading-headline5",

    XS_LEADING_HERO = "xs:pinata-leading-hero",

    XS_LEADING_LOOSE = "xs:pinata-leading-loose",

    XS_LEADING_MEGA = "xs:pinata-leading-mega",

    XS_LEADING_NONE = "xs:pinata-leading-none",

    XS_LEADING_NORMAL = "xs:pinata-leading-normal",

    XS_LEADING_TIGHT = "xs:pinata-leading-tight",

    LEADING_BASE = "pinata-leading-base",

    LEADING_HEADLINE1 = "pinata-leading-headline1",

    LEADING_HEADLINE2 = "pinata-leading-headline2",

    LEADING_HEADLINE3 = "pinata-leading-headline3",

    LEADING_HEADLINE4 = "pinata-leading-headline4",

    LEADING_HEADLINE5 = "pinata-leading-headline5",

    LEADING_HERO = "pinata-leading-hero",

    LEADING_LOOSE = "pinata-leading-loose",

    LEADING_MEGA = "pinata-leading-mega",

    LEADING_NONE = "pinata-leading-none",

    LEADING_NORMAL = "pinata-leading-normal",

    LEADING_TIGHT = "pinata-leading-tight",

}