///-- THIS FILE IS GENERATED --///
export enum BackgroundRepeat {

    BG_NO_REPEAT = "pinata-bg-no-repeat",

    BG_REPEAT = "pinata-bg-repeat",

    BG_REPEAT_X = "pinata-bg-repeat-x",

    BG_REPEAT_Y = "pinata-bg-repeat-y",

}