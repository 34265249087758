///-- THIS FILE IS GENERATED --///
export enum BorderColor {

    HOVER_BORDER = "hover:pinata-border",

    HOVER_BORDER_BLACK = "hover:pinata-border-black",

    HOVER_BORDER_HEADER = "hover:pinata-border-header",

    HOVER_BORDER_INFO = "hover:pinata-border-info",

    HOVER_BORDER_NEGATIVE = "hover:pinata-border-negative",

    HOVER_BORDER_NEUTRAL_1 = "hover:pinata-border-neutral-1",

    HOVER_BORDER_NEUTRAL_2 = "hover:pinata-border-neutral-2",

    HOVER_BORDER_NEUTRAL_3 = "hover:pinata-border-neutral-3",

    HOVER_BORDER_NEUTRAL_4 = "hover:pinata-border-neutral-4",

    HOVER_BORDER_NEUTRAL_5 = "hover:pinata-border-neutral-5",

    HOVER_BORDER_NEUTRAL_6 = "hover:pinata-border-neutral-6",

    HOVER_BORDER_NEUTRAL_7 = "hover:pinata-border-neutral-7",

    HOVER_BORDER_NEUTRAL_8 = "hover:pinata-border-neutral-8",

    HOVER_BORDER_POSITIVE = "hover:pinata-border-positive",

    HOVER_BORDER_PRIMARY_1 = "hover:pinata-border-primary-1",

    HOVER_BORDER_PRIMARY_2 = "hover:pinata-border-primary-2",

    HOVER_BORDER_PRIMARY_3 = "hover:pinata-border-primary-3",

    HOVER_BORDER_PRIMARY_4 = "hover:pinata-border-primary-4",

    HOVER_BORDER_PRIMARY_DARK = "hover:pinata-border-primary-dark",

    HOVER_BORDER_PRIMARY_LIGHT = "hover:pinata-border-primary-light",

    HOVER_BORDER_SECONDARY_1 = "hover:pinata-border-secondary-1",

    HOVER_BORDER_SECONDARY_2 = "hover:pinata-border-secondary-2",

    HOVER_BORDER_SECONDARY_3 = "hover:pinata-border-secondary-3",

    HOVER_BORDER_SECONDARY_4 = "hover:pinata-border-secondary-4",

    HOVER_BORDER_TERTIARY_1 = "hover:pinata-border-tertiary-1",

    HOVER_BORDER_TERTIARY_2 = "hover:pinata-border-tertiary-2",

    HOVER_BORDER_TERTIARY_3 = "hover:pinata-border-tertiary-3",

    HOVER_BORDER_TERTIARY_4 = "hover:pinata-border-tertiary-4",

    HOVER_BORDER_TRANSPARENT = "hover:pinata-border-transparent",

    HOVER_BORDER_WARNING = "hover:pinata-border-warning",

    HOVER_BORDER_WHITE = "hover:pinata-border-white",

    FOCUS_BORDER = "focus:pinata-border",

    FOCUS_BORDER_BLACK = "focus:pinata-border-black",

    FOCUS_BORDER_HEADER = "focus:pinata-border-header",

    FOCUS_BORDER_INFO = "focus:pinata-border-info",

    FOCUS_BORDER_NEGATIVE = "focus:pinata-border-negative",

    FOCUS_BORDER_NEUTRAL_1 = "focus:pinata-border-neutral-1",

    FOCUS_BORDER_NEUTRAL_2 = "focus:pinata-border-neutral-2",

    FOCUS_BORDER_NEUTRAL_3 = "focus:pinata-border-neutral-3",

    FOCUS_BORDER_NEUTRAL_4 = "focus:pinata-border-neutral-4",

    FOCUS_BORDER_NEUTRAL_5 = "focus:pinata-border-neutral-5",

    FOCUS_BORDER_NEUTRAL_6 = "focus:pinata-border-neutral-6",

    FOCUS_BORDER_NEUTRAL_7 = "focus:pinata-border-neutral-7",

    FOCUS_BORDER_NEUTRAL_8 = "focus:pinata-border-neutral-8",

    FOCUS_BORDER_POSITIVE = "focus:pinata-border-positive",

    FOCUS_BORDER_PRIMARY_1 = "focus:pinata-border-primary-1",

    FOCUS_BORDER_PRIMARY_2 = "focus:pinata-border-primary-2",

    FOCUS_BORDER_PRIMARY_3 = "focus:pinata-border-primary-3",

    FOCUS_BORDER_PRIMARY_4 = "focus:pinata-border-primary-4",

    FOCUS_BORDER_PRIMARY_DARK = "focus:pinata-border-primary-dark",

    FOCUS_BORDER_PRIMARY_LIGHT = "focus:pinata-border-primary-light",

    FOCUS_BORDER_SECONDARY_1 = "focus:pinata-border-secondary-1",

    FOCUS_BORDER_SECONDARY_2 = "focus:pinata-border-secondary-2",

    FOCUS_BORDER_SECONDARY_3 = "focus:pinata-border-secondary-3",

    FOCUS_BORDER_SECONDARY_4 = "focus:pinata-border-secondary-4",

    FOCUS_BORDER_TERTIARY_1 = "focus:pinata-border-tertiary-1",

    FOCUS_BORDER_TERTIARY_2 = "focus:pinata-border-tertiary-2",

    FOCUS_BORDER_TERTIARY_3 = "focus:pinata-border-tertiary-3",

    FOCUS_BORDER_TERTIARY_4 = "focus:pinata-border-tertiary-4",

    FOCUS_BORDER_TRANSPARENT = "focus:pinata-border-transparent",

    FOCUS_BORDER_WARNING = "focus:pinata-border-warning",

    FOCUS_BORDER_WHITE = "focus:pinata-border-white",

    BORDER = "pinata-border",

    BORDER_BLACK = "pinata-border-black",

    BORDER_HEADER = "pinata-border-header",

    BORDER_INFO = "pinata-border-info",

    BORDER_NEGATIVE = "pinata-border-negative",

    BORDER_NEUTRAL_1 = "pinata-border-neutral-1",

    BORDER_NEUTRAL_2 = "pinata-border-neutral-2",

    BORDER_NEUTRAL_3 = "pinata-border-neutral-3",

    BORDER_NEUTRAL_4 = "pinata-border-neutral-4",

    BORDER_NEUTRAL_5 = "pinata-border-neutral-5",

    BORDER_NEUTRAL_6 = "pinata-border-neutral-6",

    BORDER_NEUTRAL_7 = "pinata-border-neutral-7",

    BORDER_NEUTRAL_8 = "pinata-border-neutral-8",

    BORDER_POSITIVE = "pinata-border-positive",

    BORDER_PRIMARY_1 = "pinata-border-primary-1",

    BORDER_PRIMARY_2 = "pinata-border-primary-2",

    BORDER_PRIMARY_3 = "pinata-border-primary-3",

    BORDER_PRIMARY_4 = "pinata-border-primary-4",

    BORDER_PRIMARY_DARK = "pinata-border-primary-dark",

    BORDER_PRIMARY_LIGHT = "pinata-border-primary-light",

    BORDER_SECONDARY_1 = "pinata-border-secondary-1",

    BORDER_SECONDARY_2 = "pinata-border-secondary-2",

    BORDER_SECONDARY_3 = "pinata-border-secondary-3",

    BORDER_SECONDARY_4 = "pinata-border-secondary-4",

    BORDER_TERTIARY_1 = "pinata-border-tertiary-1",

    BORDER_TERTIARY_2 = "pinata-border-tertiary-2",

    BORDER_TERTIARY_3 = "pinata-border-tertiary-3",

    BORDER_TERTIARY_4 = "pinata-border-tertiary-4",

    BORDER_TRANSPARENT = "pinata-border-transparent",

    BORDER_WARNING = "pinata-border-warning",

    BORDER_WHITE = "pinata-border-white",

}