///-- THIS FILE IS GENERATED --///
export enum Order {

    LG_ORDER_1 = "lg:pinata-order-1",

    LG_ORDER_10 = "lg:pinata-order-10",

    LG_ORDER_11 = "lg:pinata-order-11",

    LG_ORDER_12 = "lg:pinata-order-12",

    LG_ORDER_2 = "lg:pinata-order-2",

    LG_ORDER_3 = "lg:pinata-order-3",

    LG_ORDER_4 = "lg:pinata-order-4",

    LG_ORDER_5 = "lg:pinata-order-5",

    LG_ORDER_6 = "lg:pinata-order-6",

    LG_ORDER_7 = "lg:pinata-order-7",

    LG_ORDER_8 = "lg:pinata-order-8",

    LG_ORDER_9 = "lg:pinata-order-9",

    LG_ORDER_FIRST = "lg:pinata-order-first",

    LG_ORDER_LAST = "lg:pinata-order-last",

    LG_ORDER_NONE = "lg:pinata-order-none",

    MD_ORDER_1 = "md:pinata-order-1",

    MD_ORDER_10 = "md:pinata-order-10",

    MD_ORDER_11 = "md:pinata-order-11",

    MD_ORDER_12 = "md:pinata-order-12",

    MD_ORDER_2 = "md:pinata-order-2",

    MD_ORDER_3 = "md:pinata-order-3",

    MD_ORDER_4 = "md:pinata-order-4",

    MD_ORDER_5 = "md:pinata-order-5",

    MD_ORDER_6 = "md:pinata-order-6",

    MD_ORDER_7 = "md:pinata-order-7",

    MD_ORDER_8 = "md:pinata-order-8",

    MD_ORDER_9 = "md:pinata-order-9",

    MD_ORDER_FIRST = "md:pinata-order-first",

    MD_ORDER_LAST = "md:pinata-order-last",

    MD_ORDER_NONE = "md:pinata-order-none",

    SM_ORDER_1 = "sm:pinata-order-1",

    SM_ORDER_10 = "sm:pinata-order-10",

    SM_ORDER_11 = "sm:pinata-order-11",

    SM_ORDER_12 = "sm:pinata-order-12",

    SM_ORDER_2 = "sm:pinata-order-2",

    SM_ORDER_3 = "sm:pinata-order-3",

    SM_ORDER_4 = "sm:pinata-order-4",

    SM_ORDER_5 = "sm:pinata-order-5",

    SM_ORDER_6 = "sm:pinata-order-6",

    SM_ORDER_7 = "sm:pinata-order-7",

    SM_ORDER_8 = "sm:pinata-order-8",

    SM_ORDER_9 = "sm:pinata-order-9",

    SM_ORDER_FIRST = "sm:pinata-order-first",

    SM_ORDER_LAST = "sm:pinata-order-last",

    SM_ORDER_NONE = "sm:pinata-order-none",

    XL_ORDER_1 = "xl:pinata-order-1",

    XL_ORDER_10 = "xl:pinata-order-10",

    XL_ORDER_11 = "xl:pinata-order-11",

    XL_ORDER_12 = "xl:pinata-order-12",

    XL_ORDER_2 = "xl:pinata-order-2",

    XL_ORDER_3 = "xl:pinata-order-3",

    XL_ORDER_4 = "xl:pinata-order-4",

    XL_ORDER_5 = "xl:pinata-order-5",

    XL_ORDER_6 = "xl:pinata-order-6",

    XL_ORDER_7 = "xl:pinata-order-7",

    XL_ORDER_8 = "xl:pinata-order-8",

    XL_ORDER_9 = "xl:pinata-order-9",

    XL_ORDER_FIRST = "xl:pinata-order-first",

    XL_ORDER_LAST = "xl:pinata-order-last",

    XL_ORDER_NONE = "xl:pinata-order-none",

    XS_ORDER_1 = "xs:pinata-order-1",

    XS_ORDER_10 = "xs:pinata-order-10",

    XS_ORDER_11 = "xs:pinata-order-11",

    XS_ORDER_12 = "xs:pinata-order-12",

    XS_ORDER_2 = "xs:pinata-order-2",

    XS_ORDER_3 = "xs:pinata-order-3",

    XS_ORDER_4 = "xs:pinata-order-4",

    XS_ORDER_5 = "xs:pinata-order-5",

    XS_ORDER_6 = "xs:pinata-order-6",

    XS_ORDER_7 = "xs:pinata-order-7",

    XS_ORDER_8 = "xs:pinata-order-8",

    XS_ORDER_9 = "xs:pinata-order-9",

    XS_ORDER_FIRST = "xs:pinata-order-first",

    XS_ORDER_LAST = "xs:pinata-order-last",

    XS_ORDER_NONE = "xs:pinata-order-none",

    ORDER_1 = "pinata-order-1",

    ORDER_10 = "pinata-order-10",

    ORDER_11 = "pinata-order-11",

    ORDER_12 = "pinata-order-12",

    ORDER_2 = "pinata-order-2",

    ORDER_3 = "pinata-order-3",

    ORDER_4 = "pinata-order-4",

    ORDER_5 = "pinata-order-5",

    ORDER_6 = "pinata-order-6",

    ORDER_7 = "pinata-order-7",

    ORDER_8 = "pinata-order-8",

    ORDER_9 = "pinata-order-9",

    ORDER_FIRST = "pinata-order-first",

    ORDER_LAST = "pinata-order-last",

    ORDER_NONE = "pinata-order-none",

}