import * as React from "react";
import responsiveToMedia from './ResponsiveToMedia';
import onlyRenderInBrowser from './onlyRenderInBrowser';

const screens: any = process.env.SCREENS;


type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Subtract<T, K> = Omit<T, keyof K>;

export interface IWithResponsiveProps {
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
    portrait?: boolean;
    landscape?: boolean;
}

export const withResponsive = <P extends IWithResponsiveProps>(Component: React.ComponentType<P>): React.ComponentType<P> => responsiveToMedia({
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    mobile: {minWidth: screens.xs, maxWidth: screens.md - 1},
    tablet: {minWidth: screens.md, maxWidth: screens.lg},
    desktop: {minWidth: screens.lg + 1}
})(Component);

export const withResponsiveBrowserOnly = <P extends IWithResponsiveProps>(Component: React.ComponentType<P>): React.ComponentType<P> => responsiveToMedia({
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
    mobile: {minWidth: screens.xs, maxWidth: screens.md - 1},
    tablet: {minWidth: screens.md, maxWidth: screens.lg},
    desktop: {minWidth: screens.lg + 1}
})(onlyRenderInBrowser(Component));






