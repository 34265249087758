///-- THIS FILE IS GENERATED --///
export enum AlignSelf {

    LG_SELF_AUTO = "lg:pinata-self-auto",

    LG_SELF_CENTER = "lg:pinata-self-center",

    LG_SELF_END = "lg:pinata-self-end",

    LG_SELF_START = "lg:pinata-self-start",

    LG_SELF_STRETCH = "lg:pinata-self-stretch",

    MD_SELF_AUTO = "md:pinata-self-auto",

    MD_SELF_CENTER = "md:pinata-self-center",

    MD_SELF_END = "md:pinata-self-end",

    MD_SELF_START = "md:pinata-self-start",

    MD_SELF_STRETCH = "md:pinata-self-stretch",

    SM_SELF_AUTO = "sm:pinata-self-auto",

    SM_SELF_CENTER = "sm:pinata-self-center",

    SM_SELF_END = "sm:pinata-self-end",

    SM_SELF_START = "sm:pinata-self-start",

    SM_SELF_STRETCH = "sm:pinata-self-stretch",

    XL_SELF_AUTO = "xl:pinata-self-auto",

    XL_SELF_CENTER = "xl:pinata-self-center",

    XL_SELF_END = "xl:pinata-self-end",

    XL_SELF_START = "xl:pinata-self-start",

    XL_SELF_STRETCH = "xl:pinata-self-stretch",

    XS_SELF_AUTO = "xs:pinata-self-auto",

    XS_SELF_CENTER = "xs:pinata-self-center",

    XS_SELF_END = "xs:pinata-self-end",

    XS_SELF_START = "xs:pinata-self-start",

    XS_SELF_STRETCH = "xs:pinata-self-stretch",

    SELF_AUTO = "pinata-self-auto",

    SELF_CENTER = "pinata-self-center",

    SELF_END = "pinata-self-end",

    SELF_START = "pinata-self-start",

    SELF_STRETCH = "pinata-self-stretch",

}