///-- THIS FILE IS GENERATED --///
export enum FontSize {

    LG_TEXT_2XL = "lg:pinata-text-2xl",

    LG_TEXT_3XL = "lg:pinata-text-3xl",

    LG_TEXT_4XL = "lg:pinata-text-4xl",

    LG_TEXT_5XL = "lg:pinata-text-5xl",

    LG_TEXT_6XL = "lg:pinata-text-6xl",

    LG_TEXT_BASE = "lg:pinata-text-base",

    LG_TEXT_HEADLINE1 = "lg:pinata-text-headline1",

    LG_TEXT_HEADLINE2 = "lg:pinata-text-headline2",

    LG_TEXT_HEADLINE3 = "lg:pinata-text-headline3",

    LG_TEXT_HEADLINE4 = "lg:pinata-text-headline4",

    LG_TEXT_HEADLINE5 = "lg:pinata-text-headline5",

    LG_TEXT_HERO = "lg:pinata-text-hero",

    LG_TEXT_LG = "lg:pinata-text-lg",

    LG_TEXT_MEGA = "lg:pinata-text-mega",

    LG_TEXT_SM = "lg:pinata-text-sm",

    LG_TEXT_XL = "lg:pinata-text-xl",

    LG_TEXT_XS = "lg:pinata-text-xs",

    MD_TEXT_2XL = "md:pinata-text-2xl",

    MD_TEXT_3XL = "md:pinata-text-3xl",

    MD_TEXT_4XL = "md:pinata-text-4xl",

    MD_TEXT_5XL = "md:pinata-text-5xl",

    MD_TEXT_6XL = "md:pinata-text-6xl",

    MD_TEXT_BASE = "md:pinata-text-base",

    MD_TEXT_HEADLINE1 = "md:pinata-text-headline1",

    MD_TEXT_HEADLINE2 = "md:pinata-text-headline2",

    MD_TEXT_HEADLINE3 = "md:pinata-text-headline3",

    MD_TEXT_HEADLINE4 = "md:pinata-text-headline4",

    MD_TEXT_HEADLINE5 = "md:pinata-text-headline5",

    MD_TEXT_HERO = "md:pinata-text-hero",

    MD_TEXT_LG = "md:pinata-text-lg",

    MD_TEXT_MEGA = "md:pinata-text-mega",

    MD_TEXT_SM = "md:pinata-text-sm",

    MD_TEXT_XL = "md:pinata-text-xl",

    MD_TEXT_XS = "md:pinata-text-xs",

    SM_TEXT_2XL = "sm:pinata-text-2xl",

    SM_TEXT_3XL = "sm:pinata-text-3xl",

    SM_TEXT_4XL = "sm:pinata-text-4xl",

    SM_TEXT_5XL = "sm:pinata-text-5xl",

    SM_TEXT_6XL = "sm:pinata-text-6xl",

    SM_TEXT_BASE = "sm:pinata-text-base",

    SM_TEXT_HEADLINE1 = "sm:pinata-text-headline1",

    SM_TEXT_HEADLINE2 = "sm:pinata-text-headline2",

    SM_TEXT_HEADLINE3 = "sm:pinata-text-headline3",

    SM_TEXT_HEADLINE4 = "sm:pinata-text-headline4",

    SM_TEXT_HEADLINE5 = "sm:pinata-text-headline5",

    SM_TEXT_HERO = "sm:pinata-text-hero",

    SM_TEXT_LG = "sm:pinata-text-lg",

    SM_TEXT_MEGA = "sm:pinata-text-mega",

    SM_TEXT_SM = "sm:pinata-text-sm",

    SM_TEXT_XL = "sm:pinata-text-xl",

    SM_TEXT_XS = "sm:pinata-text-xs",

    XL_TEXT_2XL = "xl:pinata-text-2xl",

    XL_TEXT_3XL = "xl:pinata-text-3xl",

    XL_TEXT_4XL = "xl:pinata-text-4xl",

    XL_TEXT_5XL = "xl:pinata-text-5xl",

    XL_TEXT_6XL = "xl:pinata-text-6xl",

    XL_TEXT_BASE = "xl:pinata-text-base",

    XL_TEXT_HEADLINE1 = "xl:pinata-text-headline1",

    XL_TEXT_HEADLINE2 = "xl:pinata-text-headline2",

    XL_TEXT_HEADLINE3 = "xl:pinata-text-headline3",

    XL_TEXT_HEADLINE4 = "xl:pinata-text-headline4",

    XL_TEXT_HEADLINE5 = "xl:pinata-text-headline5",

    XL_TEXT_HERO = "xl:pinata-text-hero",

    XL_TEXT_LG = "xl:pinata-text-lg",

    XL_TEXT_MEGA = "xl:pinata-text-mega",

    XL_TEXT_SM = "xl:pinata-text-sm",

    XL_TEXT_XL = "xl:pinata-text-xl",

    XL_TEXT_XS = "xl:pinata-text-xs",

    XS_TEXT_2XL = "xs:pinata-text-2xl",

    XS_TEXT_3XL = "xs:pinata-text-3xl",

    XS_TEXT_4XL = "xs:pinata-text-4xl",

    XS_TEXT_5XL = "xs:pinata-text-5xl",

    XS_TEXT_6XL = "xs:pinata-text-6xl",

    XS_TEXT_BASE = "xs:pinata-text-base",

    XS_TEXT_HEADLINE1 = "xs:pinata-text-headline1",

    XS_TEXT_HEADLINE2 = "xs:pinata-text-headline2",

    XS_TEXT_HEADLINE3 = "xs:pinata-text-headline3",

    XS_TEXT_HEADLINE4 = "xs:pinata-text-headline4",

    XS_TEXT_HEADLINE5 = "xs:pinata-text-headline5",

    XS_TEXT_HERO = "xs:pinata-text-hero",

    XS_TEXT_LG = "xs:pinata-text-lg",

    XS_TEXT_MEGA = "xs:pinata-text-mega",

    XS_TEXT_SM = "xs:pinata-text-sm",

    XS_TEXT_XL = "xs:pinata-text-xl",

    XS_TEXT_XS = "xs:pinata-text-xs",

    TEXT_2XL = "pinata-text-2xl",

    TEXT_3XL = "pinata-text-3xl",

    TEXT_4XL = "pinata-text-4xl",

    TEXT_5XL = "pinata-text-5xl",

    TEXT_6XL = "pinata-text-6xl",

    TEXT_BASE = "pinata-text-base",

    TEXT_HEADLINE1 = "pinata-text-headline1",

    TEXT_HEADLINE2 = "pinata-text-headline2",

    TEXT_HEADLINE3 = "pinata-text-headline3",

    TEXT_HEADLINE4 = "pinata-text-headline4",

    TEXT_HEADLINE5 = "pinata-text-headline5",

    TEXT_HERO = "pinata-text-hero",

    TEXT_LG = "pinata-text-lg",

    TEXT_MEGA = "pinata-text-mega",

    TEXT_SM = "pinata-text-sm",

    TEXT_XL = "pinata-text-xl",

    TEXT_XS = "pinata-text-xs",

}