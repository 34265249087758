///-- THIS FILE IS GENERATED --///
export enum TextTransform {

    LG_CAPITALIZE = "lg:pinata-capitalize",

    LG_LOWERCASE = "lg:pinata-lowercase",

    LG_NORMAL_CASE = "lg:pinata-normal-case",

    LG_UPPERCASE = "lg:pinata-uppercase",

    MD_CAPITALIZE = "md:pinata-capitalize",

    MD_LOWERCASE = "md:pinata-lowercase",

    MD_NORMAL_CASE = "md:pinata-normal-case",

    MD_UPPERCASE = "md:pinata-uppercase",

    SM_CAPITALIZE = "sm:pinata-capitalize",

    SM_LOWERCASE = "sm:pinata-lowercase",

    SM_NORMAL_CASE = "sm:pinata-normal-case",

    SM_UPPERCASE = "sm:pinata-uppercase",

    XL_CAPITALIZE = "xl:pinata-capitalize",

    XL_LOWERCASE = "xl:pinata-lowercase",

    XL_NORMAL_CASE = "xl:pinata-normal-case",

    XL_UPPERCASE = "xl:pinata-uppercase",

    XS_CAPITALIZE = "xs:pinata-capitalize",

    XS_LOWERCASE = "xs:pinata-lowercase",

    XS_NORMAL_CASE = "xs:pinata-normal-case",

    XS_UPPERCASE = "xs:pinata-uppercase",

    CAPITALIZE = "pinata-capitalize",

    LOWERCASE = "pinata-lowercase",

    NORMAL_CASE = "pinata-normal-case",

    UPPERCASE = "pinata-uppercase",

}