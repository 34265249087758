import * as React from 'react';
import isArray from "lodash-es/isArray";
import {classNames} from "../../utils";
import {GridComposition, GridDirection, GridGutter, GridOffset, GridSpan} from "./GridEnums";
import {GridItem} from "./GridItem";
import {createComposition} from "./composition";

export * from "./GridEnums";

export * from "./GridItem";

export interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
    composition?: GridComposition;
    direction?: GridDirection;
    span?: GridSpan | GridSpan[] | undefined;
    gutter?: GridGutter | GridGutter[] | undefined;
    offset?: GridOffset | GridOffset[] | undefined;
    isNested?: boolean;
    emptyChildren?: boolean;
}

export const Grid: React.FunctionComponent<GridProps> = (props) => {

    const styleClass = {
        root: 'pinata-grid',
        section: 'pinata-grid__section',
        span: (span: GridSpan | GridSpan[] | undefined) => {
            if (isArray(span)) {
                return span.map(s => `pinata-grid--span-${s}`).concat('pinata-grid--span')
            }
            return `pinata-grid--span pinata-grid--span-${span}`
        },
        offset: (offset: GridOffset | GridOffset[] | undefined) => {
            if(isArray(offset)) {
                return offset.map(s => `pinata-grid--offset-${s}`).concat('pinata-grid--offset');
            }
            return `pinata-grid--offset pinata-grid--offset-${offset}`
        },
        gutter: (gutter: GridGutter | GridGutter[] | undefined) => {
            if(isArray(gutter)) {
                return gutter.map(s => `pinata-grid--gutter-${s}`).concat('pinata-grid--gutter');
            }
            return `pinata-grid--gutter pinata-grid--gutter-${gutter}`
        },
        composition: (composition: GridComposition) => `pinata-grid--composition-${composition}`,
        direction: (direction: GridDirection) => `pinata-grid--direction-${direction}`,
    };

    const {
        children,
        composition,
        emptyChildren = true,
        span,
        offset,
        gutter,
        isNested,
        direction,
        className,
        ...rest
    } = props;

    if(!children) {
        return null;
    }

    const items = () => {
        const cmp = createComposition(composition || GridComposition.DEFAULT, {
            size: React.Children.count(children) || 0,
            grid: Grid,
            gridItem: GridItem,
            emptyChildren,
        });

        return React.Children.map(children, (child, index) => {
            if (!child) {
                return null;
            }
            return cmp.layout(child, index);
        });
    };

    const classes = classNames(
        isNested ? styleClass.section : styleClass.root,
        {[styleClass.composition(composition || GridComposition.DEFAULT)]: composition},
        {[styleClass.direction(direction || GridDirection.DEFAULT)]: direction},

        gutter ? styleClass.gutter(gutter) : null,
        span ? styleClass.span(span) : null,
        offset ? styleClass.offset(offset) : null,
        className
    );

    return (
        <div
            {...rest}
            className={classes}
        >
            {items()}
        </div>
    );

};

Grid.displayName = 'Grid';
