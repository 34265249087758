///-- THIS FILE IS GENERATED --///
export enum Margin {

    LG_M_0 = "lg:pinata-m-0",

    LG_M_1 = "lg:pinata-m-1",

    LG_M_10 = "lg:pinata-m-10",

    LG_M_12 = "lg:pinata-m-12",

    LG_M_14 = "lg:pinata-m-14",

    LG_M_16 = "lg:pinata-m-16",

    LG_M_2 = "lg:pinata-m-2",

    LG_M_20 = "lg:pinata-m-20",

    LG_M_24 = "lg:pinata-m-24",

    LG_M_2XL = "lg:pinata-m-2xl",

    LG_M_3 = "lg:pinata-m-3",

    LG_M_32 = "lg:pinata-m-32",

    LG_M_3XL = "lg:pinata-m-3xl",

    LG_M_4 = "lg:pinata-m-4",

    LG_M_40 = "lg:pinata-m-40",

    LG_M_48 = "lg:pinata-m-48",

    LG_M_4XL = "lg:pinata-m-4xl",

    LG_M_5 = "lg:pinata-m-5",

    LG_M_56 = "lg:pinata-m-56",

    LG_M_5XL = "lg:pinata-m-5xl",

    LG_M_6 = "lg:pinata-m-6",

    LG_M_64 = "lg:pinata-m-64",

    LG_M_6XL = "lg:pinata-m-6xl",

    LG_M_8 = "lg:pinata-m-8",

    LG_M_AUTO = "lg:pinata-m-auto",

    LG_M_FULL = "lg:pinata-m-full",

    LG_M_LG = "lg:pinata-m-lg",

    LG_M_MD = "lg:pinata-m-md",

    LG_M_NEGATIVE_0 = "lg:pinata-m-negative_0",

    LG_M_NEGATIVE_1 = "lg:pinata-m-negative_1",

    LG_M_NEGATIVE_10 = "lg:pinata-m-negative_10",

    LG_M_NEGATIVE_12 = "lg:pinata-m-negative_12",

    LG_M_NEGATIVE_14 = "lg:pinata-m-negative_14",

    LG_M_NEGATIVE_16 = "lg:pinata-m-negative_16",

    LG_M_NEGATIVE_2 = "lg:pinata-m-negative_2",

    LG_M_NEGATIVE_20 = "lg:pinata-m-negative_20",

    LG_M_NEGATIVE_24 = "lg:pinata-m-negative_24",

    LG_M_NEGATIVE_2XL = "lg:pinata-m-negative_2xl",

    LG_M_NEGATIVE_3 = "lg:pinata-m-negative_3",

    LG_M_NEGATIVE_32 = "lg:pinata-m-negative_32",

    LG_M_NEGATIVE_3XL = "lg:pinata-m-negative_3xl",

    LG_M_NEGATIVE_4 = "lg:pinata-m-negative_4",

    LG_M_NEGATIVE_40 = "lg:pinata-m-negative_40",

    LG_M_NEGATIVE_48 = "lg:pinata-m-negative_48",

    LG_M_NEGATIVE_4XL = "lg:pinata-m-negative_4xl",

    LG_M_NEGATIVE_5 = "lg:pinata-m-negative_5",

    LG_M_NEGATIVE_56 = "lg:pinata-m-negative_56",

    LG_M_NEGATIVE_5XL = "lg:pinata-m-negative_5xl",

    LG_M_NEGATIVE_6 = "lg:pinata-m-negative_6",

    LG_M_NEGATIVE_64 = "lg:pinata-m-negative_64",

    LG_M_NEGATIVE_6XL = "lg:pinata-m-negative_6xl",

    LG_M_NEGATIVE_8 = "lg:pinata-m-negative_8",

    LG_M_NEGATIVE_FULL = "lg:pinata-m-negative_full",

    LG_M_NEGATIVE_LG = "lg:pinata-m-negative_lg",

    LG_M_NEGATIVE_MD = "lg:pinata-m-negative_md",

    LG_M_NEGATIVE_PX = "lg:pinata-m-negative_px",

    LG_M_NEGATIVE_SM = "lg:pinata-m-negative_sm",

    LG_M_NEGATIVE_XL = "lg:pinata-m-negative_xl",

    LG_M_NEGATIVE_XS = "lg:pinata-m-negative_xs",

    LG_M_PX = "lg:pinata-m-px",

    LG_M_SM = "lg:pinata-m-sm",

    LG_M_XL = "lg:pinata-m-xl",

    LG_M_XS = "lg:pinata-m-xs",

    LG_MB_0 = "lg:pinata-mb-0",

    LG_MB_1 = "lg:pinata-mb-1",

    LG_MB_10 = "lg:pinata-mb-10",

    LG_MB_12 = "lg:pinata-mb-12",

    LG_MB_14 = "lg:pinata-mb-14",

    LG_MB_16 = "lg:pinata-mb-16",

    LG_MB_2 = "lg:pinata-mb-2",

    LG_MB_20 = "lg:pinata-mb-20",

    LG_MB_24 = "lg:pinata-mb-24",

    LG_MB_2XL = "lg:pinata-mb-2xl",

    LG_MB_3 = "lg:pinata-mb-3",

    LG_MB_32 = "lg:pinata-mb-32",

    LG_MB_3XL = "lg:pinata-mb-3xl",

    LG_MB_4 = "lg:pinata-mb-4",

    LG_MB_40 = "lg:pinata-mb-40",

    LG_MB_48 = "lg:pinata-mb-48",

    LG_MB_4XL = "lg:pinata-mb-4xl",

    LG_MB_5 = "lg:pinata-mb-5",

    LG_MB_56 = "lg:pinata-mb-56",

    LG_MB_5XL = "lg:pinata-mb-5xl",

    LG_MB_6 = "lg:pinata-mb-6",

    LG_MB_64 = "lg:pinata-mb-64",

    LG_MB_6XL = "lg:pinata-mb-6xl",

    LG_MB_8 = "lg:pinata-mb-8",

    LG_MB_AUTO = "lg:pinata-mb-auto",

    LG_MB_FULL = "lg:pinata-mb-full",

    LG_MB_LG = "lg:pinata-mb-lg",

    LG_MB_MD = "lg:pinata-mb-md",

    LG_MB_NEGATIVE_0 = "lg:pinata-mb-negative_0",

    LG_MB_NEGATIVE_1 = "lg:pinata-mb-negative_1",

    LG_MB_NEGATIVE_10 = "lg:pinata-mb-negative_10",

    LG_MB_NEGATIVE_12 = "lg:pinata-mb-negative_12",

    LG_MB_NEGATIVE_14 = "lg:pinata-mb-negative_14",

    LG_MB_NEGATIVE_16 = "lg:pinata-mb-negative_16",

    LG_MB_NEGATIVE_2 = "lg:pinata-mb-negative_2",

    LG_MB_NEGATIVE_20 = "lg:pinata-mb-negative_20",

    LG_MB_NEGATIVE_24 = "lg:pinata-mb-negative_24",

    LG_MB_NEGATIVE_2XL = "lg:pinata-mb-negative_2xl",

    LG_MB_NEGATIVE_3 = "lg:pinata-mb-negative_3",

    LG_MB_NEGATIVE_32 = "lg:pinata-mb-negative_32",

    LG_MB_NEGATIVE_3XL = "lg:pinata-mb-negative_3xl",

    LG_MB_NEGATIVE_4 = "lg:pinata-mb-negative_4",

    LG_MB_NEGATIVE_40 = "lg:pinata-mb-negative_40",

    LG_MB_NEGATIVE_48 = "lg:pinata-mb-negative_48",

    LG_MB_NEGATIVE_4XL = "lg:pinata-mb-negative_4xl",

    LG_MB_NEGATIVE_5 = "lg:pinata-mb-negative_5",

    LG_MB_NEGATIVE_56 = "lg:pinata-mb-negative_56",

    LG_MB_NEGATIVE_5XL = "lg:pinata-mb-negative_5xl",

    LG_MB_NEGATIVE_6 = "lg:pinata-mb-negative_6",

    LG_MB_NEGATIVE_64 = "lg:pinata-mb-negative_64",

    LG_MB_NEGATIVE_6XL = "lg:pinata-mb-negative_6xl",

    LG_MB_NEGATIVE_8 = "lg:pinata-mb-negative_8",

    LG_MB_NEGATIVE_FULL = "lg:pinata-mb-negative_full",

    LG_MB_NEGATIVE_LG = "lg:pinata-mb-negative_lg",

    LG_MB_NEGATIVE_MD = "lg:pinata-mb-negative_md",

    LG_MB_NEGATIVE_PX = "lg:pinata-mb-negative_px",

    LG_MB_NEGATIVE_SM = "lg:pinata-mb-negative_sm",

    LG_MB_NEGATIVE_XL = "lg:pinata-mb-negative_xl",

    LG_MB_NEGATIVE_XS = "lg:pinata-mb-negative_xs",

    LG_MB_PX = "lg:pinata-mb-px",

    LG_MB_SM = "lg:pinata-mb-sm",

    LG_MB_XL = "lg:pinata-mb-xl",

    LG_MB_XS = "lg:pinata-mb-xs",

    LG_ML_0 = "lg:pinata-ml-0",

    LG_ML_1 = "lg:pinata-ml-1",

    LG_ML_10 = "lg:pinata-ml-10",

    LG_ML_12 = "lg:pinata-ml-12",

    LG_ML_14 = "lg:pinata-ml-14",

    LG_ML_16 = "lg:pinata-ml-16",

    LG_ML_2 = "lg:pinata-ml-2",

    LG_ML_20 = "lg:pinata-ml-20",

    LG_ML_24 = "lg:pinata-ml-24",

    LG_ML_2XL = "lg:pinata-ml-2xl",

    LG_ML_3 = "lg:pinata-ml-3",

    LG_ML_32 = "lg:pinata-ml-32",

    LG_ML_3XL = "lg:pinata-ml-3xl",

    LG_ML_4 = "lg:pinata-ml-4",

    LG_ML_40 = "lg:pinata-ml-40",

    LG_ML_48 = "lg:pinata-ml-48",

    LG_ML_4XL = "lg:pinata-ml-4xl",

    LG_ML_5 = "lg:pinata-ml-5",

    LG_ML_56 = "lg:pinata-ml-56",

    LG_ML_5XL = "lg:pinata-ml-5xl",

    LG_ML_6 = "lg:pinata-ml-6",

    LG_ML_64 = "lg:pinata-ml-64",

    LG_ML_6XL = "lg:pinata-ml-6xl",

    LG_ML_8 = "lg:pinata-ml-8",

    LG_ML_AUTO = "lg:pinata-ml-auto",

    LG_ML_FULL = "lg:pinata-ml-full",

    LG_ML_LG = "lg:pinata-ml-lg",

    LG_ML_MD = "lg:pinata-ml-md",

    LG_ML_NEGATIVE_0 = "lg:pinata-ml-negative_0",

    LG_ML_NEGATIVE_1 = "lg:pinata-ml-negative_1",

    LG_ML_NEGATIVE_10 = "lg:pinata-ml-negative_10",

    LG_ML_NEGATIVE_12 = "lg:pinata-ml-negative_12",

    LG_ML_NEGATIVE_14 = "lg:pinata-ml-negative_14",

    LG_ML_NEGATIVE_16 = "lg:pinata-ml-negative_16",

    LG_ML_NEGATIVE_2 = "lg:pinata-ml-negative_2",

    LG_ML_NEGATIVE_20 = "lg:pinata-ml-negative_20",

    LG_ML_NEGATIVE_24 = "lg:pinata-ml-negative_24",

    LG_ML_NEGATIVE_2XL = "lg:pinata-ml-negative_2xl",

    LG_ML_NEGATIVE_3 = "lg:pinata-ml-negative_3",

    LG_ML_NEGATIVE_32 = "lg:pinata-ml-negative_32",

    LG_ML_NEGATIVE_3XL = "lg:pinata-ml-negative_3xl",

    LG_ML_NEGATIVE_4 = "lg:pinata-ml-negative_4",

    LG_ML_NEGATIVE_40 = "lg:pinata-ml-negative_40",

    LG_ML_NEGATIVE_48 = "lg:pinata-ml-negative_48",

    LG_ML_NEGATIVE_4XL = "lg:pinata-ml-negative_4xl",

    LG_ML_NEGATIVE_5 = "lg:pinata-ml-negative_5",

    LG_ML_NEGATIVE_56 = "lg:pinata-ml-negative_56",

    LG_ML_NEGATIVE_5XL = "lg:pinata-ml-negative_5xl",

    LG_ML_NEGATIVE_6 = "lg:pinata-ml-negative_6",

    LG_ML_NEGATIVE_64 = "lg:pinata-ml-negative_64",

    LG_ML_NEGATIVE_6XL = "lg:pinata-ml-negative_6xl",

    LG_ML_NEGATIVE_8 = "lg:pinata-ml-negative_8",

    LG_ML_NEGATIVE_FULL = "lg:pinata-ml-negative_full",

    LG_ML_NEGATIVE_LG = "lg:pinata-ml-negative_lg",

    LG_ML_NEGATIVE_MD = "lg:pinata-ml-negative_md",

    LG_ML_NEGATIVE_PX = "lg:pinata-ml-negative_px",

    LG_ML_NEGATIVE_SM = "lg:pinata-ml-negative_sm",

    LG_ML_NEGATIVE_XL = "lg:pinata-ml-negative_xl",

    LG_ML_NEGATIVE_XS = "lg:pinata-ml-negative_xs",

    LG_ML_PX = "lg:pinata-ml-px",

    LG_ML_SM = "lg:pinata-ml-sm",

    LG_ML_XL = "lg:pinata-ml-xl",

    LG_ML_XS = "lg:pinata-ml-xs",

    LG_MR_0 = "lg:pinata-mr-0",

    LG_MR_1 = "lg:pinata-mr-1",

    LG_MR_10 = "lg:pinata-mr-10",

    LG_MR_12 = "lg:pinata-mr-12",

    LG_MR_14 = "lg:pinata-mr-14",

    LG_MR_16 = "lg:pinata-mr-16",

    LG_MR_2 = "lg:pinata-mr-2",

    LG_MR_20 = "lg:pinata-mr-20",

    LG_MR_24 = "lg:pinata-mr-24",

    LG_MR_2XL = "lg:pinata-mr-2xl",

    LG_MR_3 = "lg:pinata-mr-3",

    LG_MR_32 = "lg:pinata-mr-32",

    LG_MR_3XL = "lg:pinata-mr-3xl",

    LG_MR_4 = "lg:pinata-mr-4",

    LG_MR_40 = "lg:pinata-mr-40",

    LG_MR_48 = "lg:pinata-mr-48",

    LG_MR_4XL = "lg:pinata-mr-4xl",

    LG_MR_5 = "lg:pinata-mr-5",

    LG_MR_56 = "lg:pinata-mr-56",

    LG_MR_5XL = "lg:pinata-mr-5xl",

    LG_MR_6 = "lg:pinata-mr-6",

    LG_MR_64 = "lg:pinata-mr-64",

    LG_MR_6XL = "lg:pinata-mr-6xl",

    LG_MR_8 = "lg:pinata-mr-8",

    LG_MR_AUTO = "lg:pinata-mr-auto",

    LG_MR_FULL = "lg:pinata-mr-full",

    LG_MR_LG = "lg:pinata-mr-lg",

    LG_MR_MD = "lg:pinata-mr-md",

    LG_MR_NEGATIVE_0 = "lg:pinata-mr-negative_0",

    LG_MR_NEGATIVE_1 = "lg:pinata-mr-negative_1",

    LG_MR_NEGATIVE_10 = "lg:pinata-mr-negative_10",

    LG_MR_NEGATIVE_12 = "lg:pinata-mr-negative_12",

    LG_MR_NEGATIVE_14 = "lg:pinata-mr-negative_14",

    LG_MR_NEGATIVE_16 = "lg:pinata-mr-negative_16",

    LG_MR_NEGATIVE_2 = "lg:pinata-mr-negative_2",

    LG_MR_NEGATIVE_20 = "lg:pinata-mr-negative_20",

    LG_MR_NEGATIVE_24 = "lg:pinata-mr-negative_24",

    LG_MR_NEGATIVE_2XL = "lg:pinata-mr-negative_2xl",

    LG_MR_NEGATIVE_3 = "lg:pinata-mr-negative_3",

    LG_MR_NEGATIVE_32 = "lg:pinata-mr-negative_32",

    LG_MR_NEGATIVE_3XL = "lg:pinata-mr-negative_3xl",

    LG_MR_NEGATIVE_4 = "lg:pinata-mr-negative_4",

    LG_MR_NEGATIVE_40 = "lg:pinata-mr-negative_40",

    LG_MR_NEGATIVE_48 = "lg:pinata-mr-negative_48",

    LG_MR_NEGATIVE_4XL = "lg:pinata-mr-negative_4xl",

    LG_MR_NEGATIVE_5 = "lg:pinata-mr-negative_5",

    LG_MR_NEGATIVE_56 = "lg:pinata-mr-negative_56",

    LG_MR_NEGATIVE_5XL = "lg:pinata-mr-negative_5xl",

    LG_MR_NEGATIVE_6 = "lg:pinata-mr-negative_6",

    LG_MR_NEGATIVE_64 = "lg:pinata-mr-negative_64",

    LG_MR_NEGATIVE_6XL = "lg:pinata-mr-negative_6xl",

    LG_MR_NEGATIVE_8 = "lg:pinata-mr-negative_8",

    LG_MR_NEGATIVE_FULL = "lg:pinata-mr-negative_full",

    LG_MR_NEGATIVE_LG = "lg:pinata-mr-negative_lg",

    LG_MR_NEGATIVE_MD = "lg:pinata-mr-negative_md",

    LG_MR_NEGATIVE_PX = "lg:pinata-mr-negative_px",

    LG_MR_NEGATIVE_SM = "lg:pinata-mr-negative_sm",

    LG_MR_NEGATIVE_XL = "lg:pinata-mr-negative_xl",

    LG_MR_NEGATIVE_XS = "lg:pinata-mr-negative_xs",

    LG_MR_PX = "lg:pinata-mr-px",

    LG_MR_SM = "lg:pinata-mr-sm",

    LG_MR_XL = "lg:pinata-mr-xl",

    LG_MR_XS = "lg:pinata-mr-xs",

    LG_MT_0 = "lg:pinata-mt-0",

    LG_MT_1 = "lg:pinata-mt-1",

    LG_MT_10 = "lg:pinata-mt-10",

    LG_MT_12 = "lg:pinata-mt-12",

    LG_MT_14 = "lg:pinata-mt-14",

    LG_MT_16 = "lg:pinata-mt-16",

    LG_MT_2 = "lg:pinata-mt-2",

    LG_MT_20 = "lg:pinata-mt-20",

    LG_MT_24 = "lg:pinata-mt-24",

    LG_MT_2XL = "lg:pinata-mt-2xl",

    LG_MT_3 = "lg:pinata-mt-3",

    LG_MT_32 = "lg:pinata-mt-32",

    LG_MT_3XL = "lg:pinata-mt-3xl",

    LG_MT_4 = "lg:pinata-mt-4",

    LG_MT_40 = "lg:pinata-mt-40",

    LG_MT_48 = "lg:pinata-mt-48",

    LG_MT_4XL = "lg:pinata-mt-4xl",

    LG_MT_5 = "lg:pinata-mt-5",

    LG_MT_56 = "lg:pinata-mt-56",

    LG_MT_5XL = "lg:pinata-mt-5xl",

    LG_MT_6 = "lg:pinata-mt-6",

    LG_MT_64 = "lg:pinata-mt-64",

    LG_MT_6XL = "lg:pinata-mt-6xl",

    LG_MT_8 = "lg:pinata-mt-8",

    LG_MT_AUTO = "lg:pinata-mt-auto",

    LG_MT_FULL = "lg:pinata-mt-full",

    LG_MT_LG = "lg:pinata-mt-lg",

    LG_MT_MD = "lg:pinata-mt-md",

    LG_MT_NEGATIVE_0 = "lg:pinata-mt-negative_0",

    LG_MT_NEGATIVE_1 = "lg:pinata-mt-negative_1",

    LG_MT_NEGATIVE_10 = "lg:pinata-mt-negative_10",

    LG_MT_NEGATIVE_12 = "lg:pinata-mt-negative_12",

    LG_MT_NEGATIVE_14 = "lg:pinata-mt-negative_14",

    LG_MT_NEGATIVE_16 = "lg:pinata-mt-negative_16",

    LG_MT_NEGATIVE_2 = "lg:pinata-mt-negative_2",

    LG_MT_NEGATIVE_20 = "lg:pinata-mt-negative_20",

    LG_MT_NEGATIVE_24 = "lg:pinata-mt-negative_24",

    LG_MT_NEGATIVE_2XL = "lg:pinata-mt-negative_2xl",

    LG_MT_NEGATIVE_3 = "lg:pinata-mt-negative_3",

    LG_MT_NEGATIVE_32 = "lg:pinata-mt-negative_32",

    LG_MT_NEGATIVE_3XL = "lg:pinata-mt-negative_3xl",

    LG_MT_NEGATIVE_4 = "lg:pinata-mt-negative_4",

    LG_MT_NEGATIVE_40 = "lg:pinata-mt-negative_40",

    LG_MT_NEGATIVE_48 = "lg:pinata-mt-negative_48",

    LG_MT_NEGATIVE_4XL = "lg:pinata-mt-negative_4xl",

    LG_MT_NEGATIVE_5 = "lg:pinata-mt-negative_5",

    LG_MT_NEGATIVE_56 = "lg:pinata-mt-negative_56",

    LG_MT_NEGATIVE_5XL = "lg:pinata-mt-negative_5xl",

    LG_MT_NEGATIVE_6 = "lg:pinata-mt-negative_6",

    LG_MT_NEGATIVE_64 = "lg:pinata-mt-negative_64",

    LG_MT_NEGATIVE_6XL = "lg:pinata-mt-negative_6xl",

    LG_MT_NEGATIVE_8 = "lg:pinata-mt-negative_8",

    LG_MT_NEGATIVE_FULL = "lg:pinata-mt-negative_full",

    LG_MT_NEGATIVE_LG = "lg:pinata-mt-negative_lg",

    LG_MT_NEGATIVE_MD = "lg:pinata-mt-negative_md",

    LG_MT_NEGATIVE_PX = "lg:pinata-mt-negative_px",

    LG_MT_NEGATIVE_SM = "lg:pinata-mt-negative_sm",

    LG_MT_NEGATIVE_XL = "lg:pinata-mt-negative_xl",

    LG_MT_NEGATIVE_XS = "lg:pinata-mt-negative_xs",

    LG_MT_PX = "lg:pinata-mt-px",

    LG_MT_SM = "lg:pinata-mt-sm",

    LG_MT_XL = "lg:pinata-mt-xl",

    LG_MT_XS = "lg:pinata-mt-xs",

    LG_MX_0 = "lg:pinata-mx-0",

    LG_MX_1 = "lg:pinata-mx-1",

    LG_MX_10 = "lg:pinata-mx-10",

    LG_MX_12 = "lg:pinata-mx-12",

    LG_MX_14 = "lg:pinata-mx-14",

    LG_MX_16 = "lg:pinata-mx-16",

    LG_MX_2 = "lg:pinata-mx-2",

    LG_MX_20 = "lg:pinata-mx-20",

    LG_MX_24 = "lg:pinata-mx-24",

    LG_MX_2XL = "lg:pinata-mx-2xl",

    LG_MX_3 = "lg:pinata-mx-3",

    LG_MX_32 = "lg:pinata-mx-32",

    LG_MX_3XL = "lg:pinata-mx-3xl",

    LG_MX_4 = "lg:pinata-mx-4",

    LG_MX_40 = "lg:pinata-mx-40",

    LG_MX_48 = "lg:pinata-mx-48",

    LG_MX_4XL = "lg:pinata-mx-4xl",

    LG_MX_5 = "lg:pinata-mx-5",

    LG_MX_56 = "lg:pinata-mx-56",

    LG_MX_5XL = "lg:pinata-mx-5xl",

    LG_MX_6 = "lg:pinata-mx-6",

    LG_MX_64 = "lg:pinata-mx-64",

    LG_MX_6XL = "lg:pinata-mx-6xl",

    LG_MX_8 = "lg:pinata-mx-8",

    LG_MX_AUTO = "lg:pinata-mx-auto",

    LG_MX_FULL = "lg:pinata-mx-full",

    LG_MX_LG = "lg:pinata-mx-lg",

    LG_MX_MD = "lg:pinata-mx-md",

    LG_MX_NEGATIVE_0 = "lg:pinata-mx-negative_0",

    LG_MX_NEGATIVE_1 = "lg:pinata-mx-negative_1",

    LG_MX_NEGATIVE_10 = "lg:pinata-mx-negative_10",

    LG_MX_NEGATIVE_12 = "lg:pinata-mx-negative_12",

    LG_MX_NEGATIVE_14 = "lg:pinata-mx-negative_14",

    LG_MX_NEGATIVE_16 = "lg:pinata-mx-negative_16",

    LG_MX_NEGATIVE_2 = "lg:pinata-mx-negative_2",

    LG_MX_NEGATIVE_20 = "lg:pinata-mx-negative_20",

    LG_MX_NEGATIVE_24 = "lg:pinata-mx-negative_24",

    LG_MX_NEGATIVE_2XL = "lg:pinata-mx-negative_2xl",

    LG_MX_NEGATIVE_3 = "lg:pinata-mx-negative_3",

    LG_MX_NEGATIVE_32 = "lg:pinata-mx-negative_32",

    LG_MX_NEGATIVE_3XL = "lg:pinata-mx-negative_3xl",

    LG_MX_NEGATIVE_4 = "lg:pinata-mx-negative_4",

    LG_MX_NEGATIVE_40 = "lg:pinata-mx-negative_40",

    LG_MX_NEGATIVE_48 = "lg:pinata-mx-negative_48",

    LG_MX_NEGATIVE_4XL = "lg:pinata-mx-negative_4xl",

    LG_MX_NEGATIVE_5 = "lg:pinata-mx-negative_5",

    LG_MX_NEGATIVE_56 = "lg:pinata-mx-negative_56",

    LG_MX_NEGATIVE_5XL = "lg:pinata-mx-negative_5xl",

    LG_MX_NEGATIVE_6 = "lg:pinata-mx-negative_6",

    LG_MX_NEGATIVE_64 = "lg:pinata-mx-negative_64",

    LG_MX_NEGATIVE_6XL = "lg:pinata-mx-negative_6xl",

    LG_MX_NEGATIVE_8 = "lg:pinata-mx-negative_8",

    LG_MX_NEGATIVE_FULL = "lg:pinata-mx-negative_full",

    LG_MX_NEGATIVE_LG = "lg:pinata-mx-negative_lg",

    LG_MX_NEGATIVE_MD = "lg:pinata-mx-negative_md",

    LG_MX_NEGATIVE_PX = "lg:pinata-mx-negative_px",

    LG_MX_NEGATIVE_SM = "lg:pinata-mx-negative_sm",

    LG_MX_NEGATIVE_XL = "lg:pinata-mx-negative_xl",

    LG_MX_NEGATIVE_XS = "lg:pinata-mx-negative_xs",

    LG_MX_PX = "lg:pinata-mx-px",

    LG_MX_SM = "lg:pinata-mx-sm",

    LG_MX_XL = "lg:pinata-mx-xl",

    LG_MX_XS = "lg:pinata-mx-xs",

    LG_MY_0 = "lg:pinata-my-0",

    LG_MY_1 = "lg:pinata-my-1",

    LG_MY_10 = "lg:pinata-my-10",

    LG_MY_12 = "lg:pinata-my-12",

    LG_MY_14 = "lg:pinata-my-14",

    LG_MY_16 = "lg:pinata-my-16",

    LG_MY_2 = "lg:pinata-my-2",

    LG_MY_20 = "lg:pinata-my-20",

    LG_MY_24 = "lg:pinata-my-24",

    LG_MY_2XL = "lg:pinata-my-2xl",

    LG_MY_3 = "lg:pinata-my-3",

    LG_MY_32 = "lg:pinata-my-32",

    LG_MY_3XL = "lg:pinata-my-3xl",

    LG_MY_4 = "lg:pinata-my-4",

    LG_MY_40 = "lg:pinata-my-40",

    LG_MY_48 = "lg:pinata-my-48",

    LG_MY_4XL = "lg:pinata-my-4xl",

    LG_MY_5 = "lg:pinata-my-5",

    LG_MY_56 = "lg:pinata-my-56",

    LG_MY_5XL = "lg:pinata-my-5xl",

    LG_MY_6 = "lg:pinata-my-6",

    LG_MY_64 = "lg:pinata-my-64",

    LG_MY_6XL = "lg:pinata-my-6xl",

    LG_MY_8 = "lg:pinata-my-8",

    LG_MY_AUTO = "lg:pinata-my-auto",

    LG_MY_FULL = "lg:pinata-my-full",

    LG_MY_LG = "lg:pinata-my-lg",

    LG_MY_MD = "lg:pinata-my-md",

    LG_MY_NEGATIVE_0 = "lg:pinata-my-negative_0",

    LG_MY_NEGATIVE_1 = "lg:pinata-my-negative_1",

    LG_MY_NEGATIVE_10 = "lg:pinata-my-negative_10",

    LG_MY_NEGATIVE_12 = "lg:pinata-my-negative_12",

    LG_MY_NEGATIVE_14 = "lg:pinata-my-negative_14",

    LG_MY_NEGATIVE_16 = "lg:pinata-my-negative_16",

    LG_MY_NEGATIVE_2 = "lg:pinata-my-negative_2",

    LG_MY_NEGATIVE_20 = "lg:pinata-my-negative_20",

    LG_MY_NEGATIVE_24 = "lg:pinata-my-negative_24",

    LG_MY_NEGATIVE_2XL = "lg:pinata-my-negative_2xl",

    LG_MY_NEGATIVE_3 = "lg:pinata-my-negative_3",

    LG_MY_NEGATIVE_32 = "lg:pinata-my-negative_32",

    LG_MY_NEGATIVE_3XL = "lg:pinata-my-negative_3xl",

    LG_MY_NEGATIVE_4 = "lg:pinata-my-negative_4",

    LG_MY_NEGATIVE_40 = "lg:pinata-my-negative_40",

    LG_MY_NEGATIVE_48 = "lg:pinata-my-negative_48",

    LG_MY_NEGATIVE_4XL = "lg:pinata-my-negative_4xl",

    LG_MY_NEGATIVE_5 = "lg:pinata-my-negative_5",

    LG_MY_NEGATIVE_56 = "lg:pinata-my-negative_56",

    LG_MY_NEGATIVE_5XL = "lg:pinata-my-negative_5xl",

    LG_MY_NEGATIVE_6 = "lg:pinata-my-negative_6",

    LG_MY_NEGATIVE_64 = "lg:pinata-my-negative_64",

    LG_MY_NEGATIVE_6XL = "lg:pinata-my-negative_6xl",

    LG_MY_NEGATIVE_8 = "lg:pinata-my-negative_8",

    LG_MY_NEGATIVE_FULL = "lg:pinata-my-negative_full",

    LG_MY_NEGATIVE_LG = "lg:pinata-my-negative_lg",

    LG_MY_NEGATIVE_MD = "lg:pinata-my-negative_md",

    LG_MY_NEGATIVE_PX = "lg:pinata-my-negative_px",

    LG_MY_NEGATIVE_SM = "lg:pinata-my-negative_sm",

    LG_MY_NEGATIVE_XL = "lg:pinata-my-negative_xl",

    LG_MY_NEGATIVE_XS = "lg:pinata-my-negative_xs",

    LG_MY_PX = "lg:pinata-my-px",

    LG_MY_SM = "lg:pinata-my-sm",

    LG_MY_XL = "lg:pinata-my-xl",

    LG_MY_XS = "lg:pinata-my-xs",

    MD_M_0 = "md:pinata-m-0",

    MD_M_1 = "md:pinata-m-1",

    MD_M_10 = "md:pinata-m-10",

    MD_M_12 = "md:pinata-m-12",

    MD_M_14 = "md:pinata-m-14",

    MD_M_16 = "md:pinata-m-16",

    MD_M_2 = "md:pinata-m-2",

    MD_M_20 = "md:pinata-m-20",

    MD_M_24 = "md:pinata-m-24",

    MD_M_2XL = "md:pinata-m-2xl",

    MD_M_3 = "md:pinata-m-3",

    MD_M_32 = "md:pinata-m-32",

    MD_M_3XL = "md:pinata-m-3xl",

    MD_M_4 = "md:pinata-m-4",

    MD_M_40 = "md:pinata-m-40",

    MD_M_48 = "md:pinata-m-48",

    MD_M_4XL = "md:pinata-m-4xl",

    MD_M_5 = "md:pinata-m-5",

    MD_M_56 = "md:pinata-m-56",

    MD_M_5XL = "md:pinata-m-5xl",

    MD_M_6 = "md:pinata-m-6",

    MD_M_64 = "md:pinata-m-64",

    MD_M_6XL = "md:pinata-m-6xl",

    MD_M_8 = "md:pinata-m-8",

    MD_M_AUTO = "md:pinata-m-auto",

    MD_M_FULL = "md:pinata-m-full",

    MD_M_LG = "md:pinata-m-lg",

    MD_M_MD = "md:pinata-m-md",

    MD_M_NEGATIVE_0 = "md:pinata-m-negative_0",

    MD_M_NEGATIVE_1 = "md:pinata-m-negative_1",

    MD_M_NEGATIVE_10 = "md:pinata-m-negative_10",

    MD_M_NEGATIVE_12 = "md:pinata-m-negative_12",

    MD_M_NEGATIVE_14 = "md:pinata-m-negative_14",

    MD_M_NEGATIVE_16 = "md:pinata-m-negative_16",

    MD_M_NEGATIVE_2 = "md:pinata-m-negative_2",

    MD_M_NEGATIVE_20 = "md:pinata-m-negative_20",

    MD_M_NEGATIVE_24 = "md:pinata-m-negative_24",

    MD_M_NEGATIVE_2XL = "md:pinata-m-negative_2xl",

    MD_M_NEGATIVE_3 = "md:pinata-m-negative_3",

    MD_M_NEGATIVE_32 = "md:pinata-m-negative_32",

    MD_M_NEGATIVE_3XL = "md:pinata-m-negative_3xl",

    MD_M_NEGATIVE_4 = "md:pinata-m-negative_4",

    MD_M_NEGATIVE_40 = "md:pinata-m-negative_40",

    MD_M_NEGATIVE_48 = "md:pinata-m-negative_48",

    MD_M_NEGATIVE_4XL = "md:pinata-m-negative_4xl",

    MD_M_NEGATIVE_5 = "md:pinata-m-negative_5",

    MD_M_NEGATIVE_56 = "md:pinata-m-negative_56",

    MD_M_NEGATIVE_5XL = "md:pinata-m-negative_5xl",

    MD_M_NEGATIVE_6 = "md:pinata-m-negative_6",

    MD_M_NEGATIVE_64 = "md:pinata-m-negative_64",

    MD_M_NEGATIVE_6XL = "md:pinata-m-negative_6xl",

    MD_M_NEGATIVE_8 = "md:pinata-m-negative_8",

    MD_M_NEGATIVE_FULL = "md:pinata-m-negative_full",

    MD_M_NEGATIVE_LG = "md:pinata-m-negative_lg",

    MD_M_NEGATIVE_MD = "md:pinata-m-negative_md",

    MD_M_NEGATIVE_PX = "md:pinata-m-negative_px",

    MD_M_NEGATIVE_SM = "md:pinata-m-negative_sm",

    MD_M_NEGATIVE_XL = "md:pinata-m-negative_xl",

    MD_M_NEGATIVE_XS = "md:pinata-m-negative_xs",

    MD_M_PX = "md:pinata-m-px",

    MD_M_SM = "md:pinata-m-sm",

    MD_M_XL = "md:pinata-m-xl",

    MD_M_XS = "md:pinata-m-xs",

    MD_MB_0 = "md:pinata-mb-0",

    MD_MB_1 = "md:pinata-mb-1",

    MD_MB_10 = "md:pinata-mb-10",

    MD_MB_12 = "md:pinata-mb-12",

    MD_MB_14 = "md:pinata-mb-14",

    MD_MB_16 = "md:pinata-mb-16",

    MD_MB_2 = "md:pinata-mb-2",

    MD_MB_20 = "md:pinata-mb-20",

    MD_MB_24 = "md:pinata-mb-24",

    MD_MB_2XL = "md:pinata-mb-2xl",

    MD_MB_3 = "md:pinata-mb-3",

    MD_MB_32 = "md:pinata-mb-32",

    MD_MB_3XL = "md:pinata-mb-3xl",

    MD_MB_4 = "md:pinata-mb-4",

    MD_MB_40 = "md:pinata-mb-40",

    MD_MB_48 = "md:pinata-mb-48",

    MD_MB_4XL = "md:pinata-mb-4xl",

    MD_MB_5 = "md:pinata-mb-5",

    MD_MB_56 = "md:pinata-mb-56",

    MD_MB_5XL = "md:pinata-mb-5xl",

    MD_MB_6 = "md:pinata-mb-6",

    MD_MB_64 = "md:pinata-mb-64",

    MD_MB_6XL = "md:pinata-mb-6xl",

    MD_MB_8 = "md:pinata-mb-8",

    MD_MB_AUTO = "md:pinata-mb-auto",

    MD_MB_FULL = "md:pinata-mb-full",

    MD_MB_LG = "md:pinata-mb-lg",

    MD_MB_MD = "md:pinata-mb-md",

    MD_MB_NEGATIVE_0 = "md:pinata-mb-negative_0",

    MD_MB_NEGATIVE_1 = "md:pinata-mb-negative_1",

    MD_MB_NEGATIVE_10 = "md:pinata-mb-negative_10",

    MD_MB_NEGATIVE_12 = "md:pinata-mb-negative_12",

    MD_MB_NEGATIVE_14 = "md:pinata-mb-negative_14",

    MD_MB_NEGATIVE_16 = "md:pinata-mb-negative_16",

    MD_MB_NEGATIVE_2 = "md:pinata-mb-negative_2",

    MD_MB_NEGATIVE_20 = "md:pinata-mb-negative_20",

    MD_MB_NEGATIVE_24 = "md:pinata-mb-negative_24",

    MD_MB_NEGATIVE_2XL = "md:pinata-mb-negative_2xl",

    MD_MB_NEGATIVE_3 = "md:pinata-mb-negative_3",

    MD_MB_NEGATIVE_32 = "md:pinata-mb-negative_32",

    MD_MB_NEGATIVE_3XL = "md:pinata-mb-negative_3xl",

    MD_MB_NEGATIVE_4 = "md:pinata-mb-negative_4",

    MD_MB_NEGATIVE_40 = "md:pinata-mb-negative_40",

    MD_MB_NEGATIVE_48 = "md:pinata-mb-negative_48",

    MD_MB_NEGATIVE_4XL = "md:pinata-mb-negative_4xl",

    MD_MB_NEGATIVE_5 = "md:pinata-mb-negative_5",

    MD_MB_NEGATIVE_56 = "md:pinata-mb-negative_56",

    MD_MB_NEGATIVE_5XL = "md:pinata-mb-negative_5xl",

    MD_MB_NEGATIVE_6 = "md:pinata-mb-negative_6",

    MD_MB_NEGATIVE_64 = "md:pinata-mb-negative_64",

    MD_MB_NEGATIVE_6XL = "md:pinata-mb-negative_6xl",

    MD_MB_NEGATIVE_8 = "md:pinata-mb-negative_8",

    MD_MB_NEGATIVE_FULL = "md:pinata-mb-negative_full",

    MD_MB_NEGATIVE_LG = "md:pinata-mb-negative_lg",

    MD_MB_NEGATIVE_MD = "md:pinata-mb-negative_md",

    MD_MB_NEGATIVE_PX = "md:pinata-mb-negative_px",

    MD_MB_NEGATIVE_SM = "md:pinata-mb-negative_sm",

    MD_MB_NEGATIVE_XL = "md:pinata-mb-negative_xl",

    MD_MB_NEGATIVE_XS = "md:pinata-mb-negative_xs",

    MD_MB_PX = "md:pinata-mb-px",

    MD_MB_SM = "md:pinata-mb-sm",

    MD_MB_XL = "md:pinata-mb-xl",

    MD_MB_XS = "md:pinata-mb-xs",

    MD_ML_0 = "md:pinata-ml-0",

    MD_ML_1 = "md:pinata-ml-1",

    MD_ML_10 = "md:pinata-ml-10",

    MD_ML_12 = "md:pinata-ml-12",

    MD_ML_14 = "md:pinata-ml-14",

    MD_ML_16 = "md:pinata-ml-16",

    MD_ML_2 = "md:pinata-ml-2",

    MD_ML_20 = "md:pinata-ml-20",

    MD_ML_24 = "md:pinata-ml-24",

    MD_ML_2XL = "md:pinata-ml-2xl",

    MD_ML_3 = "md:pinata-ml-3",

    MD_ML_32 = "md:pinata-ml-32",

    MD_ML_3XL = "md:pinata-ml-3xl",

    MD_ML_4 = "md:pinata-ml-4",

    MD_ML_40 = "md:pinata-ml-40",

    MD_ML_48 = "md:pinata-ml-48",

    MD_ML_4XL = "md:pinata-ml-4xl",

    MD_ML_5 = "md:pinata-ml-5",

    MD_ML_56 = "md:pinata-ml-56",

    MD_ML_5XL = "md:pinata-ml-5xl",

    MD_ML_6 = "md:pinata-ml-6",

    MD_ML_64 = "md:pinata-ml-64",

    MD_ML_6XL = "md:pinata-ml-6xl",

    MD_ML_8 = "md:pinata-ml-8",

    MD_ML_AUTO = "md:pinata-ml-auto",

    MD_ML_FULL = "md:pinata-ml-full",

    MD_ML_LG = "md:pinata-ml-lg",

    MD_ML_MD = "md:pinata-ml-md",

    MD_ML_NEGATIVE_0 = "md:pinata-ml-negative_0",

    MD_ML_NEGATIVE_1 = "md:pinata-ml-negative_1",

    MD_ML_NEGATIVE_10 = "md:pinata-ml-negative_10",

    MD_ML_NEGATIVE_12 = "md:pinata-ml-negative_12",

    MD_ML_NEGATIVE_14 = "md:pinata-ml-negative_14",

    MD_ML_NEGATIVE_16 = "md:pinata-ml-negative_16",

    MD_ML_NEGATIVE_2 = "md:pinata-ml-negative_2",

    MD_ML_NEGATIVE_20 = "md:pinata-ml-negative_20",

    MD_ML_NEGATIVE_24 = "md:pinata-ml-negative_24",

    MD_ML_NEGATIVE_2XL = "md:pinata-ml-negative_2xl",

    MD_ML_NEGATIVE_3 = "md:pinata-ml-negative_3",

    MD_ML_NEGATIVE_32 = "md:pinata-ml-negative_32",

    MD_ML_NEGATIVE_3XL = "md:pinata-ml-negative_3xl",

    MD_ML_NEGATIVE_4 = "md:pinata-ml-negative_4",

    MD_ML_NEGATIVE_40 = "md:pinata-ml-negative_40",

    MD_ML_NEGATIVE_48 = "md:pinata-ml-negative_48",

    MD_ML_NEGATIVE_4XL = "md:pinata-ml-negative_4xl",

    MD_ML_NEGATIVE_5 = "md:pinata-ml-negative_5",

    MD_ML_NEGATIVE_56 = "md:pinata-ml-negative_56",

    MD_ML_NEGATIVE_5XL = "md:pinata-ml-negative_5xl",

    MD_ML_NEGATIVE_6 = "md:pinata-ml-negative_6",

    MD_ML_NEGATIVE_64 = "md:pinata-ml-negative_64",

    MD_ML_NEGATIVE_6XL = "md:pinata-ml-negative_6xl",

    MD_ML_NEGATIVE_8 = "md:pinata-ml-negative_8",

    MD_ML_NEGATIVE_FULL = "md:pinata-ml-negative_full",

    MD_ML_NEGATIVE_LG = "md:pinata-ml-negative_lg",

    MD_ML_NEGATIVE_MD = "md:pinata-ml-negative_md",

    MD_ML_NEGATIVE_PX = "md:pinata-ml-negative_px",

    MD_ML_NEGATIVE_SM = "md:pinata-ml-negative_sm",

    MD_ML_NEGATIVE_XL = "md:pinata-ml-negative_xl",

    MD_ML_NEGATIVE_XS = "md:pinata-ml-negative_xs",

    MD_ML_PX = "md:pinata-ml-px",

    MD_ML_SM = "md:pinata-ml-sm",

    MD_ML_XL = "md:pinata-ml-xl",

    MD_ML_XS = "md:pinata-ml-xs",

    MD_MR_0 = "md:pinata-mr-0",

    MD_MR_1 = "md:pinata-mr-1",

    MD_MR_10 = "md:pinata-mr-10",

    MD_MR_12 = "md:pinata-mr-12",

    MD_MR_14 = "md:pinata-mr-14",

    MD_MR_16 = "md:pinata-mr-16",

    MD_MR_2 = "md:pinata-mr-2",

    MD_MR_20 = "md:pinata-mr-20",

    MD_MR_24 = "md:pinata-mr-24",

    MD_MR_2XL = "md:pinata-mr-2xl",

    MD_MR_3 = "md:pinata-mr-3",

    MD_MR_32 = "md:pinata-mr-32",

    MD_MR_3XL = "md:pinata-mr-3xl",

    MD_MR_4 = "md:pinata-mr-4",

    MD_MR_40 = "md:pinata-mr-40",

    MD_MR_48 = "md:pinata-mr-48",

    MD_MR_4XL = "md:pinata-mr-4xl",

    MD_MR_5 = "md:pinata-mr-5",

    MD_MR_56 = "md:pinata-mr-56",

    MD_MR_5XL = "md:pinata-mr-5xl",

    MD_MR_6 = "md:pinata-mr-6",

    MD_MR_64 = "md:pinata-mr-64",

    MD_MR_6XL = "md:pinata-mr-6xl",

    MD_MR_8 = "md:pinata-mr-8",

    MD_MR_AUTO = "md:pinata-mr-auto",

    MD_MR_FULL = "md:pinata-mr-full",

    MD_MR_LG = "md:pinata-mr-lg",

    MD_MR_MD = "md:pinata-mr-md",

    MD_MR_NEGATIVE_0 = "md:pinata-mr-negative_0",

    MD_MR_NEGATIVE_1 = "md:pinata-mr-negative_1",

    MD_MR_NEGATIVE_10 = "md:pinata-mr-negative_10",

    MD_MR_NEGATIVE_12 = "md:pinata-mr-negative_12",

    MD_MR_NEGATIVE_14 = "md:pinata-mr-negative_14",

    MD_MR_NEGATIVE_16 = "md:pinata-mr-negative_16",

    MD_MR_NEGATIVE_2 = "md:pinata-mr-negative_2",

    MD_MR_NEGATIVE_20 = "md:pinata-mr-negative_20",

    MD_MR_NEGATIVE_24 = "md:pinata-mr-negative_24",

    MD_MR_NEGATIVE_2XL = "md:pinata-mr-negative_2xl",

    MD_MR_NEGATIVE_3 = "md:pinata-mr-negative_3",

    MD_MR_NEGATIVE_32 = "md:pinata-mr-negative_32",

    MD_MR_NEGATIVE_3XL = "md:pinata-mr-negative_3xl",

    MD_MR_NEGATIVE_4 = "md:pinata-mr-negative_4",

    MD_MR_NEGATIVE_40 = "md:pinata-mr-negative_40",

    MD_MR_NEGATIVE_48 = "md:pinata-mr-negative_48",

    MD_MR_NEGATIVE_4XL = "md:pinata-mr-negative_4xl",

    MD_MR_NEGATIVE_5 = "md:pinata-mr-negative_5",

    MD_MR_NEGATIVE_56 = "md:pinata-mr-negative_56",

    MD_MR_NEGATIVE_5XL = "md:pinata-mr-negative_5xl",

    MD_MR_NEGATIVE_6 = "md:pinata-mr-negative_6",

    MD_MR_NEGATIVE_64 = "md:pinata-mr-negative_64",

    MD_MR_NEGATIVE_6XL = "md:pinata-mr-negative_6xl",

    MD_MR_NEGATIVE_8 = "md:pinata-mr-negative_8",

    MD_MR_NEGATIVE_FULL = "md:pinata-mr-negative_full",

    MD_MR_NEGATIVE_LG = "md:pinata-mr-negative_lg",

    MD_MR_NEGATIVE_MD = "md:pinata-mr-negative_md",

    MD_MR_NEGATIVE_PX = "md:pinata-mr-negative_px",

    MD_MR_NEGATIVE_SM = "md:pinata-mr-negative_sm",

    MD_MR_NEGATIVE_XL = "md:pinata-mr-negative_xl",

    MD_MR_NEGATIVE_XS = "md:pinata-mr-negative_xs",

    MD_MR_PX = "md:pinata-mr-px",

    MD_MR_SM = "md:pinata-mr-sm",

    MD_MR_XL = "md:pinata-mr-xl",

    MD_MR_XS = "md:pinata-mr-xs",

    MD_MT_0 = "md:pinata-mt-0",

    MD_MT_1 = "md:pinata-mt-1",

    MD_MT_10 = "md:pinata-mt-10",

    MD_MT_12 = "md:pinata-mt-12",

    MD_MT_14 = "md:pinata-mt-14",

    MD_MT_16 = "md:pinata-mt-16",

    MD_MT_2 = "md:pinata-mt-2",

    MD_MT_20 = "md:pinata-mt-20",

    MD_MT_24 = "md:pinata-mt-24",

    MD_MT_2XL = "md:pinata-mt-2xl",

    MD_MT_3 = "md:pinata-mt-3",

    MD_MT_32 = "md:pinata-mt-32",

    MD_MT_3XL = "md:pinata-mt-3xl",

    MD_MT_4 = "md:pinata-mt-4",

    MD_MT_40 = "md:pinata-mt-40",

    MD_MT_48 = "md:pinata-mt-48",

    MD_MT_4XL = "md:pinata-mt-4xl",

    MD_MT_5 = "md:pinata-mt-5",

    MD_MT_56 = "md:pinata-mt-56",

    MD_MT_5XL = "md:pinata-mt-5xl",

    MD_MT_6 = "md:pinata-mt-6",

    MD_MT_64 = "md:pinata-mt-64",

    MD_MT_6XL = "md:pinata-mt-6xl",

    MD_MT_8 = "md:pinata-mt-8",

    MD_MT_AUTO = "md:pinata-mt-auto",

    MD_MT_FULL = "md:pinata-mt-full",

    MD_MT_LG = "md:pinata-mt-lg",

    MD_MT_MD = "md:pinata-mt-md",

    MD_MT_NEGATIVE_0 = "md:pinata-mt-negative_0",

    MD_MT_NEGATIVE_1 = "md:pinata-mt-negative_1",

    MD_MT_NEGATIVE_10 = "md:pinata-mt-negative_10",

    MD_MT_NEGATIVE_12 = "md:pinata-mt-negative_12",

    MD_MT_NEGATIVE_14 = "md:pinata-mt-negative_14",

    MD_MT_NEGATIVE_16 = "md:pinata-mt-negative_16",

    MD_MT_NEGATIVE_2 = "md:pinata-mt-negative_2",

    MD_MT_NEGATIVE_20 = "md:pinata-mt-negative_20",

    MD_MT_NEGATIVE_24 = "md:pinata-mt-negative_24",

    MD_MT_NEGATIVE_2XL = "md:pinata-mt-negative_2xl",

    MD_MT_NEGATIVE_3 = "md:pinata-mt-negative_3",

    MD_MT_NEGATIVE_32 = "md:pinata-mt-negative_32",

    MD_MT_NEGATIVE_3XL = "md:pinata-mt-negative_3xl",

    MD_MT_NEGATIVE_4 = "md:pinata-mt-negative_4",

    MD_MT_NEGATIVE_40 = "md:pinata-mt-negative_40",

    MD_MT_NEGATIVE_48 = "md:pinata-mt-negative_48",

    MD_MT_NEGATIVE_4XL = "md:pinata-mt-negative_4xl",

    MD_MT_NEGATIVE_5 = "md:pinata-mt-negative_5",

    MD_MT_NEGATIVE_56 = "md:pinata-mt-negative_56",

    MD_MT_NEGATIVE_5XL = "md:pinata-mt-negative_5xl",

    MD_MT_NEGATIVE_6 = "md:pinata-mt-negative_6",

    MD_MT_NEGATIVE_64 = "md:pinata-mt-negative_64",

    MD_MT_NEGATIVE_6XL = "md:pinata-mt-negative_6xl",

    MD_MT_NEGATIVE_8 = "md:pinata-mt-negative_8",

    MD_MT_NEGATIVE_FULL = "md:pinata-mt-negative_full",

    MD_MT_NEGATIVE_LG = "md:pinata-mt-negative_lg",

    MD_MT_NEGATIVE_MD = "md:pinata-mt-negative_md",

    MD_MT_NEGATIVE_PX = "md:pinata-mt-negative_px",

    MD_MT_NEGATIVE_SM = "md:pinata-mt-negative_sm",

    MD_MT_NEGATIVE_XL = "md:pinata-mt-negative_xl",

    MD_MT_NEGATIVE_XS = "md:pinata-mt-negative_xs",

    MD_MT_PX = "md:pinata-mt-px",

    MD_MT_SM = "md:pinata-mt-sm",

    MD_MT_XL = "md:pinata-mt-xl",

    MD_MT_XS = "md:pinata-mt-xs",

    MD_MX_0 = "md:pinata-mx-0",

    MD_MX_1 = "md:pinata-mx-1",

    MD_MX_10 = "md:pinata-mx-10",

    MD_MX_12 = "md:pinata-mx-12",

    MD_MX_14 = "md:pinata-mx-14",

    MD_MX_16 = "md:pinata-mx-16",

    MD_MX_2 = "md:pinata-mx-2",

    MD_MX_20 = "md:pinata-mx-20",

    MD_MX_24 = "md:pinata-mx-24",

    MD_MX_2XL = "md:pinata-mx-2xl",

    MD_MX_3 = "md:pinata-mx-3",

    MD_MX_32 = "md:pinata-mx-32",

    MD_MX_3XL = "md:pinata-mx-3xl",

    MD_MX_4 = "md:pinata-mx-4",

    MD_MX_40 = "md:pinata-mx-40",

    MD_MX_48 = "md:pinata-mx-48",

    MD_MX_4XL = "md:pinata-mx-4xl",

    MD_MX_5 = "md:pinata-mx-5",

    MD_MX_56 = "md:pinata-mx-56",

    MD_MX_5XL = "md:pinata-mx-5xl",

    MD_MX_6 = "md:pinata-mx-6",

    MD_MX_64 = "md:pinata-mx-64",

    MD_MX_6XL = "md:pinata-mx-6xl",

    MD_MX_8 = "md:pinata-mx-8",

    MD_MX_AUTO = "md:pinata-mx-auto",

    MD_MX_FULL = "md:pinata-mx-full",

    MD_MX_LG = "md:pinata-mx-lg",

    MD_MX_MD = "md:pinata-mx-md",

    MD_MX_NEGATIVE_0 = "md:pinata-mx-negative_0",

    MD_MX_NEGATIVE_1 = "md:pinata-mx-negative_1",

    MD_MX_NEGATIVE_10 = "md:pinata-mx-negative_10",

    MD_MX_NEGATIVE_12 = "md:pinata-mx-negative_12",

    MD_MX_NEGATIVE_14 = "md:pinata-mx-negative_14",

    MD_MX_NEGATIVE_16 = "md:pinata-mx-negative_16",

    MD_MX_NEGATIVE_2 = "md:pinata-mx-negative_2",

    MD_MX_NEGATIVE_20 = "md:pinata-mx-negative_20",

    MD_MX_NEGATIVE_24 = "md:pinata-mx-negative_24",

    MD_MX_NEGATIVE_2XL = "md:pinata-mx-negative_2xl",

    MD_MX_NEGATIVE_3 = "md:pinata-mx-negative_3",

    MD_MX_NEGATIVE_32 = "md:pinata-mx-negative_32",

    MD_MX_NEGATIVE_3XL = "md:pinata-mx-negative_3xl",

    MD_MX_NEGATIVE_4 = "md:pinata-mx-negative_4",

    MD_MX_NEGATIVE_40 = "md:pinata-mx-negative_40",

    MD_MX_NEGATIVE_48 = "md:pinata-mx-negative_48",

    MD_MX_NEGATIVE_4XL = "md:pinata-mx-negative_4xl",

    MD_MX_NEGATIVE_5 = "md:pinata-mx-negative_5",

    MD_MX_NEGATIVE_56 = "md:pinata-mx-negative_56",

    MD_MX_NEGATIVE_5XL = "md:pinata-mx-negative_5xl",

    MD_MX_NEGATIVE_6 = "md:pinata-mx-negative_6",

    MD_MX_NEGATIVE_64 = "md:pinata-mx-negative_64",

    MD_MX_NEGATIVE_6XL = "md:pinata-mx-negative_6xl",

    MD_MX_NEGATIVE_8 = "md:pinata-mx-negative_8",

    MD_MX_NEGATIVE_FULL = "md:pinata-mx-negative_full",

    MD_MX_NEGATIVE_LG = "md:pinata-mx-negative_lg",

    MD_MX_NEGATIVE_MD = "md:pinata-mx-negative_md",

    MD_MX_NEGATIVE_PX = "md:pinata-mx-negative_px",

    MD_MX_NEGATIVE_SM = "md:pinata-mx-negative_sm",

    MD_MX_NEGATIVE_XL = "md:pinata-mx-negative_xl",

    MD_MX_NEGATIVE_XS = "md:pinata-mx-negative_xs",

    MD_MX_PX = "md:pinata-mx-px",

    MD_MX_SM = "md:pinata-mx-sm",

    MD_MX_XL = "md:pinata-mx-xl",

    MD_MX_XS = "md:pinata-mx-xs",

    MD_MY_0 = "md:pinata-my-0",

    MD_MY_1 = "md:pinata-my-1",

    MD_MY_10 = "md:pinata-my-10",

    MD_MY_12 = "md:pinata-my-12",

    MD_MY_14 = "md:pinata-my-14",

    MD_MY_16 = "md:pinata-my-16",

    MD_MY_2 = "md:pinata-my-2",

    MD_MY_20 = "md:pinata-my-20",

    MD_MY_24 = "md:pinata-my-24",

    MD_MY_2XL = "md:pinata-my-2xl",

    MD_MY_3 = "md:pinata-my-3",

    MD_MY_32 = "md:pinata-my-32",

    MD_MY_3XL = "md:pinata-my-3xl",

    MD_MY_4 = "md:pinata-my-4",

    MD_MY_40 = "md:pinata-my-40",

    MD_MY_48 = "md:pinata-my-48",

    MD_MY_4XL = "md:pinata-my-4xl",

    MD_MY_5 = "md:pinata-my-5",

    MD_MY_56 = "md:pinata-my-56",

    MD_MY_5XL = "md:pinata-my-5xl",

    MD_MY_6 = "md:pinata-my-6",

    MD_MY_64 = "md:pinata-my-64",

    MD_MY_6XL = "md:pinata-my-6xl",

    MD_MY_8 = "md:pinata-my-8",

    MD_MY_AUTO = "md:pinata-my-auto",

    MD_MY_FULL = "md:pinata-my-full",

    MD_MY_LG = "md:pinata-my-lg",

    MD_MY_MD = "md:pinata-my-md",

    MD_MY_NEGATIVE_0 = "md:pinata-my-negative_0",

    MD_MY_NEGATIVE_1 = "md:pinata-my-negative_1",

    MD_MY_NEGATIVE_10 = "md:pinata-my-negative_10",

    MD_MY_NEGATIVE_12 = "md:pinata-my-negative_12",

    MD_MY_NEGATIVE_14 = "md:pinata-my-negative_14",

    MD_MY_NEGATIVE_16 = "md:pinata-my-negative_16",

    MD_MY_NEGATIVE_2 = "md:pinata-my-negative_2",

    MD_MY_NEGATIVE_20 = "md:pinata-my-negative_20",

    MD_MY_NEGATIVE_24 = "md:pinata-my-negative_24",

    MD_MY_NEGATIVE_2XL = "md:pinata-my-negative_2xl",

    MD_MY_NEGATIVE_3 = "md:pinata-my-negative_3",

    MD_MY_NEGATIVE_32 = "md:pinata-my-negative_32",

    MD_MY_NEGATIVE_3XL = "md:pinata-my-negative_3xl",

    MD_MY_NEGATIVE_4 = "md:pinata-my-negative_4",

    MD_MY_NEGATIVE_40 = "md:pinata-my-negative_40",

    MD_MY_NEGATIVE_48 = "md:pinata-my-negative_48",

    MD_MY_NEGATIVE_4XL = "md:pinata-my-negative_4xl",

    MD_MY_NEGATIVE_5 = "md:pinata-my-negative_5",

    MD_MY_NEGATIVE_56 = "md:pinata-my-negative_56",

    MD_MY_NEGATIVE_5XL = "md:pinata-my-negative_5xl",

    MD_MY_NEGATIVE_6 = "md:pinata-my-negative_6",

    MD_MY_NEGATIVE_64 = "md:pinata-my-negative_64",

    MD_MY_NEGATIVE_6XL = "md:pinata-my-negative_6xl",

    MD_MY_NEGATIVE_8 = "md:pinata-my-negative_8",

    MD_MY_NEGATIVE_FULL = "md:pinata-my-negative_full",

    MD_MY_NEGATIVE_LG = "md:pinata-my-negative_lg",

    MD_MY_NEGATIVE_MD = "md:pinata-my-negative_md",

    MD_MY_NEGATIVE_PX = "md:pinata-my-negative_px",

    MD_MY_NEGATIVE_SM = "md:pinata-my-negative_sm",

    MD_MY_NEGATIVE_XL = "md:pinata-my-negative_xl",

    MD_MY_NEGATIVE_XS = "md:pinata-my-negative_xs",

    MD_MY_PX = "md:pinata-my-px",

    MD_MY_SM = "md:pinata-my-sm",

    MD_MY_XL = "md:pinata-my-xl",

    MD_MY_XS = "md:pinata-my-xs",

    SM_M_0 = "sm:pinata-m-0",

    SM_M_1 = "sm:pinata-m-1",

    SM_M_10 = "sm:pinata-m-10",

    SM_M_12 = "sm:pinata-m-12",

    SM_M_14 = "sm:pinata-m-14",

    SM_M_16 = "sm:pinata-m-16",

    SM_M_2 = "sm:pinata-m-2",

    SM_M_20 = "sm:pinata-m-20",

    SM_M_24 = "sm:pinata-m-24",

    SM_M_2XL = "sm:pinata-m-2xl",

    SM_M_3 = "sm:pinata-m-3",

    SM_M_32 = "sm:pinata-m-32",

    SM_M_3XL = "sm:pinata-m-3xl",

    SM_M_4 = "sm:pinata-m-4",

    SM_M_40 = "sm:pinata-m-40",

    SM_M_48 = "sm:pinata-m-48",

    SM_M_4XL = "sm:pinata-m-4xl",

    SM_M_5 = "sm:pinata-m-5",

    SM_M_56 = "sm:pinata-m-56",

    SM_M_5XL = "sm:pinata-m-5xl",

    SM_M_6 = "sm:pinata-m-6",

    SM_M_64 = "sm:pinata-m-64",

    SM_M_6XL = "sm:pinata-m-6xl",

    SM_M_8 = "sm:pinata-m-8",

    SM_M_AUTO = "sm:pinata-m-auto",

    SM_M_FULL = "sm:pinata-m-full",

    SM_M_LG = "sm:pinata-m-lg",

    SM_M_MD = "sm:pinata-m-md",

    SM_M_NEGATIVE_0 = "sm:pinata-m-negative_0",

    SM_M_NEGATIVE_1 = "sm:pinata-m-negative_1",

    SM_M_NEGATIVE_10 = "sm:pinata-m-negative_10",

    SM_M_NEGATIVE_12 = "sm:pinata-m-negative_12",

    SM_M_NEGATIVE_14 = "sm:pinata-m-negative_14",

    SM_M_NEGATIVE_16 = "sm:pinata-m-negative_16",

    SM_M_NEGATIVE_2 = "sm:pinata-m-negative_2",

    SM_M_NEGATIVE_20 = "sm:pinata-m-negative_20",

    SM_M_NEGATIVE_24 = "sm:pinata-m-negative_24",

    SM_M_NEGATIVE_2XL = "sm:pinata-m-negative_2xl",

    SM_M_NEGATIVE_3 = "sm:pinata-m-negative_3",

    SM_M_NEGATIVE_32 = "sm:pinata-m-negative_32",

    SM_M_NEGATIVE_3XL = "sm:pinata-m-negative_3xl",

    SM_M_NEGATIVE_4 = "sm:pinata-m-negative_4",

    SM_M_NEGATIVE_40 = "sm:pinata-m-negative_40",

    SM_M_NEGATIVE_48 = "sm:pinata-m-negative_48",

    SM_M_NEGATIVE_4XL = "sm:pinata-m-negative_4xl",

    SM_M_NEGATIVE_5 = "sm:pinata-m-negative_5",

    SM_M_NEGATIVE_56 = "sm:pinata-m-negative_56",

    SM_M_NEGATIVE_5XL = "sm:pinata-m-negative_5xl",

    SM_M_NEGATIVE_6 = "sm:pinata-m-negative_6",

    SM_M_NEGATIVE_64 = "sm:pinata-m-negative_64",

    SM_M_NEGATIVE_6XL = "sm:pinata-m-negative_6xl",

    SM_M_NEGATIVE_8 = "sm:pinata-m-negative_8",

    SM_M_NEGATIVE_FULL = "sm:pinata-m-negative_full",

    SM_M_NEGATIVE_LG = "sm:pinata-m-negative_lg",

    SM_M_NEGATIVE_MD = "sm:pinata-m-negative_md",

    SM_M_NEGATIVE_PX = "sm:pinata-m-negative_px",

    SM_M_NEGATIVE_SM = "sm:pinata-m-negative_sm",

    SM_M_NEGATIVE_XL = "sm:pinata-m-negative_xl",

    SM_M_NEGATIVE_XS = "sm:pinata-m-negative_xs",

    SM_M_PX = "sm:pinata-m-px",

    SM_M_SM = "sm:pinata-m-sm",

    SM_M_XL = "sm:pinata-m-xl",

    SM_M_XS = "sm:pinata-m-xs",

    SM_MB_0 = "sm:pinata-mb-0",

    SM_MB_1 = "sm:pinata-mb-1",

    SM_MB_10 = "sm:pinata-mb-10",

    SM_MB_12 = "sm:pinata-mb-12",

    SM_MB_14 = "sm:pinata-mb-14",

    SM_MB_16 = "sm:pinata-mb-16",

    SM_MB_2 = "sm:pinata-mb-2",

    SM_MB_20 = "sm:pinata-mb-20",

    SM_MB_24 = "sm:pinata-mb-24",

    SM_MB_2XL = "sm:pinata-mb-2xl",

    SM_MB_3 = "sm:pinata-mb-3",

    SM_MB_32 = "sm:pinata-mb-32",

    SM_MB_3XL = "sm:pinata-mb-3xl",

    SM_MB_4 = "sm:pinata-mb-4",

    SM_MB_40 = "sm:pinata-mb-40",

    SM_MB_48 = "sm:pinata-mb-48",

    SM_MB_4XL = "sm:pinata-mb-4xl",

    SM_MB_5 = "sm:pinata-mb-5",

    SM_MB_56 = "sm:pinata-mb-56",

    SM_MB_5XL = "sm:pinata-mb-5xl",

    SM_MB_6 = "sm:pinata-mb-6",

    SM_MB_64 = "sm:pinata-mb-64",

    SM_MB_6XL = "sm:pinata-mb-6xl",

    SM_MB_8 = "sm:pinata-mb-8",

    SM_MB_AUTO = "sm:pinata-mb-auto",

    SM_MB_FULL = "sm:pinata-mb-full",

    SM_MB_LG = "sm:pinata-mb-lg",

    SM_MB_MD = "sm:pinata-mb-md",

    SM_MB_NEGATIVE_0 = "sm:pinata-mb-negative_0",

    SM_MB_NEGATIVE_1 = "sm:pinata-mb-negative_1",

    SM_MB_NEGATIVE_10 = "sm:pinata-mb-negative_10",

    SM_MB_NEGATIVE_12 = "sm:pinata-mb-negative_12",

    SM_MB_NEGATIVE_14 = "sm:pinata-mb-negative_14",

    SM_MB_NEGATIVE_16 = "sm:pinata-mb-negative_16",

    SM_MB_NEGATIVE_2 = "sm:pinata-mb-negative_2",

    SM_MB_NEGATIVE_20 = "sm:pinata-mb-negative_20",

    SM_MB_NEGATIVE_24 = "sm:pinata-mb-negative_24",

    SM_MB_NEGATIVE_2XL = "sm:pinata-mb-negative_2xl",

    SM_MB_NEGATIVE_3 = "sm:pinata-mb-negative_3",

    SM_MB_NEGATIVE_32 = "sm:pinata-mb-negative_32",

    SM_MB_NEGATIVE_3XL = "sm:pinata-mb-negative_3xl",

    SM_MB_NEGATIVE_4 = "sm:pinata-mb-negative_4",

    SM_MB_NEGATIVE_40 = "sm:pinata-mb-negative_40",

    SM_MB_NEGATIVE_48 = "sm:pinata-mb-negative_48",

    SM_MB_NEGATIVE_4XL = "sm:pinata-mb-negative_4xl",

    SM_MB_NEGATIVE_5 = "sm:pinata-mb-negative_5",

    SM_MB_NEGATIVE_56 = "sm:pinata-mb-negative_56",

    SM_MB_NEGATIVE_5XL = "sm:pinata-mb-negative_5xl",

    SM_MB_NEGATIVE_6 = "sm:pinata-mb-negative_6",

    SM_MB_NEGATIVE_64 = "sm:pinata-mb-negative_64",

    SM_MB_NEGATIVE_6XL = "sm:pinata-mb-negative_6xl",

    SM_MB_NEGATIVE_8 = "sm:pinata-mb-negative_8",

    SM_MB_NEGATIVE_FULL = "sm:pinata-mb-negative_full",

    SM_MB_NEGATIVE_LG = "sm:pinata-mb-negative_lg",

    SM_MB_NEGATIVE_MD = "sm:pinata-mb-negative_md",

    SM_MB_NEGATIVE_PX = "sm:pinata-mb-negative_px",

    SM_MB_NEGATIVE_SM = "sm:pinata-mb-negative_sm",

    SM_MB_NEGATIVE_XL = "sm:pinata-mb-negative_xl",

    SM_MB_NEGATIVE_XS = "sm:pinata-mb-negative_xs",

    SM_MB_PX = "sm:pinata-mb-px",

    SM_MB_SM = "sm:pinata-mb-sm",

    SM_MB_XL = "sm:pinata-mb-xl",

    SM_MB_XS = "sm:pinata-mb-xs",

    SM_ML_0 = "sm:pinata-ml-0",

    SM_ML_1 = "sm:pinata-ml-1",

    SM_ML_10 = "sm:pinata-ml-10",

    SM_ML_12 = "sm:pinata-ml-12",

    SM_ML_14 = "sm:pinata-ml-14",

    SM_ML_16 = "sm:pinata-ml-16",

    SM_ML_2 = "sm:pinata-ml-2",

    SM_ML_20 = "sm:pinata-ml-20",

    SM_ML_24 = "sm:pinata-ml-24",

    SM_ML_2XL = "sm:pinata-ml-2xl",

    SM_ML_3 = "sm:pinata-ml-3",

    SM_ML_32 = "sm:pinata-ml-32",

    SM_ML_3XL = "sm:pinata-ml-3xl",

    SM_ML_4 = "sm:pinata-ml-4",

    SM_ML_40 = "sm:pinata-ml-40",

    SM_ML_48 = "sm:pinata-ml-48",

    SM_ML_4XL = "sm:pinata-ml-4xl",

    SM_ML_5 = "sm:pinata-ml-5",

    SM_ML_56 = "sm:pinata-ml-56",

    SM_ML_5XL = "sm:pinata-ml-5xl",

    SM_ML_6 = "sm:pinata-ml-6",

    SM_ML_64 = "sm:pinata-ml-64",

    SM_ML_6XL = "sm:pinata-ml-6xl",

    SM_ML_8 = "sm:pinata-ml-8",

    SM_ML_AUTO = "sm:pinata-ml-auto",

    SM_ML_FULL = "sm:pinata-ml-full",

    SM_ML_LG = "sm:pinata-ml-lg",

    SM_ML_MD = "sm:pinata-ml-md",

    SM_ML_NEGATIVE_0 = "sm:pinata-ml-negative_0",

    SM_ML_NEGATIVE_1 = "sm:pinata-ml-negative_1",

    SM_ML_NEGATIVE_10 = "sm:pinata-ml-negative_10",

    SM_ML_NEGATIVE_12 = "sm:pinata-ml-negative_12",

    SM_ML_NEGATIVE_14 = "sm:pinata-ml-negative_14",

    SM_ML_NEGATIVE_16 = "sm:pinata-ml-negative_16",

    SM_ML_NEGATIVE_2 = "sm:pinata-ml-negative_2",

    SM_ML_NEGATIVE_20 = "sm:pinata-ml-negative_20",

    SM_ML_NEGATIVE_24 = "sm:pinata-ml-negative_24",

    SM_ML_NEGATIVE_2XL = "sm:pinata-ml-negative_2xl",

    SM_ML_NEGATIVE_3 = "sm:pinata-ml-negative_3",

    SM_ML_NEGATIVE_32 = "sm:pinata-ml-negative_32",

    SM_ML_NEGATIVE_3XL = "sm:pinata-ml-negative_3xl",

    SM_ML_NEGATIVE_4 = "sm:pinata-ml-negative_4",

    SM_ML_NEGATIVE_40 = "sm:pinata-ml-negative_40",

    SM_ML_NEGATIVE_48 = "sm:pinata-ml-negative_48",

    SM_ML_NEGATIVE_4XL = "sm:pinata-ml-negative_4xl",

    SM_ML_NEGATIVE_5 = "sm:pinata-ml-negative_5",

    SM_ML_NEGATIVE_56 = "sm:pinata-ml-negative_56",

    SM_ML_NEGATIVE_5XL = "sm:pinata-ml-negative_5xl",

    SM_ML_NEGATIVE_6 = "sm:pinata-ml-negative_6",

    SM_ML_NEGATIVE_64 = "sm:pinata-ml-negative_64",

    SM_ML_NEGATIVE_6XL = "sm:pinata-ml-negative_6xl",

    SM_ML_NEGATIVE_8 = "sm:pinata-ml-negative_8",

    SM_ML_NEGATIVE_FULL = "sm:pinata-ml-negative_full",

    SM_ML_NEGATIVE_LG = "sm:pinata-ml-negative_lg",

    SM_ML_NEGATIVE_MD = "sm:pinata-ml-negative_md",

    SM_ML_NEGATIVE_PX = "sm:pinata-ml-negative_px",

    SM_ML_NEGATIVE_SM = "sm:pinata-ml-negative_sm",

    SM_ML_NEGATIVE_XL = "sm:pinata-ml-negative_xl",

    SM_ML_NEGATIVE_XS = "sm:pinata-ml-negative_xs",

    SM_ML_PX = "sm:pinata-ml-px",

    SM_ML_SM = "sm:pinata-ml-sm",

    SM_ML_XL = "sm:pinata-ml-xl",

    SM_ML_XS = "sm:pinata-ml-xs",

    SM_MR_0 = "sm:pinata-mr-0",

    SM_MR_1 = "sm:pinata-mr-1",

    SM_MR_10 = "sm:pinata-mr-10",

    SM_MR_12 = "sm:pinata-mr-12",

    SM_MR_14 = "sm:pinata-mr-14",

    SM_MR_16 = "sm:pinata-mr-16",

    SM_MR_2 = "sm:pinata-mr-2",

    SM_MR_20 = "sm:pinata-mr-20",

    SM_MR_24 = "sm:pinata-mr-24",

    SM_MR_2XL = "sm:pinata-mr-2xl",

    SM_MR_3 = "sm:pinata-mr-3",

    SM_MR_32 = "sm:pinata-mr-32",

    SM_MR_3XL = "sm:pinata-mr-3xl",

    SM_MR_4 = "sm:pinata-mr-4",

    SM_MR_40 = "sm:pinata-mr-40",

    SM_MR_48 = "sm:pinata-mr-48",

    SM_MR_4XL = "sm:pinata-mr-4xl",

    SM_MR_5 = "sm:pinata-mr-5",

    SM_MR_56 = "sm:pinata-mr-56",

    SM_MR_5XL = "sm:pinata-mr-5xl",

    SM_MR_6 = "sm:pinata-mr-6",

    SM_MR_64 = "sm:pinata-mr-64",

    SM_MR_6XL = "sm:pinata-mr-6xl",

    SM_MR_8 = "sm:pinata-mr-8",

    SM_MR_AUTO = "sm:pinata-mr-auto",

    SM_MR_FULL = "sm:pinata-mr-full",

    SM_MR_LG = "sm:pinata-mr-lg",

    SM_MR_MD = "sm:pinata-mr-md",

    SM_MR_NEGATIVE_0 = "sm:pinata-mr-negative_0",

    SM_MR_NEGATIVE_1 = "sm:pinata-mr-negative_1",

    SM_MR_NEGATIVE_10 = "sm:pinata-mr-negative_10",

    SM_MR_NEGATIVE_12 = "sm:pinata-mr-negative_12",

    SM_MR_NEGATIVE_14 = "sm:pinata-mr-negative_14",

    SM_MR_NEGATIVE_16 = "sm:pinata-mr-negative_16",

    SM_MR_NEGATIVE_2 = "sm:pinata-mr-negative_2",

    SM_MR_NEGATIVE_20 = "sm:pinata-mr-negative_20",

    SM_MR_NEGATIVE_24 = "sm:pinata-mr-negative_24",

    SM_MR_NEGATIVE_2XL = "sm:pinata-mr-negative_2xl",

    SM_MR_NEGATIVE_3 = "sm:pinata-mr-negative_3",

    SM_MR_NEGATIVE_32 = "sm:pinata-mr-negative_32",

    SM_MR_NEGATIVE_3XL = "sm:pinata-mr-negative_3xl",

    SM_MR_NEGATIVE_4 = "sm:pinata-mr-negative_4",

    SM_MR_NEGATIVE_40 = "sm:pinata-mr-negative_40",

    SM_MR_NEGATIVE_48 = "sm:pinata-mr-negative_48",

    SM_MR_NEGATIVE_4XL = "sm:pinata-mr-negative_4xl",

    SM_MR_NEGATIVE_5 = "sm:pinata-mr-negative_5",

    SM_MR_NEGATIVE_56 = "sm:pinata-mr-negative_56",

    SM_MR_NEGATIVE_5XL = "sm:pinata-mr-negative_5xl",

    SM_MR_NEGATIVE_6 = "sm:pinata-mr-negative_6",

    SM_MR_NEGATIVE_64 = "sm:pinata-mr-negative_64",

    SM_MR_NEGATIVE_6XL = "sm:pinata-mr-negative_6xl",

    SM_MR_NEGATIVE_8 = "sm:pinata-mr-negative_8",

    SM_MR_NEGATIVE_FULL = "sm:pinata-mr-negative_full",

    SM_MR_NEGATIVE_LG = "sm:pinata-mr-negative_lg",

    SM_MR_NEGATIVE_MD = "sm:pinata-mr-negative_md",

    SM_MR_NEGATIVE_PX = "sm:pinata-mr-negative_px",

    SM_MR_NEGATIVE_SM = "sm:pinata-mr-negative_sm",

    SM_MR_NEGATIVE_XL = "sm:pinata-mr-negative_xl",

    SM_MR_NEGATIVE_XS = "sm:pinata-mr-negative_xs",

    SM_MR_PX = "sm:pinata-mr-px",

    SM_MR_SM = "sm:pinata-mr-sm",

    SM_MR_XL = "sm:pinata-mr-xl",

    SM_MR_XS = "sm:pinata-mr-xs",

    SM_MT_0 = "sm:pinata-mt-0",

    SM_MT_1 = "sm:pinata-mt-1",

    SM_MT_10 = "sm:pinata-mt-10",

    SM_MT_12 = "sm:pinata-mt-12",

    SM_MT_14 = "sm:pinata-mt-14",

    SM_MT_16 = "sm:pinata-mt-16",

    SM_MT_2 = "sm:pinata-mt-2",

    SM_MT_20 = "sm:pinata-mt-20",

    SM_MT_24 = "sm:pinata-mt-24",

    SM_MT_2XL = "sm:pinata-mt-2xl",

    SM_MT_3 = "sm:pinata-mt-3",

    SM_MT_32 = "sm:pinata-mt-32",

    SM_MT_3XL = "sm:pinata-mt-3xl",

    SM_MT_4 = "sm:pinata-mt-4",

    SM_MT_40 = "sm:pinata-mt-40",

    SM_MT_48 = "sm:pinata-mt-48",

    SM_MT_4XL = "sm:pinata-mt-4xl",

    SM_MT_5 = "sm:pinata-mt-5",

    SM_MT_56 = "sm:pinata-mt-56",

    SM_MT_5XL = "sm:pinata-mt-5xl",

    SM_MT_6 = "sm:pinata-mt-6",

    SM_MT_64 = "sm:pinata-mt-64",

    SM_MT_6XL = "sm:pinata-mt-6xl",

    SM_MT_8 = "sm:pinata-mt-8",

    SM_MT_AUTO = "sm:pinata-mt-auto",

    SM_MT_FULL = "sm:pinata-mt-full",

    SM_MT_LG = "sm:pinata-mt-lg",

    SM_MT_MD = "sm:pinata-mt-md",

    SM_MT_NEGATIVE_0 = "sm:pinata-mt-negative_0",

    SM_MT_NEGATIVE_1 = "sm:pinata-mt-negative_1",

    SM_MT_NEGATIVE_10 = "sm:pinata-mt-negative_10",

    SM_MT_NEGATIVE_12 = "sm:pinata-mt-negative_12",

    SM_MT_NEGATIVE_14 = "sm:pinata-mt-negative_14",

    SM_MT_NEGATIVE_16 = "sm:pinata-mt-negative_16",

    SM_MT_NEGATIVE_2 = "sm:pinata-mt-negative_2",

    SM_MT_NEGATIVE_20 = "sm:pinata-mt-negative_20",

    SM_MT_NEGATIVE_24 = "sm:pinata-mt-negative_24",

    SM_MT_NEGATIVE_2XL = "sm:pinata-mt-negative_2xl",

    SM_MT_NEGATIVE_3 = "sm:pinata-mt-negative_3",

    SM_MT_NEGATIVE_32 = "sm:pinata-mt-negative_32",

    SM_MT_NEGATIVE_3XL = "sm:pinata-mt-negative_3xl",

    SM_MT_NEGATIVE_4 = "sm:pinata-mt-negative_4",

    SM_MT_NEGATIVE_40 = "sm:pinata-mt-negative_40",

    SM_MT_NEGATIVE_48 = "sm:pinata-mt-negative_48",

    SM_MT_NEGATIVE_4XL = "sm:pinata-mt-negative_4xl",

    SM_MT_NEGATIVE_5 = "sm:pinata-mt-negative_5",

    SM_MT_NEGATIVE_56 = "sm:pinata-mt-negative_56",

    SM_MT_NEGATIVE_5XL = "sm:pinata-mt-negative_5xl",

    SM_MT_NEGATIVE_6 = "sm:pinata-mt-negative_6",

    SM_MT_NEGATIVE_64 = "sm:pinata-mt-negative_64",

    SM_MT_NEGATIVE_6XL = "sm:pinata-mt-negative_6xl",

    SM_MT_NEGATIVE_8 = "sm:pinata-mt-negative_8",

    SM_MT_NEGATIVE_FULL = "sm:pinata-mt-negative_full",

    SM_MT_NEGATIVE_LG = "sm:pinata-mt-negative_lg",

    SM_MT_NEGATIVE_MD = "sm:pinata-mt-negative_md",

    SM_MT_NEGATIVE_PX = "sm:pinata-mt-negative_px",

    SM_MT_NEGATIVE_SM = "sm:pinata-mt-negative_sm",

    SM_MT_NEGATIVE_XL = "sm:pinata-mt-negative_xl",

    SM_MT_NEGATIVE_XS = "sm:pinata-mt-negative_xs",

    SM_MT_PX = "sm:pinata-mt-px",

    SM_MT_SM = "sm:pinata-mt-sm",

    SM_MT_XL = "sm:pinata-mt-xl",

    SM_MT_XS = "sm:pinata-mt-xs",

    SM_MX_0 = "sm:pinata-mx-0",

    SM_MX_1 = "sm:pinata-mx-1",

    SM_MX_10 = "sm:pinata-mx-10",

    SM_MX_12 = "sm:pinata-mx-12",

    SM_MX_14 = "sm:pinata-mx-14",

    SM_MX_16 = "sm:pinata-mx-16",

    SM_MX_2 = "sm:pinata-mx-2",

    SM_MX_20 = "sm:pinata-mx-20",

    SM_MX_24 = "sm:pinata-mx-24",

    SM_MX_2XL = "sm:pinata-mx-2xl",

    SM_MX_3 = "sm:pinata-mx-3",

    SM_MX_32 = "sm:pinata-mx-32",

    SM_MX_3XL = "sm:pinata-mx-3xl",

    SM_MX_4 = "sm:pinata-mx-4",

    SM_MX_40 = "sm:pinata-mx-40",

    SM_MX_48 = "sm:pinata-mx-48",

    SM_MX_4XL = "sm:pinata-mx-4xl",

    SM_MX_5 = "sm:pinata-mx-5",

    SM_MX_56 = "sm:pinata-mx-56",

    SM_MX_5XL = "sm:pinata-mx-5xl",

    SM_MX_6 = "sm:pinata-mx-6",

    SM_MX_64 = "sm:pinata-mx-64",

    SM_MX_6XL = "sm:pinata-mx-6xl",

    SM_MX_8 = "sm:pinata-mx-8",

    SM_MX_AUTO = "sm:pinata-mx-auto",

    SM_MX_FULL = "sm:pinata-mx-full",

    SM_MX_LG = "sm:pinata-mx-lg",

    SM_MX_MD = "sm:pinata-mx-md",

    SM_MX_NEGATIVE_0 = "sm:pinata-mx-negative_0",

    SM_MX_NEGATIVE_1 = "sm:pinata-mx-negative_1",

    SM_MX_NEGATIVE_10 = "sm:pinata-mx-negative_10",

    SM_MX_NEGATIVE_12 = "sm:pinata-mx-negative_12",

    SM_MX_NEGATIVE_14 = "sm:pinata-mx-negative_14",

    SM_MX_NEGATIVE_16 = "sm:pinata-mx-negative_16",

    SM_MX_NEGATIVE_2 = "sm:pinata-mx-negative_2",

    SM_MX_NEGATIVE_20 = "sm:pinata-mx-negative_20",

    SM_MX_NEGATIVE_24 = "sm:pinata-mx-negative_24",

    SM_MX_NEGATIVE_2XL = "sm:pinata-mx-negative_2xl",

    SM_MX_NEGATIVE_3 = "sm:pinata-mx-negative_3",

    SM_MX_NEGATIVE_32 = "sm:pinata-mx-negative_32",

    SM_MX_NEGATIVE_3XL = "sm:pinata-mx-negative_3xl",

    SM_MX_NEGATIVE_4 = "sm:pinata-mx-negative_4",

    SM_MX_NEGATIVE_40 = "sm:pinata-mx-negative_40",

    SM_MX_NEGATIVE_48 = "sm:pinata-mx-negative_48",

    SM_MX_NEGATIVE_4XL = "sm:pinata-mx-negative_4xl",

    SM_MX_NEGATIVE_5 = "sm:pinata-mx-negative_5",

    SM_MX_NEGATIVE_56 = "sm:pinata-mx-negative_56",

    SM_MX_NEGATIVE_5XL = "sm:pinata-mx-negative_5xl",

    SM_MX_NEGATIVE_6 = "sm:pinata-mx-negative_6",

    SM_MX_NEGATIVE_64 = "sm:pinata-mx-negative_64",

    SM_MX_NEGATIVE_6XL = "sm:pinata-mx-negative_6xl",

    SM_MX_NEGATIVE_8 = "sm:pinata-mx-negative_8",

    SM_MX_NEGATIVE_FULL = "sm:pinata-mx-negative_full",

    SM_MX_NEGATIVE_LG = "sm:pinata-mx-negative_lg",

    SM_MX_NEGATIVE_MD = "sm:pinata-mx-negative_md",

    SM_MX_NEGATIVE_PX = "sm:pinata-mx-negative_px",

    SM_MX_NEGATIVE_SM = "sm:pinata-mx-negative_sm",

    SM_MX_NEGATIVE_XL = "sm:pinata-mx-negative_xl",

    SM_MX_NEGATIVE_XS = "sm:pinata-mx-negative_xs",

    SM_MX_PX = "sm:pinata-mx-px",

    SM_MX_SM = "sm:pinata-mx-sm",

    SM_MX_XL = "sm:pinata-mx-xl",

    SM_MX_XS = "sm:pinata-mx-xs",

    SM_MY_0 = "sm:pinata-my-0",

    SM_MY_1 = "sm:pinata-my-1",

    SM_MY_10 = "sm:pinata-my-10",

    SM_MY_12 = "sm:pinata-my-12",

    SM_MY_14 = "sm:pinata-my-14",

    SM_MY_16 = "sm:pinata-my-16",

    SM_MY_2 = "sm:pinata-my-2",

    SM_MY_20 = "sm:pinata-my-20",

    SM_MY_24 = "sm:pinata-my-24",

    SM_MY_2XL = "sm:pinata-my-2xl",

    SM_MY_3 = "sm:pinata-my-3",

    SM_MY_32 = "sm:pinata-my-32",

    SM_MY_3XL = "sm:pinata-my-3xl",

    SM_MY_4 = "sm:pinata-my-4",

    SM_MY_40 = "sm:pinata-my-40",

    SM_MY_48 = "sm:pinata-my-48",

    SM_MY_4XL = "sm:pinata-my-4xl",

    SM_MY_5 = "sm:pinata-my-5",

    SM_MY_56 = "sm:pinata-my-56",

    SM_MY_5XL = "sm:pinata-my-5xl",

    SM_MY_6 = "sm:pinata-my-6",

    SM_MY_64 = "sm:pinata-my-64",

    SM_MY_6XL = "sm:pinata-my-6xl",

    SM_MY_8 = "sm:pinata-my-8",

    SM_MY_AUTO = "sm:pinata-my-auto",

    SM_MY_FULL = "sm:pinata-my-full",

    SM_MY_LG = "sm:pinata-my-lg",

    SM_MY_MD = "sm:pinata-my-md",

    SM_MY_NEGATIVE_0 = "sm:pinata-my-negative_0",

    SM_MY_NEGATIVE_1 = "sm:pinata-my-negative_1",

    SM_MY_NEGATIVE_10 = "sm:pinata-my-negative_10",

    SM_MY_NEGATIVE_12 = "sm:pinata-my-negative_12",

    SM_MY_NEGATIVE_14 = "sm:pinata-my-negative_14",

    SM_MY_NEGATIVE_16 = "sm:pinata-my-negative_16",

    SM_MY_NEGATIVE_2 = "sm:pinata-my-negative_2",

    SM_MY_NEGATIVE_20 = "sm:pinata-my-negative_20",

    SM_MY_NEGATIVE_24 = "sm:pinata-my-negative_24",

    SM_MY_NEGATIVE_2XL = "sm:pinata-my-negative_2xl",

    SM_MY_NEGATIVE_3 = "sm:pinata-my-negative_3",

    SM_MY_NEGATIVE_32 = "sm:pinata-my-negative_32",

    SM_MY_NEGATIVE_3XL = "sm:pinata-my-negative_3xl",

    SM_MY_NEGATIVE_4 = "sm:pinata-my-negative_4",

    SM_MY_NEGATIVE_40 = "sm:pinata-my-negative_40",

    SM_MY_NEGATIVE_48 = "sm:pinata-my-negative_48",

    SM_MY_NEGATIVE_4XL = "sm:pinata-my-negative_4xl",

    SM_MY_NEGATIVE_5 = "sm:pinata-my-negative_5",

    SM_MY_NEGATIVE_56 = "sm:pinata-my-negative_56",

    SM_MY_NEGATIVE_5XL = "sm:pinata-my-negative_5xl",

    SM_MY_NEGATIVE_6 = "sm:pinata-my-negative_6",

    SM_MY_NEGATIVE_64 = "sm:pinata-my-negative_64",

    SM_MY_NEGATIVE_6XL = "sm:pinata-my-negative_6xl",

    SM_MY_NEGATIVE_8 = "sm:pinata-my-negative_8",

    SM_MY_NEGATIVE_FULL = "sm:pinata-my-negative_full",

    SM_MY_NEGATIVE_LG = "sm:pinata-my-negative_lg",

    SM_MY_NEGATIVE_MD = "sm:pinata-my-negative_md",

    SM_MY_NEGATIVE_PX = "sm:pinata-my-negative_px",

    SM_MY_NEGATIVE_SM = "sm:pinata-my-negative_sm",

    SM_MY_NEGATIVE_XL = "sm:pinata-my-negative_xl",

    SM_MY_NEGATIVE_XS = "sm:pinata-my-negative_xs",

    SM_MY_PX = "sm:pinata-my-px",

    SM_MY_SM = "sm:pinata-my-sm",

    SM_MY_XL = "sm:pinata-my-xl",

    SM_MY_XS = "sm:pinata-my-xs",

    XL_M_0 = "xl:pinata-m-0",

    XL_M_1 = "xl:pinata-m-1",

    XL_M_10 = "xl:pinata-m-10",

    XL_M_12 = "xl:pinata-m-12",

    XL_M_14 = "xl:pinata-m-14",

    XL_M_16 = "xl:pinata-m-16",

    XL_M_2 = "xl:pinata-m-2",

    XL_M_20 = "xl:pinata-m-20",

    XL_M_24 = "xl:pinata-m-24",

    XL_M_2XL = "xl:pinata-m-2xl",

    XL_M_3 = "xl:pinata-m-3",

    XL_M_32 = "xl:pinata-m-32",

    XL_M_3XL = "xl:pinata-m-3xl",

    XL_M_4 = "xl:pinata-m-4",

    XL_M_40 = "xl:pinata-m-40",

    XL_M_48 = "xl:pinata-m-48",

    XL_M_4XL = "xl:pinata-m-4xl",

    XL_M_5 = "xl:pinata-m-5",

    XL_M_56 = "xl:pinata-m-56",

    XL_M_5XL = "xl:pinata-m-5xl",

    XL_M_6 = "xl:pinata-m-6",

    XL_M_64 = "xl:pinata-m-64",

    XL_M_6XL = "xl:pinata-m-6xl",

    XL_M_8 = "xl:pinata-m-8",

    XL_M_AUTO = "xl:pinata-m-auto",

    XL_M_FULL = "xl:pinata-m-full",

    XL_M_LG = "xl:pinata-m-lg",

    XL_M_MD = "xl:pinata-m-md",

    XL_M_NEGATIVE_0 = "xl:pinata-m-negative_0",

    XL_M_NEGATIVE_1 = "xl:pinata-m-negative_1",

    XL_M_NEGATIVE_10 = "xl:pinata-m-negative_10",

    XL_M_NEGATIVE_12 = "xl:pinata-m-negative_12",

    XL_M_NEGATIVE_14 = "xl:pinata-m-negative_14",

    XL_M_NEGATIVE_16 = "xl:pinata-m-negative_16",

    XL_M_NEGATIVE_2 = "xl:pinata-m-negative_2",

    XL_M_NEGATIVE_20 = "xl:pinata-m-negative_20",

    XL_M_NEGATIVE_24 = "xl:pinata-m-negative_24",

    XL_M_NEGATIVE_2XL = "xl:pinata-m-negative_2xl",

    XL_M_NEGATIVE_3 = "xl:pinata-m-negative_3",

    XL_M_NEGATIVE_32 = "xl:pinata-m-negative_32",

    XL_M_NEGATIVE_3XL = "xl:pinata-m-negative_3xl",

    XL_M_NEGATIVE_4 = "xl:pinata-m-negative_4",

    XL_M_NEGATIVE_40 = "xl:pinata-m-negative_40",

    XL_M_NEGATIVE_48 = "xl:pinata-m-negative_48",

    XL_M_NEGATIVE_4XL = "xl:pinata-m-negative_4xl",

    XL_M_NEGATIVE_5 = "xl:pinata-m-negative_5",

    XL_M_NEGATIVE_56 = "xl:pinata-m-negative_56",

    XL_M_NEGATIVE_5XL = "xl:pinata-m-negative_5xl",

    XL_M_NEGATIVE_6 = "xl:pinata-m-negative_6",

    XL_M_NEGATIVE_64 = "xl:pinata-m-negative_64",

    XL_M_NEGATIVE_6XL = "xl:pinata-m-negative_6xl",

    XL_M_NEGATIVE_8 = "xl:pinata-m-negative_8",

    XL_M_NEGATIVE_FULL = "xl:pinata-m-negative_full",

    XL_M_NEGATIVE_LG = "xl:pinata-m-negative_lg",

    XL_M_NEGATIVE_MD = "xl:pinata-m-negative_md",

    XL_M_NEGATIVE_PX = "xl:pinata-m-negative_px",

    XL_M_NEGATIVE_SM = "xl:pinata-m-negative_sm",

    XL_M_NEGATIVE_XL = "xl:pinata-m-negative_xl",

    XL_M_NEGATIVE_XS = "xl:pinata-m-negative_xs",

    XL_M_PX = "xl:pinata-m-px",

    XL_M_SM = "xl:pinata-m-sm",

    XL_M_XL = "xl:pinata-m-xl",

    XL_M_XS = "xl:pinata-m-xs",

    XL_MB_0 = "xl:pinata-mb-0",

    XL_MB_1 = "xl:pinata-mb-1",

    XL_MB_10 = "xl:pinata-mb-10",

    XL_MB_12 = "xl:pinata-mb-12",

    XL_MB_14 = "xl:pinata-mb-14",

    XL_MB_16 = "xl:pinata-mb-16",

    XL_MB_2 = "xl:pinata-mb-2",

    XL_MB_20 = "xl:pinata-mb-20",

    XL_MB_24 = "xl:pinata-mb-24",

    XL_MB_2XL = "xl:pinata-mb-2xl",

    XL_MB_3 = "xl:pinata-mb-3",

    XL_MB_32 = "xl:pinata-mb-32",

    XL_MB_3XL = "xl:pinata-mb-3xl",

    XL_MB_4 = "xl:pinata-mb-4",

    XL_MB_40 = "xl:pinata-mb-40",

    XL_MB_48 = "xl:pinata-mb-48",

    XL_MB_4XL = "xl:pinata-mb-4xl",

    XL_MB_5 = "xl:pinata-mb-5",

    XL_MB_56 = "xl:pinata-mb-56",

    XL_MB_5XL = "xl:pinata-mb-5xl",

    XL_MB_6 = "xl:pinata-mb-6",

    XL_MB_64 = "xl:pinata-mb-64",

    XL_MB_6XL = "xl:pinata-mb-6xl",

    XL_MB_8 = "xl:pinata-mb-8",

    XL_MB_AUTO = "xl:pinata-mb-auto",

    XL_MB_FULL = "xl:pinata-mb-full",

    XL_MB_LG = "xl:pinata-mb-lg",

    XL_MB_MD = "xl:pinata-mb-md",

    XL_MB_NEGATIVE_0 = "xl:pinata-mb-negative_0",

    XL_MB_NEGATIVE_1 = "xl:pinata-mb-negative_1",

    XL_MB_NEGATIVE_10 = "xl:pinata-mb-negative_10",

    XL_MB_NEGATIVE_12 = "xl:pinata-mb-negative_12",

    XL_MB_NEGATIVE_14 = "xl:pinata-mb-negative_14",

    XL_MB_NEGATIVE_16 = "xl:pinata-mb-negative_16",

    XL_MB_NEGATIVE_2 = "xl:pinata-mb-negative_2",

    XL_MB_NEGATIVE_20 = "xl:pinata-mb-negative_20",

    XL_MB_NEGATIVE_24 = "xl:pinata-mb-negative_24",

    XL_MB_NEGATIVE_2XL = "xl:pinata-mb-negative_2xl",

    XL_MB_NEGATIVE_3 = "xl:pinata-mb-negative_3",

    XL_MB_NEGATIVE_32 = "xl:pinata-mb-negative_32",

    XL_MB_NEGATIVE_3XL = "xl:pinata-mb-negative_3xl",

    XL_MB_NEGATIVE_4 = "xl:pinata-mb-negative_4",

    XL_MB_NEGATIVE_40 = "xl:pinata-mb-negative_40",

    XL_MB_NEGATIVE_48 = "xl:pinata-mb-negative_48",

    XL_MB_NEGATIVE_4XL = "xl:pinata-mb-negative_4xl",

    XL_MB_NEGATIVE_5 = "xl:pinata-mb-negative_5",

    XL_MB_NEGATIVE_56 = "xl:pinata-mb-negative_56",

    XL_MB_NEGATIVE_5XL = "xl:pinata-mb-negative_5xl",

    XL_MB_NEGATIVE_6 = "xl:pinata-mb-negative_6",

    XL_MB_NEGATIVE_64 = "xl:pinata-mb-negative_64",

    XL_MB_NEGATIVE_6XL = "xl:pinata-mb-negative_6xl",

    XL_MB_NEGATIVE_8 = "xl:pinata-mb-negative_8",

    XL_MB_NEGATIVE_FULL = "xl:pinata-mb-negative_full",

    XL_MB_NEGATIVE_LG = "xl:pinata-mb-negative_lg",

    XL_MB_NEGATIVE_MD = "xl:pinata-mb-negative_md",

    XL_MB_NEGATIVE_PX = "xl:pinata-mb-negative_px",

    XL_MB_NEGATIVE_SM = "xl:pinata-mb-negative_sm",

    XL_MB_NEGATIVE_XL = "xl:pinata-mb-negative_xl",

    XL_MB_NEGATIVE_XS = "xl:pinata-mb-negative_xs",

    XL_MB_PX = "xl:pinata-mb-px",

    XL_MB_SM = "xl:pinata-mb-sm",

    XL_MB_XL = "xl:pinata-mb-xl",

    XL_MB_XS = "xl:pinata-mb-xs",

    XL_ML_0 = "xl:pinata-ml-0",

    XL_ML_1 = "xl:pinata-ml-1",

    XL_ML_10 = "xl:pinata-ml-10",

    XL_ML_12 = "xl:pinata-ml-12",

    XL_ML_14 = "xl:pinata-ml-14",

    XL_ML_16 = "xl:pinata-ml-16",

    XL_ML_2 = "xl:pinata-ml-2",

    XL_ML_20 = "xl:pinata-ml-20",

    XL_ML_24 = "xl:pinata-ml-24",

    XL_ML_2XL = "xl:pinata-ml-2xl",

    XL_ML_3 = "xl:pinata-ml-3",

    XL_ML_32 = "xl:pinata-ml-32",

    XL_ML_3XL = "xl:pinata-ml-3xl",

    XL_ML_4 = "xl:pinata-ml-4",

    XL_ML_40 = "xl:pinata-ml-40",

    XL_ML_48 = "xl:pinata-ml-48",

    XL_ML_4XL = "xl:pinata-ml-4xl",

    XL_ML_5 = "xl:pinata-ml-5",

    XL_ML_56 = "xl:pinata-ml-56",

    XL_ML_5XL = "xl:pinata-ml-5xl",

    XL_ML_6 = "xl:pinata-ml-6",

    XL_ML_64 = "xl:pinata-ml-64",

    XL_ML_6XL = "xl:pinata-ml-6xl",

    XL_ML_8 = "xl:pinata-ml-8",

    XL_ML_AUTO = "xl:pinata-ml-auto",

    XL_ML_FULL = "xl:pinata-ml-full",

    XL_ML_LG = "xl:pinata-ml-lg",

    XL_ML_MD = "xl:pinata-ml-md",

    XL_ML_NEGATIVE_0 = "xl:pinata-ml-negative_0",

    XL_ML_NEGATIVE_1 = "xl:pinata-ml-negative_1",

    XL_ML_NEGATIVE_10 = "xl:pinata-ml-negative_10",

    XL_ML_NEGATIVE_12 = "xl:pinata-ml-negative_12",

    XL_ML_NEGATIVE_14 = "xl:pinata-ml-negative_14",

    XL_ML_NEGATIVE_16 = "xl:pinata-ml-negative_16",

    XL_ML_NEGATIVE_2 = "xl:pinata-ml-negative_2",

    XL_ML_NEGATIVE_20 = "xl:pinata-ml-negative_20",

    XL_ML_NEGATIVE_24 = "xl:pinata-ml-negative_24",

    XL_ML_NEGATIVE_2XL = "xl:pinata-ml-negative_2xl",

    XL_ML_NEGATIVE_3 = "xl:pinata-ml-negative_3",

    XL_ML_NEGATIVE_32 = "xl:pinata-ml-negative_32",

    XL_ML_NEGATIVE_3XL = "xl:pinata-ml-negative_3xl",

    XL_ML_NEGATIVE_4 = "xl:pinata-ml-negative_4",

    XL_ML_NEGATIVE_40 = "xl:pinata-ml-negative_40",

    XL_ML_NEGATIVE_48 = "xl:pinata-ml-negative_48",

    XL_ML_NEGATIVE_4XL = "xl:pinata-ml-negative_4xl",

    XL_ML_NEGATIVE_5 = "xl:pinata-ml-negative_5",

    XL_ML_NEGATIVE_56 = "xl:pinata-ml-negative_56",

    XL_ML_NEGATIVE_5XL = "xl:pinata-ml-negative_5xl",

    XL_ML_NEGATIVE_6 = "xl:pinata-ml-negative_6",

    XL_ML_NEGATIVE_64 = "xl:pinata-ml-negative_64",

    XL_ML_NEGATIVE_6XL = "xl:pinata-ml-negative_6xl",

    XL_ML_NEGATIVE_8 = "xl:pinata-ml-negative_8",

    XL_ML_NEGATIVE_FULL = "xl:pinata-ml-negative_full",

    XL_ML_NEGATIVE_LG = "xl:pinata-ml-negative_lg",

    XL_ML_NEGATIVE_MD = "xl:pinata-ml-negative_md",

    XL_ML_NEGATIVE_PX = "xl:pinata-ml-negative_px",

    XL_ML_NEGATIVE_SM = "xl:pinata-ml-negative_sm",

    XL_ML_NEGATIVE_XL = "xl:pinata-ml-negative_xl",

    XL_ML_NEGATIVE_XS = "xl:pinata-ml-negative_xs",

    XL_ML_PX = "xl:pinata-ml-px",

    XL_ML_SM = "xl:pinata-ml-sm",

    XL_ML_XL = "xl:pinata-ml-xl",

    XL_ML_XS = "xl:pinata-ml-xs",

    XL_MR_0 = "xl:pinata-mr-0",

    XL_MR_1 = "xl:pinata-mr-1",

    XL_MR_10 = "xl:pinata-mr-10",

    XL_MR_12 = "xl:pinata-mr-12",

    XL_MR_14 = "xl:pinata-mr-14",

    XL_MR_16 = "xl:pinata-mr-16",

    XL_MR_2 = "xl:pinata-mr-2",

    XL_MR_20 = "xl:pinata-mr-20",

    XL_MR_24 = "xl:pinata-mr-24",

    XL_MR_2XL = "xl:pinata-mr-2xl",

    XL_MR_3 = "xl:pinata-mr-3",

    XL_MR_32 = "xl:pinata-mr-32",

    XL_MR_3XL = "xl:pinata-mr-3xl",

    XL_MR_4 = "xl:pinata-mr-4",

    XL_MR_40 = "xl:pinata-mr-40",

    XL_MR_48 = "xl:pinata-mr-48",

    XL_MR_4XL = "xl:pinata-mr-4xl",

    XL_MR_5 = "xl:pinata-mr-5",

    XL_MR_56 = "xl:pinata-mr-56",

    XL_MR_5XL = "xl:pinata-mr-5xl",

    XL_MR_6 = "xl:pinata-mr-6",

    XL_MR_64 = "xl:pinata-mr-64",

    XL_MR_6XL = "xl:pinata-mr-6xl",

    XL_MR_8 = "xl:pinata-mr-8",

    XL_MR_AUTO = "xl:pinata-mr-auto",

    XL_MR_FULL = "xl:pinata-mr-full",

    XL_MR_LG = "xl:pinata-mr-lg",

    XL_MR_MD = "xl:pinata-mr-md",

    XL_MR_NEGATIVE_0 = "xl:pinata-mr-negative_0",

    XL_MR_NEGATIVE_1 = "xl:pinata-mr-negative_1",

    XL_MR_NEGATIVE_10 = "xl:pinata-mr-negative_10",

    XL_MR_NEGATIVE_12 = "xl:pinata-mr-negative_12",

    XL_MR_NEGATIVE_14 = "xl:pinata-mr-negative_14",

    XL_MR_NEGATIVE_16 = "xl:pinata-mr-negative_16",

    XL_MR_NEGATIVE_2 = "xl:pinata-mr-negative_2",

    XL_MR_NEGATIVE_20 = "xl:pinata-mr-negative_20",

    XL_MR_NEGATIVE_24 = "xl:pinata-mr-negative_24",

    XL_MR_NEGATIVE_2XL = "xl:pinata-mr-negative_2xl",

    XL_MR_NEGATIVE_3 = "xl:pinata-mr-negative_3",

    XL_MR_NEGATIVE_32 = "xl:pinata-mr-negative_32",

    XL_MR_NEGATIVE_3XL = "xl:pinata-mr-negative_3xl",

    XL_MR_NEGATIVE_4 = "xl:pinata-mr-negative_4",

    XL_MR_NEGATIVE_40 = "xl:pinata-mr-negative_40",

    XL_MR_NEGATIVE_48 = "xl:pinata-mr-negative_48",

    XL_MR_NEGATIVE_4XL = "xl:pinata-mr-negative_4xl",

    XL_MR_NEGATIVE_5 = "xl:pinata-mr-negative_5",

    XL_MR_NEGATIVE_56 = "xl:pinata-mr-negative_56",

    XL_MR_NEGATIVE_5XL = "xl:pinata-mr-negative_5xl",

    XL_MR_NEGATIVE_6 = "xl:pinata-mr-negative_6",

    XL_MR_NEGATIVE_64 = "xl:pinata-mr-negative_64",

    XL_MR_NEGATIVE_6XL = "xl:pinata-mr-negative_6xl",

    XL_MR_NEGATIVE_8 = "xl:pinata-mr-negative_8",

    XL_MR_NEGATIVE_FULL = "xl:pinata-mr-negative_full",

    XL_MR_NEGATIVE_LG = "xl:pinata-mr-negative_lg",

    XL_MR_NEGATIVE_MD = "xl:pinata-mr-negative_md",

    XL_MR_NEGATIVE_PX = "xl:pinata-mr-negative_px",

    XL_MR_NEGATIVE_SM = "xl:pinata-mr-negative_sm",

    XL_MR_NEGATIVE_XL = "xl:pinata-mr-negative_xl",

    XL_MR_NEGATIVE_XS = "xl:pinata-mr-negative_xs",

    XL_MR_PX = "xl:pinata-mr-px",

    XL_MR_SM = "xl:pinata-mr-sm",

    XL_MR_XL = "xl:pinata-mr-xl",

    XL_MR_XS = "xl:pinata-mr-xs",

    XL_MT_0 = "xl:pinata-mt-0",

    XL_MT_1 = "xl:pinata-mt-1",

    XL_MT_10 = "xl:pinata-mt-10",

    XL_MT_12 = "xl:pinata-mt-12",

    XL_MT_14 = "xl:pinata-mt-14",

    XL_MT_16 = "xl:pinata-mt-16",

    XL_MT_2 = "xl:pinata-mt-2",

    XL_MT_20 = "xl:pinata-mt-20",

    XL_MT_24 = "xl:pinata-mt-24",

    XL_MT_2XL = "xl:pinata-mt-2xl",

    XL_MT_3 = "xl:pinata-mt-3",

    XL_MT_32 = "xl:pinata-mt-32",

    XL_MT_3XL = "xl:pinata-mt-3xl",

    XL_MT_4 = "xl:pinata-mt-4",

    XL_MT_40 = "xl:pinata-mt-40",

    XL_MT_48 = "xl:pinata-mt-48",

    XL_MT_4XL = "xl:pinata-mt-4xl",

    XL_MT_5 = "xl:pinata-mt-5",

    XL_MT_56 = "xl:pinata-mt-56",

    XL_MT_5XL = "xl:pinata-mt-5xl",

    XL_MT_6 = "xl:pinata-mt-6",

    XL_MT_64 = "xl:pinata-mt-64",

    XL_MT_6XL = "xl:pinata-mt-6xl",

    XL_MT_8 = "xl:pinata-mt-8",

    XL_MT_AUTO = "xl:pinata-mt-auto",

    XL_MT_FULL = "xl:pinata-mt-full",

    XL_MT_LG = "xl:pinata-mt-lg",

    XL_MT_MD = "xl:pinata-mt-md",

    XL_MT_NEGATIVE_0 = "xl:pinata-mt-negative_0",

    XL_MT_NEGATIVE_1 = "xl:pinata-mt-negative_1",

    XL_MT_NEGATIVE_10 = "xl:pinata-mt-negative_10",

    XL_MT_NEGATIVE_12 = "xl:pinata-mt-negative_12",

    XL_MT_NEGATIVE_14 = "xl:pinata-mt-negative_14",

    XL_MT_NEGATIVE_16 = "xl:pinata-mt-negative_16",

    XL_MT_NEGATIVE_2 = "xl:pinata-mt-negative_2",

    XL_MT_NEGATIVE_20 = "xl:pinata-mt-negative_20",

    XL_MT_NEGATIVE_24 = "xl:pinata-mt-negative_24",

    XL_MT_NEGATIVE_2XL = "xl:pinata-mt-negative_2xl",

    XL_MT_NEGATIVE_3 = "xl:pinata-mt-negative_3",

    XL_MT_NEGATIVE_32 = "xl:pinata-mt-negative_32",

    XL_MT_NEGATIVE_3XL = "xl:pinata-mt-negative_3xl",

    XL_MT_NEGATIVE_4 = "xl:pinata-mt-negative_4",

    XL_MT_NEGATIVE_40 = "xl:pinata-mt-negative_40",

    XL_MT_NEGATIVE_48 = "xl:pinata-mt-negative_48",

    XL_MT_NEGATIVE_4XL = "xl:pinata-mt-negative_4xl",

    XL_MT_NEGATIVE_5 = "xl:pinata-mt-negative_5",

    XL_MT_NEGATIVE_56 = "xl:pinata-mt-negative_56",

    XL_MT_NEGATIVE_5XL = "xl:pinata-mt-negative_5xl",

    XL_MT_NEGATIVE_6 = "xl:pinata-mt-negative_6",

    XL_MT_NEGATIVE_64 = "xl:pinata-mt-negative_64",

    XL_MT_NEGATIVE_6XL = "xl:pinata-mt-negative_6xl",

    XL_MT_NEGATIVE_8 = "xl:pinata-mt-negative_8",

    XL_MT_NEGATIVE_FULL = "xl:pinata-mt-negative_full",

    XL_MT_NEGATIVE_LG = "xl:pinata-mt-negative_lg",

    XL_MT_NEGATIVE_MD = "xl:pinata-mt-negative_md",

    XL_MT_NEGATIVE_PX = "xl:pinata-mt-negative_px",

    XL_MT_NEGATIVE_SM = "xl:pinata-mt-negative_sm",

    XL_MT_NEGATIVE_XL = "xl:pinata-mt-negative_xl",

    XL_MT_NEGATIVE_XS = "xl:pinata-mt-negative_xs",

    XL_MT_PX = "xl:pinata-mt-px",

    XL_MT_SM = "xl:pinata-mt-sm",

    XL_MT_XL = "xl:pinata-mt-xl",

    XL_MT_XS = "xl:pinata-mt-xs",

    XL_MX_0 = "xl:pinata-mx-0",

    XL_MX_1 = "xl:pinata-mx-1",

    XL_MX_10 = "xl:pinata-mx-10",

    XL_MX_12 = "xl:pinata-mx-12",

    XL_MX_14 = "xl:pinata-mx-14",

    XL_MX_16 = "xl:pinata-mx-16",

    XL_MX_2 = "xl:pinata-mx-2",

    XL_MX_20 = "xl:pinata-mx-20",

    XL_MX_24 = "xl:pinata-mx-24",

    XL_MX_2XL = "xl:pinata-mx-2xl",

    XL_MX_3 = "xl:pinata-mx-3",

    XL_MX_32 = "xl:pinata-mx-32",

    XL_MX_3XL = "xl:pinata-mx-3xl",

    XL_MX_4 = "xl:pinata-mx-4",

    XL_MX_40 = "xl:pinata-mx-40",

    XL_MX_48 = "xl:pinata-mx-48",

    XL_MX_4XL = "xl:pinata-mx-4xl",

    XL_MX_5 = "xl:pinata-mx-5",

    XL_MX_56 = "xl:pinata-mx-56",

    XL_MX_5XL = "xl:pinata-mx-5xl",

    XL_MX_6 = "xl:pinata-mx-6",

    XL_MX_64 = "xl:pinata-mx-64",

    XL_MX_6XL = "xl:pinata-mx-6xl",

    XL_MX_8 = "xl:pinata-mx-8",

    XL_MX_AUTO = "xl:pinata-mx-auto",

    XL_MX_FULL = "xl:pinata-mx-full",

    XL_MX_LG = "xl:pinata-mx-lg",

    XL_MX_MD = "xl:pinata-mx-md",

    XL_MX_NEGATIVE_0 = "xl:pinata-mx-negative_0",

    XL_MX_NEGATIVE_1 = "xl:pinata-mx-negative_1",

    XL_MX_NEGATIVE_10 = "xl:pinata-mx-negative_10",

    XL_MX_NEGATIVE_12 = "xl:pinata-mx-negative_12",

    XL_MX_NEGATIVE_14 = "xl:pinata-mx-negative_14",

    XL_MX_NEGATIVE_16 = "xl:pinata-mx-negative_16",

    XL_MX_NEGATIVE_2 = "xl:pinata-mx-negative_2",

    XL_MX_NEGATIVE_20 = "xl:pinata-mx-negative_20",

    XL_MX_NEGATIVE_24 = "xl:pinata-mx-negative_24",

    XL_MX_NEGATIVE_2XL = "xl:pinata-mx-negative_2xl",

    XL_MX_NEGATIVE_3 = "xl:pinata-mx-negative_3",

    XL_MX_NEGATIVE_32 = "xl:pinata-mx-negative_32",

    XL_MX_NEGATIVE_3XL = "xl:pinata-mx-negative_3xl",

    XL_MX_NEGATIVE_4 = "xl:pinata-mx-negative_4",

    XL_MX_NEGATIVE_40 = "xl:pinata-mx-negative_40",

    XL_MX_NEGATIVE_48 = "xl:pinata-mx-negative_48",

    XL_MX_NEGATIVE_4XL = "xl:pinata-mx-negative_4xl",

    XL_MX_NEGATIVE_5 = "xl:pinata-mx-negative_5",

    XL_MX_NEGATIVE_56 = "xl:pinata-mx-negative_56",

    XL_MX_NEGATIVE_5XL = "xl:pinata-mx-negative_5xl",

    XL_MX_NEGATIVE_6 = "xl:pinata-mx-negative_6",

    XL_MX_NEGATIVE_64 = "xl:pinata-mx-negative_64",

    XL_MX_NEGATIVE_6XL = "xl:pinata-mx-negative_6xl",

    XL_MX_NEGATIVE_8 = "xl:pinata-mx-negative_8",

    XL_MX_NEGATIVE_FULL = "xl:pinata-mx-negative_full",

    XL_MX_NEGATIVE_LG = "xl:pinata-mx-negative_lg",

    XL_MX_NEGATIVE_MD = "xl:pinata-mx-negative_md",

    XL_MX_NEGATIVE_PX = "xl:pinata-mx-negative_px",

    XL_MX_NEGATIVE_SM = "xl:pinata-mx-negative_sm",

    XL_MX_NEGATIVE_XL = "xl:pinata-mx-negative_xl",

    XL_MX_NEGATIVE_XS = "xl:pinata-mx-negative_xs",

    XL_MX_PX = "xl:pinata-mx-px",

    XL_MX_SM = "xl:pinata-mx-sm",

    XL_MX_XL = "xl:pinata-mx-xl",

    XL_MX_XS = "xl:pinata-mx-xs",

    XL_MY_0 = "xl:pinata-my-0",

    XL_MY_1 = "xl:pinata-my-1",

    XL_MY_10 = "xl:pinata-my-10",

    XL_MY_12 = "xl:pinata-my-12",

    XL_MY_14 = "xl:pinata-my-14",

    XL_MY_16 = "xl:pinata-my-16",

    XL_MY_2 = "xl:pinata-my-2",

    XL_MY_20 = "xl:pinata-my-20",

    XL_MY_24 = "xl:pinata-my-24",

    XL_MY_2XL = "xl:pinata-my-2xl",

    XL_MY_3 = "xl:pinata-my-3",

    XL_MY_32 = "xl:pinata-my-32",

    XL_MY_3XL = "xl:pinata-my-3xl",

    XL_MY_4 = "xl:pinata-my-4",

    XL_MY_40 = "xl:pinata-my-40",

    XL_MY_48 = "xl:pinata-my-48",

    XL_MY_4XL = "xl:pinata-my-4xl",

    XL_MY_5 = "xl:pinata-my-5",

    XL_MY_56 = "xl:pinata-my-56",

    XL_MY_5XL = "xl:pinata-my-5xl",

    XL_MY_6 = "xl:pinata-my-6",

    XL_MY_64 = "xl:pinata-my-64",

    XL_MY_6XL = "xl:pinata-my-6xl",

    XL_MY_8 = "xl:pinata-my-8",

    XL_MY_AUTO = "xl:pinata-my-auto",

    XL_MY_FULL = "xl:pinata-my-full",

    XL_MY_LG = "xl:pinata-my-lg",

    XL_MY_MD = "xl:pinata-my-md",

    XL_MY_NEGATIVE_0 = "xl:pinata-my-negative_0",

    XL_MY_NEGATIVE_1 = "xl:pinata-my-negative_1",

    XL_MY_NEGATIVE_10 = "xl:pinata-my-negative_10",

    XL_MY_NEGATIVE_12 = "xl:pinata-my-negative_12",

    XL_MY_NEGATIVE_14 = "xl:pinata-my-negative_14",

    XL_MY_NEGATIVE_16 = "xl:pinata-my-negative_16",

    XL_MY_NEGATIVE_2 = "xl:pinata-my-negative_2",

    XL_MY_NEGATIVE_20 = "xl:pinata-my-negative_20",

    XL_MY_NEGATIVE_24 = "xl:pinata-my-negative_24",

    XL_MY_NEGATIVE_2XL = "xl:pinata-my-negative_2xl",

    XL_MY_NEGATIVE_3 = "xl:pinata-my-negative_3",

    XL_MY_NEGATIVE_32 = "xl:pinata-my-negative_32",

    XL_MY_NEGATIVE_3XL = "xl:pinata-my-negative_3xl",

    XL_MY_NEGATIVE_4 = "xl:pinata-my-negative_4",

    XL_MY_NEGATIVE_40 = "xl:pinata-my-negative_40",

    XL_MY_NEGATIVE_48 = "xl:pinata-my-negative_48",

    XL_MY_NEGATIVE_4XL = "xl:pinata-my-negative_4xl",

    XL_MY_NEGATIVE_5 = "xl:pinata-my-negative_5",

    XL_MY_NEGATIVE_56 = "xl:pinata-my-negative_56",

    XL_MY_NEGATIVE_5XL = "xl:pinata-my-negative_5xl",

    XL_MY_NEGATIVE_6 = "xl:pinata-my-negative_6",

    XL_MY_NEGATIVE_64 = "xl:pinata-my-negative_64",

    XL_MY_NEGATIVE_6XL = "xl:pinata-my-negative_6xl",

    XL_MY_NEGATIVE_8 = "xl:pinata-my-negative_8",

    XL_MY_NEGATIVE_FULL = "xl:pinata-my-negative_full",

    XL_MY_NEGATIVE_LG = "xl:pinata-my-negative_lg",

    XL_MY_NEGATIVE_MD = "xl:pinata-my-negative_md",

    XL_MY_NEGATIVE_PX = "xl:pinata-my-negative_px",

    XL_MY_NEGATIVE_SM = "xl:pinata-my-negative_sm",

    XL_MY_NEGATIVE_XL = "xl:pinata-my-negative_xl",

    XL_MY_NEGATIVE_XS = "xl:pinata-my-negative_xs",

    XL_MY_PX = "xl:pinata-my-px",

    XL_MY_SM = "xl:pinata-my-sm",

    XL_MY_XL = "xl:pinata-my-xl",

    XL_MY_XS = "xl:pinata-my-xs",

    XS_M_0 = "xs:pinata-m-0",

    XS_M_1 = "xs:pinata-m-1",

    XS_M_10 = "xs:pinata-m-10",

    XS_M_12 = "xs:pinata-m-12",

    XS_M_14 = "xs:pinata-m-14",

    XS_M_16 = "xs:pinata-m-16",

    XS_M_2 = "xs:pinata-m-2",

    XS_M_20 = "xs:pinata-m-20",

    XS_M_24 = "xs:pinata-m-24",

    XS_M_2XL = "xs:pinata-m-2xl",

    XS_M_3 = "xs:pinata-m-3",

    XS_M_32 = "xs:pinata-m-32",

    XS_M_3XL = "xs:pinata-m-3xl",

    XS_M_4 = "xs:pinata-m-4",

    XS_M_40 = "xs:pinata-m-40",

    XS_M_48 = "xs:pinata-m-48",

    XS_M_4XL = "xs:pinata-m-4xl",

    XS_M_5 = "xs:pinata-m-5",

    XS_M_56 = "xs:pinata-m-56",

    XS_M_5XL = "xs:pinata-m-5xl",

    XS_M_6 = "xs:pinata-m-6",

    XS_M_64 = "xs:pinata-m-64",

    XS_M_6XL = "xs:pinata-m-6xl",

    XS_M_8 = "xs:pinata-m-8",

    XS_M_AUTO = "xs:pinata-m-auto",

    XS_M_FULL = "xs:pinata-m-full",

    XS_M_LG = "xs:pinata-m-lg",

    XS_M_MD = "xs:pinata-m-md",

    XS_M_NEGATIVE_0 = "xs:pinata-m-negative_0",

    XS_M_NEGATIVE_1 = "xs:pinata-m-negative_1",

    XS_M_NEGATIVE_10 = "xs:pinata-m-negative_10",

    XS_M_NEGATIVE_12 = "xs:pinata-m-negative_12",

    XS_M_NEGATIVE_14 = "xs:pinata-m-negative_14",

    XS_M_NEGATIVE_16 = "xs:pinata-m-negative_16",

    XS_M_NEGATIVE_2 = "xs:pinata-m-negative_2",

    XS_M_NEGATIVE_20 = "xs:pinata-m-negative_20",

    XS_M_NEGATIVE_24 = "xs:pinata-m-negative_24",

    XS_M_NEGATIVE_2XL = "xs:pinata-m-negative_2xl",

    XS_M_NEGATIVE_3 = "xs:pinata-m-negative_3",

    XS_M_NEGATIVE_32 = "xs:pinata-m-negative_32",

    XS_M_NEGATIVE_3XL = "xs:pinata-m-negative_3xl",

    XS_M_NEGATIVE_4 = "xs:pinata-m-negative_4",

    XS_M_NEGATIVE_40 = "xs:pinata-m-negative_40",

    XS_M_NEGATIVE_48 = "xs:pinata-m-negative_48",

    XS_M_NEGATIVE_4XL = "xs:pinata-m-negative_4xl",

    XS_M_NEGATIVE_5 = "xs:pinata-m-negative_5",

    XS_M_NEGATIVE_56 = "xs:pinata-m-negative_56",

    XS_M_NEGATIVE_5XL = "xs:pinata-m-negative_5xl",

    XS_M_NEGATIVE_6 = "xs:pinata-m-negative_6",

    XS_M_NEGATIVE_64 = "xs:pinata-m-negative_64",

    XS_M_NEGATIVE_6XL = "xs:pinata-m-negative_6xl",

    XS_M_NEGATIVE_8 = "xs:pinata-m-negative_8",

    XS_M_NEGATIVE_FULL = "xs:pinata-m-negative_full",

    XS_M_NEGATIVE_LG = "xs:pinata-m-negative_lg",

    XS_M_NEGATIVE_MD = "xs:pinata-m-negative_md",

    XS_M_NEGATIVE_PX = "xs:pinata-m-negative_px",

    XS_M_NEGATIVE_SM = "xs:pinata-m-negative_sm",

    XS_M_NEGATIVE_XL = "xs:pinata-m-negative_xl",

    XS_M_NEGATIVE_XS = "xs:pinata-m-negative_xs",

    XS_M_PX = "xs:pinata-m-px",

    XS_M_SM = "xs:pinata-m-sm",

    XS_M_XL = "xs:pinata-m-xl",

    XS_M_XS = "xs:pinata-m-xs",

    XS_MB_0 = "xs:pinata-mb-0",

    XS_MB_1 = "xs:pinata-mb-1",

    XS_MB_10 = "xs:pinata-mb-10",

    XS_MB_12 = "xs:pinata-mb-12",

    XS_MB_14 = "xs:pinata-mb-14",

    XS_MB_16 = "xs:pinata-mb-16",

    XS_MB_2 = "xs:pinata-mb-2",

    XS_MB_20 = "xs:pinata-mb-20",

    XS_MB_24 = "xs:pinata-mb-24",

    XS_MB_2XL = "xs:pinata-mb-2xl",

    XS_MB_3 = "xs:pinata-mb-3",

    XS_MB_32 = "xs:pinata-mb-32",

    XS_MB_3XL = "xs:pinata-mb-3xl",

    XS_MB_4 = "xs:pinata-mb-4",

    XS_MB_40 = "xs:pinata-mb-40",

    XS_MB_48 = "xs:pinata-mb-48",

    XS_MB_4XL = "xs:pinata-mb-4xl",

    XS_MB_5 = "xs:pinata-mb-5",

    XS_MB_56 = "xs:pinata-mb-56",

    XS_MB_5XL = "xs:pinata-mb-5xl",

    XS_MB_6 = "xs:pinata-mb-6",

    XS_MB_64 = "xs:pinata-mb-64",

    XS_MB_6XL = "xs:pinata-mb-6xl",

    XS_MB_8 = "xs:pinata-mb-8",

    XS_MB_AUTO = "xs:pinata-mb-auto",

    XS_MB_FULL = "xs:pinata-mb-full",

    XS_MB_LG = "xs:pinata-mb-lg",

    XS_MB_MD = "xs:pinata-mb-md",

    XS_MB_NEGATIVE_0 = "xs:pinata-mb-negative_0",

    XS_MB_NEGATIVE_1 = "xs:pinata-mb-negative_1",

    XS_MB_NEGATIVE_10 = "xs:pinata-mb-negative_10",

    XS_MB_NEGATIVE_12 = "xs:pinata-mb-negative_12",

    XS_MB_NEGATIVE_14 = "xs:pinata-mb-negative_14",

    XS_MB_NEGATIVE_16 = "xs:pinata-mb-negative_16",

    XS_MB_NEGATIVE_2 = "xs:pinata-mb-negative_2",

    XS_MB_NEGATIVE_20 = "xs:pinata-mb-negative_20",

    XS_MB_NEGATIVE_24 = "xs:pinata-mb-negative_24",

    XS_MB_NEGATIVE_2XL = "xs:pinata-mb-negative_2xl",

    XS_MB_NEGATIVE_3 = "xs:pinata-mb-negative_3",

    XS_MB_NEGATIVE_32 = "xs:pinata-mb-negative_32",

    XS_MB_NEGATIVE_3XL = "xs:pinata-mb-negative_3xl",

    XS_MB_NEGATIVE_4 = "xs:pinata-mb-negative_4",

    XS_MB_NEGATIVE_40 = "xs:pinata-mb-negative_40",

    XS_MB_NEGATIVE_48 = "xs:pinata-mb-negative_48",

    XS_MB_NEGATIVE_4XL = "xs:pinata-mb-negative_4xl",

    XS_MB_NEGATIVE_5 = "xs:pinata-mb-negative_5",

    XS_MB_NEGATIVE_56 = "xs:pinata-mb-negative_56",

    XS_MB_NEGATIVE_5XL = "xs:pinata-mb-negative_5xl",

    XS_MB_NEGATIVE_6 = "xs:pinata-mb-negative_6",

    XS_MB_NEGATIVE_64 = "xs:pinata-mb-negative_64",

    XS_MB_NEGATIVE_6XL = "xs:pinata-mb-negative_6xl",

    XS_MB_NEGATIVE_8 = "xs:pinata-mb-negative_8",

    XS_MB_NEGATIVE_FULL = "xs:pinata-mb-negative_full",

    XS_MB_NEGATIVE_LG = "xs:pinata-mb-negative_lg",

    XS_MB_NEGATIVE_MD = "xs:pinata-mb-negative_md",

    XS_MB_NEGATIVE_PX = "xs:pinata-mb-negative_px",

    XS_MB_NEGATIVE_SM = "xs:pinata-mb-negative_sm",

    XS_MB_NEGATIVE_XL = "xs:pinata-mb-negative_xl",

    XS_MB_NEGATIVE_XS = "xs:pinata-mb-negative_xs",

    XS_MB_PX = "xs:pinata-mb-px",

    XS_MB_SM = "xs:pinata-mb-sm",

    XS_MB_XL = "xs:pinata-mb-xl",

    XS_MB_XS = "xs:pinata-mb-xs",

    XS_ML_0 = "xs:pinata-ml-0",

    XS_ML_1 = "xs:pinata-ml-1",

    XS_ML_10 = "xs:pinata-ml-10",

    XS_ML_12 = "xs:pinata-ml-12",

    XS_ML_14 = "xs:pinata-ml-14",

    XS_ML_16 = "xs:pinata-ml-16",

    XS_ML_2 = "xs:pinata-ml-2",

    XS_ML_20 = "xs:pinata-ml-20",

    XS_ML_24 = "xs:pinata-ml-24",

    XS_ML_2XL = "xs:pinata-ml-2xl",

    XS_ML_3 = "xs:pinata-ml-3",

    XS_ML_32 = "xs:pinata-ml-32",

    XS_ML_3XL = "xs:pinata-ml-3xl",

    XS_ML_4 = "xs:pinata-ml-4",

    XS_ML_40 = "xs:pinata-ml-40",

    XS_ML_48 = "xs:pinata-ml-48",

    XS_ML_4XL = "xs:pinata-ml-4xl",

    XS_ML_5 = "xs:pinata-ml-5",

    XS_ML_56 = "xs:pinata-ml-56",

    XS_ML_5XL = "xs:pinata-ml-5xl",

    XS_ML_6 = "xs:pinata-ml-6",

    XS_ML_64 = "xs:pinata-ml-64",

    XS_ML_6XL = "xs:pinata-ml-6xl",

    XS_ML_8 = "xs:pinata-ml-8",

    XS_ML_AUTO = "xs:pinata-ml-auto",

    XS_ML_FULL = "xs:pinata-ml-full",

    XS_ML_LG = "xs:pinata-ml-lg",

    XS_ML_MD = "xs:pinata-ml-md",

    XS_ML_NEGATIVE_0 = "xs:pinata-ml-negative_0",

    XS_ML_NEGATIVE_1 = "xs:pinata-ml-negative_1",

    XS_ML_NEGATIVE_10 = "xs:pinata-ml-negative_10",

    XS_ML_NEGATIVE_12 = "xs:pinata-ml-negative_12",

    XS_ML_NEGATIVE_14 = "xs:pinata-ml-negative_14",

    XS_ML_NEGATIVE_16 = "xs:pinata-ml-negative_16",

    XS_ML_NEGATIVE_2 = "xs:pinata-ml-negative_2",

    XS_ML_NEGATIVE_20 = "xs:pinata-ml-negative_20",

    XS_ML_NEGATIVE_24 = "xs:pinata-ml-negative_24",

    XS_ML_NEGATIVE_2XL = "xs:pinata-ml-negative_2xl",

    XS_ML_NEGATIVE_3 = "xs:pinata-ml-negative_3",

    XS_ML_NEGATIVE_32 = "xs:pinata-ml-negative_32",

    XS_ML_NEGATIVE_3XL = "xs:pinata-ml-negative_3xl",

    XS_ML_NEGATIVE_4 = "xs:pinata-ml-negative_4",

    XS_ML_NEGATIVE_40 = "xs:pinata-ml-negative_40",

    XS_ML_NEGATIVE_48 = "xs:pinata-ml-negative_48",

    XS_ML_NEGATIVE_4XL = "xs:pinata-ml-negative_4xl",

    XS_ML_NEGATIVE_5 = "xs:pinata-ml-negative_5",

    XS_ML_NEGATIVE_56 = "xs:pinata-ml-negative_56",

    XS_ML_NEGATIVE_5XL = "xs:pinata-ml-negative_5xl",

    XS_ML_NEGATIVE_6 = "xs:pinata-ml-negative_6",

    XS_ML_NEGATIVE_64 = "xs:pinata-ml-negative_64",

    XS_ML_NEGATIVE_6XL = "xs:pinata-ml-negative_6xl",

    XS_ML_NEGATIVE_8 = "xs:pinata-ml-negative_8",

    XS_ML_NEGATIVE_FULL = "xs:pinata-ml-negative_full",

    XS_ML_NEGATIVE_LG = "xs:pinata-ml-negative_lg",

    XS_ML_NEGATIVE_MD = "xs:pinata-ml-negative_md",

    XS_ML_NEGATIVE_PX = "xs:pinata-ml-negative_px",

    XS_ML_NEGATIVE_SM = "xs:pinata-ml-negative_sm",

    XS_ML_NEGATIVE_XL = "xs:pinata-ml-negative_xl",

    XS_ML_NEGATIVE_XS = "xs:pinata-ml-negative_xs",

    XS_ML_PX = "xs:pinata-ml-px",

    XS_ML_SM = "xs:pinata-ml-sm",

    XS_ML_XL = "xs:pinata-ml-xl",

    XS_ML_XS = "xs:pinata-ml-xs",

    XS_MR_0 = "xs:pinata-mr-0",

    XS_MR_1 = "xs:pinata-mr-1",

    XS_MR_10 = "xs:pinata-mr-10",

    XS_MR_12 = "xs:pinata-mr-12",

    XS_MR_14 = "xs:pinata-mr-14",

    XS_MR_16 = "xs:pinata-mr-16",

    XS_MR_2 = "xs:pinata-mr-2",

    XS_MR_20 = "xs:pinata-mr-20",

    XS_MR_24 = "xs:pinata-mr-24",

    XS_MR_2XL = "xs:pinata-mr-2xl",

    XS_MR_3 = "xs:pinata-mr-3",

    XS_MR_32 = "xs:pinata-mr-32",

    XS_MR_3XL = "xs:pinata-mr-3xl",

    XS_MR_4 = "xs:pinata-mr-4",

    XS_MR_40 = "xs:pinata-mr-40",

    XS_MR_48 = "xs:pinata-mr-48",

    XS_MR_4XL = "xs:pinata-mr-4xl",

    XS_MR_5 = "xs:pinata-mr-5",

    XS_MR_56 = "xs:pinata-mr-56",

    XS_MR_5XL = "xs:pinata-mr-5xl",

    XS_MR_6 = "xs:pinata-mr-6",

    XS_MR_64 = "xs:pinata-mr-64",

    XS_MR_6XL = "xs:pinata-mr-6xl",

    XS_MR_8 = "xs:pinata-mr-8",

    XS_MR_AUTO = "xs:pinata-mr-auto",

    XS_MR_FULL = "xs:pinata-mr-full",

    XS_MR_LG = "xs:pinata-mr-lg",

    XS_MR_MD = "xs:pinata-mr-md",

    XS_MR_NEGATIVE_0 = "xs:pinata-mr-negative_0",

    XS_MR_NEGATIVE_1 = "xs:pinata-mr-negative_1",

    XS_MR_NEGATIVE_10 = "xs:pinata-mr-negative_10",

    XS_MR_NEGATIVE_12 = "xs:pinata-mr-negative_12",

    XS_MR_NEGATIVE_14 = "xs:pinata-mr-negative_14",

    XS_MR_NEGATIVE_16 = "xs:pinata-mr-negative_16",

    XS_MR_NEGATIVE_2 = "xs:pinata-mr-negative_2",

    XS_MR_NEGATIVE_20 = "xs:pinata-mr-negative_20",

    XS_MR_NEGATIVE_24 = "xs:pinata-mr-negative_24",

    XS_MR_NEGATIVE_2XL = "xs:pinata-mr-negative_2xl",

    XS_MR_NEGATIVE_3 = "xs:pinata-mr-negative_3",

    XS_MR_NEGATIVE_32 = "xs:pinata-mr-negative_32",

    XS_MR_NEGATIVE_3XL = "xs:pinata-mr-negative_3xl",

    XS_MR_NEGATIVE_4 = "xs:pinata-mr-negative_4",

    XS_MR_NEGATIVE_40 = "xs:pinata-mr-negative_40",

    XS_MR_NEGATIVE_48 = "xs:pinata-mr-negative_48",

    XS_MR_NEGATIVE_4XL = "xs:pinata-mr-negative_4xl",

    XS_MR_NEGATIVE_5 = "xs:pinata-mr-negative_5",

    XS_MR_NEGATIVE_56 = "xs:pinata-mr-negative_56",

    XS_MR_NEGATIVE_5XL = "xs:pinata-mr-negative_5xl",

    XS_MR_NEGATIVE_6 = "xs:pinata-mr-negative_6",

    XS_MR_NEGATIVE_64 = "xs:pinata-mr-negative_64",

    XS_MR_NEGATIVE_6XL = "xs:pinata-mr-negative_6xl",

    XS_MR_NEGATIVE_8 = "xs:pinata-mr-negative_8",

    XS_MR_NEGATIVE_FULL = "xs:pinata-mr-negative_full",

    XS_MR_NEGATIVE_LG = "xs:pinata-mr-negative_lg",

    XS_MR_NEGATIVE_MD = "xs:pinata-mr-negative_md",

    XS_MR_NEGATIVE_PX = "xs:pinata-mr-negative_px",

    XS_MR_NEGATIVE_SM = "xs:pinata-mr-negative_sm",

    XS_MR_NEGATIVE_XL = "xs:pinata-mr-negative_xl",

    XS_MR_NEGATIVE_XS = "xs:pinata-mr-negative_xs",

    XS_MR_PX = "xs:pinata-mr-px",

    XS_MR_SM = "xs:pinata-mr-sm",

    XS_MR_XL = "xs:pinata-mr-xl",

    XS_MR_XS = "xs:pinata-mr-xs",

    XS_MT_0 = "xs:pinata-mt-0",

    XS_MT_1 = "xs:pinata-mt-1",

    XS_MT_10 = "xs:pinata-mt-10",

    XS_MT_12 = "xs:pinata-mt-12",

    XS_MT_14 = "xs:pinata-mt-14",

    XS_MT_16 = "xs:pinata-mt-16",

    XS_MT_2 = "xs:pinata-mt-2",

    XS_MT_20 = "xs:pinata-mt-20",

    XS_MT_24 = "xs:pinata-mt-24",

    XS_MT_2XL = "xs:pinata-mt-2xl",

    XS_MT_3 = "xs:pinata-mt-3",

    XS_MT_32 = "xs:pinata-mt-32",

    XS_MT_3XL = "xs:pinata-mt-3xl",

    XS_MT_4 = "xs:pinata-mt-4",

    XS_MT_40 = "xs:pinata-mt-40",

    XS_MT_48 = "xs:pinata-mt-48",

    XS_MT_4XL = "xs:pinata-mt-4xl",

    XS_MT_5 = "xs:pinata-mt-5",

    XS_MT_56 = "xs:pinata-mt-56",

    XS_MT_5XL = "xs:pinata-mt-5xl",

    XS_MT_6 = "xs:pinata-mt-6",

    XS_MT_64 = "xs:pinata-mt-64",

    XS_MT_6XL = "xs:pinata-mt-6xl",

    XS_MT_8 = "xs:pinata-mt-8",

    XS_MT_AUTO = "xs:pinata-mt-auto",

    XS_MT_FULL = "xs:pinata-mt-full",

    XS_MT_LG = "xs:pinata-mt-lg",

    XS_MT_MD = "xs:pinata-mt-md",

    XS_MT_NEGATIVE_0 = "xs:pinata-mt-negative_0",

    XS_MT_NEGATIVE_1 = "xs:pinata-mt-negative_1",

    XS_MT_NEGATIVE_10 = "xs:pinata-mt-negative_10",

    XS_MT_NEGATIVE_12 = "xs:pinata-mt-negative_12",

    XS_MT_NEGATIVE_14 = "xs:pinata-mt-negative_14",

    XS_MT_NEGATIVE_16 = "xs:pinata-mt-negative_16",

    XS_MT_NEGATIVE_2 = "xs:pinata-mt-negative_2",

    XS_MT_NEGATIVE_20 = "xs:pinata-mt-negative_20",

    XS_MT_NEGATIVE_24 = "xs:pinata-mt-negative_24",

    XS_MT_NEGATIVE_2XL = "xs:pinata-mt-negative_2xl",

    XS_MT_NEGATIVE_3 = "xs:pinata-mt-negative_3",

    XS_MT_NEGATIVE_32 = "xs:pinata-mt-negative_32",

    XS_MT_NEGATIVE_3XL = "xs:pinata-mt-negative_3xl",

    XS_MT_NEGATIVE_4 = "xs:pinata-mt-negative_4",

    XS_MT_NEGATIVE_40 = "xs:pinata-mt-negative_40",

    XS_MT_NEGATIVE_48 = "xs:pinata-mt-negative_48",

    XS_MT_NEGATIVE_4XL = "xs:pinata-mt-negative_4xl",

    XS_MT_NEGATIVE_5 = "xs:pinata-mt-negative_5",

    XS_MT_NEGATIVE_56 = "xs:pinata-mt-negative_56",

    XS_MT_NEGATIVE_5XL = "xs:pinata-mt-negative_5xl",

    XS_MT_NEGATIVE_6 = "xs:pinata-mt-negative_6",

    XS_MT_NEGATIVE_64 = "xs:pinata-mt-negative_64",

    XS_MT_NEGATIVE_6XL = "xs:pinata-mt-negative_6xl",

    XS_MT_NEGATIVE_8 = "xs:pinata-mt-negative_8",

    XS_MT_NEGATIVE_FULL = "xs:pinata-mt-negative_full",

    XS_MT_NEGATIVE_LG = "xs:pinata-mt-negative_lg",

    XS_MT_NEGATIVE_MD = "xs:pinata-mt-negative_md",

    XS_MT_NEGATIVE_PX = "xs:pinata-mt-negative_px",

    XS_MT_NEGATIVE_SM = "xs:pinata-mt-negative_sm",

    XS_MT_NEGATIVE_XL = "xs:pinata-mt-negative_xl",

    XS_MT_NEGATIVE_XS = "xs:pinata-mt-negative_xs",

    XS_MT_PX = "xs:pinata-mt-px",

    XS_MT_SM = "xs:pinata-mt-sm",

    XS_MT_XL = "xs:pinata-mt-xl",

    XS_MT_XS = "xs:pinata-mt-xs",

    XS_MX_0 = "xs:pinata-mx-0",

    XS_MX_1 = "xs:pinata-mx-1",

    XS_MX_10 = "xs:pinata-mx-10",

    XS_MX_12 = "xs:pinata-mx-12",

    XS_MX_14 = "xs:pinata-mx-14",

    XS_MX_16 = "xs:pinata-mx-16",

    XS_MX_2 = "xs:pinata-mx-2",

    XS_MX_20 = "xs:pinata-mx-20",

    XS_MX_24 = "xs:pinata-mx-24",

    XS_MX_2XL = "xs:pinata-mx-2xl",

    XS_MX_3 = "xs:pinata-mx-3",

    XS_MX_32 = "xs:pinata-mx-32",

    XS_MX_3XL = "xs:pinata-mx-3xl",

    XS_MX_4 = "xs:pinata-mx-4",

    XS_MX_40 = "xs:pinata-mx-40",

    XS_MX_48 = "xs:pinata-mx-48",

    XS_MX_4XL = "xs:pinata-mx-4xl",

    XS_MX_5 = "xs:pinata-mx-5",

    XS_MX_56 = "xs:pinata-mx-56",

    XS_MX_5XL = "xs:pinata-mx-5xl",

    XS_MX_6 = "xs:pinata-mx-6",

    XS_MX_64 = "xs:pinata-mx-64",

    XS_MX_6XL = "xs:pinata-mx-6xl",

    XS_MX_8 = "xs:pinata-mx-8",

    XS_MX_AUTO = "xs:pinata-mx-auto",

    XS_MX_FULL = "xs:pinata-mx-full",

    XS_MX_LG = "xs:pinata-mx-lg",

    XS_MX_MD = "xs:pinata-mx-md",

    XS_MX_NEGATIVE_0 = "xs:pinata-mx-negative_0",

    XS_MX_NEGATIVE_1 = "xs:pinata-mx-negative_1",

    XS_MX_NEGATIVE_10 = "xs:pinata-mx-negative_10",

    XS_MX_NEGATIVE_12 = "xs:pinata-mx-negative_12",

    XS_MX_NEGATIVE_14 = "xs:pinata-mx-negative_14",

    XS_MX_NEGATIVE_16 = "xs:pinata-mx-negative_16",

    XS_MX_NEGATIVE_2 = "xs:pinata-mx-negative_2",

    XS_MX_NEGATIVE_20 = "xs:pinata-mx-negative_20",

    XS_MX_NEGATIVE_24 = "xs:pinata-mx-negative_24",

    XS_MX_NEGATIVE_2XL = "xs:pinata-mx-negative_2xl",

    XS_MX_NEGATIVE_3 = "xs:pinata-mx-negative_3",

    XS_MX_NEGATIVE_32 = "xs:pinata-mx-negative_32",

    XS_MX_NEGATIVE_3XL = "xs:pinata-mx-negative_3xl",

    XS_MX_NEGATIVE_4 = "xs:pinata-mx-negative_4",

    XS_MX_NEGATIVE_40 = "xs:pinata-mx-negative_40",

    XS_MX_NEGATIVE_48 = "xs:pinata-mx-negative_48",

    XS_MX_NEGATIVE_4XL = "xs:pinata-mx-negative_4xl",

    XS_MX_NEGATIVE_5 = "xs:pinata-mx-negative_5",

    XS_MX_NEGATIVE_56 = "xs:pinata-mx-negative_56",

    XS_MX_NEGATIVE_5XL = "xs:pinata-mx-negative_5xl",

    XS_MX_NEGATIVE_6 = "xs:pinata-mx-negative_6",

    XS_MX_NEGATIVE_64 = "xs:pinata-mx-negative_64",

    XS_MX_NEGATIVE_6XL = "xs:pinata-mx-negative_6xl",

    XS_MX_NEGATIVE_8 = "xs:pinata-mx-negative_8",

    XS_MX_NEGATIVE_FULL = "xs:pinata-mx-negative_full",

    XS_MX_NEGATIVE_LG = "xs:pinata-mx-negative_lg",

    XS_MX_NEGATIVE_MD = "xs:pinata-mx-negative_md",

    XS_MX_NEGATIVE_PX = "xs:pinata-mx-negative_px",

    XS_MX_NEGATIVE_SM = "xs:pinata-mx-negative_sm",

    XS_MX_NEGATIVE_XL = "xs:pinata-mx-negative_xl",

    XS_MX_NEGATIVE_XS = "xs:pinata-mx-negative_xs",

    XS_MX_PX = "xs:pinata-mx-px",

    XS_MX_SM = "xs:pinata-mx-sm",

    XS_MX_XL = "xs:pinata-mx-xl",

    XS_MX_XS = "xs:pinata-mx-xs",

    XS_MY_0 = "xs:pinata-my-0",

    XS_MY_1 = "xs:pinata-my-1",

    XS_MY_10 = "xs:pinata-my-10",

    XS_MY_12 = "xs:pinata-my-12",

    XS_MY_14 = "xs:pinata-my-14",

    XS_MY_16 = "xs:pinata-my-16",

    XS_MY_2 = "xs:pinata-my-2",

    XS_MY_20 = "xs:pinata-my-20",

    XS_MY_24 = "xs:pinata-my-24",

    XS_MY_2XL = "xs:pinata-my-2xl",

    XS_MY_3 = "xs:pinata-my-3",

    XS_MY_32 = "xs:pinata-my-32",

    XS_MY_3XL = "xs:pinata-my-3xl",

    XS_MY_4 = "xs:pinata-my-4",

    XS_MY_40 = "xs:pinata-my-40",

    XS_MY_48 = "xs:pinata-my-48",

    XS_MY_4XL = "xs:pinata-my-4xl",

    XS_MY_5 = "xs:pinata-my-5",

    XS_MY_56 = "xs:pinata-my-56",

    XS_MY_5XL = "xs:pinata-my-5xl",

    XS_MY_6 = "xs:pinata-my-6",

    XS_MY_64 = "xs:pinata-my-64",

    XS_MY_6XL = "xs:pinata-my-6xl",

    XS_MY_8 = "xs:pinata-my-8",

    XS_MY_AUTO = "xs:pinata-my-auto",

    XS_MY_FULL = "xs:pinata-my-full",

    XS_MY_LG = "xs:pinata-my-lg",

    XS_MY_MD = "xs:pinata-my-md",

    XS_MY_NEGATIVE_0 = "xs:pinata-my-negative_0",

    XS_MY_NEGATIVE_1 = "xs:pinata-my-negative_1",

    XS_MY_NEGATIVE_10 = "xs:pinata-my-negative_10",

    XS_MY_NEGATIVE_12 = "xs:pinata-my-negative_12",

    XS_MY_NEGATIVE_14 = "xs:pinata-my-negative_14",

    XS_MY_NEGATIVE_16 = "xs:pinata-my-negative_16",

    XS_MY_NEGATIVE_2 = "xs:pinata-my-negative_2",

    XS_MY_NEGATIVE_20 = "xs:pinata-my-negative_20",

    XS_MY_NEGATIVE_24 = "xs:pinata-my-negative_24",

    XS_MY_NEGATIVE_2XL = "xs:pinata-my-negative_2xl",

    XS_MY_NEGATIVE_3 = "xs:pinata-my-negative_3",

    XS_MY_NEGATIVE_32 = "xs:pinata-my-negative_32",

    XS_MY_NEGATIVE_3XL = "xs:pinata-my-negative_3xl",

    XS_MY_NEGATIVE_4 = "xs:pinata-my-negative_4",

    XS_MY_NEGATIVE_40 = "xs:pinata-my-negative_40",

    XS_MY_NEGATIVE_48 = "xs:pinata-my-negative_48",

    XS_MY_NEGATIVE_4XL = "xs:pinata-my-negative_4xl",

    XS_MY_NEGATIVE_5 = "xs:pinata-my-negative_5",

    XS_MY_NEGATIVE_56 = "xs:pinata-my-negative_56",

    XS_MY_NEGATIVE_5XL = "xs:pinata-my-negative_5xl",

    XS_MY_NEGATIVE_6 = "xs:pinata-my-negative_6",

    XS_MY_NEGATIVE_64 = "xs:pinata-my-negative_64",

    XS_MY_NEGATIVE_6XL = "xs:pinata-my-negative_6xl",

    XS_MY_NEGATIVE_8 = "xs:pinata-my-negative_8",

    XS_MY_NEGATIVE_FULL = "xs:pinata-my-negative_full",

    XS_MY_NEGATIVE_LG = "xs:pinata-my-negative_lg",

    XS_MY_NEGATIVE_MD = "xs:pinata-my-negative_md",

    XS_MY_NEGATIVE_PX = "xs:pinata-my-negative_px",

    XS_MY_NEGATIVE_SM = "xs:pinata-my-negative_sm",

    XS_MY_NEGATIVE_XL = "xs:pinata-my-negative_xl",

    XS_MY_NEGATIVE_XS = "xs:pinata-my-negative_xs",

    XS_MY_PX = "xs:pinata-my-px",

    XS_MY_SM = "xs:pinata-my-sm",

    XS_MY_XL = "xs:pinata-my-xl",

    XS_MY_XS = "xs:pinata-my-xs",

    M_0 = "pinata-m-0",

    M_1 = "pinata-m-1",

    M_10 = "pinata-m-10",

    M_12 = "pinata-m-12",

    M_14 = "pinata-m-14",

    M_16 = "pinata-m-16",

    M_2 = "pinata-m-2",

    M_20 = "pinata-m-20",

    M_24 = "pinata-m-24",

    M_2XL = "pinata-m-2xl",

    M_3 = "pinata-m-3",

    M_32 = "pinata-m-32",

    M_3XL = "pinata-m-3xl",

    M_4 = "pinata-m-4",

    M_40 = "pinata-m-40",

    M_48 = "pinata-m-48",

    M_4XL = "pinata-m-4xl",

    M_5 = "pinata-m-5",

    M_56 = "pinata-m-56",

    M_5XL = "pinata-m-5xl",

    M_6 = "pinata-m-6",

    M_64 = "pinata-m-64",

    M_6XL = "pinata-m-6xl",

    M_8 = "pinata-m-8",

    M_AUTO = "pinata-m-auto",

    M_FULL = "pinata-m-full",

    M_LG = "pinata-m-lg",

    M_MD = "pinata-m-md",

    M_NEGATIVE_0 = "pinata-m-negative_0",

    M_NEGATIVE_1 = "pinata-m-negative_1",

    M_NEGATIVE_10 = "pinata-m-negative_10",

    M_NEGATIVE_12 = "pinata-m-negative_12",

    M_NEGATIVE_14 = "pinata-m-negative_14",

    M_NEGATIVE_16 = "pinata-m-negative_16",

    M_NEGATIVE_2 = "pinata-m-negative_2",

    M_NEGATIVE_20 = "pinata-m-negative_20",

    M_NEGATIVE_24 = "pinata-m-negative_24",

    M_NEGATIVE_2XL = "pinata-m-negative_2xl",

    M_NEGATIVE_3 = "pinata-m-negative_3",

    M_NEGATIVE_32 = "pinata-m-negative_32",

    M_NEGATIVE_3XL = "pinata-m-negative_3xl",

    M_NEGATIVE_4 = "pinata-m-negative_4",

    M_NEGATIVE_40 = "pinata-m-negative_40",

    M_NEGATIVE_48 = "pinata-m-negative_48",

    M_NEGATIVE_4XL = "pinata-m-negative_4xl",

    M_NEGATIVE_5 = "pinata-m-negative_5",

    M_NEGATIVE_56 = "pinata-m-negative_56",

    M_NEGATIVE_5XL = "pinata-m-negative_5xl",

    M_NEGATIVE_6 = "pinata-m-negative_6",

    M_NEGATIVE_64 = "pinata-m-negative_64",

    M_NEGATIVE_6XL = "pinata-m-negative_6xl",

    M_NEGATIVE_8 = "pinata-m-negative_8",

    M_NEGATIVE_FULL = "pinata-m-negative_full",

    M_NEGATIVE_LG = "pinata-m-negative_lg",

    M_NEGATIVE_MD = "pinata-m-negative_md",

    M_NEGATIVE_PX = "pinata-m-negative_px",

    M_NEGATIVE_SM = "pinata-m-negative_sm",

    M_NEGATIVE_XL = "pinata-m-negative_xl",

    M_NEGATIVE_XS = "pinata-m-negative_xs",

    M_PX = "pinata-m-px",

    M_SM = "pinata-m-sm",

    M_XL = "pinata-m-xl",

    M_XS = "pinata-m-xs",

    MB_0 = "pinata-mb-0",

    MB_1 = "pinata-mb-1",

    MB_10 = "pinata-mb-10",

    MB_12 = "pinata-mb-12",

    MB_14 = "pinata-mb-14",

    MB_16 = "pinata-mb-16",

    MB_2 = "pinata-mb-2",

    MB_20 = "pinata-mb-20",

    MB_24 = "pinata-mb-24",

    MB_2XL = "pinata-mb-2xl",

    MB_3 = "pinata-mb-3",

    MB_32 = "pinata-mb-32",

    MB_3XL = "pinata-mb-3xl",

    MB_4 = "pinata-mb-4",

    MB_40 = "pinata-mb-40",

    MB_48 = "pinata-mb-48",

    MB_4XL = "pinata-mb-4xl",

    MB_5 = "pinata-mb-5",

    MB_56 = "pinata-mb-56",

    MB_5XL = "pinata-mb-5xl",

    MB_6 = "pinata-mb-6",

    MB_64 = "pinata-mb-64",

    MB_6XL = "pinata-mb-6xl",

    MB_8 = "pinata-mb-8",

    MB_AUTO = "pinata-mb-auto",

    MB_FULL = "pinata-mb-full",

    MB_LG = "pinata-mb-lg",

    MB_MD = "pinata-mb-md",

    MB_NEGATIVE_0 = "pinata-mb-negative_0",

    MB_NEGATIVE_1 = "pinata-mb-negative_1",

    MB_NEGATIVE_10 = "pinata-mb-negative_10",

    MB_NEGATIVE_12 = "pinata-mb-negative_12",

    MB_NEGATIVE_14 = "pinata-mb-negative_14",

    MB_NEGATIVE_16 = "pinata-mb-negative_16",

    MB_NEGATIVE_2 = "pinata-mb-negative_2",

    MB_NEGATIVE_20 = "pinata-mb-negative_20",

    MB_NEGATIVE_24 = "pinata-mb-negative_24",

    MB_NEGATIVE_2XL = "pinata-mb-negative_2xl",

    MB_NEGATIVE_3 = "pinata-mb-negative_3",

    MB_NEGATIVE_32 = "pinata-mb-negative_32",

    MB_NEGATIVE_3XL = "pinata-mb-negative_3xl",

    MB_NEGATIVE_4 = "pinata-mb-negative_4",

    MB_NEGATIVE_40 = "pinata-mb-negative_40",

    MB_NEGATIVE_48 = "pinata-mb-negative_48",

    MB_NEGATIVE_4XL = "pinata-mb-negative_4xl",

    MB_NEGATIVE_5 = "pinata-mb-negative_5",

    MB_NEGATIVE_56 = "pinata-mb-negative_56",

    MB_NEGATIVE_5XL = "pinata-mb-negative_5xl",

    MB_NEGATIVE_6 = "pinata-mb-negative_6",

    MB_NEGATIVE_64 = "pinata-mb-negative_64",

    MB_NEGATIVE_6XL = "pinata-mb-negative_6xl",

    MB_NEGATIVE_8 = "pinata-mb-negative_8",

    MB_NEGATIVE_FULL = "pinata-mb-negative_full",

    MB_NEGATIVE_LG = "pinata-mb-negative_lg",

    MB_NEGATIVE_MD = "pinata-mb-negative_md",

    MB_NEGATIVE_PX = "pinata-mb-negative_px",

    MB_NEGATIVE_SM = "pinata-mb-negative_sm",

    MB_NEGATIVE_XL = "pinata-mb-negative_xl",

    MB_NEGATIVE_XS = "pinata-mb-negative_xs",

    MB_PX = "pinata-mb-px",

    MB_SM = "pinata-mb-sm",

    MB_XL = "pinata-mb-xl",

    MB_XS = "pinata-mb-xs",

    ML_0 = "pinata-ml-0",

    ML_1 = "pinata-ml-1",

    ML_10 = "pinata-ml-10",

    ML_12 = "pinata-ml-12",

    ML_14 = "pinata-ml-14",

    ML_16 = "pinata-ml-16",

    ML_2 = "pinata-ml-2",

    ML_20 = "pinata-ml-20",

    ML_24 = "pinata-ml-24",

    ML_2XL = "pinata-ml-2xl",

    ML_3 = "pinata-ml-3",

    ML_32 = "pinata-ml-32",

    ML_3XL = "pinata-ml-3xl",

    ML_4 = "pinata-ml-4",

    ML_40 = "pinata-ml-40",

    ML_48 = "pinata-ml-48",

    ML_4XL = "pinata-ml-4xl",

    ML_5 = "pinata-ml-5",

    ML_56 = "pinata-ml-56",

    ML_5XL = "pinata-ml-5xl",

    ML_6 = "pinata-ml-6",

    ML_64 = "pinata-ml-64",

    ML_6XL = "pinata-ml-6xl",

    ML_8 = "pinata-ml-8",

    ML_AUTO = "pinata-ml-auto",

    ML_FULL = "pinata-ml-full",

    ML_LG = "pinata-ml-lg",

    ML_MD = "pinata-ml-md",

    ML_NEGATIVE_0 = "pinata-ml-negative_0",

    ML_NEGATIVE_1 = "pinata-ml-negative_1",

    ML_NEGATIVE_10 = "pinata-ml-negative_10",

    ML_NEGATIVE_12 = "pinata-ml-negative_12",

    ML_NEGATIVE_14 = "pinata-ml-negative_14",

    ML_NEGATIVE_16 = "pinata-ml-negative_16",

    ML_NEGATIVE_2 = "pinata-ml-negative_2",

    ML_NEGATIVE_20 = "pinata-ml-negative_20",

    ML_NEGATIVE_24 = "pinata-ml-negative_24",

    ML_NEGATIVE_2XL = "pinata-ml-negative_2xl",

    ML_NEGATIVE_3 = "pinata-ml-negative_3",

    ML_NEGATIVE_32 = "pinata-ml-negative_32",

    ML_NEGATIVE_3XL = "pinata-ml-negative_3xl",

    ML_NEGATIVE_4 = "pinata-ml-negative_4",

    ML_NEGATIVE_40 = "pinata-ml-negative_40",

    ML_NEGATIVE_48 = "pinata-ml-negative_48",

    ML_NEGATIVE_4XL = "pinata-ml-negative_4xl",

    ML_NEGATIVE_5 = "pinata-ml-negative_5",

    ML_NEGATIVE_56 = "pinata-ml-negative_56",

    ML_NEGATIVE_5XL = "pinata-ml-negative_5xl",

    ML_NEGATIVE_6 = "pinata-ml-negative_6",

    ML_NEGATIVE_64 = "pinata-ml-negative_64",

    ML_NEGATIVE_6XL = "pinata-ml-negative_6xl",

    ML_NEGATIVE_8 = "pinata-ml-negative_8",

    ML_NEGATIVE_FULL = "pinata-ml-negative_full",

    ML_NEGATIVE_LG = "pinata-ml-negative_lg",

    ML_NEGATIVE_MD = "pinata-ml-negative_md",

    ML_NEGATIVE_PX = "pinata-ml-negative_px",

    ML_NEGATIVE_SM = "pinata-ml-negative_sm",

    ML_NEGATIVE_XL = "pinata-ml-negative_xl",

    ML_NEGATIVE_XS = "pinata-ml-negative_xs",

    ML_PX = "pinata-ml-px",

    ML_SM = "pinata-ml-sm",

    ML_XL = "pinata-ml-xl",

    ML_XS = "pinata-ml-xs",

    MR_0 = "pinata-mr-0",

    MR_1 = "pinata-mr-1",

    MR_10 = "pinata-mr-10",

    MR_12 = "pinata-mr-12",

    MR_14 = "pinata-mr-14",

    MR_16 = "pinata-mr-16",

    MR_2 = "pinata-mr-2",

    MR_20 = "pinata-mr-20",

    MR_24 = "pinata-mr-24",

    MR_2XL = "pinata-mr-2xl",

    MR_3 = "pinata-mr-3",

    MR_32 = "pinata-mr-32",

    MR_3XL = "pinata-mr-3xl",

    MR_4 = "pinata-mr-4",

    MR_40 = "pinata-mr-40",

    MR_48 = "pinata-mr-48",

    MR_4XL = "pinata-mr-4xl",

    MR_5 = "pinata-mr-5",

    MR_56 = "pinata-mr-56",

    MR_5XL = "pinata-mr-5xl",

    MR_6 = "pinata-mr-6",

    MR_64 = "pinata-mr-64",

    MR_6XL = "pinata-mr-6xl",

    MR_8 = "pinata-mr-8",

    MR_AUTO = "pinata-mr-auto",

    MR_FULL = "pinata-mr-full",

    MR_LG = "pinata-mr-lg",

    MR_MD = "pinata-mr-md",

    MR_NEGATIVE_0 = "pinata-mr-negative_0",

    MR_NEGATIVE_1 = "pinata-mr-negative_1",

    MR_NEGATIVE_10 = "pinata-mr-negative_10",

    MR_NEGATIVE_12 = "pinata-mr-negative_12",

    MR_NEGATIVE_14 = "pinata-mr-negative_14",

    MR_NEGATIVE_16 = "pinata-mr-negative_16",

    MR_NEGATIVE_2 = "pinata-mr-negative_2",

    MR_NEGATIVE_20 = "pinata-mr-negative_20",

    MR_NEGATIVE_24 = "pinata-mr-negative_24",

    MR_NEGATIVE_2XL = "pinata-mr-negative_2xl",

    MR_NEGATIVE_3 = "pinata-mr-negative_3",

    MR_NEGATIVE_32 = "pinata-mr-negative_32",

    MR_NEGATIVE_3XL = "pinata-mr-negative_3xl",

    MR_NEGATIVE_4 = "pinata-mr-negative_4",

    MR_NEGATIVE_40 = "pinata-mr-negative_40",

    MR_NEGATIVE_48 = "pinata-mr-negative_48",

    MR_NEGATIVE_4XL = "pinata-mr-negative_4xl",

    MR_NEGATIVE_5 = "pinata-mr-negative_5",

    MR_NEGATIVE_56 = "pinata-mr-negative_56",

    MR_NEGATIVE_5XL = "pinata-mr-negative_5xl",

    MR_NEGATIVE_6 = "pinata-mr-negative_6",

    MR_NEGATIVE_64 = "pinata-mr-negative_64",

    MR_NEGATIVE_6XL = "pinata-mr-negative_6xl",

    MR_NEGATIVE_8 = "pinata-mr-negative_8",

    MR_NEGATIVE_FULL = "pinata-mr-negative_full",

    MR_NEGATIVE_LG = "pinata-mr-negative_lg",

    MR_NEGATIVE_MD = "pinata-mr-negative_md",

    MR_NEGATIVE_PX = "pinata-mr-negative_px",

    MR_NEGATIVE_SM = "pinata-mr-negative_sm",

    MR_NEGATIVE_XL = "pinata-mr-negative_xl",

    MR_NEGATIVE_XS = "pinata-mr-negative_xs",

    MR_PX = "pinata-mr-px",

    MR_SM = "pinata-mr-sm",

    MR_XL = "pinata-mr-xl",

    MR_XS = "pinata-mr-xs",

    MT_0 = "pinata-mt-0",

    MT_1 = "pinata-mt-1",

    MT_10 = "pinata-mt-10",

    MT_12 = "pinata-mt-12",

    MT_14 = "pinata-mt-14",

    MT_16 = "pinata-mt-16",

    MT_2 = "pinata-mt-2",

    MT_20 = "pinata-mt-20",

    MT_24 = "pinata-mt-24",

    MT_2XL = "pinata-mt-2xl",

    MT_3 = "pinata-mt-3",

    MT_32 = "pinata-mt-32",

    MT_3XL = "pinata-mt-3xl",

    MT_4 = "pinata-mt-4",

    MT_40 = "pinata-mt-40",

    MT_48 = "pinata-mt-48",

    MT_4XL = "pinata-mt-4xl",

    MT_5 = "pinata-mt-5",

    MT_56 = "pinata-mt-56",

    MT_5XL = "pinata-mt-5xl",

    MT_6 = "pinata-mt-6",

    MT_64 = "pinata-mt-64",

    MT_6XL = "pinata-mt-6xl",

    MT_8 = "pinata-mt-8",

    MT_AUTO = "pinata-mt-auto",

    MT_FULL = "pinata-mt-full",

    MT_LG = "pinata-mt-lg",

    MT_MD = "pinata-mt-md",

    MT_NEGATIVE_0 = "pinata-mt-negative_0",

    MT_NEGATIVE_1 = "pinata-mt-negative_1",

    MT_NEGATIVE_10 = "pinata-mt-negative_10",

    MT_NEGATIVE_12 = "pinata-mt-negative_12",

    MT_NEGATIVE_14 = "pinata-mt-negative_14",

    MT_NEGATIVE_16 = "pinata-mt-negative_16",

    MT_NEGATIVE_2 = "pinata-mt-negative_2",

    MT_NEGATIVE_20 = "pinata-mt-negative_20",

    MT_NEGATIVE_24 = "pinata-mt-negative_24",

    MT_NEGATIVE_2XL = "pinata-mt-negative_2xl",

    MT_NEGATIVE_3 = "pinata-mt-negative_3",

    MT_NEGATIVE_32 = "pinata-mt-negative_32",

    MT_NEGATIVE_3XL = "pinata-mt-negative_3xl",

    MT_NEGATIVE_4 = "pinata-mt-negative_4",

    MT_NEGATIVE_40 = "pinata-mt-negative_40",

    MT_NEGATIVE_48 = "pinata-mt-negative_48",

    MT_NEGATIVE_4XL = "pinata-mt-negative_4xl",

    MT_NEGATIVE_5 = "pinata-mt-negative_5",

    MT_NEGATIVE_56 = "pinata-mt-negative_56",

    MT_NEGATIVE_5XL = "pinata-mt-negative_5xl",

    MT_NEGATIVE_6 = "pinata-mt-negative_6",

    MT_NEGATIVE_64 = "pinata-mt-negative_64",

    MT_NEGATIVE_6XL = "pinata-mt-negative_6xl",

    MT_NEGATIVE_8 = "pinata-mt-negative_8",

    MT_NEGATIVE_FULL = "pinata-mt-negative_full",

    MT_NEGATIVE_LG = "pinata-mt-negative_lg",

    MT_NEGATIVE_MD = "pinata-mt-negative_md",

    MT_NEGATIVE_PX = "pinata-mt-negative_px",

    MT_NEGATIVE_SM = "pinata-mt-negative_sm",

    MT_NEGATIVE_XL = "pinata-mt-negative_xl",

    MT_NEGATIVE_XS = "pinata-mt-negative_xs",

    MT_PX = "pinata-mt-px",

    MT_SM = "pinata-mt-sm",

    MT_XL = "pinata-mt-xl",

    MT_XS = "pinata-mt-xs",

    MX_0 = "pinata-mx-0",

    MX_1 = "pinata-mx-1",

    MX_10 = "pinata-mx-10",

    MX_12 = "pinata-mx-12",

    MX_14 = "pinata-mx-14",

    MX_16 = "pinata-mx-16",

    MX_2 = "pinata-mx-2",

    MX_20 = "pinata-mx-20",

    MX_24 = "pinata-mx-24",

    MX_2XL = "pinata-mx-2xl",

    MX_3 = "pinata-mx-3",

    MX_32 = "pinata-mx-32",

    MX_3XL = "pinata-mx-3xl",

    MX_4 = "pinata-mx-4",

    MX_40 = "pinata-mx-40",

    MX_48 = "pinata-mx-48",

    MX_4XL = "pinata-mx-4xl",

    MX_5 = "pinata-mx-5",

    MX_56 = "pinata-mx-56",

    MX_5XL = "pinata-mx-5xl",

    MX_6 = "pinata-mx-6",

    MX_64 = "pinata-mx-64",

    MX_6XL = "pinata-mx-6xl",

    MX_8 = "pinata-mx-8",

    MX_AUTO = "pinata-mx-auto",

    MX_FULL = "pinata-mx-full",

    MX_LG = "pinata-mx-lg",

    MX_MD = "pinata-mx-md",

    MX_NEGATIVE_0 = "pinata-mx-negative_0",

    MX_NEGATIVE_1 = "pinata-mx-negative_1",

    MX_NEGATIVE_10 = "pinata-mx-negative_10",

    MX_NEGATIVE_12 = "pinata-mx-negative_12",

    MX_NEGATIVE_14 = "pinata-mx-negative_14",

    MX_NEGATIVE_16 = "pinata-mx-negative_16",

    MX_NEGATIVE_2 = "pinata-mx-negative_2",

    MX_NEGATIVE_20 = "pinata-mx-negative_20",

    MX_NEGATIVE_24 = "pinata-mx-negative_24",

    MX_NEGATIVE_2XL = "pinata-mx-negative_2xl",

    MX_NEGATIVE_3 = "pinata-mx-negative_3",

    MX_NEGATIVE_32 = "pinata-mx-negative_32",

    MX_NEGATIVE_3XL = "pinata-mx-negative_3xl",

    MX_NEGATIVE_4 = "pinata-mx-negative_4",

    MX_NEGATIVE_40 = "pinata-mx-negative_40",

    MX_NEGATIVE_48 = "pinata-mx-negative_48",

    MX_NEGATIVE_4XL = "pinata-mx-negative_4xl",

    MX_NEGATIVE_5 = "pinata-mx-negative_5",

    MX_NEGATIVE_56 = "pinata-mx-negative_56",

    MX_NEGATIVE_5XL = "pinata-mx-negative_5xl",

    MX_NEGATIVE_6 = "pinata-mx-negative_6",

    MX_NEGATIVE_64 = "pinata-mx-negative_64",

    MX_NEGATIVE_6XL = "pinata-mx-negative_6xl",

    MX_NEGATIVE_8 = "pinata-mx-negative_8",

    MX_NEGATIVE_FULL = "pinata-mx-negative_full",

    MX_NEGATIVE_LG = "pinata-mx-negative_lg",

    MX_NEGATIVE_MD = "pinata-mx-negative_md",

    MX_NEGATIVE_PX = "pinata-mx-negative_px",

    MX_NEGATIVE_SM = "pinata-mx-negative_sm",

    MX_NEGATIVE_XL = "pinata-mx-negative_xl",

    MX_NEGATIVE_XS = "pinata-mx-negative_xs",

    MX_PX = "pinata-mx-px",

    MX_SM = "pinata-mx-sm",

    MX_XL = "pinata-mx-xl",

    MX_XS = "pinata-mx-xs",

    MY_0 = "pinata-my-0",

    MY_1 = "pinata-my-1",

    MY_10 = "pinata-my-10",

    MY_12 = "pinata-my-12",

    MY_14 = "pinata-my-14",

    MY_16 = "pinata-my-16",

    MY_2 = "pinata-my-2",

    MY_20 = "pinata-my-20",

    MY_24 = "pinata-my-24",

    MY_2XL = "pinata-my-2xl",

    MY_3 = "pinata-my-3",

    MY_32 = "pinata-my-32",

    MY_3XL = "pinata-my-3xl",

    MY_4 = "pinata-my-4",

    MY_40 = "pinata-my-40",

    MY_48 = "pinata-my-48",

    MY_4XL = "pinata-my-4xl",

    MY_5 = "pinata-my-5",

    MY_56 = "pinata-my-56",

    MY_5XL = "pinata-my-5xl",

    MY_6 = "pinata-my-6",

    MY_64 = "pinata-my-64",

    MY_6XL = "pinata-my-6xl",

    MY_8 = "pinata-my-8",

    MY_AUTO = "pinata-my-auto",

    MY_FULL = "pinata-my-full",

    MY_LG = "pinata-my-lg",

    MY_MD = "pinata-my-md",

    MY_NEGATIVE_0 = "pinata-my-negative_0",

    MY_NEGATIVE_1 = "pinata-my-negative_1",

    MY_NEGATIVE_10 = "pinata-my-negative_10",

    MY_NEGATIVE_12 = "pinata-my-negative_12",

    MY_NEGATIVE_14 = "pinata-my-negative_14",

    MY_NEGATIVE_16 = "pinata-my-negative_16",

    MY_NEGATIVE_2 = "pinata-my-negative_2",

    MY_NEGATIVE_20 = "pinata-my-negative_20",

    MY_NEGATIVE_24 = "pinata-my-negative_24",

    MY_NEGATIVE_2XL = "pinata-my-negative_2xl",

    MY_NEGATIVE_3 = "pinata-my-negative_3",

    MY_NEGATIVE_32 = "pinata-my-negative_32",

    MY_NEGATIVE_3XL = "pinata-my-negative_3xl",

    MY_NEGATIVE_4 = "pinata-my-negative_4",

    MY_NEGATIVE_40 = "pinata-my-negative_40",

    MY_NEGATIVE_48 = "pinata-my-negative_48",

    MY_NEGATIVE_4XL = "pinata-my-negative_4xl",

    MY_NEGATIVE_5 = "pinata-my-negative_5",

    MY_NEGATIVE_56 = "pinata-my-negative_56",

    MY_NEGATIVE_5XL = "pinata-my-negative_5xl",

    MY_NEGATIVE_6 = "pinata-my-negative_6",

    MY_NEGATIVE_64 = "pinata-my-negative_64",

    MY_NEGATIVE_6XL = "pinata-my-negative_6xl",

    MY_NEGATIVE_8 = "pinata-my-negative_8",

    MY_NEGATIVE_FULL = "pinata-my-negative_full",

    MY_NEGATIVE_LG = "pinata-my-negative_lg",

    MY_NEGATIVE_MD = "pinata-my-negative_md",

    MY_NEGATIVE_PX = "pinata-my-negative_px",

    MY_NEGATIVE_SM = "pinata-my-negative_sm",

    MY_NEGATIVE_XL = "pinata-my-negative_xl",

    MY_NEGATIVE_XS = "pinata-my-negative_xs",

    MY_PX = "pinata-my-px",

    MY_SM = "pinata-my-sm",

    MY_XL = "pinata-my-xl",

    MY_XS = "pinata-my-xs",

}