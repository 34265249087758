///-- THIS FILE IS GENERATED --///
export enum AlignItems {

    LG_ITEMS_BASELINE = "lg:pinata-items-baseline",

    LG_ITEMS_CENTER = "lg:pinata-items-center",

    LG_ITEMS_END = "lg:pinata-items-end",

    LG_ITEMS_START = "lg:pinata-items-start",

    LG_ITEMS_STRETCH = "lg:pinata-items-stretch",

    MD_ITEMS_BASELINE = "md:pinata-items-baseline",

    MD_ITEMS_CENTER = "md:pinata-items-center",

    MD_ITEMS_END = "md:pinata-items-end",

    MD_ITEMS_START = "md:pinata-items-start",

    MD_ITEMS_STRETCH = "md:pinata-items-stretch",

    SM_ITEMS_BASELINE = "sm:pinata-items-baseline",

    SM_ITEMS_CENTER = "sm:pinata-items-center",

    SM_ITEMS_END = "sm:pinata-items-end",

    SM_ITEMS_START = "sm:pinata-items-start",

    SM_ITEMS_STRETCH = "sm:pinata-items-stretch",

    XL_ITEMS_BASELINE = "xl:pinata-items-baseline",

    XL_ITEMS_CENTER = "xl:pinata-items-center",

    XL_ITEMS_END = "xl:pinata-items-end",

    XL_ITEMS_START = "xl:pinata-items-start",

    XL_ITEMS_STRETCH = "xl:pinata-items-stretch",

    XS_ITEMS_BASELINE = "xs:pinata-items-baseline",

    XS_ITEMS_CENTER = "xs:pinata-items-center",

    XS_ITEMS_END = "xs:pinata-items-end",

    XS_ITEMS_START = "xs:pinata-items-start",

    XS_ITEMS_STRETCH = "xs:pinata-items-stretch",

    ITEMS_BASELINE = "pinata-items-baseline",

    ITEMS_CENTER = "pinata-items-center",

    ITEMS_END = "pinata-items-end",

    ITEMS_START = "pinata-items-start",

    ITEMS_STRETCH = "pinata-items-stretch",

}