///-- THIS FILE IS GENERATED --///
export enum FontSmoothing {

    LG_ANTIALIASED = "lg:pinata-antialiased",

    LG_SUBPIXEL_ANTIALIASED = "lg:pinata-subpixel-antialiased",

    MD_ANTIALIASED = "md:pinata-antialiased",

    MD_SUBPIXEL_ANTIALIASED = "md:pinata-subpixel-antialiased",

    SM_ANTIALIASED = "sm:pinata-antialiased",

    SM_SUBPIXEL_ANTIALIASED = "sm:pinata-subpixel-antialiased",

    XL_ANTIALIASED = "xl:pinata-antialiased",

    XL_SUBPIXEL_ANTIALIASED = "xl:pinata-subpixel-antialiased",

    XS_ANTIALIASED = "xs:pinata-antialiased",

    XS_SUBPIXEL_ANTIALIASED = "xs:pinata-subpixel-antialiased",

    ANTIALIASED = "pinata-antialiased",

    SUBPIXEL_ANTIALIASED = "pinata-subpixel-antialiased",

}