/// <reference path="../../@types/global/window.d.ts" />
'use strict';

import window from "global/window";

type Setter = (handle: (...args: any[]) => void, delta?: number) => void;
const setter = (_setter: Setter, _clearer: Clearer, array: string) => {
    return function (callback: () => void, delta: number) {
        const id = _setter((...args) => {
            // @ts-ignore
            _clearer.call(this, id);
            // @ts-ignore
            callback.apply(this, args);
        }, delta);

        // @ts-ignore
        if (!this[array]) {
            // @ts-ignore
            this[array] = [id];
        } else {
            // @ts-ignore
            this[array].push(id);
        }
        return id;
    };
};

type Clearer = (handle: number) => void;
const clearer = (_clearer: Clearer, array: string) => {
    return function (id: number) {
        // @ts-ignore
        if (this[array]) {
            // @ts-ignore
            const index = this[array].indexOf(id);
            if (index !== -1) {
                // @ts-ignore
                this[array].splice(index, 1);
            }
        }
        _clearer(id);
    };
};

const _timeouts = 'Timer_timeouts';
const _clearTimeout: (id: number) => void = () => window && window.clearTimeout ? clearer(window.clearTimeout, _timeouts) : () => 0;
// @ts-ignore
const _setTimeout: (callback: () => any, timeout: number) => number = window && window.setTimeout ? setter(window.setTimeout, _clearTimeout, _timeouts) as any : () => 0;

const _intervals = 'Timer_intervals';
const _clearInterval: (id: number) => void = window && window.clearInterval ? clearer(window.clearInterval, _intervals) : () => void(0);
// @ts-ignore
const _setInterval: (callback: () => any, interval: number) => number = window && window.setInterval ? setter(window.setInterval, () => {/* noop */
}, _intervals) as any : () => 0;

const _rafs = 'Timer_rafs';
const _cancelAnimationFrame: (id: number) => void = window && window.cancelAnimationFrame ? clearer(window.cancelAnimationFrame, _rafs) : () => void(0);
const _requestAnimationFrame: (callback: () => any) => number = window && window.requestAnimationFrame ? setter(window.requestAnimationFrame, _cancelAnimationFrame, _rafs) as any : () => 0;

export class Timer {

    static setTimeout = _setTimeout;
    static clearTimeout = _clearTimeout;

    static setInterval = _setInterval;
    static clearInterval = _clearInterval;

    static requestAnimationFrame = _requestAnimationFrame;
    static cancelAnimationFrame = _cancelAnimationFrame;
}
