///-- THIS FILE IS GENERATED --///
export enum UserSelect {

    LG_SELECT_NONE = "lg:pinata-select-none",

    LG_SELECT_TEXT = "lg:pinata-select-text",

    MD_SELECT_NONE = "md:pinata-select-none",

    MD_SELECT_TEXT = "md:pinata-select-text",

    SM_SELECT_NONE = "sm:pinata-select-none",

    SM_SELECT_TEXT = "sm:pinata-select-text",

    XL_SELECT_NONE = "xl:pinata-select-none",

    XL_SELECT_TEXT = "xl:pinata-select-text",

    XS_SELECT_NONE = "xs:pinata-select-none",

    XS_SELECT_TEXT = "xs:pinata-select-text",

    SELECT_NONE = "pinata-select-none",

    SELECT_TEXT = "pinata-select-text",

}