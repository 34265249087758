///-- THIS FILE IS GENERATED --///
export enum WordBreak {

    LG_BREAK_ALL = "lg:pinata-break-all",

    LG_BREAK_NORMAL = "lg:pinata-break-normal",

    LG_BREAK_WORDS = "lg:pinata-break-words",

    LG_TRUNCATE = "lg:pinata-truncate",

    MD_BREAK_ALL = "md:pinata-break-all",

    MD_BREAK_NORMAL = "md:pinata-break-normal",

    MD_BREAK_WORDS = "md:pinata-break-words",

    MD_TRUNCATE = "md:pinata-truncate",

    SM_BREAK_ALL = "sm:pinata-break-all",

    SM_BREAK_NORMAL = "sm:pinata-break-normal",

    SM_BREAK_WORDS = "sm:pinata-break-words",

    SM_TRUNCATE = "sm:pinata-truncate",

    XL_BREAK_ALL = "xl:pinata-break-all",

    XL_BREAK_NORMAL = "xl:pinata-break-normal",

    XL_BREAK_WORDS = "xl:pinata-break-words",

    XL_TRUNCATE = "xl:pinata-truncate",

    XS_BREAK_ALL = "xs:pinata-break-all",

    XS_BREAK_NORMAL = "xs:pinata-break-normal",

    XS_BREAK_WORDS = "xs:pinata-break-words",

    XS_TRUNCATE = "xs:pinata-truncate",

    BREAK_ALL = "pinata-break-all",

    BREAK_NORMAL = "pinata-break-normal",

    BREAK_WORDS = "pinata-break-words",

    TRUNCATE = "pinata-truncate",

}