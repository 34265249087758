///-- THIS FILE IS GENERATED --///
export enum PlaceholderColor {

    FOCUS_PLACEHOLDER_BLACK = "focus:pinata-placeholder-black",

    FOCUS_PLACEHOLDER_HEADER = "focus:pinata-placeholder-header",

    FOCUS_PLACEHOLDER_INFO = "focus:pinata-placeholder-info",

    FOCUS_PLACEHOLDER_NEGATIVE = "focus:pinata-placeholder-negative",

    FOCUS_PLACEHOLDER_NEUTRAL_1 = "focus:pinata-placeholder-neutral-1",

    FOCUS_PLACEHOLDER_NEUTRAL_2 = "focus:pinata-placeholder-neutral-2",

    FOCUS_PLACEHOLDER_NEUTRAL_3 = "focus:pinata-placeholder-neutral-3",

    FOCUS_PLACEHOLDER_NEUTRAL_4 = "focus:pinata-placeholder-neutral-4",

    FOCUS_PLACEHOLDER_NEUTRAL_5 = "focus:pinata-placeholder-neutral-5",

    FOCUS_PLACEHOLDER_NEUTRAL_6 = "focus:pinata-placeholder-neutral-6",

    FOCUS_PLACEHOLDER_NEUTRAL_7 = "focus:pinata-placeholder-neutral-7",

    FOCUS_PLACEHOLDER_NEUTRAL_8 = "focus:pinata-placeholder-neutral-8",

    FOCUS_PLACEHOLDER_POSITIVE = "focus:pinata-placeholder-positive",

    FOCUS_PLACEHOLDER_PRIMARY_1 = "focus:pinata-placeholder-primary-1",

    FOCUS_PLACEHOLDER_PRIMARY_2 = "focus:pinata-placeholder-primary-2",

    FOCUS_PLACEHOLDER_PRIMARY_3 = "focus:pinata-placeholder-primary-3",

    FOCUS_PLACEHOLDER_PRIMARY_4 = "focus:pinata-placeholder-primary-4",

    FOCUS_PLACEHOLDER_PRIMARY_DARK = "focus:pinata-placeholder-primary-dark",

    FOCUS_PLACEHOLDER_PRIMARY_LIGHT = "focus:pinata-placeholder-primary-light",

    FOCUS_PLACEHOLDER_SECONDARY_1 = "focus:pinata-placeholder-secondary-1",

    FOCUS_PLACEHOLDER_SECONDARY_2 = "focus:pinata-placeholder-secondary-2",

    FOCUS_PLACEHOLDER_SECONDARY_3 = "focus:pinata-placeholder-secondary-3",

    FOCUS_PLACEHOLDER_SECONDARY_4 = "focus:pinata-placeholder-secondary-4",

    FOCUS_PLACEHOLDER_TERTIARY_1 = "focus:pinata-placeholder-tertiary-1",

    FOCUS_PLACEHOLDER_TERTIARY_2 = "focus:pinata-placeholder-tertiary-2",

    FOCUS_PLACEHOLDER_TERTIARY_3 = "focus:pinata-placeholder-tertiary-3",

    FOCUS_PLACEHOLDER_TERTIARY_4 = "focus:pinata-placeholder-tertiary-4",

    FOCUS_PLACEHOLDER_TRANSPARENT = "focus:pinata-placeholder-transparent",

    FOCUS_PLACEHOLDER_WARNING = "focus:pinata-placeholder-warning",

    FOCUS_PLACEHOLDER_WHITE = "focus:pinata-placeholder-white",

    PLACEHOLDER_BLACK = "pinata-placeholder-black",

    PLACEHOLDER_HEADER = "pinata-placeholder-header",

    PLACEHOLDER_INFO = "pinata-placeholder-info",

    PLACEHOLDER_NEGATIVE = "pinata-placeholder-negative",

    PLACEHOLDER_NEUTRAL_1 = "pinata-placeholder-neutral-1",

    PLACEHOLDER_NEUTRAL_2 = "pinata-placeholder-neutral-2",

    PLACEHOLDER_NEUTRAL_3 = "pinata-placeholder-neutral-3",

    PLACEHOLDER_NEUTRAL_4 = "pinata-placeholder-neutral-4",

    PLACEHOLDER_NEUTRAL_5 = "pinata-placeholder-neutral-5",

    PLACEHOLDER_NEUTRAL_6 = "pinata-placeholder-neutral-6",

    PLACEHOLDER_NEUTRAL_7 = "pinata-placeholder-neutral-7",

    PLACEHOLDER_NEUTRAL_8 = "pinata-placeholder-neutral-8",

    PLACEHOLDER_POSITIVE = "pinata-placeholder-positive",

    PLACEHOLDER_PRIMARY_1 = "pinata-placeholder-primary-1",

    PLACEHOLDER_PRIMARY_2 = "pinata-placeholder-primary-2",

    PLACEHOLDER_PRIMARY_3 = "pinata-placeholder-primary-3",

    PLACEHOLDER_PRIMARY_4 = "pinata-placeholder-primary-4",

    PLACEHOLDER_PRIMARY_DARK = "pinata-placeholder-primary-dark",

    PLACEHOLDER_PRIMARY_LIGHT = "pinata-placeholder-primary-light",

    PLACEHOLDER_SECONDARY_1 = "pinata-placeholder-secondary-1",

    PLACEHOLDER_SECONDARY_2 = "pinata-placeholder-secondary-2",

    PLACEHOLDER_SECONDARY_3 = "pinata-placeholder-secondary-3",

    PLACEHOLDER_SECONDARY_4 = "pinata-placeholder-secondary-4",

    PLACEHOLDER_TERTIARY_1 = "pinata-placeholder-tertiary-1",

    PLACEHOLDER_TERTIARY_2 = "pinata-placeholder-tertiary-2",

    PLACEHOLDER_TERTIARY_3 = "pinata-placeholder-tertiary-3",

    PLACEHOLDER_TERTIARY_4 = "pinata-placeholder-tertiary-4",

    PLACEHOLDER_TRANSPARENT = "pinata-placeholder-transparent",

    PLACEHOLDER_WARNING = "pinata-placeholder-warning",

    PLACEHOLDER_WHITE = "pinata-placeholder-white",

}