import * as React from 'react'
import {
    ChangeEventHandler,
    FocusEventHandler,
    MouseEventHandler,
    RefObject,
    TouchEvent,
    TouchEventHandler,
    useEffect,
    useState
} from 'react'
import {classNames, pointerCoord} from "../../utils";
import {ToggleSize, ToggleSkin} from "./ToggleEnums";
import uniqueId from "lodash-es/uniqueId";
import {Icon, IconColor, IconName} from '../icon';

export * from './ToggleEnums';

export interface IToggleProps extends React.HTMLAttributes<HTMLElement> {
    size?: ToggleSize;
    disabled?: boolean;
    asyncAction?: () => Promise<any>;
    skin?: ToggleSkin;
    checked?: boolean;
    loading?: boolean;
    labelText?: { on: string, off: string };
}

export interface IToggleState {
    checked: boolean;
    hasFocus: boolean;
    operationState?: 'error' | 'loading';
}

const styleClass = {
    root: 'pinata-toggle',
    animateRoot: {
        checked: {
            default: 'pinata-toggle--checked-default',
            start: 'pinata-toggle--checked-start',
            end: 'pinata-toggle--checked-end'
        },
        unchecked: {
            default: 'pinata-toggle--unchecked-default',
            start: 'pinata-toggle--unchecked-start',
            end: 'pinata-toggle--unchecked-end'
        }
    },
    focus: 'pinata-toggle--focus',
    disabled: 'pinata-toggle--disabled',
    track: {
        root: (skin: string) => `pinata-toggle-track pinata-toggle-track-skin--${skin}`,
        check: 'pinata-toggle-track-check',
        unchecked: 'pinata-toggle-track-x',
    },
    thumb: (skin: string) => `pinata-toggle-thumb pinata-toggle-thumb-skin--${skin}`,
    animateThumb: {
        checked: {
            default: 'pinata-toggle-thumb-checked-default',
            start: 'pinata-toggle-thumb-checked-start',
            end: 'pinata-toggle-thumb-checked-end'
        },
        unchecked: {
            default: 'pinata-toggle-thumb-unchecked-default',
            start: 'pinata-toggle-thumb-unchecked-start',
            end: 'pinata-toggle-thumb-unchecked-end'
        }
    },
    srOnly: 'pinata-toggle-screenreader-only',
    label: 'pinata-toggle-label',
    loading: 'pinata-toggle__loading',
    size: (size: ToggleSize) => `pinata-toggle--size-${size}`
};

export const Toggle: React.FunctionComponent<IToggleProps> = (props) => {

    const id: string = uniqueId('toggle_');
    let previouslyChecked: boolean = !!(props.checked || props.defaultChecked);
    let moved: boolean;
    let startX: null | number;
    let activated: boolean;
    const inputRef: RefObject<any> = React.createRef();

    const [state, setState] = useState<IToggleState>({
        checked: !!(props.checked || props.defaultChecked),
        hasFocus: false,
        operationState: props.loading ? 'loading' : undefined
    });
    useEffect(() => {
        let nextState: Partial<IToggleState> | null = null;
        if ('checked' in props) {
            nextState = Object.assign({}, {checked: !!props.checked})
        }
        if ('loading' in props) {
            nextState = Object.assign(nextState || {}, {operationState: props.loading ? 'loading' : undefined})
        }
        if (nextState) {
            setState({
                ...state,
                ...nextState,
            });
        }
    }, [props.checked, props.loading]);

    const labels = (id: string): { checked: React.ReactElement, unchecked: React.ReactElement } => ({
        checked: <span id={id} className={styleClass.label}>{props.labelText!.on}</span>,
        unchecked: <span id={id} className={styleClass.label}>{props.labelText!.off}</span>,
    });

    const onClick: MouseEventHandler<HTMLDivElement> = async (__event) => {
        __event.persist();
        if (isLoading()) return;

        const checkbox = inputRef.current;
        if (__event.target !== checkbox && !moved) {
            previouslyChecked = checkbox.checked;
            __event.preventDefault();
            checkbox.focus();
            checkbox.click();
            return;
        }
        const {checked} = state;
        const {onClick, asyncAction} = props;

        onClick!(__event);

        if (__event.isDefaultPrevented()) {
            return;
        }

        const prevChecked = checked;
        const nextChecked = !checked;
        const operationState = asyncAction ? 'loading' : undefined;

        setState({
            ...state,
            checked: nextChecked,
            operationState,
        });

        if (asyncAction) {
            try {
                await asyncAction();
                setState({...state, operationState: undefined});
            } catch (e) {
                setState({...state, checked: prevChecked, operationState: 'error'});
            }
        }
    };

    const onTouchStart: TouchEventHandler<HTMLDivElement> = (__event) => {
        startX = pointerCoord(__event).x;
        activated = true;
    };

    const onTouchMove: TouchEventHandler<HTMLDivElement> = (__event) => {
        if (!activated) return;
        moved = true;

        if (startX) {
            let currentX = pointerCoord(__event).x;
            if (state.checked && currentX + 15 < startX) {
                setState({...state, checked: false});
                startX = currentX;
                activated = true;
            } else if (currentX - 15 > startX) {
                setState({...state, checked: true});
                startX = currentX;
                activated = (currentX < startX + 5)
            }
        }
    };

    const onTouchEnd = (__event: TouchEvent<HTMLDivElement>) => {

        if (!moved) {
            return;
        }

        const checkbox = inputRef.current;
        __event.preventDefault();

        const {checked} = state;

        if (startX) {
            let endX = pointerCoord(__event).x;
            if (previouslyChecked && startX + 4 > endX) {
                if (previouslyChecked !== checked) {
                    setState({
                        ...state,
                        checked: false,
                    });
                    previouslyChecked = checked;
                    checkbox.click();
                }
            } else if (startX - 4 < endX) {
                if (previouslyChecked !== checked) {
                    setState({
                        ...state,
                        checked: true,
                    });
                    previouslyChecked = checked;
                    checkbox.click();
                }
            }

            activated = false;
            startX = null;
            moved = false;
        }
    };

    const onFocus: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onFocus} = props;

        if (onFocus) {
            onFocus(__event)
        }

        setState({...state, hasFocus: true})
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (__event) => {
        const {onBlur} = props;

        if (onBlur) {
            onBlur(__event)
        }

        setState({...state, hasFocus: false})
    };

    const onChange: ChangeEventHandler<HTMLInputElement> = (__event) => {
        const {onChange} = props;
        __event.preventDefault();
        if (onChange) {
            onChange(__event);
        }
    };

    const getIcon = (type: 'checked' | 'unchecked'): React.ReactElement => {
        return labels(id)[type];
    };

    const isLoading = (): boolean => {
        return state.operationState === 'loading';
    };

    const hasError = (): boolean => {
        return state.operationState === 'error';
    };


    const {skin, className, size, asyncAction, labelText, disabled, loading, ...inputProps} = props;
    const {checked, hasFocus} = state;
    const hasAsyncAction = asyncAction !== undefined;
    const containerClasses = classNames(
        styleClass.root,
        {
            [styleClass.animateRoot.checked.default]: checked && !hasAsyncAction,
            [styleClass.animateRoot.checked.start]: checked && hasAsyncAction,
            [styleClass.animateRoot.checked.end]: checked && !isLoading() && !hasError() && hasAsyncAction || checked && !isLoading() && hasError() && hasAsyncAction,
            [styleClass.animateRoot.unchecked.default]: !checked && !hasAsyncAction,
            [styleClass.animateRoot.unchecked.start]: !checked && !hasError() && hasFocus && hasAsyncAction || !checked && !hasFocus && !hasError() && hasAsyncAction,
            [styleClass.animateRoot.unchecked.end]: !checked && !isLoading() && hasError() && hasAsyncAction || !checked && !isLoading() && !hasError() && hasAsyncAction,
            [styleClass.focus]: hasFocus,
            [styleClass.disabled]: disabled,
            [styleClass.size(size!)]: size
        },
        className
    );

    const thumbClasses = classNames(
        styleClass.thumb(skin!),
        {
            [styleClass.animateThumb.checked.default]: checked && !hasAsyncAction,
            [styleClass.animateThumb.checked.start]: checked && hasAsyncAction,
            [styleClass.animateThumb.checked.end]: checked && !isLoading() && !hasError() && hasAsyncAction || checked && !isLoading() && hasError() && hasAsyncAction,
            [styleClass.animateThumb.unchecked.default]: !checked && !hasAsyncAction,
            [styleClass.animateThumb.unchecked.start]: !checked && !hasError() && hasFocus && hasAsyncAction || !checked && !hasFocus && !hasError() && hasAsyncAction,
            [styleClass.animateThumb.unchecked.end]: !checked && !isLoading() && hasError() && hasAsyncAction || !checked && !isLoading() && !hasError() && hasAsyncAction,
            [styleClass.loading]: isLoading()
        },
        className
    );

    if(size === ToggleSize.SMALL && !inputProps.hasOwnProperty('aria-label')) {
        console.warn('All Toggles of size small must have aria-label with a description of the purpose of the Toggle to improve accessibility.');
    }

    return (
        <div className={classNames('pinata-flex', size === ToggleSize.SMALL ? 'pinata-w-12' : 'pinata-w-16')}>
            <div className={containerClasses}
                 onClick={onClick}
                 onTouchStart={onTouchStart}
                 onTouchMove={onTouchMove}
                 onTouchEnd={onTouchEnd}
            >

                <div className={styleClass.track.root(skin!)}>
                    <div className={styleClass.track.check}>
                        {size === ToggleSize.LARGE && state.checked ? getIcon('checked') : null}
                    </div>
                    <div className={styleClass.track.unchecked}>
                        {size === ToggleSize.LARGE && !state.checked ? getIcon('unchecked') : null}
                    </div>
                    <div className={thumbClasses}>
                        {isLoading() ? <Icon name={IconName.LOADING_WHEEL} color={IconColor.NEUTRAL_4}/> : null}
                    </div>
                </div>

                <input
                    aria-labelledby={size === ToggleSize.LARGE ? id : undefined}
                    {...inputProps}
                    ref={inputRef}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={styleClass.srOnly}
                    disabled={disabled}
                    type='checkbox'/>
            </div>
        </div>
    );
};

Toggle.defaultProps = {
    size: ToggleSize.SMALL,
    skin: ToggleSkin.PRIMARY_3,
    onClick: (__event) => void 0,
    labelText: {on: "PÅ", off: "AV"},
};
Toggle.displayName = 'Toggle';
