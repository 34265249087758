///-- THIS FILE IS GENERATED --///
export enum PointerEvents {

    LG_POINTER_EVENTS_AUTO = "lg:pinata-pointer-events-auto",

    LG_POINTER_EVENTS_NONE = "lg:pinata-pointer-events-none",

    MD_POINTER_EVENTS_AUTO = "md:pinata-pointer-events-auto",

    MD_POINTER_EVENTS_NONE = "md:pinata-pointer-events-none",

    SM_POINTER_EVENTS_AUTO = "sm:pinata-pointer-events-auto",

    SM_POINTER_EVENTS_NONE = "sm:pinata-pointer-events-none",

    XL_POINTER_EVENTS_AUTO = "xl:pinata-pointer-events-auto",

    XL_POINTER_EVENTS_NONE = "xl:pinata-pointer-events-none",

    XS_POINTER_EVENTS_AUTO = "xs:pinata-pointer-events-auto",

    XS_POINTER_EVENTS_NONE = "xs:pinata-pointer-events-none",

    POINTER_EVENTS_AUTO = "pinata-pointer-events-auto",

    POINTER_EVENTS_NONE = "pinata-pointer-events-none",

}