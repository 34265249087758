///-- THIS FILE IS GENERATED --///
export enum FlexGrow {

    LG_FLEX_GROW = "lg:pinata-flex-grow",

    LG_FLEX_GROW_0 = "lg:pinata-flex-grow-0",

    MD_FLEX_GROW = "md:pinata-flex-grow",

    MD_FLEX_GROW_0 = "md:pinata-flex-grow-0",

    SM_FLEX_GROW = "sm:pinata-flex-grow",

    SM_FLEX_GROW_0 = "sm:pinata-flex-grow-0",

    XL_FLEX_GROW = "xl:pinata-flex-grow",

    XL_FLEX_GROW_0 = "xl:pinata-flex-grow-0",

    XS_FLEX_GROW = "xs:pinata-flex-grow",

    XS_FLEX_GROW_0 = "xs:pinata-flex-grow-0",

    FLEX_GROW = "pinata-flex-grow",

    FLEX_GROW_0 = "pinata-flex-grow-0",

}