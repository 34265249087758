///-- THIS FILE IS GENERATED --///
export enum JustifyContent {

    LG_JUSTIFY_AROUND = "lg:pinata-justify-around",

    LG_JUSTIFY_BETWEEN = "lg:pinata-justify-between",

    LG_JUSTIFY_CENTER = "lg:pinata-justify-center",

    LG_JUSTIFY_END = "lg:pinata-justify-end",

    LG_JUSTIFY_START = "lg:pinata-justify-start",

    MD_JUSTIFY_AROUND = "md:pinata-justify-around",

    MD_JUSTIFY_BETWEEN = "md:pinata-justify-between",

    MD_JUSTIFY_CENTER = "md:pinata-justify-center",

    MD_JUSTIFY_END = "md:pinata-justify-end",

    MD_JUSTIFY_START = "md:pinata-justify-start",

    SM_JUSTIFY_AROUND = "sm:pinata-justify-around",

    SM_JUSTIFY_BETWEEN = "sm:pinata-justify-between",

    SM_JUSTIFY_CENTER = "sm:pinata-justify-center",

    SM_JUSTIFY_END = "sm:pinata-justify-end",

    SM_JUSTIFY_START = "sm:pinata-justify-start",

    XL_JUSTIFY_AROUND = "xl:pinata-justify-around",

    XL_JUSTIFY_BETWEEN = "xl:pinata-justify-between",

    XL_JUSTIFY_CENTER = "xl:pinata-justify-center",

    XL_JUSTIFY_END = "xl:pinata-justify-end",

    XL_JUSTIFY_START = "xl:pinata-justify-start",

    XS_JUSTIFY_AROUND = "xs:pinata-justify-around",

    XS_JUSTIFY_BETWEEN = "xs:pinata-justify-between",

    XS_JUSTIFY_CENTER = "xs:pinata-justify-center",

    XS_JUSTIFY_END = "xs:pinata-justify-end",

    XS_JUSTIFY_START = "xs:pinata-justify-start",

    JUSTIFY_AROUND = "pinata-justify-around",

    JUSTIFY_BETWEEN = "pinata-justify-between",

    JUSTIFY_CENTER = "pinata-justify-center",

    JUSTIFY_END = "pinata-justify-end",

    JUSTIFY_START = "pinata-justify-start",

}