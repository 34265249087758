///-- THIS FILE IS GENERATED --///
export enum FlexShrink {

    LG_FLEX_SHRINK = "lg:pinata-flex-shrink",

    LG_FLEX_SHRINK_0 = "lg:pinata-flex-shrink-0",

    MD_FLEX_SHRINK = "md:pinata-flex-shrink",

    MD_FLEX_SHRINK_0 = "md:pinata-flex-shrink-0",

    SM_FLEX_SHRINK = "sm:pinata-flex-shrink",

    SM_FLEX_SHRINK_0 = "sm:pinata-flex-shrink-0",

    XL_FLEX_SHRINK = "xl:pinata-flex-shrink",

    XL_FLEX_SHRINK_0 = "xl:pinata-flex-shrink-0",

    XS_FLEX_SHRINK = "xs:pinata-flex-shrink",

    XS_FLEX_SHRINK_0 = "xs:pinata-flex-shrink-0",

    FLEX_SHRINK = "pinata-flex-shrink",

    FLEX_SHRINK_0 = "pinata-flex-shrink-0",

}