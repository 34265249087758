///-- THIS FILE IS GENERATED --///
export enum Whitespace {

    BREAK_NORMAL = "pinata-break-normal",

    BREAK_WORDS = "pinata-break-words",

    TRUNCATE = "pinata-truncate",

    WHITESPACE_NO_WRAP = "pinata-whitespace-no-wrap",

    WHITESPACE_NORMAL = "pinata-whitespace-normal",

    WHITESPACE_PRE = "pinata-whitespace-pre",

    WHITESPACE_PRE_LINE = "pinata-whitespace-pre-line",

    WHITESPACE_PRE_WRAP = "pinata-whitespace-pre-wrap",

}