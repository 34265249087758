///-- THIS FILE IS GENERATED --///
export enum ObjectPosition {

    LG_OBJECT_BOTTOM = "lg:pinata-object-bottom",

    LG_OBJECT_CENTER = "lg:pinata-object-center",

    LG_OBJECT_LEFT = "lg:pinata-object-left",

    LG_OBJECT_LEFT_BOTTOM = "lg:pinata-object-left-bottom",

    LG_OBJECT_LEFT_TOP = "lg:pinata-object-left-top",

    LG_OBJECT_RIGHT = "lg:pinata-object-right",

    LG_OBJECT_RIGHT_BOTTOM = "lg:pinata-object-right-bottom",

    LG_OBJECT_RIGHT_TOP = "lg:pinata-object-right-top",

    LG_OBJECT_TOP = "lg:pinata-object-top",

    MD_OBJECT_BOTTOM = "md:pinata-object-bottom",

    MD_OBJECT_CENTER = "md:pinata-object-center",

    MD_OBJECT_LEFT = "md:pinata-object-left",

    MD_OBJECT_LEFT_BOTTOM = "md:pinata-object-left-bottom",

    MD_OBJECT_LEFT_TOP = "md:pinata-object-left-top",

    MD_OBJECT_RIGHT = "md:pinata-object-right",

    MD_OBJECT_RIGHT_BOTTOM = "md:pinata-object-right-bottom",

    MD_OBJECT_RIGHT_TOP = "md:pinata-object-right-top",

    MD_OBJECT_TOP = "md:pinata-object-top",

    SM_OBJECT_BOTTOM = "sm:pinata-object-bottom",

    SM_OBJECT_CENTER = "sm:pinata-object-center",

    SM_OBJECT_LEFT = "sm:pinata-object-left",

    SM_OBJECT_LEFT_BOTTOM = "sm:pinata-object-left-bottom",

    SM_OBJECT_LEFT_TOP = "sm:pinata-object-left-top",

    SM_OBJECT_RIGHT = "sm:pinata-object-right",

    SM_OBJECT_RIGHT_BOTTOM = "sm:pinata-object-right-bottom",

    SM_OBJECT_RIGHT_TOP = "sm:pinata-object-right-top",

    SM_OBJECT_TOP = "sm:pinata-object-top",

    XL_OBJECT_BOTTOM = "xl:pinata-object-bottom",

    XL_OBJECT_CENTER = "xl:pinata-object-center",

    XL_OBJECT_LEFT = "xl:pinata-object-left",

    XL_OBJECT_LEFT_BOTTOM = "xl:pinata-object-left-bottom",

    XL_OBJECT_LEFT_TOP = "xl:pinata-object-left-top",

    XL_OBJECT_RIGHT = "xl:pinata-object-right",

    XL_OBJECT_RIGHT_BOTTOM = "xl:pinata-object-right-bottom",

    XL_OBJECT_RIGHT_TOP = "xl:pinata-object-right-top",

    XL_OBJECT_TOP = "xl:pinata-object-top",

    XS_OBJECT_BOTTOM = "xs:pinata-object-bottom",

    XS_OBJECT_CENTER = "xs:pinata-object-center",

    XS_OBJECT_LEFT = "xs:pinata-object-left",

    XS_OBJECT_LEFT_BOTTOM = "xs:pinata-object-left-bottom",

    XS_OBJECT_LEFT_TOP = "xs:pinata-object-left-top",

    XS_OBJECT_RIGHT = "xs:pinata-object-right",

    XS_OBJECT_RIGHT_BOTTOM = "xs:pinata-object-right-bottom",

    XS_OBJECT_RIGHT_TOP = "xs:pinata-object-right-top",

    XS_OBJECT_TOP = "xs:pinata-object-top",

    OBJECT_BOTTOM = "pinata-object-bottom",

    OBJECT_CENTER = "pinata-object-center",

    OBJECT_LEFT = "pinata-object-left",

    OBJECT_LEFT_BOTTOM = "pinata-object-left-bottom",

    OBJECT_LEFT_TOP = "pinata-object-left-top",

    OBJECT_RIGHT = "pinata-object-right",

    OBJECT_RIGHT_BOTTOM = "pinata-object-right-bottom",

    OBJECT_RIGHT_TOP = "pinata-object-right-top",

    OBJECT_TOP = "pinata-object-top",

}