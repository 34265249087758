///-- THIS FILE IS GENERATED --///
export enum Cursor {

    CURSOR = "pinata-cursor",

    CURSOR_AUTO = "pinata-cursor-auto",

    CURSOR_MOVE = "pinata-cursor-move",

    CURSOR_NOT_ALLOWED = "pinata-cursor-not-allowed",

    CURSOR_POINTER = "pinata-cursor-pointer",

    CURSOR_TEXT = "pinata-cursor-text",

    CURSOR_WAIT = "pinata-cursor-wait",

}