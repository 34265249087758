import * as React from 'react';
import { classNames } from "../../utils/";
import { withResponsive } from "../../utils";
import { TooltipPosition, TooltipWidth } from "./TooltipEnums";
import { MouseEventHandler, useState } from 'react';

export * from "./TooltipEnums";

export interface ITooltipProps {
    position?: TooltipPosition;
    width?: TooltipWidth;
    mobile?: boolean;
    onTriggerClick?: any;
    className?: string;
    disabled?: boolean;
    alwaysShow?: boolean;
}

const styleClass = {
    root: (className?: string) => classNames(
        'pinata-tooltip',
        className
    ),
    content: (position: TooltipPosition, width: TooltipWidth) => classNames(
        'pinata-tooltip--content',
        `pinata-tooltip--content__${position}`,
        `pinata-tooltip--content__w-${width}`,
    ),
    contentArrow: 'pinata-tooltip--content__arrow',
};

interface IWithTooltipProps {
    Component: React.ComponentType<any>;
    componentProps: any;
}

const Tooltip: React.FunctionComponent<ITooltipProps & IWithTooltipProps> = (props) => {

    // Arrow is set to 20 x 20px so half the arrow Width is 10px. This is needed to place the arrow correctly
    let halfArrowSize = 10;
    let arrowPositionX = "";
    let arrowPositionY = "";

    const [show, setShow] = useState<boolean>(props.alwaysShow || false);

    const onMouseOver: MouseEventHandler<HTMLElement> = (ev) => {
        if (props.disabled) {
            return;
        }

        arrowPositionX = `${ev.currentTarget.offsetWidth / 2 - halfArrowSize}`;
        arrowPositionY = `${ev.currentTarget.offsetHeight / 2 - halfArrowSize}`;

        if (!props.alwaysShow) {
            setShow(true);
        }
    };

    const onMouseOut: MouseEventHandler<HTMLElement> = () => {
        if (!props.alwaysShow) {
            setShow(false);
        }
    };

    const placeArrow = (position: TooltipPosition) => {
        if (position === TooltipPosition.TOP || position === TooltipPosition.BOTTOM) {
            return {
                left: `${arrowPositionX}px`
            };
        } else if (position === TooltipPosition.RIGHT || position === TooltipPosition.LEFT) {
            return {
                top: `${arrowPositionY}px`
            };
        }
    };

    const {position, width, mobile, onTriggerClick, className, Component, componentProps} = props;

    return (
        <div className={styleClass.root(className)}>
            <Component
                {...componentProps}
                onClick={onTriggerClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />

            {!mobile && show ?
                <div className={styleClass.content(position || TooltipPosition.TOP, width || TooltipWidth.MEDIUM)}>
                    {props.children}
                    <span aria-hidden="true" className={styleClass.contentArrow} style={placeArrow(position || TooltipPosition.TOP)}/>
                </div>
                : null}
        </div>
    );
};

Tooltip.defaultProps = {
    position: TooltipPosition.TOP,
    width: TooltipWidth.MEDIUM,
    alwaysShow: false,
};
Tooltip.displayName = 'Tooltip';

export const withTooltip = (Component: React.ComponentType<any>, componentProps: any) => withResponsive((p: ITooltipProps) => (
    <Tooltip Component={Component} componentProps={componentProps} {...p} />
));
