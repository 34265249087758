///-- THIS FILE IS GENERATED --///
export enum ObjectFit {

    LG_OBJECT_CONTAIN = "lg:pinata-object-contain",

    LG_OBJECT_COVER = "lg:pinata-object-cover",

    LG_OBJECT_FILL = "lg:pinata-object-fill",

    LG_OBJECT_NONE = "lg:pinata-object-none",

    LG_OBJECT_SCALE_DOWN = "lg:pinata-object-scale-down",

    MD_OBJECT_CONTAIN = "md:pinata-object-contain",

    MD_OBJECT_COVER = "md:pinata-object-cover",

    MD_OBJECT_FILL = "md:pinata-object-fill",

    MD_OBJECT_NONE = "md:pinata-object-none",

    MD_OBJECT_SCALE_DOWN = "md:pinata-object-scale-down",

    SM_OBJECT_CONTAIN = "sm:pinata-object-contain",

    SM_OBJECT_COVER = "sm:pinata-object-cover",

    SM_OBJECT_FILL = "sm:pinata-object-fill",

    SM_OBJECT_NONE = "sm:pinata-object-none",

    SM_OBJECT_SCALE_DOWN = "sm:pinata-object-scale-down",

    XL_OBJECT_CONTAIN = "xl:pinata-object-contain",

    XL_OBJECT_COVER = "xl:pinata-object-cover",

    XL_OBJECT_FILL = "xl:pinata-object-fill",

    XL_OBJECT_NONE = "xl:pinata-object-none",

    XL_OBJECT_SCALE_DOWN = "xl:pinata-object-scale-down",

    XS_OBJECT_CONTAIN = "xs:pinata-object-contain",

    XS_OBJECT_COVER = "xs:pinata-object-cover",

    XS_OBJECT_FILL = "xs:pinata-object-fill",

    XS_OBJECT_NONE = "xs:pinata-object-none",

    XS_OBJECT_SCALE_DOWN = "xs:pinata-object-scale-down",

    OBJECT_CONTAIN = "pinata-object-contain",

    OBJECT_COVER = "pinata-object-cover",

    OBJECT_FILL = "pinata-object-fill",

    OBJECT_NONE = "pinata-object-none",

    OBJECT_SCALE_DOWN = "pinata-object-scale-down",

}