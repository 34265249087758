///-- THIS FILE IS GENERATED --///
export enum Float {

    CLEARFIX = "pinata-clearfix",

    FLOAT_LEFT = "pinata-float-left",

    FLOAT_NONE = "pinata-float-none",

    FLOAT_RIGHT = "pinata-float-right",

}