///-- THIS FILE IS GENERATED --///
export enum BoxShadow {

    SHADOW = "pinata-shadow",

    SHADOW_2XL = "pinata-shadow-2xl",

    SHADOW_BORDER_BLACK = "pinata-shadow-border-black",

    SHADOW_BORDER_HEADER = "pinata-shadow-border-header",

    SHADOW_BORDER_INFO = "pinata-shadow-border-info",

    SHADOW_BORDER_NEGATIVE = "pinata-shadow-border-negative",

    SHADOW_BORDER_NEUTRAL_1 = "pinata-shadow-border-neutral-1",

    SHADOW_BORDER_NEUTRAL_2 = "pinata-shadow-border-neutral-2",

    SHADOW_BORDER_NEUTRAL_3 = "pinata-shadow-border-neutral-3",

    SHADOW_BORDER_NEUTRAL_4 = "pinata-shadow-border-neutral-4",

    SHADOW_BORDER_NEUTRAL_5 = "pinata-shadow-border-neutral-5",

    SHADOW_BORDER_NEUTRAL_6 = "pinata-shadow-border-neutral-6",

    SHADOW_BORDER_NEUTRAL_7 = "pinata-shadow-border-neutral-7",

    SHADOW_BORDER_NEUTRAL_8 = "pinata-shadow-border-neutral-8",

    SHADOW_BORDER_POSITIVE = "pinata-shadow-border-positive",

    SHADOW_BORDER_PRIMARY_1 = "pinata-shadow-border-primary-1",

    SHADOW_BORDER_PRIMARY_2 = "pinata-shadow-border-primary-2",

    SHADOW_BORDER_PRIMARY_3 = "pinata-shadow-border-primary-3",

    SHADOW_BORDER_PRIMARY_4 = "pinata-shadow-border-primary-4",

    SHADOW_BORDER_PRIMARY_DARK = "pinata-shadow-border-primary-dark",

    SHADOW_BORDER_PRIMARY_LIGHT = "pinata-shadow-border-primary-light",

    SHADOW_BORDER_SECONDARY_1 = "pinata-shadow-border-secondary-1",

    SHADOW_BORDER_SECONDARY_2 = "pinata-shadow-border-secondary-2",

    SHADOW_BORDER_SECONDARY_3 = "pinata-shadow-border-secondary-3",

    SHADOW_BORDER_SECONDARY_4 = "pinata-shadow-border-secondary-4",

    SHADOW_BORDER_TERTIARY_1 = "pinata-shadow-border-tertiary-1",

    SHADOW_BORDER_TERTIARY_2 = "pinata-shadow-border-tertiary-2",

    SHADOW_BORDER_TERTIARY_3 = "pinata-shadow-border-tertiary-3",

    SHADOW_BORDER_TERTIARY_4 = "pinata-shadow-border-tertiary-4",

    SHADOW_BORDER_TRANSPARENT = "pinata-shadow-border-transparent",

    SHADOW_BORDER_WARNING = "pinata-shadow-border-warning",

    SHADOW_BORDER_WHITE = "pinata-shadow-border-white",

    SHADOW_INNER = "pinata-shadow-inner",

    SHADOW_LG = "pinata-shadow-lg",

    SHADOW_MD = "pinata-shadow-md",

    SHADOW_NONE = "pinata-shadow-none",

    SHADOW_OUTLINE = "pinata-shadow-outline",

    SHADOW_XL = "pinata-shadow-xl",

}