import * as React from 'react';
import json2mq from 'json2mq';

const createMediaQueries = (mediaSpec: any) => {
  return Object.keys(mediaSpec).map(key => {
    const stringMediaQuery = typeof mediaSpec[key] === 'object' ? json2mq(mediaSpec[key]) : mediaSpec[key];
    const mdl = window.matchMedia(stringMediaQuery);
    return {
      key: key,
      initialValue: mdl.matches,
      subscribe: (onChange: any) => {
        const handler = (e: {readonly matches: boolean}) => { onChange({ [key]: e.matches }) };
        mdl.addListener(handler);
        return () => mdl.removeListener(handler)
      }
    }
  })
};

const initialState = (queries: any[]) => queries.reduce((state, query) => {
  state[query.key] = query.initialValue;
  return state
}, {});

const createMediaSubscription = (mediaSpec: any) => {
  return {
    subscribe: (onMediaChange: any) => {
      const queries = createMediaQueries(mediaSpec);
      onMediaChange(initialState(queries));
      const unsubscribes = queries.map(query => query.subscribe(onMediaChange));
      return () => {
        unsubscribes.forEach(fn => fn());
      }
    }
  }
};

interface MediaSpecProps {
  mobile?: string | object;
  tablet?: string | object;
  desktop?: string | object;
  portrait?: string | object;
  landscape?: string | object;
}
interface MediaSpecState {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  portrait?: boolean;
  landscape?: boolean;
}

type responsiveToMedia = (mediaSpec: MediaSpecProps) => (WrappedComponent: any) => any;
const responsiveToMedia: responsiveToMedia = (mediaSpec) => (WrappedComponent) => {

  const subscription = createMediaSubscription(mediaSpec);

  class ResponsiveComponent extends React.Component<MediaSpecProps & any, MediaSpecState> {

    private unsubscribe?: () => void;

    componentDidMount() {
      if (typeof window !== 'undefined') {
        this.unsubscribe = subscription.subscribe((s: any) => this.setState(s));
      }
    }

    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
    }

    render() {
      return (
          <WrappedComponent {...this.state} {...this.props}/>
      )
    }
  }
  return ResponsiveComponent
};

export default responsiveToMedia;
