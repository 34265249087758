import * as React from 'react';
import { KeyboardEvent, useEffect, useState } from 'react';
import isArray from "lodash-es/isArray";
import {classNames} from "../../utils";

export * from "./Tab";

export interface ITabsProps extends React.HTMLAttributes<HTMLDivElement> {
    defaultActiveTabIndex?: number;
    contentClasses?: string;
    navClasses?: string;
    onTabClick?: (index: number) => void;
    alwaysOpen?: boolean;
}

export const Tabs: React.FunctionComponent<ITabsProps> = (props) => {

    const styleClass = {
        root: 'pinata-tabs',
        nav: 'pinata-tabs__nav',
        content: 'pinata-tabs__content'
    };

    const {
        children,
        defaultActiveTabIndex = props.alwaysOpen ? 0 : -1,
        onTabClick,
        className,
        navClasses,
        contentClasses,
        alwaysOpen,
        ...rest
    } = props;

    const [activeTabIndex, setActiveTabIndex] = useState<number>(defaultActiveTabIndex);

    useEffect(() => {
        if (onTabClick && activeTabIndex >= 0) {
            onTabClick(activeTabIndex);
        }
    }, [activeTabIndex]);

    // Toggle currently active tab
    const handleTabClick = (tabIndex: number) => {
        setActiveTabIndex(tabIndex === activeTabIndex ? alwaysOpen ? tabIndex : (defaultActiveTabIndex || -1) : tabIndex);
    };

    const handleOnKeyDown = (index: number) => (__event: KeyboardEvent<HTMLElement>) => {
        if(__event.key == "Enter" || __event.key == "Space") {
            handleTabClick(index);
        }
    };

    // Encapsulate <Tabs/> component API as props for <Tab/> children
    const renderChildrenWithTabsApiAsProps = () => {
        return React.Children.map(children as Array<React.ReactElement<any>>, (child, index) => {
            return React.cloneElement(child, {
                onClick: handleTabClick,
                onKeyDown: handleOnKeyDown(index),
                tabIndex: index,
                isActive: index === activeTabIndex
            });
        });
    };

    // Render current active tab content @todo
    const renderActiveTabContent = () => {
        let {children} = props;

        if (!isArray(children)) {
            children = [children];
        }

        if ((children as any)[activeTabIndex]) {
            return ((children as any)[activeTabIndex] as React.ReactElement<any>).props.children;
        }
    };

    return (
        <div
            {...rest}
            className={classNames(styleClass.root, className)}
        >
            <ul className={classNames(styleClass.nav, navClasses)}>
                {renderChildrenWithTabsApiAsProps()}
            </ul>
            <div className={classNames(styleClass.content, contentClasses)}>
                {renderActiveTabContent()}
            </div>
        </div>
    );
};

Tabs.displayName = "Tabs";
