///-- THIS FILE IS GENERATED --///
export enum Fill {

    HOVER_FILL_BLACK = "hover:pinata-fill-black",

    HOVER_FILL_CURRENT = "hover:pinata-fill-current",

    HOVER_FILL_HEADER = "hover:pinata-fill-header",

    HOVER_FILL_INFO = "hover:pinata-fill-info",

    HOVER_FILL_NEGATIVE = "hover:pinata-fill-negative",

    HOVER_FILL_NEUTRAL_1 = "hover:pinata-fill-neutral-1",

    HOVER_FILL_NEUTRAL_2 = "hover:pinata-fill-neutral-2",

    HOVER_FILL_NEUTRAL_3 = "hover:pinata-fill-neutral-3",

    HOVER_FILL_NEUTRAL_4 = "hover:pinata-fill-neutral-4",

    HOVER_FILL_NEUTRAL_5 = "hover:pinata-fill-neutral-5",

    HOVER_FILL_NEUTRAL_6 = "hover:pinata-fill-neutral-6",

    HOVER_FILL_NEUTRAL_7 = "hover:pinata-fill-neutral-7",

    HOVER_FILL_NEUTRAL_8 = "hover:pinata-fill-neutral-8",

    HOVER_FILL_POSITIVE = "hover:pinata-fill-positive",

    HOVER_FILL_PRIMARY_1 = "hover:pinata-fill-primary-1",

    HOVER_FILL_PRIMARY_2 = "hover:pinata-fill-primary-2",

    HOVER_FILL_PRIMARY_3 = "hover:pinata-fill-primary-3",

    HOVER_FILL_PRIMARY_4 = "hover:pinata-fill-primary-4",

    HOVER_FILL_PRIMARY_DARK = "hover:pinata-fill-primary-dark",

    HOVER_FILL_PRIMARY_LIGHT = "hover:pinata-fill-primary-light",

    HOVER_FILL_SECONDARY_1 = "hover:pinata-fill-secondary-1",

    HOVER_FILL_SECONDARY_2 = "hover:pinata-fill-secondary-2",

    HOVER_FILL_SECONDARY_3 = "hover:pinata-fill-secondary-3",

    HOVER_FILL_SECONDARY_4 = "hover:pinata-fill-secondary-4",

    HOVER_FILL_TERTIARY_1 = "hover:pinata-fill-tertiary-1",

    HOVER_FILL_TERTIARY_2 = "hover:pinata-fill-tertiary-2",

    HOVER_FILL_TERTIARY_3 = "hover:pinata-fill-tertiary-3",

    HOVER_FILL_TERTIARY_4 = "hover:pinata-fill-tertiary-4",

    HOVER_FILL_TRANSPARENT = "hover:pinata-fill-transparent",

    HOVER_FILL_WARNING = "hover:pinata-fill-warning",

    HOVER_FILL_WHITE = "hover:pinata-fill-white",

    FOCUS_FILL_BLACK = "focus:pinata-fill-black",

    FOCUS_FILL_CURRENT = "focus:pinata-fill-current",

    FOCUS_FILL_HEADER = "focus:pinata-fill-header",

    FOCUS_FILL_INFO = "focus:pinata-fill-info",

    FOCUS_FILL_NEGATIVE = "focus:pinata-fill-negative",

    FOCUS_FILL_NEUTRAL_1 = "focus:pinata-fill-neutral-1",

    FOCUS_FILL_NEUTRAL_2 = "focus:pinata-fill-neutral-2",

    FOCUS_FILL_NEUTRAL_3 = "focus:pinata-fill-neutral-3",

    FOCUS_FILL_NEUTRAL_4 = "focus:pinata-fill-neutral-4",

    FOCUS_FILL_NEUTRAL_5 = "focus:pinata-fill-neutral-5",

    FOCUS_FILL_NEUTRAL_6 = "focus:pinata-fill-neutral-6",

    FOCUS_FILL_NEUTRAL_7 = "focus:pinata-fill-neutral-7",

    FOCUS_FILL_NEUTRAL_8 = "focus:pinata-fill-neutral-8",

    FOCUS_FILL_POSITIVE = "focus:pinata-fill-positive",

    FOCUS_FILL_PRIMARY_1 = "focus:pinata-fill-primary-1",

    FOCUS_FILL_PRIMARY_2 = "focus:pinata-fill-primary-2",

    FOCUS_FILL_PRIMARY_3 = "focus:pinata-fill-primary-3",

    FOCUS_FILL_PRIMARY_4 = "focus:pinata-fill-primary-4",

    FOCUS_FILL_PRIMARY_DARK = "focus:pinata-fill-primary-dark",

    FOCUS_FILL_PRIMARY_LIGHT = "focus:pinata-fill-primary-light",

    FOCUS_FILL_SECONDARY_1 = "focus:pinata-fill-secondary-1",

    FOCUS_FILL_SECONDARY_2 = "focus:pinata-fill-secondary-2",

    FOCUS_FILL_SECONDARY_3 = "focus:pinata-fill-secondary-3",

    FOCUS_FILL_SECONDARY_4 = "focus:pinata-fill-secondary-4",

    FOCUS_FILL_TERTIARY_1 = "focus:pinata-fill-tertiary-1",

    FOCUS_FILL_TERTIARY_2 = "focus:pinata-fill-tertiary-2",

    FOCUS_FILL_TERTIARY_3 = "focus:pinata-fill-tertiary-3",

    FOCUS_FILL_TERTIARY_4 = "focus:pinata-fill-tertiary-4",

    FOCUS_FILL_TRANSPARENT = "focus:pinata-fill-transparent",

    FOCUS_FILL_WARNING = "focus:pinata-fill-warning",

    FOCUS_FILL_WHITE = "focus:pinata-fill-white",

    FILL_BLACK = "pinata-fill-black",

    FILL_CURRENT = "pinata-fill-current",

    FILL_HEADER = "pinata-fill-header",

    FILL_INFO = "pinata-fill-info",

    FILL_NEGATIVE = "pinata-fill-negative",

    FILL_NEUTRAL_1 = "pinata-fill-neutral-1",

    FILL_NEUTRAL_2 = "pinata-fill-neutral-2",

    FILL_NEUTRAL_3 = "pinata-fill-neutral-3",

    FILL_NEUTRAL_4 = "pinata-fill-neutral-4",

    FILL_NEUTRAL_5 = "pinata-fill-neutral-5",

    FILL_NEUTRAL_6 = "pinata-fill-neutral-6",

    FILL_NEUTRAL_7 = "pinata-fill-neutral-7",

    FILL_NEUTRAL_8 = "pinata-fill-neutral-8",

    FILL_POSITIVE = "pinata-fill-positive",

    FILL_PRIMARY_1 = "pinata-fill-primary-1",

    FILL_PRIMARY_2 = "pinata-fill-primary-2",

    FILL_PRIMARY_3 = "pinata-fill-primary-3",

    FILL_PRIMARY_4 = "pinata-fill-primary-4",

    FILL_PRIMARY_DARK = "pinata-fill-primary-dark",

    FILL_PRIMARY_LIGHT = "pinata-fill-primary-light",

    FILL_SECONDARY_1 = "pinata-fill-secondary-1",

    FILL_SECONDARY_2 = "pinata-fill-secondary-2",

    FILL_SECONDARY_3 = "pinata-fill-secondary-3",

    FILL_SECONDARY_4 = "pinata-fill-secondary-4",

    FILL_TERTIARY_1 = "pinata-fill-tertiary-1",

    FILL_TERTIARY_2 = "pinata-fill-tertiary-2",

    FILL_TERTIARY_3 = "pinata-fill-tertiary-3",

    FILL_TERTIARY_4 = "pinata-fill-tertiary-4",

    FILL_TRANSPARENT = "pinata-fill-transparent",

    FILL_WARNING = "pinata-fill-warning",

    FILL_WHITE = "pinata-fill-white",

}