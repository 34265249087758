import * as React from 'react';
import { Autosuggest, IFilteringAutosuggestProps } from './index';

export const FilteringAutosuggest: React.FunctionComponent<IFilteringAutosuggestProps> = (props) => {
    const { data, value } = props;
    const filteredData = data.filter((item) => item.value.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1);
    return (
        <Autosuggest {...props} data={filteredData}/>
    );
};

FilteringAutosuggest.displayName = "FilteringAutosuggest";
