export enum ContainerWidth {
    DEFAULT = '',
    NARROW = 'narrow',
    WIDE = 'wide',
    WIDER = 'wider'
}
export enum ContainerAlign {
    DEFAULT = '',
    LEFT = 'left',
    RIGHT = 'right'
}