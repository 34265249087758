import * as React from "react";
import { ChangeEvent, FocusEvent, useState } from "react";
import { Icon, IconColor, IconName } from "../icon";
import { classNames, IWithResponsiveProps } from "../../utils";
import { IButtonProps } from "../button";
import { FocusEventHandler } from 'react';
import { MouseEventHandler } from 'react';

export interface ISearchButtonProps extends IButtonProps {
    onSearchButtonClick?: (filterValue: string) => void;
    searchButtonClassname: string;
    clearInputValueOnBlur?: boolean;
}

export interface ISearchProps
    extends IWithResponsiveProps,
        React.InputHTMLAttributes<HTMLInputElement | HTMLButtonElement> {
    iconName?: IconName;
    buttonProps?: ISearchButtonProps;
    placeholder?: string;
    clearOnFocus?: boolean;
}

const styleClass = {
    search: "pinata-search",
    icon: "pinata-search--icon",
    button: (classname: string) => classNames("pinata-search--button", classname),
    input: "pinata-search--input"
};

export const Search: React.FunctionComponent<ISearchProps> = (props) => {

    const [value, setValue] = useState<string>('');
    const ref = React.createRef<HTMLInputElement>();

    const onFocus = (evt: FocusEvent<HTMLInputElement>) => {
        const {clearOnFocus, onFocus} = props;
        if (clearOnFocus) {
            evt.target.value = "";
            onFocus && onFocus(evt);
            setValue("");
        }
    };

    const onButtonBlur: FocusEventHandler<HTMLButtonElement> = () => {
        const {
            buttonProps
        } = props;
        if (buttonProps && buttonProps.clearInputValueOnBlur) {
            ref.current!.value = "";
            setValue("");
        }
    };

    const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const {onChange} = props;
        setValue(evt.target.value);
        onChange && onChange(evt);
    };

    const onIconClick = (evt: React.MouseEvent<SVGSVGElement>) => {
        const {onClick} = props;
        setValue(ref.current!.value);
        onClick && onClick(evt as any);
    };

    const onSearchButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
        const {
            mobile,
            buttonProps,
        } = props;
        buttonProps && buttonProps.onSearchButtonClick && buttonProps.onSearchButtonClick(value);
        ref.current!.value = "";
        setValue("");
        if (!mobile) {
            ref.current!.focus();
        }
    };

    const {
        iconName,
        placeholder,
        onChange: onChangeProp,
        onFocus: onFocusProp,
        className,
        buttonProps,
        clearOnFocus,
        mobile,
        ...otherProps
    } = props;

    const defaultButtonProps: Partial<ISearchButtonProps> & Pick<ISearchButtonProps, "onSearchButtonClick" | "searchButtonClassname"> = {
        onSearchButtonClick: () => void 0,
        searchButtonClassname: "",
        ...buttonProps
    };

    const {
        onSearchButtonClick: onSearchButtonClickProp,
        searchButtonClassname,
        text,
        clearInputValueOnBlur,
        ...buttonPropsRest
    }: ISearchButtonProps = buttonProps || defaultButtonProps;

    if(!otherProps.hasOwnProperty('aria-label')) {
      console.warn('The search bar component should have an aria-label to improve accessibility.');
    }

    return (
        <div className={styleClass.search}>
            <input
                tabIndex={0}
                aria-haspopup={"true"}
                autoComplete="off"
                placeholder={placeholder}
                className={classNames(className, styleClass.input)}
                onFocus={onFocus}
                type={"search"}
                ref={ref}
                value={value}
                onChange={onChange}
                {...otherProps}
            />
            {buttonProps ? (
                <button
                    className={styleClass.button(searchButtonClassname)}
                    onClick={onSearchButtonClick}
                    onBlur={onButtonBlur}
                    {...buttonPropsRest}
                >
                    {iconName ? (
                        <Icon
                            name={iconName}
                            className={styleClass.icon}
                            color={IconColor.NEUTRAL_1}
                        />
                    ) : (
                        text
                    )}
                </button>
            ) : (
                iconName && (
                    <Icon
                        name={iconName}
                        className={styleClass.icon}
                        color={value && IconColor.PRIMARY_1}
                        onClick={onIconClick}
                    />
                )
            )}
        </div>
    );
};
