///-- THIS FILE IS GENERATED --///
export enum BorderRadius {

    ROUNDED = "pinata-rounded",

    ROUNDED_B = "pinata-rounded-b",

    ROUNDED_B_BRAND = "pinata-rounded-b-brand",

    ROUNDED_B_FULL = "pinata-rounded-b-full",

    ROUNDED_B_LG = "pinata-rounded-b-lg",

    ROUNDED_B_NONE = "pinata-rounded-b-none",

    ROUNDED_B_SM = "pinata-rounded-b-sm",

    ROUNDED_BL = "pinata-rounded-bl",

    ROUNDED_BL_BRAND = "pinata-rounded-bl-brand",

    ROUNDED_BL_FULL = "pinata-rounded-bl-full",

    ROUNDED_BL_LG = "pinata-rounded-bl-lg",

    ROUNDED_BL_NONE = "pinata-rounded-bl-none",

    ROUNDED_BL_SM = "pinata-rounded-bl-sm",

    ROUNDED_BR = "pinata-rounded-br",

    ROUNDED_BR_BRAND = "pinata-rounded-br-brand",

    ROUNDED_BR_FULL = "pinata-rounded-br-full",

    ROUNDED_BR_LG = "pinata-rounded-br-lg",

    ROUNDED_BR_NONE = "pinata-rounded-br-none",

    ROUNDED_BR_SM = "pinata-rounded-br-sm",

    ROUNDED_BRAND = "pinata-rounded-brand",

    ROUNDED_FULL = "pinata-rounded-full",

    ROUNDED_L = "pinata-rounded-l",

    ROUNDED_L_BRAND = "pinata-rounded-l-brand",

    ROUNDED_L_FULL = "pinata-rounded-l-full",

    ROUNDED_L_LG = "pinata-rounded-l-lg",

    ROUNDED_L_NONE = "pinata-rounded-l-none",

    ROUNDED_L_SM = "pinata-rounded-l-sm",

    ROUNDED_LG = "pinata-rounded-lg",

    ROUNDED_NONE = "pinata-rounded-none",

    ROUNDED_R = "pinata-rounded-r",

    ROUNDED_R_BRAND = "pinata-rounded-r-brand",

    ROUNDED_R_FULL = "pinata-rounded-r-full",

    ROUNDED_R_LG = "pinata-rounded-r-lg",

    ROUNDED_R_NONE = "pinata-rounded-r-none",

    ROUNDED_R_SM = "pinata-rounded-r-sm",

    ROUNDED_SM = "pinata-rounded-sm",

    ROUNDED_T = "pinata-rounded-t",

    ROUNDED_T_BRAND = "pinata-rounded-t-brand",

    ROUNDED_T_FULL = "pinata-rounded-t-full",

    ROUNDED_T_LG = "pinata-rounded-t-lg",

    ROUNDED_T_NONE = "pinata-rounded-t-none",

    ROUNDED_T_SM = "pinata-rounded-t-sm",

    ROUNDED_TL = "pinata-rounded-tl",

    ROUNDED_TL_BRAND = "pinata-rounded-tl-brand",

    ROUNDED_TL_FULL = "pinata-rounded-tl-full",

    ROUNDED_TL_LG = "pinata-rounded-tl-lg",

    ROUNDED_TL_NONE = "pinata-rounded-tl-none",

    ROUNDED_TL_SM = "pinata-rounded-tl-sm",

    ROUNDED_TR = "pinata-rounded-tr",

    ROUNDED_TR_BRAND = "pinata-rounded-tr-brand",

    ROUNDED_TR_FULL = "pinata-rounded-tr-full",

    ROUNDED_TR_LG = "pinata-rounded-tr-lg",

    ROUNDED_TR_NONE = "pinata-rounded-tr-none",

    ROUNDED_TR_SM = "pinata-rounded-tr-sm",

}