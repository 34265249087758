// Test via a getter in the options object to see if the passive property is accessed
let supportsPassive = false;
try {
    const opts = Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    });
    window.addEventListener("testPassive" as keyof WindowEventMap, null as unknown as EventListener, opts);
    window.removeEventListener("testPassive" as keyof WindowEventMap, null as unknown as EventListener, opts);
} catch (e) {
}

export type ISwipeCallbackList = Array<(__event: TouchEvent) => void>;

export class Swipe {

    static TOUCH_START = 'touchstart';
    static TOUCH_MOVE = 'touchmove';

    private xDown: null | number = null;
    private yDown: null | number = null;

    private _left: ISwipeCallbackList = [];
    private _right: ISwipeCallbackList = [];
    private _up: ISwipeCallbackList = [];
    private _down: ISwipeCallbackList = [];

    constructor(private element: Element) {

        this.xDown = null;
        this.yDown = null;

        if(!this.element) {
            return;
        }

        this.element.addEventListener(
            Swipe.TOUCH_START,
            this.onTouchStart as EventListener,
            supportsPassive ? {passive: true} : false
        );

        this.element.addEventListener(
            Swipe.TOUCH_MOVE,
            this.onTouchMove as EventListener,
            supportsPassive ? {passive: true} : false
        );
    }

    private onTouchMove = (__event: TouchEvent) => {
        this.handleTouchMove(__event);
    };

    private onTouchStart = (__event: TouchEvent) => {
        this.xDown = __event.touches[0].clientX;
        this.yDown = __event.touches[0].clientY;
    };

    private addCallback(callback: (e: TouchEvent) => void, list: Array<(e: TouchEvent) => void>): void {
        if (list.indexOf(callback) === -1) {
            list.push(callback);
        }
    }

    private runCallbacks(__event: TouchEvent, list: ISwipeCallbackList): void {
        list.forEach(cb => cb(__event));
    }

    public onLeft(callback: (e: TouchEvent) => void) {
        this.addCallback(callback, this._left);
        return this;
    }

    public onRight(callback: (e: TouchEvent) => void) {
        this.addCallback(callback, this._right);
        return this;
    }

    public onUp(callback: (e: TouchEvent) => void) {
        this.addCallback(callback, this._up);
        return this;
    }

    public onDown(callback: (e: TouchEvent) => void) {
        this.addCallback(callback, this._down);
        return this;
    }

    public removeEventListeners(): void {
        this.element.removeEventListener(
            Swipe.TOUCH_START,
            this.onTouchStart as EventListener,
            false
        );

        this.element.removeEventListener(
            Swipe.TOUCH_MOVE,
            this.onTouchMove as EventListener,
            false
        );
    }

    protected handleTouchMove(__event: TouchEvent) {

        if (!this.xDown || !this.yDown) {
            return;
        }

        const xUp = __event.touches[0].clientX;
        const yUp = __event.touches[0].clientY;

        const xDelta = this.xDown - xUp;
        const yDelta = this.yDown - yUp;

        if (Math.abs(xDelta) > Math.abs(yDelta)) { // Most significant.
            if (xDelta > 0) {
                this.runCallbacks(__event, this._left);
            } else {
                this.runCallbacks(__event, this._right);
            }
        } else {
            if (yDelta > 0) {
                this.runCallbacks(__event, this._up);
            } else {
                this.runCallbacks(__event, this._down);
            }
        }

        // Reset values.
        this.xDown = null;
        this.yDown = null;
    }
}
