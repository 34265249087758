///-- THIS FILE IS GENERATED --///
export enum BackgroundPosition {

    BG_BOTTOM = "pinata-bg-bottom",

    BG_CENTER = "pinata-bg-center",

    BG_LEFT = "pinata-bg-left",

    BG_LEFT_BOTTOM = "pinata-bg-left-bottom",

    BG_LEFT_TOP = "pinata-bg-left-top",

    BG_RIGHT = "pinata-bg-right",

    BG_RIGHT_BOTTOM = "pinata-bg-right-bottom",

    BG_RIGHT_TOP = "pinata-bg-right-top",

    BG_TOP = "pinata-bg-top",

}