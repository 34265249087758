///-- THIS FILE IS GENERATED --///
export enum MinWidth {

    LG_MIN_W_0 = "lg:pinata-min-w-0",

    LG_MIN_W_1 = "lg:pinata-min-w-1",

    LG_MIN_W_10 = "lg:pinata-min-w-10",

    LG_MIN_W_12 = "lg:pinata-min-w-12",

    LG_MIN_W_14 = "lg:pinata-min-w-14",

    LG_MIN_W_16 = "lg:pinata-min-w-16",

    LG_MIN_W_2 = "lg:pinata-min-w-2",

    LG_MIN_W_20 = "lg:pinata-min-w-20",

    LG_MIN_W_24 = "lg:pinata-min-w-24",

    LG_MIN_W_2XL = "lg:pinata-min-w-2xl",

    LG_MIN_W_3 = "lg:pinata-min-w-3",

    LG_MIN_W_32 = "lg:pinata-min-w-32",

    LG_MIN_W_3XL = "lg:pinata-min-w-3xl",

    LG_MIN_W_4 = "lg:pinata-min-w-4",

    LG_MIN_W_40 = "lg:pinata-min-w-40",

    LG_MIN_W_48 = "lg:pinata-min-w-48",

    LG_MIN_W_4XL = "lg:pinata-min-w-4xl",

    LG_MIN_W_5 = "lg:pinata-min-w-5",

    LG_MIN_W_56 = "lg:pinata-min-w-56",

    LG_MIN_W_5XL = "lg:pinata-min-w-5xl",

    LG_MIN_W_6 = "lg:pinata-min-w-6",

    LG_MIN_W_64 = "lg:pinata-min-w-64",

    LG_MIN_W_6XL = "lg:pinata-min-w-6xl",

    LG_MIN_W_8 = "lg:pinata-min-w-8",

    LG_MIN_W_FULL = "lg:pinata-min-w-full",

    LG_MIN_W_LG = "lg:pinata-min-w-lg",

    LG_MIN_W_MD = "lg:pinata-min-w-md",

    LG_MIN_W_PX = "lg:pinata-min-w-px",

    LG_MIN_W_SCREEN = "lg:pinata-min-w-screen",

    LG_MIN_W_SM = "lg:pinata-min-w-sm",

    LG_MIN_W_XL = "lg:pinata-min-w-xl",

    LG_MIN_W_XS = "lg:pinata-min-w-xs",

    MD_MIN_W_0 = "md:pinata-min-w-0",

    MD_MIN_W_1 = "md:pinata-min-w-1",

    MD_MIN_W_10 = "md:pinata-min-w-10",

    MD_MIN_W_12 = "md:pinata-min-w-12",

    MD_MIN_W_14 = "md:pinata-min-w-14",

    MD_MIN_W_16 = "md:pinata-min-w-16",

    MD_MIN_W_2 = "md:pinata-min-w-2",

    MD_MIN_W_20 = "md:pinata-min-w-20",

    MD_MIN_W_24 = "md:pinata-min-w-24",

    MD_MIN_W_2XL = "md:pinata-min-w-2xl",

    MD_MIN_W_3 = "md:pinata-min-w-3",

    MD_MIN_W_32 = "md:pinata-min-w-32",

    MD_MIN_W_3XL = "md:pinata-min-w-3xl",

    MD_MIN_W_4 = "md:pinata-min-w-4",

    MD_MIN_W_40 = "md:pinata-min-w-40",

    MD_MIN_W_48 = "md:pinata-min-w-48",

    MD_MIN_W_4XL = "md:pinata-min-w-4xl",

    MD_MIN_W_5 = "md:pinata-min-w-5",

    MD_MIN_W_56 = "md:pinata-min-w-56",

    MD_MIN_W_5XL = "md:pinata-min-w-5xl",

    MD_MIN_W_6 = "md:pinata-min-w-6",

    MD_MIN_W_64 = "md:pinata-min-w-64",

    MD_MIN_W_6XL = "md:pinata-min-w-6xl",

    MD_MIN_W_8 = "md:pinata-min-w-8",

    MD_MIN_W_FULL = "md:pinata-min-w-full",

    MD_MIN_W_LG = "md:pinata-min-w-lg",

    MD_MIN_W_MD = "md:pinata-min-w-md",

    MD_MIN_W_PX = "md:pinata-min-w-px",

    MD_MIN_W_SCREEN = "md:pinata-min-w-screen",

    MD_MIN_W_SM = "md:pinata-min-w-sm",

    MD_MIN_W_XL = "md:pinata-min-w-xl",

    MD_MIN_W_XS = "md:pinata-min-w-xs",

    SM_MIN_W_0 = "sm:pinata-min-w-0",

    SM_MIN_W_1 = "sm:pinata-min-w-1",

    SM_MIN_W_10 = "sm:pinata-min-w-10",

    SM_MIN_W_12 = "sm:pinata-min-w-12",

    SM_MIN_W_14 = "sm:pinata-min-w-14",

    SM_MIN_W_16 = "sm:pinata-min-w-16",

    SM_MIN_W_2 = "sm:pinata-min-w-2",

    SM_MIN_W_20 = "sm:pinata-min-w-20",

    SM_MIN_W_24 = "sm:pinata-min-w-24",

    SM_MIN_W_2XL = "sm:pinata-min-w-2xl",

    SM_MIN_W_3 = "sm:pinata-min-w-3",

    SM_MIN_W_32 = "sm:pinata-min-w-32",

    SM_MIN_W_3XL = "sm:pinata-min-w-3xl",

    SM_MIN_W_4 = "sm:pinata-min-w-4",

    SM_MIN_W_40 = "sm:pinata-min-w-40",

    SM_MIN_W_48 = "sm:pinata-min-w-48",

    SM_MIN_W_4XL = "sm:pinata-min-w-4xl",

    SM_MIN_W_5 = "sm:pinata-min-w-5",

    SM_MIN_W_56 = "sm:pinata-min-w-56",

    SM_MIN_W_5XL = "sm:pinata-min-w-5xl",

    SM_MIN_W_6 = "sm:pinata-min-w-6",

    SM_MIN_W_64 = "sm:pinata-min-w-64",

    SM_MIN_W_6XL = "sm:pinata-min-w-6xl",

    SM_MIN_W_8 = "sm:pinata-min-w-8",

    SM_MIN_W_FULL = "sm:pinata-min-w-full",

    SM_MIN_W_LG = "sm:pinata-min-w-lg",

    SM_MIN_W_MD = "sm:pinata-min-w-md",

    SM_MIN_W_PX = "sm:pinata-min-w-px",

    SM_MIN_W_SCREEN = "sm:pinata-min-w-screen",

    SM_MIN_W_SM = "sm:pinata-min-w-sm",

    SM_MIN_W_XL = "sm:pinata-min-w-xl",

    SM_MIN_W_XS = "sm:pinata-min-w-xs",

    XL_MIN_W_0 = "xl:pinata-min-w-0",

    XL_MIN_W_1 = "xl:pinata-min-w-1",

    XL_MIN_W_10 = "xl:pinata-min-w-10",

    XL_MIN_W_12 = "xl:pinata-min-w-12",

    XL_MIN_W_14 = "xl:pinata-min-w-14",

    XL_MIN_W_16 = "xl:pinata-min-w-16",

    XL_MIN_W_2 = "xl:pinata-min-w-2",

    XL_MIN_W_20 = "xl:pinata-min-w-20",

    XL_MIN_W_24 = "xl:pinata-min-w-24",

    XL_MIN_W_2XL = "xl:pinata-min-w-2xl",

    XL_MIN_W_3 = "xl:pinata-min-w-3",

    XL_MIN_W_32 = "xl:pinata-min-w-32",

    XL_MIN_W_3XL = "xl:pinata-min-w-3xl",

    XL_MIN_W_4 = "xl:pinata-min-w-4",

    XL_MIN_W_40 = "xl:pinata-min-w-40",

    XL_MIN_W_48 = "xl:pinata-min-w-48",

    XL_MIN_W_4XL = "xl:pinata-min-w-4xl",

    XL_MIN_W_5 = "xl:pinata-min-w-5",

    XL_MIN_W_56 = "xl:pinata-min-w-56",

    XL_MIN_W_5XL = "xl:pinata-min-w-5xl",

    XL_MIN_W_6 = "xl:pinata-min-w-6",

    XL_MIN_W_64 = "xl:pinata-min-w-64",

    XL_MIN_W_6XL = "xl:pinata-min-w-6xl",

    XL_MIN_W_8 = "xl:pinata-min-w-8",

    XL_MIN_W_FULL = "xl:pinata-min-w-full",

    XL_MIN_W_LG = "xl:pinata-min-w-lg",

    XL_MIN_W_MD = "xl:pinata-min-w-md",

    XL_MIN_W_PX = "xl:pinata-min-w-px",

    XL_MIN_W_SCREEN = "xl:pinata-min-w-screen",

    XL_MIN_W_SM = "xl:pinata-min-w-sm",

    XL_MIN_W_XL = "xl:pinata-min-w-xl",

    XL_MIN_W_XS = "xl:pinata-min-w-xs",

    XS_MIN_W_0 = "xs:pinata-min-w-0",

    XS_MIN_W_1 = "xs:pinata-min-w-1",

    XS_MIN_W_10 = "xs:pinata-min-w-10",

    XS_MIN_W_12 = "xs:pinata-min-w-12",

    XS_MIN_W_14 = "xs:pinata-min-w-14",

    XS_MIN_W_16 = "xs:pinata-min-w-16",

    XS_MIN_W_2 = "xs:pinata-min-w-2",

    XS_MIN_W_20 = "xs:pinata-min-w-20",

    XS_MIN_W_24 = "xs:pinata-min-w-24",

    XS_MIN_W_2XL = "xs:pinata-min-w-2xl",

    XS_MIN_W_3 = "xs:pinata-min-w-3",

    XS_MIN_W_32 = "xs:pinata-min-w-32",

    XS_MIN_W_3XL = "xs:pinata-min-w-3xl",

    XS_MIN_W_4 = "xs:pinata-min-w-4",

    XS_MIN_W_40 = "xs:pinata-min-w-40",

    XS_MIN_W_48 = "xs:pinata-min-w-48",

    XS_MIN_W_4XL = "xs:pinata-min-w-4xl",

    XS_MIN_W_5 = "xs:pinata-min-w-5",

    XS_MIN_W_56 = "xs:pinata-min-w-56",

    XS_MIN_W_5XL = "xs:pinata-min-w-5xl",

    XS_MIN_W_6 = "xs:pinata-min-w-6",

    XS_MIN_W_64 = "xs:pinata-min-w-64",

    XS_MIN_W_6XL = "xs:pinata-min-w-6xl",

    XS_MIN_W_8 = "xs:pinata-min-w-8",

    XS_MIN_W_FULL = "xs:pinata-min-w-full",

    XS_MIN_W_LG = "xs:pinata-min-w-lg",

    XS_MIN_W_MD = "xs:pinata-min-w-md",

    XS_MIN_W_PX = "xs:pinata-min-w-px",

    XS_MIN_W_SCREEN = "xs:pinata-min-w-screen",

    XS_MIN_W_SM = "xs:pinata-min-w-sm",

    XS_MIN_W_XL = "xs:pinata-min-w-xl",

    XS_MIN_W_XS = "xs:pinata-min-w-xs",

    MIN_W_0 = "pinata-min-w-0",

    MIN_W_1 = "pinata-min-w-1",

    MIN_W_10 = "pinata-min-w-10",

    MIN_W_12 = "pinata-min-w-12",

    MIN_W_14 = "pinata-min-w-14",

    MIN_W_16 = "pinata-min-w-16",

    MIN_W_2 = "pinata-min-w-2",

    MIN_W_20 = "pinata-min-w-20",

    MIN_W_24 = "pinata-min-w-24",

    MIN_W_2XL = "pinata-min-w-2xl",

    MIN_W_3 = "pinata-min-w-3",

    MIN_W_32 = "pinata-min-w-32",

    MIN_W_3XL = "pinata-min-w-3xl",

    MIN_W_4 = "pinata-min-w-4",

    MIN_W_40 = "pinata-min-w-40",

    MIN_W_48 = "pinata-min-w-48",

    MIN_W_4XL = "pinata-min-w-4xl",

    MIN_W_5 = "pinata-min-w-5",

    MIN_W_56 = "pinata-min-w-56",

    MIN_W_5XL = "pinata-min-w-5xl",

    MIN_W_6 = "pinata-min-w-6",

    MIN_W_64 = "pinata-min-w-64",

    MIN_W_6XL = "pinata-min-w-6xl",

    MIN_W_8 = "pinata-min-w-8",

    MIN_W_FULL = "pinata-min-w-full",

    MIN_W_LG = "pinata-min-w-lg",

    MIN_W_MD = "pinata-min-w-md",

    MIN_W_PX = "pinata-min-w-px",

    MIN_W_SCREEN = "pinata-min-w-screen",

    MIN_W_SM = "pinata-min-w-sm",

    MIN_W_XL = "pinata-min-w-xl",

    MIN_W_XS = "pinata-min-w-xs",

}