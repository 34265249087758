import * as React from 'react';
import { KeyboardEventHandler, MouseEventHandler, RefObject, useEffect, useState } from 'react';
import { IDropdownListItem } from './index';

export interface IDropdownListProps {
    data: IDropdownListItem[];
    onCancel: () => void;
    onSelect: (value: string, index: number) => void;
    value?: string;
}

const styleClass = {
    list: (isVisible: boolean) => isVisible ? 'pinata-dropdown--list' : 'pinata-dropdown--list pinata-dropdown--list__hidden',
    listItem: (isSelected: boolean) => isSelected ? 'pinata-dropdown--list-item pinata-dropdown--selected' : 'pinata-dropdown--list-item',
};

export const DropdownList: React.FunctionComponent<IDropdownListProps> = ({data, onCancel, onSelect, value}) => {

    const itemRefs: { [k: number]: RefObject<HTMLLIElement> | undefined } = {};
    const listRef = React.createRef<HTMLUListElement>();
    const keysPressed: { [k: string]: boolean | undefined } = {};

    const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(value !== undefined ? data.findIndex((e) => e.value === value) : -1);

    useEffect(() => {
        const ref = itemRefs[highlightedItemIndex !== -1 ? highlightedItemIndex : 0];
        if (ref && ref.current) {
            ref.current.focus();
        }
    }, []);

    const getItemOffset = (index: number, direction: "offsetTop"): number => {
        return itemRefs[index]!.current![direction];
    };

    const scrollTo = (offset: number): void => {
        listRef.current!.scrollTop = offset;
    };

    const isVisibleListItem = (el: Element, direction: "top" | "bottom"): boolean => {
        if (direction === "top") {
            return el.getBoundingClientRect().top < (el.parentNode as Element).getBoundingClientRect().bottom;
        } else if (direction === "bottom") {
            return el.getBoundingClientRect().bottom >= (el.parentNode as Element).getBoundingClientRect().bottom;
        }
        return false;
    };

    const onTriggerKeyDown: KeyboardEventHandler<HTMLUListElement> = (__event) => {
        const key = __event.key;

        if (key === "Alt" || key === "ArrowDown") {
            keysPressed[key] = true; // record if alt or arrow down keys are pressed
        }

        if (key === " " || key === "Tab") {
            onSelect(data[highlightedItemIndex].value, highlightedItemIndex);
        }

        if (key === "Enter") {
            __event.preventDefault();
            onSelect(data[highlightedItemIndex].value, highlightedItemIndex);
        }

        if (key === "ArrowDown") {
            if (highlightedItemIndex < data.length) {
                if (keysPressed["Alt"]) {
                    if (highlightedItemIndex >= 0) {
                        onSelect(data[highlightedItemIndex].value, highlightedItemIndex);
                    } else {
                        onCancel();
                    }
                }
                const offset = getItemOffset(Math.max(highlightedItemIndex, 0), "offsetTop");
                setHighlightedItemIndex(highlightedItemIndex + 1);
                scrollTo(offset);
            }
        }

        if (key === "ArrowUp") {
            if (highlightedItemIndex !== 0) {
                const nextIndex = highlightedItemIndex - 1 < 0 ? data.length - 1 : highlightedItemIndex - 1;
                const offset = getItemOffset(nextIndex, "offsetTop");
                setHighlightedItemIndex(nextIndex);
                scrollTo(offset);
            }
        }

        if (key === "Escape") {
            onCancel();
        }

        if (key === "Home" || key === "PageUp") {
            if (data.length > 0) {
                setHighlightedItemIndex(0);
                const ref = itemRefs[0]!.current!;
                if (isVisibleListItem(ref, "top")) {
                    ref.scrollIntoView(false);
                }
            }
        }

        if (key === "End" || key === "PageDown") {
            if (data.length > 0) {
                setHighlightedItemIndex(data.length - 1);
                const ref = itemRefs[data.length - 1]!.current!;
                if (isVisibleListItem(ref, "bottom")) {
                    ref.scrollIntoView(false);
                }
            }
        }
    };

    const onTriggerKeyUp: KeyboardEventHandler<HTMLUListElement> = (__event) => {
        const key = __event.key;

        if (keysPressed[key]) {
            keysPressed[key] = false;
        }
    };

    const onItemClick = (item: IDropdownListItem, index: number): MouseEventHandler<HTMLLIElement> => () => {
        onSelect(item.value, index);
    };

    return (
        <ul
            className={styleClass.list(data.length > 0)}
            onKeyDown={onTriggerKeyDown}
            onKeyUp={onTriggerKeyUp}
            ref={listRef}
            role={"menu"}
        >
            {
                data.map((item: IDropdownListItem, key: number) => {
                    const ref = itemRefs[key] = React.createRef<HTMLLIElement>();
                    return (
                        <li
                            key={`dropdown-item--${key}`}
                            className={styleClass.listItem(key === highlightedItemIndex)}
                            ref={ref}
                            onClick={onItemClick(item, key)}
                            role={"menuitem"}
                            tabIndex={0}
                        >
                            {item.name}
                        </li>
                    )
                })}
        </ul>
    );
};

DropdownList.displayName = 'DropdownList';
