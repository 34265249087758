import * as React from 'react';
import { KeyboardEventHandler, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import { Icon, IconName } from "../icon";
import { classNames } from "../../utils";
import { DropdownList } from './DropdownList';

export interface IDropdownProps {
    data?: IDropdownListItem[];
    errorMsg?: ReactNode;
    validMsg?: ReactNode;
    defaultTriggerText?: string;
    onChange: (value: string, index: number) => void;
    className?: string;
    hideLabel?: boolean;
    value?: string;
    disabled?: boolean;
}

export interface IDropdownListItem {
    name: string;
    value: string;
}

const styleClass = {
    root: (listVisible: boolean) => listVisible ? 'pinata-dropdown pinata-dropdown__list-shown' : 'pinata-dropdown',
    dropdownLabel: 'pinata-dropdown--label',
    dropdownTrigger: 'pinata-dropdown--trigger',
    dropdownTriggerText: 'pinata-dropdown--trigger--text',
    portal: 'pinata-dropdown--portal',
    iconDropdownClose: 'pinata-dropdown--icon pinata-dropdown--icon__close',
    iconDropdownOpen: 'pinata-dropdown--icon pinata-dropdown--icon__open',
    dropdownMsg: 'pinta-dropdown--message',
    errorMsg: 'pinta-dropdown--message--error',
    validMsg: 'pinta-dropdown--message--valid',
};

export const Dropdown: React.FunctionComponent<IDropdownProps> = (props) => {

    const {data, value} = props;
    const keysPressed: { [k: string]: boolean | undefined } = {};

    const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
    const [selectedItemNumber, setSelectedItemNumber] = useState<number>(
        value && data ? data.findIndex(data => data.value === value) : -1
    );

    useEffect(() => {
        setSelectedItemNumber(value && data ? data.findIndex(data => data.value === value) : -1);
    }, [data, value]);

    const onTriggerKeyDown: KeyboardEventHandler<HTMLDivElement> = (__event) => {
        const key = __event.key;

        if (key === "Alt" || key === "ArrowDown") {
            keysPressed[key] = true; // record if alt or arrow down keys are pressed
        }

        if (key === " " || key === "Enter" || keysPressed["Alt"] && keysPressed["ArrowDown"]) {
            if (key === " ") {
                __event.preventDefault();
            }

            setDropDownVisible(true);
        }

    };

    const onTriggerKeyUp: KeyboardEventHandler<HTMLDivElement> = (__event) => {
        const key = __event.key;

        if (keysPressed[key]) {
            keysPressed[key] = false;
        }
    };

    const onTriggerClick: MouseEventHandler<HTMLDivElement> = () => {
        setDropDownVisible(true);
    };

    const blurDropdown: MouseEventHandler<HTMLDivElement> = (__event) => {
        setDropDownVisible(false);
    };

    const {defaultTriggerText, errorMsg, validMsg, hideLabel, className, disabled, onChange} = props;
    const showHideIcon = dropDownVisible ?
        <Icon name={IconName.ARROW_DROP_UP} className={styleClass.iconDropdownClose}/> :
        <Icon name={IconName.ARROW_DROP_DOWN} className={styleClass.iconDropdownOpen}/>;
    const currentName: undefined | string = selectedItemNumber !== undefined && selectedItemNumber !== -1 && data ? data[selectedItemNumber].name : undefined;
    const triggerText = currentName || defaultTriggerText || (data || [])[0].name;

    return (
        <React.Fragment>
            {dropDownVisible ? <div className={styleClass.portal} onClick={blurDropdown}/> : null}
            <div
                className={classNames(className, styleClass.root(data && data.length > 0 && dropDownVisible || false))}>
                {hideLabel ? null : <div
                    className={styleClass.dropdownLabel}>{selectedItemNumber === undefined ? " " : defaultTriggerText}</div>}
                <div
                    tabIndex={0}
                    aria-haspopup={'true'}
                    onClick={disabled ? undefined : onTriggerClick}
                    onKeyDown={onTriggerKeyDown}
                    onKeyUp={onTriggerKeyUp}
                    className={styleClass.dropdownTrigger}
                    aria-expanded={dropDownVisible}
                >
                    <span className={styleClass.dropdownTriggerText}>{triggerText}</span>
                    {showHideIcon}
                </div>
                {dropDownVisible ? (
                    <DropdownList
                        data={data || []}
                        onCancel={() => setDropDownVisible(false)}
                        onSelect={(value, index) => {
                            setDropDownVisible(false);
                            setSelectedItemNumber(index);
                            onChange(value, index);
                        }}
                        value={value}
                    />
                ) : null}
                <div className={styleClass.dropdownMsg}>
                    {errorMsg ? <span className={styleClass.errorMsg}>{errorMsg}</span> : " "}
                    {validMsg ? <span className={styleClass.validMsg}>{validMsg}</span> : " "}
                </div>
            </div>
        </React.Fragment>
    );
};

DropdownList.displayName = 'Dropdown';
