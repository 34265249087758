import * as React from 'react';
import * as ReactDOM from "react-dom";
import { BackgroundColor, Width, ZIndex, Overflow } from "../../enums";
import { classNames } from "../../utils";
import { withResponsive, IWithResponsiveProps } from '../../utils';
import { MouseEventHandler } from 'react';

export interface IModalProps extends IWithResponsiveProps {
    open?: boolean;
    onClose?: (e: MouseEvent) => void;
    backdrop?: boolean;
    className?: string;
}

export interface IModalState {
    open: boolean;
}

const ModalContext = React.createContext<(e: MouseEvent) => void>(() => void(0));

export const ModalCloseConsumer = ModalContext.Consumer;

class ModalBase extends React.PureComponent<IModalProps, IModalState> {

    static displayName = 'Modal';

    static styleClass = {
        root: 'pinata-modal',
        backdrop: 'pinata-modal__backdrop',
        dialog: (landscape: boolean, mobile: boolean, className?: string) => classNames(
            'pinata-modal__dialog',
            BackgroundColor.BG_NEUTRAL_1,
            {
                [ZIndex.Z_DIALOG]: mobile,
                [Width.W_FULL]: landscape,
                [Overflow.OVERFLOW_Y_SCROLL]: mobile
            },
            className,
        ),
        content: 'pinata-modal__dialog-content',
        closeBtn: 'pinata-modal__dialog-close',
    };

    static defaultProps = {
        onClose: (__event: MouseEvent) => __event,
        open: false,
        closeLabel: 'Lukk',
        backdrop: false
    };

    // @ts-ignore
    private portalRoot: HTMLElement;
    // @ts-ignore
    private container: HTMLDivElement;
    private id: string;
    private readonly focusField: React.RefObject<HTMLButtonElement>;

    constructor(props: IModalProps) {
        super(props);

        this.id = '_' + Math.random().toString(36).substr(2, 9);
        this.state = {
            open: !!props.open
        };

        this.focusField = React.createRef();
        console.warn("@snoam/pinata Modal component is deprecated. Please use @snoam/mono-modal instead.");

        if (typeof window === 'undefined') {
            return;
        }

        if (this.portalRootExists()) {
            this.portalRoot = document.getElementById('pinata-portal-root')!;
        } else {
            this.portalRoot = document.createElement('div');
            this.portalRoot.setAttribute('id', 'pinata-portal-root');
            document.body.appendChild(this.portalRoot);
        }

        if (!document.querySelector(`#${this.id}`)) {
            this.container = document.createElement('div');
            this.container.setAttribute('id', this.id);
            this.portalRoot.appendChild(this.container);
        } else {
            this.portalRoot = document.getElementById(this.id)!;
        }
    }

    componentDidMount() {

        if (!document) {
            return;
        }

        if (!this.portalRootExists()) {
            return console.error(`Unable to locate div#pinata-portal-root. Remember to add this adjacent to the react-app root.`);
        }

        this.portalRoot = document.getElementById('pinata-portal-root')!;
        this.container = document.createElement('div');

        this.portalRoot.appendChild(this.container);
    }

    componentWillUnmount() {

        if (!document || !this.portalRoot) {
            return;
        }

        this.portalRoot.removeChild(this.container);
    }

    componentDidUpdate(prevProps: IModalProps) {
        if(this.state.open && !prevProps.open && this.focusField.current) {
            this.focusField.current.focus();
        }
    }

    static getDerivedStateFromProps(props: IModalProps, state: IModalState): Partial<IModalState> | null {
        if (state.open !== props.open) {
            return { open: props.open };
        }
        return null;
    }

    private portalRootExists(): boolean {
        return !!document.querySelector('#pinata-portal-root');
    }

    private onClose = (__event: MouseEvent) => {
        __event.preventDefault();
        const { onClose: onCloseProp } = this.props;
        (onCloseProp || (() => {}))(__event);
        this.close();
    };

    private close = () => {
        this.setState({ open: false });
    };

    private onKeyDownEscape = (__event: any) => {
        if(__event.key === "Escape") {
            this.onClose(__event);
        }
    };

    private renderModal() {
        const { backdrop, className } = this.props;
        const { open: isOpen } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <ModalContext.Provider value={this.onClose}>
                <div className={ModalBase.styleClass.root}>
                    <div className={classNames({ [ModalBase.styleClass.backdrop]: backdrop })} onClick={this.onClose as unknown as MouseEventHandler<HTMLElement>} />

                    <div
                        onKeyDown={this.onKeyDownEscape}
                        role="dialog"
                        className={ModalBase.styleClass.dialog(this.props.landscape ? this.props.landscape : false, this.props.mobile ? this.props.mobile : false, className)}
                        aria-modal="true"
                        aria-describedby="modal-content"
                    >
                        <header className="pinata-modal__header">
                            <span />
                            <button
                                className={ModalBase.styleClass.closeBtn}
                                onClick={this.onClose as unknown as MouseEventHandler<HTMLElement>}
                                aria-label={'Lukk'}
																ref={this.focusField}
                            >
                                &#x2715;
                            </button>
                        </header>

                        <div className={ModalBase.styleClass.content} id="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </ModalContext.Provider>
        );
    }

    render() {
        return this.portalRoot ? ReactDOM.createPortal(
            this.renderModal(),
            this.container
        ) : null;
    }
}

export const Modal = withResponsive(ModalBase);
