import * as React from 'react';
import {BackgroundColor} from '../../enums';
import {classNames} from "../../utils";

export enum TransparentSkin {
    NEGATIVE_20 = "negative-20",
    NEGATIVE_40 = "negative-40",
    NEGATIVE_60 = "negative-60",
    NEGATIVE_80 = "negative-80",
    WARNING_20 = "warning-20",
    WARNING_40 = "warning-40",
    WARNING_60 = "warning-60",
    WARNING_80 = "warning-80",
    PRIMARY_DARK_20 = "primary-dark-20",
    PRIMARY_DARK_40 = "primary-dark-40",
    PRIMARY_DARK_60 = "primary-dark-60",
    PRIMARY_DARK_80 = "primary-dark-80",
    INFO_20 = "info-20",
    INFO_40 = "info-40",
    INFO_60 = "info-60",
    INFO_80 = "info-80",
    DEFAULT = '',
}

export enum Arrow {
    TOP_CENTER = "arrow-top-center",
    NO_ARROW = "",
}

export interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
    backgroundColor?: BackgroundColor;
    transparent?: TransparentSkin;
    arrow?: Arrow;
}

export const Card: React.FunctionComponent<ICardProps> = (props) => {

    const styleClass = {
        root: 'pinata-card',
        background: (backgroundColor?: BackgroundColor) => backgroundColor ? backgroundColor : '',
        transparentSkin: (transparent?: TransparentSkin) => transparent ? `pinata-card--skin-${transparent}` : '',
        arrow: (arrow?: Arrow) => arrow ? `pinata-card--${arrow}` : '',
    };

    const {arrow, children, backgroundColor, className, transparent, ...rest} = props;

    const style = classNames(
        styleClass.root,
        styleClass.background(backgroundColor),
        styleClass.transparentSkin(transparent),
        styleClass.arrow(arrow),
        className
    );

    return (
        <div
            {...rest}
            className={style}
        >
            {children}
        </div>
    );

};

Card.displayName = 'Card';
