import * as React from 'react';
import { classNames } from "../../utils";

export interface ITableGroupProps {
    type?: TableGroupType;
    className?: string;
}

export enum TableGroupType {
    HEAD = "head",
    BODY = "body",
    FOOT = "foot",
}

export const TableGroup: React.FunctionComponent<ITableGroupProps> = (props) => {

    const styleClass = {
        root: 'pinata-table__body',
        head: 'pinata-table__head'
    };

    let componentClasses = props.className;

    switch (props.type) {

        case TableGroupType.HEAD:
            componentClasses = classNames(
                styleClass.head,
                componentClasses
            );
            return (
                <thead className={componentClasses}>
                {props.children}
                </thead>
            );

        case TableGroupType.BODY:
            componentClasses = classNames(
                styleClass.root,
                componentClasses
            );
            return (
                <tbody className={componentClasses}>
                {props.children}
                </tbody>
            );

        case TableGroupType.FOOT:
            componentClasses = classNames(
                styleClass.root,
                componentClasses);
            return (
                <tfoot className={componentClasses}>
                {props.children}
                </tfoot>
            );

        default:
            return null;
    }
};

TableGroup.defaultProps = {
    type: TableGroupType.BODY,
};
TableGroup.displayName = "TableGroup";
