import * as React from 'react';
import memoize from "lodash-es/memoize";
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';

export * from './RadioButton';
export * from './RadioButtonEnums';

export interface IRadioProps {
    onChange?: ChangeEventHandler<HTMLInputElement>;
    name: string;
    radioHeading?: string;
    radioHeadingClassNames?: string;
    value?: string;
}

export const RadioContext = React.createContext<IRadioProps & {onClick: (__event: MouseEvent) => void;}>({
    get onClick(): (e: MouseEvent) => void {
        throw new Error('Need to be wrapped in RadioContext.Provider');
    },
    get name(): string {
        throw new Error('Need to be wrapped in RadioContext.Provider');
    },
    get onChange(): (e: ChangeEvent<HTMLInputElement>) => void {
        throw new Error('Need to be wrapped in RadioContext.Provider');
    },
    get value(): string {
        throw new Error('Need to be wrapped in RadioContext.Provider');
    }
});

export const RadioButtonGroup: React.FunctionComponent<IRadioProps> = (props) => {

    const [value, setValue] = useState<string | undefined>(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const onClick = (__event: MouseEvent) => {
        setValue((__event.target as HTMLInputElement).value);
    };

    const createContextValue = memoize((value, name, onChange, onClick) => ({
        value,
        name,
        onChange,
        onClick
    }));

    const {radioHeading, radioHeadingClassNames} = props;

    return (
        <RadioContext.Provider
            value={createContextValue(
                value,
                props.name,
                props.onChange,
                onClick
            )}
        >
            <fieldset className={"radiobutton-group"}>
                {radioHeading && <legend className={radioHeadingClassNames}>{radioHeading}</legend>}
                {props.children}
            </fieldset>
        </RadioContext.Provider>
    );
};

RadioButtonGroup.defaultProps = {
    onChange: (__event) => void(0),
};
