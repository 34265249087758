///-- THIS FILE IS GENERATED --///
export enum TextAlign {

    LG_TEXT_CENTER = "lg:pinata-text-center",

    LG_TEXT_JUSTIFY = "lg:pinata-text-justify",

    LG_TEXT_LEFT = "lg:pinata-text-left",

    LG_TEXT_RIGHT = "lg:pinata-text-right",

    MD_TEXT_CENTER = "md:pinata-text-center",

    MD_TEXT_JUSTIFY = "md:pinata-text-justify",

    MD_TEXT_LEFT = "md:pinata-text-left",

    MD_TEXT_RIGHT = "md:pinata-text-right",

    SM_TEXT_CENTER = "sm:pinata-text-center",

    SM_TEXT_JUSTIFY = "sm:pinata-text-justify",

    SM_TEXT_LEFT = "sm:pinata-text-left",

    SM_TEXT_RIGHT = "sm:pinata-text-right",

    XL_TEXT_CENTER = "xl:pinata-text-center",

    XL_TEXT_JUSTIFY = "xl:pinata-text-justify",

    XL_TEXT_LEFT = "xl:pinata-text-left",

    XL_TEXT_RIGHT = "xl:pinata-text-right",

    XS_TEXT_CENTER = "xs:pinata-text-center",

    XS_TEXT_JUSTIFY = "xs:pinata-text-justify",

    XS_TEXT_LEFT = "xs:pinata-text-left",

    XS_TEXT_RIGHT = "xs:pinata-text-right",

    TEXT_CENTER = "pinata-text-center",

    TEXT_JUSTIFY = "pinata-text-justify",

    TEXT_LEFT = "pinata-text-left",

    TEXT_RIGHT = "pinata-text-right",

}