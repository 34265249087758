import * as React from 'react';
import { classNames } from "../../utils";


export * from './TableRow';
export * from './TableCell';
export * from './TableGroup';

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
}

export const Table: React.FunctionComponent<TableProps> = (props) => {

    const styleClass = {
        root: 'pinata-table',
        collapseSmall: 'pinata-table--collapse-small',
        collapseMedium: 'pinata-table--collapse-medium',
        toggle: 'pinata-table--toggleable',
    };

    const {className, children, ...rest} = props;

    const componentClasses = classNames(
        styleClass.root,
        className,
    );

    return (
        <table
            {...rest}
            className={componentClasses}
            itemScope={true}
            itemType="http://schema.org/Table"
        >
            {children}
        </table>
    );
};

Table.displayName = "Table";
