///-- THIS FILE IS GENERATED --///
export enum Opacity {

    OPACITY_0 = "pinata-opacity-0",

    OPACITY_100 = "pinata-opacity-100",

    OPACITY_25 = "pinata-opacity-25",

    OPACITY_50 = "pinata-opacity-50",

    OPACITY_75 = "pinata-opacity-75",

}