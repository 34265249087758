import * as React from 'react';
import { LoaderColor, LoaderSize, LoaderTextMode } from "./LoaderEnums";
import { Timer } from "../../utils";
import { classNames } from "../../utils";
import { LoaderText } from './LoaderText';
import { useEffect, useState } from 'react';

export * from './LoaderEnums';
export { LoaderText } from './LoaderText';

export interface ILoaderProps {
    show?: boolean;
    text?: string | string[];
    delay?: number;
    textDuration?: number;
    size?: LoaderSize;
    textMode?: LoaderTextMode;
    color?: LoaderColor;
}

export const Loader: React.FunctionComponent<ILoaderProps> = (props) => {

    const styleClass = {
        root: 'pinata-loader',
        inner: 'pinata-loader__inner',
        hidden: 'pinata-loader--hidden',
        color: (color: LoaderColor) => `pinata-loader--color-${color}`,
        size: (size: LoaderSize) => `pinata-loader--size-${size}`
    };

    let timeoutId: number;
    const [show, setShow] = useState<boolean>(!!props.show && !props.delay);

    useEffect(() => {
        const {delay, show} = props;
        if (typeof delay === 'number' && show) {
            timeoutId = Timer.setTimeout(() => {
                setShow(show);
            }, delay);

            return () => {
                Timer.clearTimeout(timeoutId);
            };
        } else {
            setShow(!!show);
        }
    }, [props.show]);

    const {color, size, text, textDuration, textMode} = props;

    const loaderInner = classNames(
        styleClass.inner,
    );

    const loader = classNames(
        styleClass.root,
        {[styleClass.hidden]: !show},
        {[styleClass.size(size || LoaderSize.BASE)]: size},
        {[styleClass.color(color || LoaderColor.BLACK)]: color},
    );

    return (
        <div className={loader}>
            <div className={loaderInner}>
                <div className="pinata-loader__bounce-1"/>
                <div className="pinata-loader__bounce-2"/>
                <div className="pinata-loader__bounce-3"/>
            </div>
            {show && <LoaderText text={text || "Vennligst vent"} textDuration={textDuration} textMode={textMode}/>}
        </div>
    );
};

Loader.defaultProps = {
    show: true,
    color: LoaderColor.BLACK,
    size: LoaderSize.BASE,
    textMode: LoaderTextMode.LOOP,
    text: "Vennligst vent",
    textDuration: 2000,
};
Loader.displayName = "Loader";
