import * as React from 'react';
import { useState } from 'react';
import { FocusEventHandler } from 'react';
import { KeyboardEventHandler } from 'react';
import {classNames} from '../../utils';
import {Position} from '../../enums';

export interface IJumpToContentProps {
    labelText?: String;
    floating?: boolean;
}

const styleClass = {
    root: (floating: boolean | undefined) => classNames(
        'jump-to-content',
        {
            [Position.ABSOLUTE]: floating,
            [Position.TOP_0]: floating,
            [Position.LEFT_0]: floating,
        }
    )
};

export const JumpToContent: React.FunctionComponent<IJumpToContentProps> = (props) => {

    const [showLabel, setShowLabel] = useState<boolean>(false);
    const {floating} = props;

    const onFocus: FocusEventHandler<HTMLDivElement> = () => {
        setShowLabel(true);
    };

    const onBlur: FocusEventHandler<HTMLDivElement> = () => {
        setShowLabel(false);
    };

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (__event) => {
        if (__event.key === "Enter") {
            const main: HTMLElement | null = document.querySelector('main');
            if (main) {
                main.tabIndex = 0;
                main.focus();
            }
        }
    };

    return (
        <React.Fragment>
            <div
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                tabIndex={0}
                className={styleClass.root(floating)}
            >
                {showLabel ? props.labelText : null}
            </div>
            {props.children}
        </React.Fragment>
    );
};

JumpToContent.defaultProps = {
    labelText: 'Gå til innhold'
};
