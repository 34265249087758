///-- THIS FILE IS GENERATED --///
export enum Position {

    LG_ABSOLUTE = "lg:pinata-absolute",

    LG_BOTTOM_0 = "lg:pinata-bottom-0",

    LG_BOTTOM_AUTO = "lg:pinata-bottom-auto",

    LG_FIXED = "lg:pinata-fixed",

    LG_LEFT_0 = "lg:pinata-left-0",

    LG_LEFT_AUTO = "lg:pinata-left-auto",

    LG_RELATIVE = "lg:pinata-relative",

    LG_RIGHT_0 = "lg:pinata-right-0",

    LG_RIGHT_AUTO = "lg:pinata-right-auto",

    LG_STATIC = "lg:pinata-static",

    LG_STICKY = "lg:pinata-sticky",

    LG_TOP_0 = "lg:pinata-top-0",

    LG_TOP_AUTO = "lg:pinata-top-auto",

    MD_ABSOLUTE = "md:pinata-absolute",

    MD_BOTTOM_0 = "md:pinata-bottom-0",

    MD_BOTTOM_AUTO = "md:pinata-bottom-auto",

    MD_FIXED = "md:pinata-fixed",

    MD_LEFT_0 = "md:pinata-left-0",

    MD_LEFT_AUTO = "md:pinata-left-auto",

    MD_RELATIVE = "md:pinata-relative",

    MD_RIGHT_0 = "md:pinata-right-0",

    MD_RIGHT_AUTO = "md:pinata-right-auto",

    MD_STATIC = "md:pinata-static",

    MD_STICKY = "md:pinata-sticky",

    MD_TOP_0 = "md:pinata-top-0",

    MD_TOP_AUTO = "md:pinata-top-auto",

    SM_ABSOLUTE = "sm:pinata-absolute",

    SM_BOTTOM_0 = "sm:pinata-bottom-0",

    SM_BOTTOM_AUTO = "sm:pinata-bottom-auto",

    SM_FIXED = "sm:pinata-fixed",

    SM_LEFT_0 = "sm:pinata-left-0",

    SM_LEFT_AUTO = "sm:pinata-left-auto",

    SM_RELATIVE = "sm:pinata-relative",

    SM_RIGHT_0 = "sm:pinata-right-0",

    SM_RIGHT_AUTO = "sm:pinata-right-auto",

    SM_STATIC = "sm:pinata-static",

    SM_STICKY = "sm:pinata-sticky",

    SM_TOP_0 = "sm:pinata-top-0",

    SM_TOP_AUTO = "sm:pinata-top-auto",

    XL_ABSOLUTE = "xl:pinata-absolute",

    XL_BOTTOM_0 = "xl:pinata-bottom-0",

    XL_BOTTOM_AUTO = "xl:pinata-bottom-auto",

    XL_FIXED = "xl:pinata-fixed",

    XL_LEFT_0 = "xl:pinata-left-0",

    XL_LEFT_AUTO = "xl:pinata-left-auto",

    XL_RELATIVE = "xl:pinata-relative",

    XL_RIGHT_0 = "xl:pinata-right-0",

    XL_RIGHT_AUTO = "xl:pinata-right-auto",

    XL_STATIC = "xl:pinata-static",

    XL_STICKY = "xl:pinata-sticky",

    XL_TOP_0 = "xl:pinata-top-0",

    XL_TOP_AUTO = "xl:pinata-top-auto",

    XS_ABSOLUTE = "xs:pinata-absolute",

    XS_BOTTOM_0 = "xs:pinata-bottom-0",

    XS_BOTTOM_AUTO = "xs:pinata-bottom-auto",

    XS_FIXED = "xs:pinata-fixed",

    XS_LEFT_0 = "xs:pinata-left-0",

    XS_LEFT_AUTO = "xs:pinata-left-auto",

    XS_RELATIVE = "xs:pinata-relative",

    XS_RIGHT_0 = "xs:pinata-right-0",

    XS_RIGHT_AUTO = "xs:pinata-right-auto",

    XS_STATIC = "xs:pinata-static",

    XS_STICKY = "xs:pinata-sticky",

    XS_TOP_0 = "xs:pinata-top-0",

    XS_TOP_AUTO = "xs:pinata-top-auto",

    ABSOLUTE = "pinata-absolute",

    BOTTOM_0 = "pinata-bottom-0",

    BOTTOM_AUTO = "pinata-bottom-auto",

    FIXED = "pinata-fixed",

    LEFT_0 = "pinata-left-0",

    LEFT_AUTO = "pinata-left-auto",

    RELATIVE = "pinata-relative",

    RIGHT_0 = "pinata-right-0",

    RIGHT_AUTO = "pinata-right-auto",

    STATIC = "pinata-static",

    STICKY = "pinata-sticky",

    TOP_0 = "pinata-top-0",

    TOP_AUTO = "pinata-top-auto",

}