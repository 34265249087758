import * as React from 'react';
import { classNames } from "../../utils";
import { Icon, IconName, IconSize } from "../icon";
import { Card, TransparentSkin } from "../card";
import { BackgroundColor } from "../../enums";
import { AccordionIconPlacement } from '.';
import { KeyboardEventHandler } from 'react';
import { ReactNode } from 'react';

const styleClass = {
    root: classNames(
        'pinata-panel'
    ),
    title: {
        root: (expanded: boolean) => classNames(
            'pinata-panel__title',
            { ['pinata-panel__title--is-expanded']: expanded }
        ),
        container: classNames(
            'pinata-panel__title-container'
        ),
        inner: classNames(
            'pinata-panel__title-container__inner'
        ),
        icon: classNames('pinata-panel__icon-container')
    },

    content: (expanded: boolean) => classNames(
        'pinata-panel__content',
        { ['pinata-panel__content--is-expanded']: expanded }
    ),
};


type AccordionPanelProps = Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, "className"> & {
    expand?: boolean;
    title: ReactNode;
    iconClose?: any;
    iconOpen?: any;
    titleClasses?: string;
    contentClasses?: string;
    titleBackgroundColor?: BackgroundColor;
    titleSkin?: TransparentSkin;
    iconPlacement?: AccordionIconPlacement;
    onClick?: (e: any) => void;
}

export const AccordionPanel: React.FunctionComponent<AccordionPanelProps> = (props) => {

    const onKeyDown: KeyboardEventHandler<HTMLDivElement> = (__event) => {
        if(__event.key === "Enter") {
            (props.onClick || (() => {}))(__event);
        }
    };

    const {
        title,
        expand,
        onClick,
        children,
        iconOpen,
        titleSkin,
        iconClose,
        titleClasses,
        contentClasses,
        titleBackgroundColor,
        iconPlacement,
        className
    } = props;

    const titleWrapperClasses = classNames(styleClass.title.root(!!expand), titleClasses, className);
    const contentWrapperClasses = classNames(styleClass.content(!!expand), contentClasses);

    return (
        <React.Fragment>
            <Card
                className={titleWrapperClasses}
                onClick={onClick}
                onKeyDown={onKeyDown}
                backgroundColor={titleBackgroundColor}
                transparent={titleSkin}
                tabIndex={0}
            >
                <div className={styleClass.title.container}>
                    {iconPlacement == AccordionIconPlacement.LEFT ?
                        <div className={styleClass.title.icon}>
                            {expand ? iconClose : iconOpen}
                        </div>
                        : null}
                    <div className={styleClass.title.inner}>
                        {title}
                    </div>
                    {iconPlacement == AccordionIconPlacement.RIGHT ?
                        <div className={styleClass.title.icon}>
                            {expand ? iconClose : iconOpen}
                        </div>
                        : null}


                </div>
            </Card>
            {expand ? <div className={contentWrapperClasses}>
                {children}
            </div> : null}
        </React.Fragment>
    );
};

AccordionPanel.defaultProps = {
    iconClose: <Icon name={IconName.CLOSE} size={IconSize.BASE} />,
    iconOpen: <Icon name={IconName.MINUS} size={IconSize.BASE} />
};
