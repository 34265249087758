///-- THIS FILE IS GENERATED --///
export enum Display {

    LG_BLOCK = "lg:pinata-block",

    LG_FLEX = "lg:pinata-flex",

    LG_HIDDEN = "lg:pinata-hidden",

    LG_INLINE_BLOCK = "lg:pinata-inline-block",

    LG_INLINE_FLEX = "lg:pinata-inline-flex",

    LG_TABLE = "lg:pinata-table",

    LG_TABLE_CELL = "lg:pinata-table-cell",

    LG_TABLE_ROW = "lg:pinata-table-row",

    MD_BLOCK = "md:pinata-block",

    MD_FLEX = "md:pinata-flex",

    MD_HIDDEN = "md:pinata-hidden",

    MD_INLINE_BLOCK = "md:pinata-inline-block",

    MD_INLINE_FLEX = "md:pinata-inline-flex",

    MD_TABLE = "md:pinata-table",

    MD_TABLE_CELL = "md:pinata-table-cell",

    MD_TABLE_ROW = "md:pinata-table-row",

    SM_BLOCK = "sm:pinata-block",

    SM_FLEX = "sm:pinata-flex",

    SM_HIDDEN = "sm:pinata-hidden",

    SM_INLINE_BLOCK = "sm:pinata-inline-block",

    SM_INLINE_FLEX = "sm:pinata-inline-flex",

    SM_TABLE = "sm:pinata-table",

    SM_TABLE_CELL = "sm:pinata-table-cell",

    SM_TABLE_ROW = "sm:pinata-table-row",

    XL_BLOCK = "xl:pinata-block",

    XL_FLEX = "xl:pinata-flex",

    XL_HIDDEN = "xl:pinata-hidden",

    XL_INLINE_BLOCK = "xl:pinata-inline-block",

    XL_INLINE_FLEX = "xl:pinata-inline-flex",

    XL_TABLE = "xl:pinata-table",

    XL_TABLE_CELL = "xl:pinata-table-cell",

    XL_TABLE_ROW = "xl:pinata-table-row",

    XS_BLOCK = "xs:pinata-block",

    XS_FLEX = "xs:pinata-flex",

    XS_HIDDEN = "xs:pinata-hidden",

    XS_INLINE_BLOCK = "xs:pinata-inline-block",

    XS_INLINE_FLEX = "xs:pinata-inline-flex",

    XS_TABLE = "xs:pinata-table",

    XS_TABLE_CELL = "xs:pinata-table-cell",

    XS_TABLE_ROW = "xs:pinata-table-row",

    BLOCK = "pinata-block",

    FLEX = "pinata-flex",

    HIDDEN = "pinata-hidden",

    INLINE_BLOCK = "pinata-inline-block",

    INLINE_FLEX = "pinata-inline-flex",

    TABLE = "pinata-table",

    TABLE_CELL = "pinata-table-cell",

    TABLE_ROW = "pinata-table-row",

}