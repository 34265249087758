import * as React from 'react';
import { ReactNode, useState } from 'react';

import {classNames} from "../../utils";
import {AccordionPanel} from "./AccordionPanel";

export * from "./AccordionPanel";

const styleClass = {
    root: classNames(
        'pinata-accordion'
    )
};

export enum AccordionIconPlacement {
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}

interface IAccordionProps {
    onTabToggle?: (index: number) => void;
    iconClose?: React.ReactNode;
    iconOpen?: React.ReactNode;
    multiple?: boolean;
    titleClasses?: string;
    contentClasses?: string;
    iconPlacement?: AccordionIconPlacement;
    children?: ReactNode;
}

interface IAccordionState {
    [k: string]: boolean;
}

export const Accordion: React.FunctionComponent<IAccordionProps> = (props) => {

    const getExpandedState = () => {
        const {children} = props;
        const initialState: IAccordionState = {};
        React.Children.forEach(children, (child: any, index: number) => {
            if (child && child.type === AccordionPanel) {
                initialState[`Panel${index}`] = child.props.expand ? child.props.expand : false
            }
        });
        return initialState;
    };

    const toggle = (index: number) => {
        const {multiple} = props;
        const tmpState: any = Object.assign({}, state);

        if (!multiple) {
            for (let propName in tmpState) {
                if (!tmpState.hasOwnProperty(propName)) {
                    continue;
                }
                if (propName === `Panel${index}`) {
                    continue;
                }

                tmpState[propName] = false;
            }
        }

        tmpState[`Panel${index}`] = !tmpState[`Panel${index}`];

        setState(tmpState);
        props.onTabToggle!(index);
    };

    const [state, setState] = useState<IAccordionState>(getExpandedState());
    const [children, setChildren] = useState<ReactNode>(null);

    if (children !== props.children) {
        setChildren(props.children);
        setState(getExpandedState());
    }

    return (
        <div className={styleClass.root}>
            {
                React.Children.map(children, (child: any, index: number) => {
                    if(!child) {
                        return null;
                    }
                    const {onClick} = child.props;

                    const onAccordionPanelClick = (e: MouseEvent) => {
                        toggle(index);
                        onClick && onClick(e);
                    };

                    return React.cloneElement(child, {
                        ...child.props,
                        onClick: (e: MouseEvent) => onAccordionPanelClick(e),
                        expand: state[`Panel${index}`],
                        iconClose: props.iconClose,
                        iconOpen: props.iconOpen,
                        titleClasses: child.props.titleClasses || props.titleClasses,
                        contentClasses: props.contentClasses,
                        iconPlacement: props.iconPlacement
                    });
                })
            }
        </div>
    );
};
Accordion.defaultProps = {
    onTabToggle: () => void(0),
    iconClose: undefined,
    iconOpen: undefined,
    titleClasses: undefined,
    iconPlacement: AccordionIconPlacement.RIGHT
};
