///-- THIS FILE IS GENERATED --///
export enum VerticalAlign {

    ALIGN_BASELINE = "pinata-align-baseline",

    ALIGN_BOTTOM = "pinata-align-bottom",

    ALIGN_MIDDLE = "pinata-align-middle",

    ALIGN_TEXT_BOTTOM = "pinata-align-text-bottom",

    ALIGN_TEXT_TOP = "pinata-align-text-top",

    ALIGN_TOP = "pinata-align-top",

}