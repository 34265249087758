import * as React from 'react';

const onlyRenderInBrowser = <P extends Object>(Component: React.ComponentType<P>) =>
    class extends React.PureComponent<P> {
        render() {
            const shouldRender = typeof window !== 'undefined';
            if (shouldRender) return <Component {...this.props} />;
            return null;
        }
    };

export default onlyRenderInBrowser;
