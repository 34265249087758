import * as React from 'react';
import { classNames } from "../../utils";
import { ButtonSize, ButtonSkin } from "./ButtonEnums";
import { TextDecoration } from "../../enums";
import { Icon, IconName } from "../icon";

export * from "./ButtonEnums";

enum IconPosition {
    LEFT = 'l',
    RIGHT = 'r',
    BOTH = 'lr',
}

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement> {
    size?: ButtonSize;
    skin?: ButtonSkin;
    text?: string;
    href?: string;
    download?: string;
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    forwardedRef?: React.Ref<any>;
    composed?: boolean;
    loading?: boolean;
    loadingText?: string;
    target?: string;
    ariaLabel?: string;
}

const styleClass = {
    root: 'pinata-btn',
    skin: (skin: ButtonSkin) => `pinata-btn-skin--${skin}`,
    size: {
        root: (size: ButtonSize) => `pinata-btn-size--${size}`,
        icon: (iconPos: IconPosition | null) => iconPos ? `pinata-btn-icon--${iconPos}` : '',
        text: (size: ButtonSize) => `pinata-btn-size--${size}__text`,
    },
    text: 'pinata-btn-text',
    loading: 'pinata-btn__loading'
};

export const Button: React.FunctionComponent<IButtonProps> = (props) => {

    const renderContents = () => {
        const {size: buttonSize, text, iconLeft, iconRight, onClick, composed} = props;
        const size = styleClass.size;

        return (
            <React.Fragment>
                {iconLeft}
                <span
                    onClick={composed ? onClick : undefined}
                    className={classNames(size.text(buttonSize!), styleClass.text)}
                >
                    {text}
                </span>
                {iconRight}
            </React.Fragment>
        );
    };

    const getIconPosition = (): IconPosition | null => {
        const {iconLeft, iconRight} = props;

        if (iconLeft && !iconRight) {
            return IconPosition.LEFT;
        }
        if (!iconLeft && iconRight) {
            return IconPosition.RIGHT;
        }
        if (iconLeft && iconRight) {
            return IconPosition.BOTH;
        }
        return null;
    };

    const renderLinkButton = () => {
        const {
            children,
            className,
            size: buttonSize,
            skin: buttonSkin,
            text,
            iconLeft,
            iconRight,
            forwardedRef,
            composed,
            loading,
            loadingText,
            target,
            ariaLabel,
            ...rest
        } = props;

        const size = styleClass.size;
        const skin = styleClass.skin(buttonSkin!);
        const iconPos = getIconPosition();

        const buttonClasses = classNames(
            styleClass.root,
            {[size.icon(iconPos)]: iconPos},
            size.root(buttonSize!),
            skin,
            className,
            TextDecoration.NO_UNDERLINE
        );

        return (
            <a
                {...rest}
                target={target}
                ref={forwardedRef}
                className={buttonClasses}
                aria-label={ariaLabel || text || ''}
            >
                {renderContents()}
            </a>);
    };

    const renderComposedButton = () => {
        const {
            children,
            className,
            size: buttonSize,
            skin: buttonSkin,
            text,
            iconLeft,
            iconRight,
            forwardedRef,
            composed,
            onClick,
            loading,
            loadingText,
            ariaLabel,
            ...rest
        } = props;

        const size = styleClass.size;
        const skin = styleClass.skin(buttonSkin!);
        const iconPos = getIconPosition();

        const buttonClasses = classNames(
            styleClass.root,
            {[size.icon(iconPos)]: iconPos},
            size.root(buttonSize!),
            skin,
            className,
        );

        return (
            <div
                {...rest}
                role="button"
                ref={forwardedRef}
                className={buttonClasses}
                tabIndex={0}
                aria-label={ariaLabel || text || ''}
            >
                {renderContents()}
            </div>);
    };

    const renderLoaderButton = () => {
        const {
            children,
            className,
            size: buttonSize,
            skin: buttonSkin,
            text,
            iconLeft,
            iconRight,
            forwardedRef,
            composed,
            onClick,
            loading,
            loadingText,
            ariaLabel,
            ...rest
        } = props;

        const size = styleClass.size;
        const skin = styleClass.skin(buttonSkin!);

        const buttonClasses = classNames(
            styleClass.root,
            styleClass.loading,
            size.root(buttonSize!),
            skin,
            className,
        );

        return (
            <button
                {...rest}
                ref={forwardedRef}
                className={buttonClasses}
                aria-label={ariaLabel || loadingText || text || ''}
            >
                <span
                    onClick={composed ? onClick : undefined}
                    className={classNames(size.text(buttonSize!), styleClass.text)}
                >
                    {loadingText}
                </span>
                <Icon name={IconName.LOADING_WHEEL}/>
            </button>
        )
    };

    const renderButton = () => {
        const {
            children,
            className,
            size: buttonSize,
            skin: buttonSkin,
            text,
            iconLeft,
            iconRight,
            forwardedRef,
            composed,
            loading,
            loadingText,
            ariaLabel,
            ...rest
        } = props;

        const size = styleClass.size;
        const skin = styleClass.skin(buttonSkin!);
        const iconPos = getIconPosition();

        const buttonClasses = classNames(
            styleClass.root,
            {[size.icon(iconPos)]: iconPos},
            size.root(buttonSize!),
            skin,
            className,
        );

        return (
            <button
                {...rest}
                ref={forwardedRef}
                className={buttonClasses}
                aria-label={ariaLabel || text || ''}
            >
                {renderContents()}
            </button>);
    };


    const {composed, href, loading, ariaLabel, text} = props;

    if (!ariaLabel && !text) {
        console.warn("Button is missing the aria-label attribute. Please use the prop ariaLabel on your component to improve Accessibility if it doesn't have a text.");
    }

    if (loading) return renderLoaderButton();

    if (composed) return renderComposedButton();

    if (href) return renderLinkButton();

    return renderButton();
};

Button.defaultProps = {
    size: ButtonSize.MEDIUM,
    skin: ButtonSkin.PRIMARY,
    loading: false,
    loadingText: 'Laster',
};
Button.displayName = 'Button';
