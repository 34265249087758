import * as React from 'react';
import { classNames } from "../../utils";

export interface ITabProps extends React.HTMLAttributes<HTMLLIElement> {
    onClick?: (...args: any[]) => void; // Note that it does not work to send in onClick to Tab. Use onTabClick on Tabs instead.
    tabIndex?: number;
    linkClassName?: string;
    activeLinkClassName?: string;
    isActive?: boolean;
    label: string | JSX.Element;
}

export const Tab: React.FunctionComponent<ITabProps> = (props) => {

    const styleClass = {
        root: 'pinata-tab',
        link: 'pinata-tab__link',
        active: 'pinata-tab--active'
    };

    const {tabIndex, onClick, linkClassName, activeLinkClassName = '', isActive, label, className, ...rest} = props;

    const linkClasses = classNames(
        styleClass.link,
        linkClassName,
        {[activeLinkClassName]: isActive},
    );

    const rootClasses = classNames(
        styleClass.root,
        {[styleClass.active]: isActive},
        className
    );

    return (
        <li
            {...rest}
            className={rootClasses}
        >
            <a className={linkClasses}
               role="link"
               tabIndex={0}
               onClick={(__event) => {
                   __event.preventDefault();
                   (onClick || (() => {}))(tabIndex);
               }}>
                {label}
            </a>
        </li>
    );
};

Tab.displayName = "Tab";
