import * as React from 'react';
import { FocusEventHandler, KeyboardEventHandler, MouseEventHandler, RefObject, useState } from 'react';

import { Input } from '../input/index';

export interface IAutosuggestProps extends React.InputHTMLAttributes<HTMLInputElement> {
    data?: IAutoSuggestListItem[];
    label?: string;
    errorMsg?: string;
    validMsg?: boolean | string;
    clearonFocus?: boolean;
    disableEventOnTab?: boolean;
}

export interface IFilteringAutosuggestProps extends IAutosuggestProps {
    data: Required<IAutoSuggestListItem>[];
    value: string;
}

export interface IAutoSuggestListItem {
    content: JSX.Element;
    onClick: () => void;
    value?: string;
}

export interface IAutosuggestState {
    selectedItem: number;
    dropDownVisible: boolean;
}

const styleClass = {
    root: 'pinata-autosuggest',
    dropdown: (isVisible: boolean) => isVisible ? 'pinata-autosuggest--dropdown' : 'pinata-autosuggest--dropdown dropdown-hidden',
    dropdownItem: (isSelected: boolean) => isSelected ? 'pinata-autosuggest--dropdown-item pinata-autosuggest--selected' : 'pinata-autosuggest--dropdown-item',
    dropdownITemInner: 'pinata-autosuggest--dropdown-item-inner',
    portal: 'pinata-autosuggest--portal'
};

export { FilteringAutosuggest } from './FilteringAutosuggest';

export const Autosuggest: React.FunctionComponent<IAutosuggestProps> = (props) => {

    const data: IAutoSuggestListItem[] = props.data || [];
    const [state, setState] = useState<IAutosuggestState>({
        selectedItem: props.value ? Math.max(data.findIndex((item) => item.value === props.value), 0) : 0,
        dropDownVisible: false,
    });

    const refs: { [k: number]: RefObject<HTMLDivElement> } = {};

    const onKeypress: KeyboardEventHandler<HTMLInputElement> = (__event: any) => {
        const key = __event.key;
        const {disableEventOnTab} = props;
        const {selectedItem} = state;

        if (key === "ArrowDown") {
            if (selectedItem < data.length - 1) {
                const ref = refs[selectedItem].current;
                ref!.scrollIntoView({block: 'start', behavior: 'smooth'});
                setState({
                    ...state,
                    selectedItem: state.selectedItem + 1
                });
            }
        }

        if (key === "ArrowUp") {
            if (selectedItem !== 0) {
                const ref = refs[selectedItem].current;
                ref!.scrollIntoView({block: 'end', behavior: 'smooth'});
                setState({
                    ...state,
                    selectedItem: state.selectedItem - 1
                });
            }
        }

        if (key === "Enter") {
            __event.preventDefault();
            __event.target.blur();
            if (data[selectedItem]) {
                data[selectedItem].onClick();
            }
            setState({
                dropDownVisible: false,
                selectedItem: 0
            });
        }

        if (key === "Tab") {
            if (!disableEventOnTab) {
                if (data[selectedItem]) {
                    data[selectedItem].onClick();
                }
            }
            setState({
                dropDownVisible: false,
                selectedItem: 0
            });
        }
    };

    const onItemClick: (onClick: () => void, index: number) => MouseEventHandler<HTMLDivElement> = (onClick, index) => () => {
        onClick();
        setState({
            selectedItem: index,
            dropDownVisible: false,
        });
    };

    const onFocus: FocusEventHandler<HTMLInputElement> = (e) => {
        const {clearonFocus} = props;
        if (clearonFocus) {
            e.target.value = "";
        }
        setState({
            ...state,
            dropDownVisible: true,
        })
    };

    const blurDropdown: FocusEventHandler<HTMLInputElement> = (__event) => {
        if(__event.target.classList.contains('pinata-input')) {
            return;
        }
        setState({
            ...state,
            dropDownVisible: false,
        });
    };

    const getSearchLengthResponse = () => {
        if (data.length > 0) {
            return `${data.length} søkeforslag, naviger med opp/ned piltastene. Trykk enter for å velge søkeforslag.`;
        }
        if (data.length === 0) {
            return '0 søkeforslag, prøv å spesifiser søket ditt litt bedre';
        }
        return null;
    };

    const {clearonFocus, disableEventOnTab, ...rest} = props;
    const {dropDownVisible} = state;

    return (
        <React.Fragment>
            <div className={styleClass.root}>
                <div aria-live={'polite'} style={{display: 'none'}}>
                    {state.dropDownVisible ? getSearchLengthResponse() : null}
                </div>
                <Input
                    {...rest}
                    tabIndex={0}
                    aria-haspopup={'true'}
                    onFocus={onFocus}
                    onKeyDown={onKeypress}
                    onBlur={blurDropdown}
                    autoComplete="off"
                    label={props.label}
                    validMsg={props.validMsg}
                    errorMsg={props.errorMsg}
                />
                <div className={styleClass.dropdown(data && data.length > 0 && dropDownVisible)}>
                    {
                        data.map((item: IAutoSuggestListItem, key: number) => {
                            const ref = refs[key] = React.createRef<HTMLDivElement>();
                            return (
                                <div
                                    key={`autosuggest-item--${key}`}
                                    className={styleClass.dropdownItem(key === state.selectedItem)}
                                    ref={ref}
                                    onClick={onItemClick(item.onClick, key)}>
                                    <div className={styleClass.dropdownITemInner}>
                                        {item.content}
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </React.Fragment>
    );
};

Autosuggest.displayName = 'Autosuggest';
