export enum GridComposition {

    DEFAULT = '',

    ALPHA = 'alpha',

    BRAVO = 'bravo',

    CHARLIE = 'charlie',

    DELTA = 'delta',

    ECHO = 'echo',

    FOXTROT = 'foxtrot',

    GOLF = 'golf',

    HOTEL = 'hotel',

}

export enum GridDirection {

    DEFAULT = '',

    ROW = 'row',

    COLUMN = 'column',

}

export enum GridSpan {

    DEFAULT = '',

    ALL_1 = 'all-1',

    ALL_2 = 'all-2',

    ALL_3 = 'all-3',

    ALL_4 = 'all-4',

    ALL_5 = 'all-5',

    ALL_6 = 'all-6',

    ALL_7 = 'all-7',

    ALL_8 = 'all-8',

    ALL_9 = 'all-9',

    ALL_10 = 'all-10',

    ALL_11 = 'all-11',

    ALL_12 = 'all-12',

    XSMALL_1 = 'xs-1',

    XSMALL_2 = 'xs-2',

    XSMALL_3 = 'xs-3',

    XSMALL_4 = 'xs-4',

    XSMALL_5 = 'xs-5',

    XSMALL_6 = 'xs-6',

    XSMALL_7 = 'xs-7',

    XSMALL_8 = 'xs-8',

    XSMALL_9 = 'xs-9',

    XSMALL_10 = 'xs-10',

    XSMALL_11 = 'xs-11',

    XSMALL_12 = 'xs-12',

    SMALL_1 = 'sm-1',

    SMALL_2 = 'sm-2',

    SMALL_3 = 'sm-3',

    SMALL_4 = 'sm-4',

    SMALL_5 = 'sm-5',

    SMALL_6 = 'sm-6',

    SMALL_7 = 'sm-7',

    SMALL_8 = 'sm-8',

    SMALL_9 = 'sm-9',

    SMALL_10 = 'sm-10',

    SMALL_11 = 'sm-11',

    SMALL_12 = 'sm-12',

    MEDIUM_1 = 'md-1',

    MEDIUM_2 = 'md-2',

    MEDIUM_3 = 'md-3',

    MEDIUM_4 = 'md-4',

    MEDIUM_5 = 'md-5',

    MEDIUM_6 = 'md-6',

    MEDIUM_7 = 'md-7',

    MEDIUM_8 = 'md-8',

    MEDIUM_9 = 'md-9',

    MEDIUM_10 = 'md-10',

    MEDIUM_11 = 'md-11',

    MEDIUM_12 = 'md-12',

    LARGE_1 = 'lg-1',

    LARGE_2 = 'lg-2',

    LARGE_3 = 'lg-3',

    LARGE_4 = 'lg-4',

    LARGE_5 = 'lg-5',

    LARGE_6 = 'lg-6',

    LARGE_7 = 'lg-7',

    LARGE_8 = 'lg-8',

    LARGE_9 = 'lg-9',

    LARGE_10 = 'lg-10',

    LARGE_11 = 'lg-11',

    LARGE_12 = 'lg-12',
}

export enum GridGutter {
    NONE = 'none',
    LEFT_NONE = 'left-none',
    RIGHT_NONE = 'right-none',
    TOP_NONE = 'top-none',
    BOTTOM_NONE = 'bottom-none',
}

export enum GridOffset {
    DEFAULT = '',

    ALL_1 = 'all-1',

    ALL_2 = 'all-2',

    ALL_3 = 'all-3',

    ALL_4 = 'all-4',

    ALL_5 = 'all-5',

    ALL_6 = 'all-6',

    ALL_7 = 'all-7',

    ALL_8 = 'all-8',

    ALL_9 = 'all-9',

    ALL_10 = 'all-10',

    ALL_11 = 'all-11',

    SMALL_1 = 'sm-1',

    SMALL_2 = 'sm-2',

    SMALL_3 = 'sm-3',

    SMALL_4 = 'sm-4',

    SMALL_5 = 'sm-5',

    SMALL_6 = 'sm-6',

    SMALL_7 = 'sm-7',

    SMALL_8 = 'sm-8',

    SMALL_9 = 'sm-9',

    SMALL_10 = 'sm-10',

    SMALL_11 = 'sm-11',

    MEDIUM_1 = 'md-1',

    MEDIUM_2 = 'md-2',

    MEDIUM_3 = 'md-3',

    MEDIUM_4 = 'md-4',

    MEDIUM_5 = 'md-5',

    MEDIUM_6 = 'md-6',

    MEDIUM_7 = 'md-7',

    MEDIUM_8 = 'md-8',

    MEDIUM_9 = 'md-9',

    MEDIUM_10 = 'md-10',

    MEDIUM_11 = 'md-11',

    LARGE_1 = 'lg-1',

    LARGE_2 = 'lg-2',

    LARGE_3 = 'lg-3',

    LARGE_4 = 'lg-4',

    LARGE_5 = 'lg-5',

    LARGE_6 = 'lg-6',

    LARGE_7 = 'lg-7',

    LARGE_8 = 'lg-8',

    LARGE_9 = 'lg-9',

    LARGE_10 = 'lg-10',

    LARGE_11 = 'lg-11',
}
