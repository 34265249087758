///-- THIS FILE IS GENERATED --///
export enum Width {

    LG_W_0 = "lg:pinata-w-0",

    LG_W_1 = "lg:pinata-w-1",

    LG_W_1_12 = "lg:pinata-w-1/12",

    LG_W_1_2 = "lg:pinata-w-1/2",

    LG_W_1_3 = "lg:pinata-w-1/3",

    LG_W_1_4 = "lg:pinata-w-1/4",

    LG_W_1_5 = "lg:pinata-w-1/5",

    LG_W_1_6 = "lg:pinata-w-1/6",

    LG_W_10 = "lg:pinata-w-10",

    LG_W_10_12 = "lg:pinata-w-10/12",

    LG_W_11_12 = "lg:pinata-w-11/12",

    LG_W_12 = "lg:pinata-w-12",

    LG_W_14 = "lg:pinata-w-14",

    LG_W_16 = "lg:pinata-w-16",

    LG_W_2 = "lg:pinata-w-2",

    LG_W_2_12 = "lg:pinata-w-2/12",

    LG_W_2_3 = "lg:pinata-w-2/3",

    LG_W_2_4 = "lg:pinata-w-2/4",

    LG_W_2_5 = "lg:pinata-w-2/5",

    LG_W_2_6 = "lg:pinata-w-2/6",

    LG_W_20 = "lg:pinata-w-20",

    LG_W_24 = "lg:pinata-w-24",

    LG_W_2XL = "lg:pinata-w-2xl",

    LG_W_3 = "lg:pinata-w-3",

    LG_W_3_12 = "lg:pinata-w-3/12",

    LG_W_3_4 = "lg:pinata-w-3/4",

    LG_W_3_5 = "lg:pinata-w-3/5",

    LG_W_3_6 = "lg:pinata-w-3/6",

    LG_W_32 = "lg:pinata-w-32",

    LG_W_3XL = "lg:pinata-w-3xl",

    LG_W_4 = "lg:pinata-w-4",

    LG_W_4_12 = "lg:pinata-w-4/12",

    LG_W_4_5 = "lg:pinata-w-4/5",

    LG_W_4_6 = "lg:pinata-w-4/6",

    LG_W_40 = "lg:pinata-w-40",

    LG_W_48 = "lg:pinata-w-48",

    LG_W_4XL = "lg:pinata-w-4xl",

    LG_W_5 = "lg:pinata-w-5",

    LG_W_5_12 = "lg:pinata-w-5/12",

    LG_W_5_6 = "lg:pinata-w-5/6",

    LG_W_56 = "lg:pinata-w-56",

    LG_W_5XL = "lg:pinata-w-5xl",

    LG_W_6 = "lg:pinata-w-6",

    LG_W_6_12 = "lg:pinata-w-6/12",

    LG_W_64 = "lg:pinata-w-64",

    LG_W_6XL = "lg:pinata-w-6xl",

    LG_W_7_12 = "lg:pinata-w-7/12",

    LG_W_8 = "lg:pinata-w-8",

    LG_W_8_12 = "lg:pinata-w-8/12",

    LG_W_9_12 = "lg:pinata-w-9/12",

    LG_W_AUTO = "lg:pinata-w-auto",

    LG_W_FULL = "lg:pinata-w-full",

    LG_W_LG = "lg:pinata-w-lg",

    LG_W_MD = "lg:pinata-w-md",

    LG_W_MODAL = "lg:pinata-w-modal",

    LG_W_NARROW = "lg:pinata-w-narrow",

    LG_W_PX = "lg:pinata-w-px",

    LG_W_SCREEN = "lg:pinata-w-screen",

    LG_W_SM = "lg:pinata-w-sm",

    LG_W_WIDE = "lg:pinata-w-wide",

    LG_W_WIDER = "lg:pinata-w-wider",

    LG_W_XL = "lg:pinata-w-xl",

    LG_W_XS = "lg:pinata-w-xs",

    MD_W_0 = "md:pinata-w-0",

    MD_W_1 = "md:pinata-w-1",

    MD_W_1_12 = "md:pinata-w-1/12",

    MD_W_1_2 = "md:pinata-w-1/2",

    MD_W_1_3 = "md:pinata-w-1/3",

    MD_W_1_4 = "md:pinata-w-1/4",

    MD_W_1_5 = "md:pinata-w-1/5",

    MD_W_1_6 = "md:pinata-w-1/6",

    MD_W_10 = "md:pinata-w-10",

    MD_W_10_12 = "md:pinata-w-10/12",

    MD_W_11_12 = "md:pinata-w-11/12",

    MD_W_12 = "md:pinata-w-12",

    MD_W_14 = "md:pinata-w-14",

    MD_W_16 = "md:pinata-w-16",

    MD_W_2 = "md:pinata-w-2",

    MD_W_2_12 = "md:pinata-w-2/12",

    MD_W_2_3 = "md:pinata-w-2/3",

    MD_W_2_4 = "md:pinata-w-2/4",

    MD_W_2_5 = "md:pinata-w-2/5",

    MD_W_2_6 = "md:pinata-w-2/6",

    MD_W_20 = "md:pinata-w-20",

    MD_W_24 = "md:pinata-w-24",

    MD_W_2XL = "md:pinata-w-2xl",

    MD_W_3 = "md:pinata-w-3",

    MD_W_3_12 = "md:pinata-w-3/12",

    MD_W_3_4 = "md:pinata-w-3/4",

    MD_W_3_5 = "md:pinata-w-3/5",

    MD_W_3_6 = "md:pinata-w-3/6",

    MD_W_32 = "md:pinata-w-32",

    MD_W_3XL = "md:pinata-w-3xl",

    MD_W_4 = "md:pinata-w-4",

    MD_W_4_12 = "md:pinata-w-4/12",

    MD_W_4_5 = "md:pinata-w-4/5",

    MD_W_4_6 = "md:pinata-w-4/6",

    MD_W_40 = "md:pinata-w-40",

    MD_W_48 = "md:pinata-w-48",

    MD_W_4XL = "md:pinata-w-4xl",

    MD_W_5 = "md:pinata-w-5",

    MD_W_5_12 = "md:pinata-w-5/12",

    MD_W_5_6 = "md:pinata-w-5/6",

    MD_W_56 = "md:pinata-w-56",

    MD_W_5XL = "md:pinata-w-5xl",

    MD_W_6 = "md:pinata-w-6",

    MD_W_6_12 = "md:pinata-w-6/12",

    MD_W_64 = "md:pinata-w-64",

    MD_W_6XL = "md:pinata-w-6xl",

    MD_W_7_12 = "md:pinata-w-7/12",

    MD_W_8 = "md:pinata-w-8",

    MD_W_8_12 = "md:pinata-w-8/12",

    MD_W_9_12 = "md:pinata-w-9/12",

    MD_W_AUTO = "md:pinata-w-auto",

    MD_W_FULL = "md:pinata-w-full",

    MD_W_LG = "md:pinata-w-lg",

    MD_W_MD = "md:pinata-w-md",

    MD_W_MODAL = "md:pinata-w-modal",

    MD_W_NARROW = "md:pinata-w-narrow",

    MD_W_PX = "md:pinata-w-px",

    MD_W_SCREEN = "md:pinata-w-screen",

    MD_W_SM = "md:pinata-w-sm",

    MD_W_WIDE = "md:pinata-w-wide",

    MD_W_WIDER = "md:pinata-w-wider",

    MD_W_XL = "md:pinata-w-xl",

    MD_W_XS = "md:pinata-w-xs",

    SM_W_0 = "sm:pinata-w-0",

    SM_W_1 = "sm:pinata-w-1",

    SM_W_1_12 = "sm:pinata-w-1/12",

    SM_W_1_2 = "sm:pinata-w-1/2",

    SM_W_1_3 = "sm:pinata-w-1/3",

    SM_W_1_4 = "sm:pinata-w-1/4",

    SM_W_1_5 = "sm:pinata-w-1/5",

    SM_W_1_6 = "sm:pinata-w-1/6",

    SM_W_10 = "sm:pinata-w-10",

    SM_W_10_12 = "sm:pinata-w-10/12",

    SM_W_11_12 = "sm:pinata-w-11/12",

    SM_W_12 = "sm:pinata-w-12",

    SM_W_14 = "sm:pinata-w-14",

    SM_W_16 = "sm:pinata-w-16",

    SM_W_2 = "sm:pinata-w-2",

    SM_W_2_12 = "sm:pinata-w-2/12",

    SM_W_2_3 = "sm:pinata-w-2/3",

    SM_W_2_4 = "sm:pinata-w-2/4",

    SM_W_2_5 = "sm:pinata-w-2/5",

    SM_W_2_6 = "sm:pinata-w-2/6",

    SM_W_20 = "sm:pinata-w-20",

    SM_W_24 = "sm:pinata-w-24",

    SM_W_2XL = "sm:pinata-w-2xl",

    SM_W_3 = "sm:pinata-w-3",

    SM_W_3_12 = "sm:pinata-w-3/12",

    SM_W_3_4 = "sm:pinata-w-3/4",

    SM_W_3_5 = "sm:pinata-w-3/5",

    SM_W_3_6 = "sm:pinata-w-3/6",

    SM_W_32 = "sm:pinata-w-32",

    SM_W_3XL = "sm:pinata-w-3xl",

    SM_W_4 = "sm:pinata-w-4",

    SM_W_4_12 = "sm:pinata-w-4/12",

    SM_W_4_5 = "sm:pinata-w-4/5",

    SM_W_4_6 = "sm:pinata-w-4/6",

    SM_W_40 = "sm:pinata-w-40",

    SM_W_48 = "sm:pinata-w-48",

    SM_W_4XL = "sm:pinata-w-4xl",

    SM_W_5 = "sm:pinata-w-5",

    SM_W_5_12 = "sm:pinata-w-5/12",

    SM_W_5_6 = "sm:pinata-w-5/6",

    SM_W_56 = "sm:pinata-w-56",

    SM_W_5XL = "sm:pinata-w-5xl",

    SM_W_6 = "sm:pinata-w-6",

    SM_W_6_12 = "sm:pinata-w-6/12",

    SM_W_64 = "sm:pinata-w-64",

    SM_W_6XL = "sm:pinata-w-6xl",

    SM_W_7_12 = "sm:pinata-w-7/12",

    SM_W_8 = "sm:pinata-w-8",

    SM_W_8_12 = "sm:pinata-w-8/12",

    SM_W_9_12 = "sm:pinata-w-9/12",

    SM_W_AUTO = "sm:pinata-w-auto",

    SM_W_FULL = "sm:pinata-w-full",

    SM_W_LG = "sm:pinata-w-lg",

    SM_W_MD = "sm:pinata-w-md",

    SM_W_MODAL = "sm:pinata-w-modal",

    SM_W_NARROW = "sm:pinata-w-narrow",

    SM_W_PX = "sm:pinata-w-px",

    SM_W_SCREEN = "sm:pinata-w-screen",

    SM_W_SM = "sm:pinata-w-sm",

    SM_W_WIDE = "sm:pinata-w-wide",

    SM_W_WIDER = "sm:pinata-w-wider",

    SM_W_XL = "sm:pinata-w-xl",

    SM_W_XS = "sm:pinata-w-xs",

    XL_W_0 = "xl:pinata-w-0",

    XL_W_1 = "xl:pinata-w-1",

    XL_W_1_12 = "xl:pinata-w-1/12",

    XL_W_1_2 = "xl:pinata-w-1/2",

    XL_W_1_3 = "xl:pinata-w-1/3",

    XL_W_1_4 = "xl:pinata-w-1/4",

    XL_W_1_5 = "xl:pinata-w-1/5",

    XL_W_1_6 = "xl:pinata-w-1/6",

    XL_W_10 = "xl:pinata-w-10",

    XL_W_10_12 = "xl:pinata-w-10/12",

    XL_W_11_12 = "xl:pinata-w-11/12",

    XL_W_12 = "xl:pinata-w-12",

    XL_W_14 = "xl:pinata-w-14",

    XL_W_16 = "xl:pinata-w-16",

    XL_W_2 = "xl:pinata-w-2",

    XL_W_2_12 = "xl:pinata-w-2/12",

    XL_W_2_3 = "xl:pinata-w-2/3",

    XL_W_2_4 = "xl:pinata-w-2/4",

    XL_W_2_5 = "xl:pinata-w-2/5",

    XL_W_2_6 = "xl:pinata-w-2/6",

    XL_W_20 = "xl:pinata-w-20",

    XL_W_24 = "xl:pinata-w-24",

    XL_W_2XL = "xl:pinata-w-2xl",

    XL_W_3 = "xl:pinata-w-3",

    XL_W_3_12 = "xl:pinata-w-3/12",

    XL_W_3_4 = "xl:pinata-w-3/4",

    XL_W_3_5 = "xl:pinata-w-3/5",

    XL_W_3_6 = "xl:pinata-w-3/6",

    XL_W_32 = "xl:pinata-w-32",

    XL_W_3XL = "xl:pinata-w-3xl",

    XL_W_4 = "xl:pinata-w-4",

    XL_W_4_12 = "xl:pinata-w-4/12",

    XL_W_4_5 = "xl:pinata-w-4/5",

    XL_W_4_6 = "xl:pinata-w-4/6",

    XL_W_40 = "xl:pinata-w-40",

    XL_W_48 = "xl:pinata-w-48",

    XL_W_4XL = "xl:pinata-w-4xl",

    XL_W_5 = "xl:pinata-w-5",

    XL_W_5_12 = "xl:pinata-w-5/12",

    XL_W_5_6 = "xl:pinata-w-5/6",

    XL_W_56 = "xl:pinata-w-56",

    XL_W_5XL = "xl:pinata-w-5xl",

    XL_W_6 = "xl:pinata-w-6",

    XL_W_6_12 = "xl:pinata-w-6/12",

    XL_W_64 = "xl:pinata-w-64",

    XL_W_6XL = "xl:pinata-w-6xl",

    XL_W_7_12 = "xl:pinata-w-7/12",

    XL_W_8 = "xl:pinata-w-8",

    XL_W_8_12 = "xl:pinata-w-8/12",

    XL_W_9_12 = "xl:pinata-w-9/12",

    XL_W_AUTO = "xl:pinata-w-auto",

    XL_W_FULL = "xl:pinata-w-full",

    XL_W_LG = "xl:pinata-w-lg",

    XL_W_MD = "xl:pinata-w-md",

    XL_W_MODAL = "xl:pinata-w-modal",

    XL_W_NARROW = "xl:pinata-w-narrow",

    XL_W_PX = "xl:pinata-w-px",

    XL_W_SCREEN = "xl:pinata-w-screen",

    XL_W_SM = "xl:pinata-w-sm",

    XL_W_WIDE = "xl:pinata-w-wide",

    XL_W_WIDER = "xl:pinata-w-wider",

    XL_W_XL = "xl:pinata-w-xl",

    XL_W_XS = "xl:pinata-w-xs",

    XS_W_0 = "xs:pinata-w-0",

    XS_W_1 = "xs:pinata-w-1",

    XS_W_1_12 = "xs:pinata-w-1/12",

    XS_W_1_2 = "xs:pinata-w-1/2",

    XS_W_1_3 = "xs:pinata-w-1/3",

    XS_W_1_4 = "xs:pinata-w-1/4",

    XS_W_1_5 = "xs:pinata-w-1/5",

    XS_W_1_6 = "xs:pinata-w-1/6",

    XS_W_10 = "xs:pinata-w-10",

    XS_W_10_12 = "xs:pinata-w-10/12",

    XS_W_11_12 = "xs:pinata-w-11/12",

    XS_W_12 = "xs:pinata-w-12",

    XS_W_14 = "xs:pinata-w-14",

    XS_W_16 = "xs:pinata-w-16",

    XS_W_2 = "xs:pinata-w-2",

    XS_W_2_12 = "xs:pinata-w-2/12",

    XS_W_2_3 = "xs:pinata-w-2/3",

    XS_W_2_4 = "xs:pinata-w-2/4",

    XS_W_2_5 = "xs:pinata-w-2/5",

    XS_W_2_6 = "xs:pinata-w-2/6",

    XS_W_20 = "xs:pinata-w-20",

    XS_W_24 = "xs:pinata-w-24",

    XS_W_2XL = "xs:pinata-w-2xl",

    XS_W_3 = "xs:pinata-w-3",

    XS_W_3_12 = "xs:pinata-w-3/12",

    XS_W_3_4 = "xs:pinata-w-3/4",

    XS_W_3_5 = "xs:pinata-w-3/5",

    XS_W_3_6 = "xs:pinata-w-3/6",

    XS_W_32 = "xs:pinata-w-32",

    XS_W_3XL = "xs:pinata-w-3xl",

    XS_W_4 = "xs:pinata-w-4",

    XS_W_4_12 = "xs:pinata-w-4/12",

    XS_W_4_5 = "xs:pinata-w-4/5",

    XS_W_4_6 = "xs:pinata-w-4/6",

    XS_W_40 = "xs:pinata-w-40",

    XS_W_48 = "xs:pinata-w-48",

    XS_W_4XL = "xs:pinata-w-4xl",

    XS_W_5 = "xs:pinata-w-5",

    XS_W_5_12 = "xs:pinata-w-5/12",

    XS_W_5_6 = "xs:pinata-w-5/6",

    XS_W_56 = "xs:pinata-w-56",

    XS_W_5XL = "xs:pinata-w-5xl",

    XS_W_6 = "xs:pinata-w-6",

    XS_W_6_12 = "xs:pinata-w-6/12",

    XS_W_64 = "xs:pinata-w-64",

    XS_W_6XL = "xs:pinata-w-6xl",

    XS_W_7_12 = "xs:pinata-w-7/12",

    XS_W_8 = "xs:pinata-w-8",

    XS_W_8_12 = "xs:pinata-w-8/12",

    XS_W_9_12 = "xs:pinata-w-9/12",

    XS_W_AUTO = "xs:pinata-w-auto",

    XS_W_FULL = "xs:pinata-w-full",

    XS_W_LG = "xs:pinata-w-lg",

    XS_W_MD = "xs:pinata-w-md",

    XS_W_MODAL = "xs:pinata-w-modal",

    XS_W_NARROW = "xs:pinata-w-narrow",

    XS_W_PX = "xs:pinata-w-px",

    XS_W_SCREEN = "xs:pinata-w-screen",

    XS_W_SM = "xs:pinata-w-sm",

    XS_W_WIDE = "xs:pinata-w-wide",

    XS_W_WIDER = "xs:pinata-w-wider",

    XS_W_XL = "xs:pinata-w-xl",

    XS_W_XS = "xs:pinata-w-xs",

    W_0 = "pinata-w-0",

    W_1 = "pinata-w-1",

    W_1_12 = "pinata-w-1/12",

    W_1_2 = "pinata-w-1/2",

    W_1_3 = "pinata-w-1/3",

    W_1_4 = "pinata-w-1/4",

    W_1_5 = "pinata-w-1/5",

    W_1_6 = "pinata-w-1/6",

    W_10 = "pinata-w-10",

    W_10_12 = "pinata-w-10/12",

    W_11_12 = "pinata-w-11/12",

    W_12 = "pinata-w-12",

    W_14 = "pinata-w-14",

    W_16 = "pinata-w-16",

    W_2 = "pinata-w-2",

    W_2_12 = "pinata-w-2/12",

    W_2_3 = "pinata-w-2/3",

    W_2_4 = "pinata-w-2/4",

    W_2_5 = "pinata-w-2/5",

    W_2_6 = "pinata-w-2/6",

    W_20 = "pinata-w-20",

    W_24 = "pinata-w-24",

    W_2XL = "pinata-w-2xl",

    W_3 = "pinata-w-3",

    W_3_12 = "pinata-w-3/12",

    W_3_4 = "pinata-w-3/4",

    W_3_5 = "pinata-w-3/5",

    W_3_6 = "pinata-w-3/6",

    W_32 = "pinata-w-32",

    W_3XL = "pinata-w-3xl",

    W_4 = "pinata-w-4",

    W_4_12 = "pinata-w-4/12",

    W_4_5 = "pinata-w-4/5",

    W_4_6 = "pinata-w-4/6",

    W_40 = "pinata-w-40",

    W_48 = "pinata-w-48",

    W_4XL = "pinata-w-4xl",

    W_5 = "pinata-w-5",

    W_5_12 = "pinata-w-5/12",

    W_5_6 = "pinata-w-5/6",

    W_56 = "pinata-w-56",

    W_5XL = "pinata-w-5xl",

    W_6 = "pinata-w-6",

    W_6_12 = "pinata-w-6/12",

    W_64 = "pinata-w-64",

    W_6XL = "pinata-w-6xl",

    W_7_12 = "pinata-w-7/12",

    W_8 = "pinata-w-8",

    W_8_12 = "pinata-w-8/12",

    W_9_12 = "pinata-w-9/12",

    W_AUTO = "pinata-w-auto",

    W_FULL = "pinata-w-full",

    W_LG = "pinata-w-lg",

    W_MD = "pinata-w-md",

    W_MODAL = "pinata-w-modal",

    W_NARROW = "pinata-w-narrow",

    W_PX = "pinata-w-px",

    W_SCREEN = "pinata-w-screen",

    W_SM = "pinata-w-sm",

    W_WIDE = "pinata-w-wide",

    W_WIDER = "pinata-w-wider",

    W_XL = "pinata-w-xl",

    W_XS = "pinata-w-xs",

}