///-- THIS FILE IS GENERATED --///
export enum FlexDirection {

    LG_FLEX_COL = "lg:pinata-flex-col",

    LG_FLEX_COL_REVERSE = "lg:pinata-flex-col-reverse",

    LG_FLEX_ROW = "lg:pinata-flex-row",

    LG_FLEX_ROW_REVERSE = "lg:pinata-flex-row-reverse",

    MD_FLEX_COL = "md:pinata-flex-col",

    MD_FLEX_COL_REVERSE = "md:pinata-flex-col-reverse",

    MD_FLEX_ROW = "md:pinata-flex-row",

    MD_FLEX_ROW_REVERSE = "md:pinata-flex-row-reverse",

    SM_FLEX_COL = "sm:pinata-flex-col",

    SM_FLEX_COL_REVERSE = "sm:pinata-flex-col-reverse",

    SM_FLEX_ROW = "sm:pinata-flex-row",

    SM_FLEX_ROW_REVERSE = "sm:pinata-flex-row-reverse",

    XL_FLEX_COL = "xl:pinata-flex-col",

    XL_FLEX_COL_REVERSE = "xl:pinata-flex-col-reverse",

    XL_FLEX_ROW = "xl:pinata-flex-row",

    XL_FLEX_ROW_REVERSE = "xl:pinata-flex-row-reverse",

    XS_FLEX_COL = "xs:pinata-flex-col",

    XS_FLEX_COL_REVERSE = "xs:pinata-flex-col-reverse",

    XS_FLEX_ROW = "xs:pinata-flex-row",

    XS_FLEX_ROW_REVERSE = "xs:pinata-flex-row-reverse",

    FLEX_COL = "pinata-flex-col",

    FLEX_COL_REVERSE = "pinata-flex-col-reverse",

    FLEX_ROW = "pinata-flex-row",

    FLEX_ROW_REVERSE = "pinata-flex-row-reverse",

}